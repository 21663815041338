import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import BackgroundImageCtrl from '../../formcontrols/BackgroundImageCtrl';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import CssAiContentCtrl from './CssAiContentCtrl';

function CssBackgroundCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    //const [imageList, setImageList] = useState(sampleData());
    const [backgroundLayers, setBackgroundLayers] = useState(sampleData(widgetData));
    const [currBGLayerIndex, setCurrBGLayerIndex] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [widgetObj, setWidgetObj] = useState(widgetData);

    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function sampleData(tmpWidgetData) {
        var tmpList = [];
        
        var tmpBackgroundImage = getMergedStyleToBP(tmpWidgetData, 'backgroundImage');
        var tmpBackgroundSize = getMergedStyleToBP(tmpWidgetData, 'backgroundSize');
        var tmpBackgroundPosition = getMergedStyleToBP(tmpWidgetData, 'backgroundPosition');
        var tmpBackgroundRepeat = getMergedStyleToBP(tmpWidgetData, 'backgroundRepeat');
        var tmpBackgroundAttachment = getMergedStyleToBP(tmpWidgetData, 'backgroundAttachment');
        if (tmpBackgroundImage != '') {
            var backgroundList = getBackgroundImageArray(tmpBackgroundImage);
            for (var i = 0; i < backgroundList.length; i++) {
                if (backgroundList[i].indexOf('linear') == -1 || backgroundList[i].indexOf('radial') == -1 || backgroundList[i].indexOf('rgba') == -1) {
                    tmpList.push({ image: backgroundList[i], size: tmpBackgroundSize, position: tmpBackgroundPosition, repeat: tmpBackgroundRepeat, attachment: tmpBackgroundAttachment, width: '', height: '', left: '', top: '' });
                } else {
                    tmpList.push({ image: backgroundList[i], size: '', position: '', repeat: '', attachment: '', width: '', height: '', left: '', top: '' });
                }

            }
        }
        //tmpList.push({ image: 'linear-gradient(0deg, rgba(0,0,0,0.2) 100%, rgba(0,0,0,0.2) 100%)', size: '', position: '', repeat: '', attachment: '', width: '', height: '', left: '', top: '' });
        //tmpList.push({ image: 'url(http://localhost:3000/cdn/accountfiles/1/10293.webp)', size: 'cover', position: '', repeat: 'no-repeat', attachment: '', width: '', height: '', left: '', top: '' });
        return tmpList;
    }

    function removeBGLayer(index) {
        var tmpBackgroundLayers = [...backgroundLayers];
        var copiedItem = { ...tmpBackgroundLayers[index] };
        //remove index item

        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.hasOwnProperty('aiBGImages')) {
            if (tmpWidgetObj.aiBGImages != null) {
                tmpWidgetObj.aiBGImages.splice(index, 1);
            }
        }        
        setWidgetObj(tmpWidgetObj);

        tmpBackgroundLayers.splice(index, 1);
        setBackgroundLayers((...backgroundLayers) => { return tmpBackgroundLayers });
        sendMergedData(tmpBackgroundLayers);
    }

    function reOrderBGLayer(index, direction) {
        var tmpBackgroundLayers = [...backgroundLayers];
        var copiedItem = { ...tmpBackgroundLayers[index] };
        //remove index item 
        tmpBackgroundLayers.splice(index, 1);
        //insert index item 
        if (direction == 'Up') {
            tmpBackgroundLayers.splice(index - 1, 0, copiedItem);
        } else {
            if (tmpBackgroundLayers.length == 1) {
                tmpBackgroundLayers.push(copiedItem);
            } else {
                tmpBackgroundLayers.splice(index + 1, 0, copiedItem);
            }
            
        }        
        setBackgroundLayers((...backgroundLayers) => { return tmpBackgroundLayers });
        sendMergedData(tmpBackgroundLayers);
    }

    function newBackgroundLayer() {
        var tmpBackgroundLayers = [...backgroundLayers];
        tmpBackgroundLayers.push({ image: '', size: '', position: '', repeat: '', attachment: '', width: '', height: '', left: '', top: '' });
        setBackgroundLayers((...backgroundLayers) => { return tmpBackgroundLayers });
        if (widgetObj.aiBGImages != null) {
            var tmpWidgetObj = { ...widgetObj };
            tmpWidgetObj.aiBGImages.push({ variable: '', prompt: '', answer: '', accept: false });
            setWidgetObj(tmpWidgetObj);
        }
        //return { image: '', size: '', position: '', repeat: '', attachment: '', width: '', height: '', left: '', top: '' };
    }

    function getBackgroundImageArray(backgroundImage) {
        // get backgroundImageStyle
        let bgimg_style = backgroundImage,
            // count for parenthesis
            parenthesis = -1;

        // split background by characters...
        return bgimg_style.split('').reduce((str_to_split, character) => {
            // if opening parenthesis
            if (character === '(') {
                // if first opening parenthesis set parenthesis count to zero
                if (parenthesis === -1) {
                    parenthesis = 0;
                }
                // add 1 to parenthesis count
                parenthesis++;
            }
            // for closing parenthesis reduce parenthesis count by 1
            else if (character === ')') {
                parenthesis--;
            }
            else {
                // if current character is a comma and it is not inside a parenthesis, at a "split" character
                if (character === ',') {
                    if (parenthesis === 0) {
                        str_to_split += '||';
                        return str_to_split;
                    }
                }
            }

            // else keep the character
            str_to_split += character;

            return str_to_split;
        }, '')
            // split the resulting string by the split characters including whitespaces before and after to generate an array
            .split(/\s*\|\|\s*/);
    }




    function handleOpenColorSelector(){
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function getBackgroundType(bgLayer) {
        var bgType = 'Image';
        if (bgLayer.image.indexOf('linear') > -1 || bgLayer.image.indexOf('radial') > -1 || bgLayer.image.indexOf('rgba') > -1) {
            bgType = 'Color';
        }
        return bgType;
    }  

    function sendMergedData(tmpBackgroundLayers) {
        var tmpWidgetObj = { ...widgetObj };
        //background
        var delimeter = '';
        var tmpBackground = '';
        for (var i = 0; i < tmpBackgroundLayers.length; i++) {
            if (tmpBackgroundLayers[i].image != '') {
                tmpBackground += delimeter + tmpBackgroundLayers[i].image;
                delimeter = ', ';
            }

        }
        updateCssPropertyNoPush(tmpWidgetObj, 'backgroundImage', tmpBackground);

        delimeter = '';
        var tmpSize = '';
        for (var i = 0; i < tmpBackgroundLayers.length; i++) {
            if (tmpBackgroundLayers[i].size == 'custom') {
                tmpSize += delimeter + tmpBackgroundLayers[i].width + ' ' + tmpBackgroundLayers[i].height;
                delimeter = ', ';
            } else {
                if (tmpBackgroundLayers[i].size != '') {
                    tmpSize += delimeter + tmpBackgroundLayers[i].size;
                    delimeter = ', ';
                }
            }

        }
        updateCssPropertyNoPush(tmpWidgetObj, 'backgroundSize', tmpSize);

        delimeter = '';
        var tmpPosition = '';
        for (var i = 0; i < tmpBackgroundLayers.length; i++) {
            if (tmpBackgroundLayers[i].size == 'custom') {
                tmpPosition += delimeter + tmpBackgroundLayers[i].left + ' ' + tmpBackgroundLayers[i].top;
            } else {
                if (tmpBackgroundLayers[i].position != '') {
                    tmpPosition += delimeter + tmpBackgroundLayers[i].position;
                    delimeter = ', ';
                }
            }
        }
        updateCssPropertyNoPush(tmpWidgetObj, 'backgroundPosition', tmpPosition);

        delimeter = '';
        var tmpRepeat = '';
        for (var i = 0; i < tmpBackgroundLayers.length; i++) {
            if (tmpBackgroundLayers[i].repeat != '') {
                tmpRepeat += delimeter + tmpBackgroundLayers[i].repeat
                delimeter = ', ';
            }
        }
        updateCssPropertyNoPush(tmpWidgetObj, 'backgroundRepeat', tmpRepeat);

        delimeter = '';
        var tmpAttachment = '';
        for (var i = 0; i < tmpBackgroundLayers.length; i++) {
            if (tmpBackgroundLayers[i].attachment != '') {
                tmpAttachment += delimeter + tmpBackgroundLayers[i].attachment
                delimeter = ', ';
            }
        }
        updateCssPropertyNoPush(tmpWidgetObj, 'backgroundAttachment', tmpAttachment);


        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnBackgroundItemChange(returnValues, index) {
        var tmpBackgroundLayers = [ ...backgroundLayers ];
        //if (returnValues.image.indexOf('url'))
        //updateCssProperty('background', 'url(' + returnValues.image + ')');

        if (returnValues.image.indexOf("rgba(") > -1 || returnValues.image.indexOf("RGBA(") > -1) {
            if (returnValues.image.indexOf("linear") == -1) {
                returnValues.image = 'linear-gradient(0deg, ' + returnValues.image + ' 0%, ' + returnValues.image + ' 0%)';
                returnValues.repeat = 'no-repeat';
                returnValues.size = 'cover';
            }
        }

        tmpBackgroundLayers[index] = returnValues;
        setBackgroundLayers((...backgroundLayers) => { return tmpBackgroundLayers });
        sendMergedData(tmpBackgroundLayers);
    }

    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    function handleOpenBackgroundSettings(index) {
        var tmpBGLayer = backgroundLayers[index];
        //setCurrBGLayerIndex((...currBGLayerIndex) => { return index });
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Background Settings', component: (dialogType, setReturnValue) => <BackgroundImageCtrl background={tmpBGLayer} handlerReturnValue={dialogType, setReturnValue}></BackgroundImageCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleOnBackgroundItemChange(returnValues, index); } })
        setOpenDialog(true);
    }

    function updateCssPropertyNoPush(tmpWidgetObj, propertyName, value) {
        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
        }
        tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        return tmpWidgetObj;
    }

    function updateCssProperty(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
        }
        tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleAiBackgroundChange(value, index) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.aiBGImages[index] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleClipBackgroundToTextChange(value) {        
        if (value == "") {
            updateCssProperty('-webkit-background-clip', "");
            updateCssProperty('-webkit-text-fill-color', "");
        } else {
            updateCssProperty('-webkit-background-clip', "text");
            updateCssProperty('-webkit-text-fill-color', "transparent");
        }
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        setBackgroundLayers(sampleData(widgetData));
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Backgrounds</div>
                <div style={{backgroundColor:"white", padding:"5px"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 20px", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Image & gradient</div>
                        <div onClick={() => { newBackgroundLayer();}}><AddIcon className="fg-pg-property-sub-label"/></div>
                    </div>
                    <div id="bg-layer-list">
                        {backgroundLayers.map((bgLayer, index) => (
                            <>
                                <div key={'bgLayer_' + index} style={{ display: "grid", gridTemplateColumns: "30px 1fr 20px 20px 20px 20px 20px", gridGap: "5px", marginBottom: "5px" }}>
                                    <div style={{ background: bgLayer.image, backgroundSize: bgLayer.size, backgroundPosition: bgLayer.position, backgroundRepeat: bgLayer.repeat, backgroundAttachment: bgLayer.attachment }}></div>
                                    <div style={{ fontSize: "12px", paddingTop: "3px" }} onClick={() => { handleOpenBackgroundSettings(index); }} >{getBackgroundType(bgLayer)}</div>
                                    <div>{getBackgroundType(bgLayer) == 'Image' ? <img alt='' src="images/fg_ai_avatar_ma_profile_60_x_60.png" style={{ width: "20px", height: "20px", borderRadius: "50%", border:"1px solid #c0c0c0" }} /> : null}</div>
                                    <div><VisibilityIcon /></div>
                                    <div><DeleteIcon onClick={() => { removeBGLayer(index); } } /></div>
                                    <div><NorthIcon onClick={() => { reOrderBGLayer(index, 'Up'); }} /></div>
                                    <div><SouthIcon onClick={() => { reOrderBGLayer(index, 'Down'); }} /></div>
                                </div>
                                {getBackgroundType(bgLayer) == 'Image' ?
                                    (widgetData.hasOwnProperty('aiBGImages') ?
                                            (widgetData.aiBGImages != null ?
                                            <CssAiContentCtrl contentType="Image" aiImageData={widgetData.aiBGImages[index]} propertyChangeCallback={(value) => { handleAiBackgroundChange(value, index); }} />
                                                : null)
                                            : null
                                    )
                                    : null}
                            </>
                        ))}
                    </div>
                    {/*<BackgroundImageCtrl background={{ image: 'rgba(0,0,0,.1)', size: '', position: '', repeat: '', attachment: '', width: '', height: '', left: '', top: '' }} onDataChange={(backgroundInfo) => { handleOnBackgroundItemChange(backgroundInfo); }}></BackgroundImageCtrl>*/}
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr",marginBottom:"5px"}}>
                        <div className="fg-pb-property-sub-label" >Color</div>
                        <div><ColorFieldCtrl defaultColor={getMergedStyleToBP(widgetObj, 'backgroundColor')} onColorChange={(color) => { updateCssProperty('backgroundColor', color); }} hideColorLabel={false} hideControls={true}></ColorFieldCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Clipping</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" onChange={(e) => { handleClipBackgroundToTextChange(e.target.value)}}>
                                <option value="">None</option>
                                <option value="text">Clip background to text</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default CssBackgroundCtrl;

import React, { useContext, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';
import { BAChangeDefaultHeaders, BAFetchData } from '../customhooks/useBAFetch';

function Unsubscribe() {

    let params = useParams();
    BAChangeDefaultHeaders(params.baToken);

    function processEmailBroadcast() {
        var emailId = params.emailId;
        BAFetchData({
            callName: "Unsubscribe",
            method: "POST",
            url: "trk/event/unsubscribe?emailid=" + emailId,
            token: params.baToken,
            onSuccess: onAPISuccess, onError: onAPIError
        });

        //window.location = redir;
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "Unsubscribe":
                //window.location = packageObj.redir;
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
    }

    useEffect(() => {
        //processIncomingType();
    }, []);

    return (
        <>
            <div>Unsubscribe {params.baToken} {params.emailId}</div>
        </>
    );
}

export default Unsubscribe;


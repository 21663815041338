import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import LookupFieldCtrl from '../../formcontrols/LookupFieldCtrl';
import Forms from '../../../pages/Forms';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';


function CssFormInfoCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function openForms(actionType) {
        var msg = '';
        //setGenericDialogSchema({ title: 'Forms Search', component: (dialogType, setReturnValue) => <Forms handlerReturnValue={dialogType, setReturnValue} isLookup={true}></Forms>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleFormsSelected(returnValues, actionType); } })
        setGenericDialogSchema({ title: 'Form Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Forms handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Forms>,body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('FormSearch', returnValues); } })
        setOpenDialog(true);
    }
  

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "FormSearch":
                BAFetchData({
                    callName: "GetFormRecord",
                    method: "GET",
                    url: "marketing/form/getrecord?formid=" + returnValues.formId,
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetFormRecord':
                var tmpWidgetObj = { ...widgetObj };
                tmpWidgetObj.formId = data.formId;
                tmpWidgetObj.formName = data.formName;
                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Form Info</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Form Name</div>
                        <div>
                            <LookupFieldCtrl id="fgpbsegmentationform" key="fgpbsegmentationform" name="fgpbsegmentationform" dataPackage={widgetObj || ''} textFieldName="formName" valueFieldName="formId" onClick={() => { openForms('lookup'); }} onRemove={() => { handleOnPropertyChange('formId', null); handleOnPropertyChange('formName', null); }} style={{ border:"1px solid #ced4da",padding: "0px 0px 0px 3px"}}></LookupFieldCtrl>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Form Code</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.formCode} onChange={(e) => { handleOnPropertyChange('formCode', e.target.value); }} />
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );

}

export default CssFormInfoCtrl;


import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import { BAFetchData } from '../../customhooks/useBAFetch';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import { useGlobalState } from '../../globalstates/GState';

function BoardColumnCtrl({boardId, columns, onChange}) {

    const [currentUser] = useGlobalState("userInfo");

    const [columnList, setColumnList] = useState(columns);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const deleteIndex = useRef(null);

    function onValueChange(e, index, propertyName, value) {
        var tmpColumnList = [...columnList];
        tmpColumnList[index][propertyName] = value;
        if (tmpColumnList[index].internalState == 'unchanged') {
            tmpColumnList[index].internalState = 'modified';
        }
        onChange(e, tmpColumnList);

        setColumnList(tmpColumnList);
    }

    function handleOnColumnAddBtnClick(e) {
        var tmpColumnList = [...columnList];
        tmpColumnList.push(getNewColumnJson());
        onChange(e, tmpColumnList);
        setColumnList(tmpColumnList);
    }

    function getNewColumnJson() {
        return { columnId: "column_" + helpers.getUUID(), boardId: boardId, baid: 0, columnName: 'New Column', displayOrder: null, wipLimit: null, internalState:"added" };
    }

    function handleOnColumnRemoveBtnClick(e, index) {
        deleteIndex.current = index;
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "columnid", "ConditionOperator": "EQUAL", "Value": columnList[index].columnId, "DataType": "String" },
                ]
            }]
        };

        BAFetchData({
            callName: "CheckIfCardsExists",
            method: "GET",
            url: "pm/card/containscards?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });


    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "CheckIfCardsExists":
                if (data == true) {
                    var msg = "You cannot delete a column that contains cards.";
                    setGenericDialogSchema({ title: 'Column Delete Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
                    setOpenDialog(true);
                } else {
                    var tmpColumnList = [...columnList];
                    if (tmpColumnList[deleteIndex.current].internalState != 'added') {
                        tmpColumnList[deleteIndex.current].internalState = 'deleted';
                    } else {
                        tmpColumnList.splice(deleteIndex.current, 1);
                    }                    
                    onChange(null, tmpColumnList);
                    setColumnList(tmpColumnList);
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setColumnList(columns);
    }, [columns]);

    return (
        <>
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "25px 1fr 50px 25px", gridGap: "10px", width: "100%", marginBottom: "2px" }}>
                    <div>&nbsp;</div>
                    <div>Column/Status Name</div>
                    <div style={{ textAlign: "center" }}>Order</div>
                    {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                        <div style={{ textAlign: "center" }}>WIP</div>
                        : null}
                    <div>&nbsp;</div>
                </div>
            </div>
            {columnList != null ?
                <div>
                    {columnList.map((column, index) => (
                        column.internalState != 'deleted' ?
                        <div key={"board_column_" + index} style={{ display: "grid", gridTemplateColumns: "25px 1fr 60px  25px", gridGap: "10px", width: "100%", marginBottom: "10px" }}>
                            <div>
                                <DragIndicatorIcon />
                            </div>
                            <div>
                                    <input type="text" className="form-control form-control-sm" maxLength={15} value={column.columnName || ''} onChange={(e) => { onValueChange(e, index, 'columnName', e.target.value); }} />
                            </div>
                            <div>
                                    <input type="text" className="form-control form-control-sm" type="number" maxLength={2} value={column.displayOrder || ''} onChange={(e) => { onValueChange(e, index, 'displayOrder', e.target.value); }} />
                             </div>
                                {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                                    <div>
                                        <input type="text" className="form-control form-control-sm" maxLength={1} value={column.wipLimit || ''} onChange={(e) => { onValueChange(e, index, 'wipLimit', e.target.value); }} />
                                    </div>
                                    : null}
                            <div onClick={(e) => { handleOnColumnRemoveBtnClick(e, index); }}><DeleteIcon /></div>
                            </div>
                        : null
                    ))}
                </div>
                : null}
            <Button variant="outlined" color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { handleOnColumnAddBtnClick(e) }} >Add Column/Status</Button>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default BoardColumnCtrl;

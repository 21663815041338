import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Products from '../../pages/Products';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import helpers from '../../helpers/Helpers';
import dayjs from 'dayjs';


function TransactionDetailCtrl({ details, onChange, transactionType}) {

    const [detailsObj, setDetailsObj] = useState(details);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function openProductSearch() {
        var msg = '';
        setGenericDialogSchema({ title: 'Product Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Products handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Products>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetProductInfo', returnValues); } });
        setOpenDialog(true);
    }

    function onLineItemFieldBlur(e, index, propertyName, value) {
        var tmpDetailsObj = [...detailsObj];
        tmpDetailsObj[index][propertyName] = value;

        switch (propertyName) {
            case 'qty':
            case 'price':
                tmpDetailsObj[index].detailAmount = (tmpDetailsObj[index].price * tmpDetailsObj[index].qty).toFixed(2);
                break;
        }

        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "modified";
        }
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);

    }

    function onLineItemValueChange(e, index, propertyName, value) {
        var tmpDetailsObj = [...detailsObj];
        tmpDetailsObj[index][propertyName] = value;

        //switch (propertyName) {
        //    case 'qty':
        //    case 'price':
        //        tmpDetailsObj[index].detailAmount = (tmpDetailsObj[index].price * tmpDetailsObj[index].qty).toFixed(2);
        //        break;
        //}

        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "modified";
        }
        setDetailsObj(tmpDetailsObj);
    }



    function removeLineItem(e, index) {
        var tmpDetailsObj = [...detailsObj];
        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "deleted";
        } else {
            tmpDetailsObj.splice(index, 1);
        }
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);
    }

    function addLineItem(selectedProductInfo) {
        var tmpDetailsObj = [...detailsObj];
        var newLineItem = newLineItemShell();
        newLineItem.productId = selectedProductInfo.productId;
        newLineItem.variantId = selectedProductInfo.variantId;
        newLineItem.recurType = selectedProductInfo.recurType;
        newLineItem.payOption = selectedProductInfo.paymentOption;
        newLineItem.productName = selectedProductInfo.productName;       
        newLineItem.price = (selectedProductInfo.totalDueToday).toFixed(2);
        newLineItem.taxable = selectedProductInfo.taxable;
        newLineItem.transactionType = transactionType;
        if (selectedProductInfo.recurType != '') {
            if ("$" + newLineItem.price != selectedProductInfo.summary) {
                newLineItem.summary = selectedProductInfo.summary;
            }            
        }
        newLineItem.variantSummary = selectedProductInfo.variantSummary;
        newLineItem.detailAmount = (newLineItem.price * newLineItem.qty).toFixed(2);

        var useRecurInfo = false;
        if (selectedProductInfo.recurType == 'SubscriptionOnly-Static' && selectedProductInfo.paymentOption == 'option1') {
            useRecurInfo = true;
        } else if (selectedProductInfo.recurType == 'Subscription-Static' && selectedProductInfo.paymentOption == 'option2') {
            useRecurInfo = true;
        } else if (selectedProductInfo.recurType == 'SubscriptionOnly' && selectedProductInfo.paymentOption == 'option1') {
            useRecurInfo = true;
        } else if (selectedProductInfo.recurType == 'Subscription' && selectedProductInfo.paymentOption == 'option2') {
            useRecurInfo = true;
        }
        if (useRecurInfo == true) {
            newLineItem.downpayment = selectedProductInfo.recurInfo.downpayment;
            newLineItem.billLength = selectedProductInfo.recurInfo.billLength;
            newLineItem.billInterval = selectedProductInfo.recurInfo.billInterval;
            newLineItem.billPeriod = selectedProductInfo.recurInfo.billPeriod;
            newLineItem.billInstallment = selectedProductInfo.recurInfo.billInstallment;

            newLineItem.freeTrialInterval = selectedProductInfo.recurInfo.freeTrialInterval;
            newLineItem.freeTrialPeriod = selectedProductInfo.recurInfo.freeTrialPeriod;
            newLineItem.syncDay = selectedProductInfo.recurInfo.syncDay;
            newLineItem.prorate = selectedProductInfo.recurInfo.prorate;

            if (newLineItem.freeTrialInterval > 0) {
                newLineItem.nextBillDate = dayjs().add(newLineItem.freeTrialInterval, newLineItem.freeTrialPeriod);
            } else if (newLineItem.freeTrialInterval > 0) {
                newLineItem.nextBillDate = dayjs().add(newLineItem.billInterval, newLineItem.billPeriod);
            }
        }

        tmpDetailsObj.push(newLineItem);
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);
    }

    function newLineItemShell() {
        return {
            "detailId": 0, "headerId": 0, "baid": 0, "productId": null,
            "variantId": null, "productName": null, "variantSummary":null,"summary": null,
            "price": 0, "qty": 1, "taxable": false, "detailAmount": 0,
            "invoiceId": null, "paymentPlan": null, "posted": false,
            "datePosted": null, "cogsAmount": 0, "dateCreated": null,
            "createdBy": null, "dateModified": null, "modifiedBy": null,
            "recurringCharge": false, "proratedAmt": 0, "recurType":null,"payOption": null,
            "billInstallment": 0, "downpayment": 0, "billInterval": 0, "billPeriod": null,
            "billLength": 0, "freeTrialInterval": 0, "freeTrialPeriod": null,
            "syncDay": null, "prorate": false, "nextBillDate": null, "shippingCost": 0,
            "internalState":"added"
        }
    }

    function updateTotals() {

    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'GetProductInfo':
                addLineItem(returnValues);
                break;
        }        
    }

    useEffect(() => {
        setDetailsObj(details);
    }, [details]);

    return (
        <>
            <div style={{marginBottom:"20px"}}>
                <Button variant="outlined" onClick={(e) => { openProductSearch(); }}>Add</Button>
            </div>
            {detailsObj.map((detail, index) => (
                (detail.internalState != 'deleted' ?
                <div key={"transaction_detail_" + index} style={{marginBottom:"20px"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 60px 100px 65px 50px 95px 35px", gridGap: "15px" }}>
                        <div>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Product Name</div>
                            <div style={{fontWeight:"bold"}}>{detail.productName}</div>                            
                            {detail.variantSummary != null ?
                                <div style={{ fontSize: "12px", fontStyle: "italic" }}>{detail.variantSummary}</div>
                                : null}
                            <div><TextField fullWidth multiline maxRows={4} placeholder="Additional description" variant="standard" value={detail.summary} style={{ marginBottom: "16px", }} inputProps={{ min: 0, style: { textAlign: 'left',fontSize:"12px",lineHeight:"130%" } }} onChange={(e) => { onLineItemValueChange(e, index, 'summary', e.target.value); }} onBlur={(e) => { onLineItemFieldBlur(e, index, 'summary', e.target.value); }} /></div>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Taxable</div>
                            <div>{detail.taxable == true ? "Yes" : "No"}</div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Price</div>
                            <div><TextField id="price" fullWidth variant="standard" type="number" value={detail.price} style={{ marginBottom: "16px", }} inputProps={{ min: 0, style: { textAlign: 'right' } }} onChange={(e) => { onLineItemValueChange(e, index, 'price', e.target.value); }} onBlur={(e) => { onLineItemFieldBlur(e, index, 'price', e.target.value); }} /></div>
                        </div>

                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Shipping</div>
                            <div>{detail.shippingCost}</div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Qty</div>
                            <div><TextField id="qty" fullWidth variant="standard" type="number" style={{ marginBottom: "16px" }} inputProps={{ min: 0, style: { textAlign: 'right' } }} value={detail.qty} onChange={(e) => { onLineItemValueChange(e, index, 'qty', e.target.value); }} onBlur={(e) => { onLineItemFieldBlur(e, index, 'qty', e.target.value); }} /></div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Total</div>
                            <div>${helpers.formatNumber(detail.detailAmount, 2)}</div>                            
                        </div>
                        <div style={{ paddingTop: "0px",textAlign:"right" }}>
                            <DeleteIcon onClick={(e) => { removeLineItem (e, index)} } />
                        </div>
                    </div>
                    </div>
                : null)
            ))}
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default TransactionDetailCtrl;

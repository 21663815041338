import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import ViewOrderCtrl from './ViewOrderCtrl';
import ViewInvoiceCtrl from './ViewInvoicerCtrl';
import ViewPaymentCtrl from './ViewPaymentCtrl';
import ViewQuoteCtrl from './ViewQuoteCtrl';


function TransactionViewerCtrl({ transactionType, baToken, transactionToken}) {

    return (
        <>
            {transactionType == "order" ?
                <ViewOrderCtrl baToken={baToken} transactionToken={transactionToken} />
                : null}
            {transactionType == "invoice" ?
                <ViewInvoiceCtrl baToken={baToken} transactionToken={transactionToken} />
                : null}
            {transactionType == "payment" ?
                <ViewPaymentCtrl baToken={baToken} transactionToken={transactionToken} />
                : null}
            {transactionType == "quote" ?
                <ViewQuoteCtrl baToken={baToken} transactionToken={transactionToken} />
                : null}
        </>
    );
}

export default TransactionViewerCtrl;

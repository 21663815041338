import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';


function CssSizingCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [imageList, setImageList] = useState([{ name: "" }, { name: "" }]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [widgetObj, setWidgetObj] = useState(widgetData);

    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function handleOpenColorSelector() {
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    function parseValue(value) {       
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);
        console.log(tmpValue);
        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            console.log(value);
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnAlignChange(e, selectedAlign) {
        updateCssProperty("textAlign", selectedAlign);
    }

    function handleOnOverflowChange(e, selectedOverflow) {
        updateCssProperty("overflow", selectedOverflow);
    }

    

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Sizing</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr 50px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Width</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'width')} onDataChange={(width) => { updateCssProperty('width', width); }} /></div>
                        <div className="fg-pb-property-sub-label" style={{paddingLeft:"5px"}}>Height</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'height')} onDataChange={(height) => { updateCssProperty('height', height); }} /></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr 50px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Min W</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'minWidth')} onDataChange={(minWidth) => { updateCssProperty('minWidth', minWidth); }} /></div>
                        <div className="fg-pb-property-sub-label" style={{ paddingLeft: "5px" }}>Min H</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'minHeight')} onDataChange={(minHeight) => { updateCssProperty('minHeight', minHeight); }} /></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr 50px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Max W</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'maxWidth')} onDataChange={(maxWidth) => { updateCssProperty('maxWidth', maxWidth); }} /></div>
                        <div className="fg-pb-property-sub-label" style={{ paddingLeft: "5px" }}>Max H</div>
                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'maxHeight')} onDataChange={(maxHeight) => { updateCssProperty('maxHeight', maxHeight); }} /></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Overflow</div>
                        <div>
                            <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'overflow')} aria-label="align" onChange={handleOnOverflowChange} fullWidth>
                                <ToggleButton value="visible" title="visible" aria-label="tv" size="small">
                                    <VisibilityIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="hidden" title="hidden" aria-label="tv" size="small">
                                    <VisibilityOffIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="scroll" title="scroll" aria-label="tv" size="small">
                                    <HeightIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="auto" title="auto" aria-label="tv" size="small">
                                    <span>AUTO</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Fit</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'objectFit')} onChange={(e) => { updateCssProperty('objectFit', e.target.value); }}>
                                <option value="fill">Fill</option>
                                <option value="contain">Contain</option>
                                <option value="cover">Cover</option>
                                <option value="none">None</option>
                                <option value="scale-down">Scale Down</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssSizingCtrl;


import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

function PermissionsCtrl({ securityRoleId, roleFunctionMappings, onChange }) {


    const [roleFunctionMappingsObj, setRoleFunctionMappingsObj] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    function resetCategoryList(roleFunctionMappings) {
        var tmpList = [];
        for (var i = 0; i < roleFunctionMappings.length; i++) {
            if (tmpList.includes(roleFunctionMappings[i].category) == false) {
                tmpList.push(roleFunctionMappings[i].category);
            }
        }
        setCategoryList(tmpList);
    }


    function onCategoryChanged(value, category, index, propertyName, fPropertyName) {
        var tmpRoleFunctionMappingsObj = [...roleFunctionMappingsObj];
        for (var i = 0; i < tmpRoleFunctionMappingsObj.length; i++) {
            if (tmpRoleFunctionMappingsObj[i].category == category) {
                if (tmpRoleFunctionMappingsObj[i][fPropertyName] == true) {
                    tmpRoleFunctionMappingsObj[i][propertyName] = value;
                    tmpRoleFunctionMappingsObj[i].internalState = 'modified';
                }
            }
        }
        onChange(tmpRoleFunctionMappingsObj);
        setRoleFunctionMappingsObj(tmpRoleFunctionMappingsObj);  
    }

    function onFunctionChanged(value, category, index, propertyName,fPropertyName) {
        var tmpRoleFunctionMappingsObj = [...roleFunctionMappingsObj];
        //tmpRoleFunctionMappingsObj.filter((rfMap) => { return rfMap.category == category; })[index][propertyName] = value;
        var functionItem = tmpRoleFunctionMappingsObj.filter((rfMap) => { return rfMap.category == category; })[index];
        if (functionItem[fPropertyName] == true) {
            functionItem[propertyName] = value;
            functionItem.internalState = 'modified';
        }
        onChange(tmpRoleFunctionMappingsObj);
        setRoleFunctionMappingsObj(tmpRoleFunctionMappingsObj);  
    }

    function toggleGlobal(e, category, index) {
        var checked = false;
        if (e.target.innerText == 'CHECK ALL') {
            checked = true;
        }
        onCategoryChanged(checked, category, index, 'allowAdd', 'fAllowAdd');
        onCategoryChanged(checked, category, index, 'allowRead', 'fAllowRead');
        onCategoryChanged(checked, category, index, 'allowEdit', 'fAllowEdit');
        onCategoryChanged(checked, category, index, 'allowDelete', 'fAllowDelete');
        onCategoryChanged(checked, category, index, 'allow', 'fAllow');
    }

    function toggleRow(e, category, index) {
        var checked = false;
        if (e.target.innerText == 'CHECK ALL') {
            checked = true;
        }
        onFunctionChanged(checked, category, index, 'allowAdd','fAllowAdd');
        onFunctionChanged(checked, category, index, 'allowRead','fAllowRead');
        onFunctionChanged(checked, category, index, 'allowEdit','fAllowEdit');
        onFunctionChanged(checked, category, index, 'allowDelete','fAllowDelete');
        onFunctionChanged(checked, category, index, 'allow','fAllow');
    }

    useEffect(() => {
        if (roleFunctionMappings != undefined) {
            setRoleFunctionMappingsObj(roleFunctionMappings);
            resetCategoryList(roleFunctionMappings);
        }
    }, [roleFunctionMappings]);


    return (
        <>
            {roleFunctionMappings != undefined ?
                (
                    categoryList.map((category, index) => (
                        <>
                            <div style={{ backgroundColor:"#F5F5F5",padding:"5px",marginBottom:"5px"}}>
                                <div style={{ display: "grid", gridTemplateColumns:"250px 1fr 120px 50px 50px 50px 50px 50px", fontSize:"13px",color:"grey"}}>
                                    <div>Name</div>
                                    <div>Description</div>
                                    <div></div>
                                    <div style={{ textAlign: "center" }}>Add</div>
                                    <div style={{ textAlign: "center" }}>View</div>
                                    <div style={{ textAlign: "center" }}>Edit</div>
                                    <div style={{ textAlign: "center" }}>Del</div>
                                    <div style={{ textAlign: "center" }}>Allow</div>
                                {/*</div>*/}
                                {/*<div style={{ display: "grid", gridTemplateColumns:"250px 1fr 100px 30px 30px 30px 30px 30px", fontSize:"13px",color:"grey"}}>*/}
                                    <div style={{ fontSize: "18px", fontWeight: "bold", color:"#67757C"} }>{category}</div>
                                    <div></div>
                                    <div>
                                        <Button variant="outlined" fullWidth size="small" style={{ border: "1px solid grey", color: "grey" }} onClick={(e) => { toggleGlobal(e, category, index); (e.target.innerText == 'UNCHECK ALL') ? e.target.innerText = 'CHECK ALL' : e.target.innerText = 'UNCHECK ALL'; } }>
                                            Check All
                                        </Button>
                                    </div>
                                    <div style={{ textAlign: "center", paddingTop: "3px"}}><Switch size="small" onChange={(e) => { onCategoryChanged(e.target.checked, category, index, 'allowAdd','fAllowAdd'); }} /></div>
                                    <div style={{ textAlign: "center", paddingTop: "3px"}}><Switch size="small" onChange={(e) => { onCategoryChanged(e.target.checked, category, index, 'allowRead','fAllowRead'); }} /></div>
                                    <div style={{ textAlign: "center", paddingTop: "3px" }}><Switch size="small" onChange={(e) => { onCategoryChanged(e.target.checked, category, index, 'allowEdit','fAllowEdit'); }} /></div>
                                    <div style={{ textAlign: "center", paddingTop: "3px" }}><Switch size="small" onChange={(e) => { onCategoryChanged(e.target.checked, category, index, 'allowDelete','fAllowDelete'); }} /></div>
                                    <div style={{ textAlign: "center", paddingTop: "3px" }}><Switch size="small" onChange={(e) => { onCategoryChanged(e.target.checked, category, index, 'allow','fAllow'); }} /></div>
                                </div>
                            </div>
                            {roleFunctionMappings.filter((rfMap) => { return rfMap.category == category; }).map((rfMap, index) => (
                                <>
                                    <div style={{ display: "grid", gridTemplateColumns: "250px 1fr 120px 50px 50px 50px 50px 50px", fontSize: "13px", color: "grey", padding:"5px" }}>
                                        <div style={{ fontSize: "16px", color: "#67757C" }}>{rfMap.functionName}</div>
                                        <div style={{ fontSize: "16px", color: "#67757C" }}>{rfMap.description}</div>
                                        <div>
                                            <Button variant="outlined" fullWidth size="small" style={{ border: "1px solid grey", color: "grey" }} onClick={(e) => { toggleRow(e, category, index); (e.target.innerText == 'UNCHECK ALL') ? e.target.innerText = 'CHECK ALL' : e.target.innerText = 'UNCHECK ALL'; }}>
                                                Check All
                                            </Button>
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "3px" }}>
                                            {rfMap.fAllowAdd == true ?
                                                <Switch size="small" checked={rfMap.allowAdd} onChange={(e) => { onFunctionChanged(e.target.checked, category, index, 'allowAdd','fAllowAdd'); }} />
                                                : null}
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "3px" }}>
                                            {rfMap.fAllowRead == true ?
                                                <Switch size="small" checked={rfMap.allowRead} onChange={(e) => { onFunctionChanged(e.target.checked, category, index, 'allowRead','fAllowRead'); }} />
                                                : null}
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "3px" }}>
                                            {rfMap.fAllowEdit == true ?
                                                <Switch size="small" checked={rfMap.allowEdit} onChange={(e) => { onFunctionChanged(e.target.checked, category, index, 'allowEdit','fAllowEdit'); }} />
                                                : null}
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "3px" }}>
                                            {rfMap.fAllowDelete == true ?
                                                <Switch size="small" checked={rfMap.allowDelete} onChange={(e) => { onFunctionChanged(e.target.checked, category, index, 'allowDelete','fAllowDelete'); }} />
                                                : null}
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "3px" }}>
                                            {rfMap.fAllow == true ?
                                                <Switch size="small" checked={rfMap.allow} onChange={(e) => { onFunctionChanged(e.target.checked, category, index, 'allow','fAllow'); }} />
                                                : null}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                    ))
                )
            : null}
        </>
    );
}

export default PermissionsCtrl;

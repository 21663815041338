import React, { useContext, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';
import { BAChangeDefaultHeaders, BAFetchData } from '../customhooks/useBAFetch';

function DirectTraffic() {

    let params = useParams();
    const [searchParams] = useSearchParams();
    console.log("Search Params: ",searchParams.get('type'));
    BAChangeDefaultHeaders("fg1234");

    function processIncomingType() {
        switch (searchParams.get('type')) {
            case 'emailbroadcast':
                processEmailBroadcast();
                break;
            
        }
    }

    function processEmailBroadcast() {
        var emailId = searchParams.get('emailid');
        var action = searchParams.get('action');
        var redir = searchParams.get('redir');
        BAFetchData({
            callName: "ProcessAction",
            method: "POST",
            url: "trk/event/emailtrackinglog?emailid=" + emailId + "&action=" + action,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: { "redir": redir}
        });

        //window.location = redir;
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "ProcessAction":
                window.location = packageObj.redir;
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
    }

    useEffect(() => {
        processIncomingType();
    }, []);

    return (
        <>
            <div></div>
        </>
    );
}

export default DirectTraffic;


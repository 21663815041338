import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';

import { DataBrokerContext} from "./PageBuilderCtrl";
import CssBackgroundCtrl from './csswidgets/CssBackgroundCtrl';
import CssBorderCtrl from './csswidgets/CssBorderCtrl';
import CssPaddingCtrl from './csswidgets/CssPaddingCtrl';
import CssMarginCtrl from './csswidgets/CssMarginCtrl';
import CssRadiusCtrl from './csswidgets/CssRadiusCtrl';
import CssTypographyCtrl from './csswidgets/CssTypographyCtrl';
import CssSizingCtrl from './csswidgets/CssSizingCtrl';
import CssLayoutCtrl from './csswidgets/CssLayoutCtrl';
import CssClassNameCtrl from './csswidgets/CssClassNameCtrl';
import CssInnerHtmlCtrl from './csswidgets/CssInnerHtmlCtrl';
import CssPositionCtrl from './csswidgets/CssPositionCtrl';
import CssImageCtrl from './csswidgets/CssImageCtrl';
import CssLinkInfo from './csswidgets/CssLinkInfo';
import CssFormFieldCtrl from './csswidgets/CssFormFieldCtrl';
import CssFormInfoCtrl from './csswidgets/CssFormInfoCtrl';
import CssSegmentationCtrl from './csswidgets/CssSegmentationCtrl';
import CssListSettingsCtrl from './csswidgets/CssListSettingsCtrl';
import CssGridChildCtrl from './csswidgets/CssGridChildCtrl';
import CssBoxShadowCtrl from './csswidgets/CssBoxShadowCtrl';
import CssBackgroundVideoCtrl from './csswidgets/CssBackgroundVideoCtrl';
import CssObjCustomIdCtrl from './csswidgets/CssObjCustomIdCtrl';
import CssSectionAiTrainingCtrl from './csswidgets/CssSectionAiTrainingCtrl';
import CssVideoSettingsCtrl from './csswidgets/CssVideoSettingsCtrl';
import InteractCtrl from './interactions/InteractCtrl';
import ReferenceListCtrl from './ReferenceListCtrl';
import CssDataSourceCtrl from './csswidgets/CssDataSourceCtrl';
import CssAddToCartInfoCtrl from './csswidgets/CssAddToCartInfoCtrl';
import CssDataSourceInfoCtrl from './csswidgets/CssDataSourceInfoCtrl';
import CssDataPaginationInfoCtrl from './csswidgets/CssDataPaginationInfoCtrl';
import CssCustomEventsCtrl from './csswidgets/CssCustomEventsCtrl';
import CssCustomAttributesCtrl from './csswidgets/CssCustomAttributesCtrl';
import CssMacroPresetCtrl from './csswidgets/CssMacroPresetCtrl';
import CssCursorCtrl from './csswidgets/CssCursorCtrl';
import CssLabelForCtrl from './csswidgets/CssLabelForCtrl';
import CssEventTrackingScripts from './csswidgets/CssTrackingScripts';


function CssEditorCtrl({ widgetData, propertyChangeCallback, handleEndAnimationSelection, actionElementId, referenceData }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables, siteInfo, setSiteInfo, selectedRefernece, setSelectedReference } = useContext(DataBrokerContext);

    const [id, setId] = useState(widgetData.id);
    const [panelTab, setPanelTab] = useState('Styles')

    function showActiveTab(tabName) {
        var className = '';
        if (panelTab == tabName) {
            className = 'fg-pb-panel-tab-active';
        }
        return className;
    }

    function changeTab(tabName) {
        setPanelTab(tabName);
    }

    function updateReferenceStyle(tmpReferenceObj) {
        var tmpSiteInfo = { ...siteInfo };
        tmpSiteInfo.styleList[tmpReferenceObj.id] = JSON.parse(JSON.stringify(tmpReferenceObj));
        setSiteInfo(tmpSiteInfo);
    }

    useEffect(() => {    
        if (widgetData.id != id) {
            setId(id);
        }
    }, [widgetData])

    return (
        <>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "5px", width: "100%", borderBottom: "1px solid #C0C0C0" }}>
                <div className={"fg-pb-panel-tab " + showActiveTab('Styles')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Styles'); }}>Styles</div>
                <div className={"fg-pb-panel-tab " + showActiveTab('Interact')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Interact'); }}>Interact</div>
                <div className={"fg-pb-panel-tab " + showActiveTab('References')} style={{ cursor: "pointer" }} onClick={() => { changeTab('References'); }}>References</div>
            </div>
            {panelTab == "Styles" ?
                (referenceData == undefined ?
                <div style={{ padding: "0px" }}>
                    <div className="fg-pg-panel-section-title" style={{ fontWeight: "500", color: "black", paddingTop: "0px", marginTop: "0px", textTransform: "capitalize", }}>{widgetData.elementName || ''} Properties</div>
                    {['video'].includes(widgetData.elementName) ?
                        <CssVideoSettingsCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssVideoSettingsCtrl>
                        : null}
                    <CssObjCustomIdCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssObjCustomIdCtrl>
                        {['collectionlistwrapper'].includes(widgetData.elementName) ?
                            <>
                                <CssDataSourceInfoCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssDataSourceInfoCtrl>
                                <CssDataPaginationInfoCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssDataPaginationInfoCtrl>
                            </>
                            : null}
                        {['repeater'].includes(widgetData.elementName) ?
                            <CssDataSourceCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssDataSourceCtrl>
                            : null}
                        <CssClassNameCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssClassNameCtrl>
                        <CssMacroPresetCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssMacroPresetCtrl>
                        {['fieldlabel'].includes(widgetData.elementName) ?
                            <CssLabelForCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssLabelForCtrl>
                            : null}
                    {['section'].includes(widgetData.elementName) ?
                        <CssSectionAiTrainingCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssSectionAiTrainingCtrl>
                        : null}
                    {['heading', 'text', 'fieldlabel', 'button'].includes(widgetData.elementName) ?
                        <CssInnerHtmlCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssInnerHtmlCtrl>
                        : null}
                    {['image'].includes(widgetData.elementName) ?
                        <CssImageCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssImageCtrl>
                        : null}
                        {['button','linkblock'].includes(widgetData.elementName) ?
                        <CssLinkInfo widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssLinkInfo>
                        : null}
                        {['button'].includes(widgetData.elementName) ?
                            <>
                                {widgetData.linkType == 'form_submit' ?
                                    <CssFormInfoCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssFormInfoCtrl>
                                    : null}
                                {widgetData.linkType == 'add_to_cart' ?
                                    <CssAddToCartInfoCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssAddToCartInfoCtrl>
                                    : null}
                            </>
                        : null}
                    {['field'].includes(widgetData.elementName) ?
                        <CssFormFieldCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssFormFieldCtrl>
                        : null}
                    {['list'].includes(widgetData.elementName) ?
                        <CssListSettingsCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssListSettingsCtrl>
                        : null}
                    <CssGridChildCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssGridChildCtrl>
                    <CssLayoutCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssLayoutCtrl>
                    <CssPositionCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssPositionCtrl>
                    <CssTypographyCtrl widgetData={widgetData} referenceData={referenceData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssTypographyCtrl>
                    <CssSizingCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssSizingCtrl>
                    <CssBackgroundCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssBackgroundCtrl>
                    {['section'].includes(widgetData.elementName) ?
                        <CssBackgroundVideoCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssBackgroundVideoCtrl>
                        : null}
                    <CssBorderCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssBorderCtrl>
                    <CssRadiusCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssRadiusCtrl>
                    <CssPaddingCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssPaddingCtrl>
                    <CssMarginCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssMarginCtrl>                    
                    <CssSegmentationCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssSegmentationCtrl>
                    <CssBoxShadowCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssBoxShadowCtrl>
                        <CssCustomEventsCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssCustomEventsCtrl>
                        <CssCustomAttributesCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssCustomAttributesCtrl>
                        <CssCursorCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssCursorCtrl>
                        <CssEventTrackingScripts widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }}></CssEventTrackingScripts>
                    </div>
                    : 
                    <>
                        <div style={{ padding: "10px 0px 10px 0px"}}>
                            <div style={{fontWeight:"bold",textAlign:"center"}}>{referenceData.id} - style</div>
                            <button type="button" className="btn btn-sm btn-primary" style={{ width: "100%" }} onClick={(e) => { setSelectedReference(null);}}>Close</button>
                        </div>
                        <CssGridChildCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssGridChildCtrl>
                        <CssLayoutCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssLayoutCtrl>
                        <CssPositionCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssPositionCtrl>
                        <CssTypographyCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssTypographyCtrl>                    
                        <CssSizingCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssSizingCtrl>
                        <CssBackgroundCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssBackgroundCtrl>
                        <CssBorderCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssBorderCtrl>
                        <CssRadiusCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssRadiusCtrl>
                        <CssPaddingCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssPaddingCtrl>
                        <CssMarginCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssMarginCtrl>
                        <CssBoxShadowCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssBoxShadowCtrl>                        
                        <CssCustomEventsCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssCustomEventsCtrl>                        
                        <CssCustomAttributesCtrl widgetData={referenceData} propertyChangeCallback={(tmpReferenceObj) => { updateReferenceStyle(tmpReferenceObj); }}></CssCustomAttributesCtrl>
                    </>
                    )

                : null}
            {panelTab == "Interact" ?
                <div style={{ padding: "0px" }}>
                    <div className="fg-pg-panel-section-title" style={{ fontWeight: "500", color: "black", paddingTop: "0px", marginTop: "0px", textTransform: "capitalize", }}>{widgetData.elementName || ''} Interactions</div>
                    <InteractCtrl widgetData={widgetData} propertyChangeCallback={(propertyName, value) => { propertyChangeCallback(propertyName, value); }} handleEndAnimationSelection={handleEndAnimationSelection} actionElementId={actionElementId}></InteractCtrl>
                </div>
                : null}
            {panelTab == "References" ?
                <div style={{ padding: "0px" }}>
                    <div className="fg-pg-panel-section-title" style={{ fontWeight: "500", color: "black", paddingTop: "0px", marginTop: "0px", textTransform: "capitalize", }}>Style References</div>
                    <ReferenceListCtrl></ReferenceListCtrl>
                </div>
                : null}
        </>
    );

}

export default CssEditorCtrl;
import helpers from "../../helpers/Helpers";


function buildAiConnector(
    tmpElements,
    sourceId,
    destId,
    branch,
    connectPosition
) {
    var sourceObj = tmpElements[sourceId];
    var destObj = tmpElements[destId];
    //Loop through parents and build outputs

    sourceObj.outputs[branch][destId] = {
        connectPosition: connectPosition,
        lineType: "solid",
    };
    destObj.inputs.push(sourceId);
}

function buildAiWebinarNurtureSequence(tmpElements,sourceId,firstPositionOverride,total,startingNuture) {
    var position = {top: tmpElements[sourceId].position.top,left: tmpElements[sourceId].position.left,};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left,};
    }

    var idList = [];
    var id = null;

    for (var i = 0; i < total; i++) {
        startingNuture++;

        if (i > 0) {
            position.top = position.top + 120;
            position.left = position.left - 200;
            id = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 day",position.top,position.left, null, null, null, null, null, 100);
            idList.push(id);
        }

        if (i == 0) {
            //position.top = position.top + 200;
            position.top = position.top + 115;
            position.left = position.left;
        } else {
            position.top = position.top;
            position.left = position.left + 200;
        }

        id = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Offer Msg " + startingNuture,position.top,position.left, null, null, null, null, null, 100);
        idList.push(id);
    }

    for (var i = 0; i < idList.length; i++) {
        if (i == 0) {
            buildAiConnector(tmpElements, sourceId, idList[i], "yes", "l-2");
        } else {
            if (tmpElements[idList[i]].autoName == "Email") {
                buildAiConnector(tmpElements, idList[i - 1], idList[i], "yes", "l-2");
            } else {
                buildAiConnector(tmpElements, idList[i - 1], idList[i], "yes", "t-4");
            }
        }
    }
    return idList[idList.length - 1];
}

function buildAiWebinarReminderSequence(tmpElements,sourceId,firstPositionOverride,total,startingNuture) {
    var position = {top: tmpElements[sourceId].position.top,left: tmpElements[sourceId].position.left,};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left,};
    }

    var idList = [];
    var id = null;

    for (var i = 0; i < total; i++) {
        startingNuture++;

        if (i > 0) {
            position.top = position.top + 115;
            position.left = position.left - 200;
            id = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 day",position.top,position.left, null, null, null, null, null, 100);
            idList.push(id);
        }

        if (i == 0) {
            position.top = position.top + 200;
            position.left = position.left;
        } else {
            position.top = position.top;
            position.left = position.left + 200;
        }

        id = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Reminder " + startingNuture,position.top,position.left, null, null, null, null, null, 100);
        idList.push(id);
    }

    for (var i = 0; i < idList.length; i++) {
        if (i == 0) {
            buildAiConnector(tmpElements, sourceId, idList[i], "yes", "t-2");
        } else {
            if (tmpElements[idList[i]].autoName == "Email") {
                buildAiConnector(tmpElements, idList[i - 1], idList[i], "yes", "l-2");
            } else {
                buildAiConnector(tmpElements, idList[i - 1], idList[i], "yes", "t-4");
            }
        }
    }
    return idList[idList.length - 1];
}

function buildAiWebinarSequence(
    tmpElements,
    sourceIdList,
    firstPositionOverride,
    offerInfo
) {
    var outputOptions = {};
    var tmpPosition = null;

    var position = {top: tmpElements[sourceIdList[0]].position.top,left: tmpElements[sourceIdList[0]].position.left};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left};
    }

    var idList = [];
    var id = null;

    //var grammer = "";
    //if (offerInfo.displayOrder == 1) { grammer = "st" };
    //if (offerInfo.displayOrder == 2) { grammer = "nd" };
    //if (offerInfo.displayOrder == 3) { grammer = "rd" };

    position.left = position.left + 0;
    var id01 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Optin_Page",null,"Webinar/Event Registration",position.top,position.left, null, null, null, null, null, 100);

    if (sourceIdList.length > 0) {
        for (var i = 0; i < sourceIdList.length; i++) {
            if (sourceIdList[i] != null) {
                buildAiConnector(tmpElements, sourceIdList[i], id01, "yes", "l-2");
            }
        }
    }

    position.left = position.left + 220;
    tmpPosition = JSON.parse(JSON.stringify(position));
    var newRegistrationPosition = JSON.parse(JSON.stringify(position));
    var id02 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Lead",null,"New Registration",position.top,position.left, null, null, null, null, null, 3);

    buildAiConnector(tmpElements, id01, id02, "yes", "l-2");

    position = JSON.parse(JSON.stringify(newRegistrationPosition));
    position.top = position.top + 200;
    var id20 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Registration Instructions",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id02, id20, "yes", "t-2");

    var id21 = buildAiWebinarReminderSequence(tmpElements, id20, null, 5, 0);
    //buildAiConnector(tmpElements, id21, id04, "yes", "b-0");

    position = JSON.parse(JSON.stringify(newRegistrationPosition));
    position.left = position.left + 220;
    var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,"Registration Thankyou",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id02, id03, "yes", "l-2");

    position.left = position.left + 220;
    var webinarPage = JSON.parse(JSON.stringify(position));
    var webinarPosition = JSON.parse(JSON.stringify(position));
    var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Content_Page",null,"Webinar/ Free Event Page",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id21, id04, "yes", "b-0");

    position.left = position.left + 220;
    var clickSalesPagePosition = JSON.parse(JSON.stringify(position));
    var id05 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Clickthrough",null,"Click to sales page",position.top,position.left, null, null, null, null, null, 3);

    buildAiConnector(tmpElements, id04, id05, "yes", "l-2");
    outputOptions["clickThrough01"] = id05;

    webinarPosition.left = webinarPosition.left;
    webinarPosition.top = webinarPosition.top + 300;
    var webinarEndedPosition = JSON.parse(JSON.stringify(webinarPosition));
    var id06 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Trigger",null,"Webinar Ended",webinarPosition.top,webinarPosition.left, null, null, null, null, null, 100);
    buildAiConnector(tmpElements, id04, id06, "yes", "t-2");

    //Replay routine
    position = JSON.parse(JSON.stringify(webinarEndedPosition));
    position.top = position.top + 220;
    var attendedWebinarPosition = JSON.parse(JSON.stringify(position));
    var id07 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Condition",null,"Attended Webinar",attendedWebinarPosition.top,attendedWebinarPosition.left, null, null, null, null, null, 20);
    buildAiConnector(tmpElements, id06, id07, "yes", "t-2");

    position = JSON.parse(JSON.stringify(attendedWebinarPosition));
    position.top = position.top + 220;
    position.left = position.left + 100;
    var id08 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 hour",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id07, id08, "yes", "t-2");

    position.top = position.top + 220;
    var id09 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Replay Msg",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id08, id09, "yes", "t-2");

    position = JSON.parse(JSON.stringify(attendedWebinarPosition));
    position.top = position.top + 220;
    position.left = position.left - 100;
    var id10 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 hour",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id07, id10, "no", "t-2");

    position.top = position.top + 220;
    var id11 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Replay Msg (No Show)",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id10, id11, "yes", "t-2");

    position.top = position.top + 200;
    position.left = position.left + 100;
    var id12 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Content_Page",null,"Webinar Replay",position.top,position.left, null, null, null, null, null, 100);
    buildAiConnector(tmpElements, id11, id12, "yes", "t-1");
    buildAiConnector(tmpElements, id09, id12, "yes", "t-3");

    //Offer nurture
    position = JSON.parse(JSON.stringify(webinarEndedPosition));
    position.left = position.left + 250;
    var id06_1 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 day",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id06, id06_1, "yes", "l-2");

    position.top = position.top - 100;
    position.left = position.left + 200;
    var id06_2 = buildAiWebinarNurtureSequence(tmpElements,id06_1,{ top: position.top, left: position.left },3,0);
    //buildAiConnector(tmpElements, id06_1, id06_2, "yes", "b-0");

    position.top = position.top + 150;
    position.left = position.left + 220;
    var id06_3 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Clickthrough",null,"Click to sales page",position.top,position.left, null, null, null, null, null, 10);

    buildAiConnector(tmpElements, id06_2, id06_3, "yes", "b-0");
    outputOptions["clickThrough02"] = id06_3;

    ////webinarEndedPosition.top = webinarPosition.top + 250;
    //webinarEndedPosition.left = webinarPosition.left + 600;
    //var id06_3 = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(), "Event", "Clickthrough", null, "Click to sales page", webinarEndedPosition.top, webinarEndedPosition.left);
    //buildAiConnector(tmpElements, id06_2, id06_3, "yes", "b-2");

    return outputOptions;
}

function buildAiCallBookingSequence(tmpElements,sourceIdList,firstPositionOverride,offerInfo) {
    var outputOptions = {};
    var tmpPosition = null;

    var position = {top: tmpElements[sourceIdList[0]].position.top,left: tmpElements[sourceIdList[0]].position.left};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left};
    }

    var idList = [];
    var id = null;

    //var grammer = "";
    //if (offerInfo.displayOrder == 1) { grammer = "st" };
    //if (offerInfo.displayOrder == 2) { grammer = "nd" };
    //if (offerInfo.displayOrder == 3) { grammer = "rd" };

    position.left = position.left + 0;
    var id01 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Optin_Page",null,"Calling Booking Registration",position.top,position.left, null, null, null, null, null, 100);
    if (sourceIdList.length > 0) {
        for (var i = 0; i < sourceIdList.length; i++) {
            if (sourceIdList[i] != null) {
                buildAiConnector(tmpElements, sourceIdList[i], id01, "yes", "l-2");
            }
        }
    }

    position.left = position.left + 220;
    tmpPosition = JSON.parse(JSON.stringify(position));
    var newRegistrationPosition = JSON.parse(JSON.stringify(position));
    var id02 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Lead",null,"New Appt.",position.top,position.left, null, null, null, null, null, 3);

    buildAiConnector(tmpElements, id01, id02, "yes", "l-2");

    position = JSON.parse(JSON.stringify(newRegistrationPosition));
    position.top = position.top + 200;
    var id20 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Appt. Instructions",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id02, id20, "yes", "t-2");

    var id21 = buildAiWebinarReminderSequence(tmpElements, id20, null, 5, 0);
    //buildAiConnector(tmpElements, id21, id04, "yes", "b-0");

    position = JSON.parse(JSON.stringify(newRegistrationPosition));
    position.left = position.left + 220;
    var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,"Booking Thankyou",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id02, id03, "yes", "l-2");

    position.left = position.left + 220;
    var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Content_Page",null,"Call",position.top,position.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id21, id04, "yes", "b-1");

    outputOptions["call"] = id04;

    return outputOptions;
}


function buildAiHighTicketSalesPageSequence(tmpElements,sourceIdList,firstPositionOverride,offerInfo,includeNoBranch,includeUpsell,includeDownsell) {
    var outputOptions = null;
    var webinarOutputOptions = buildAiWebinarSequence(tmpElements,sourceIdList,firstPositionOverride);
    var position = null;
    position = JSON.parse(
        JSON.stringify(tmpElements[webinarOutputOptions["clickThrough01"]].position)
    );
    position.left = position.left + 700;
    var callBookingOutputOptions = buildAiCallBookingSequence(tmpElements,[webinarOutputOptions["clickThrough01"],webinarOutputOptions["clickThrough02"]],position,offerInfo,false,false,false);

    position = JSON.parse(JSON.stringify(tmpElements[callBookingOutputOptions["call"]].position));
    position.left = position.left + 220;
    var id01 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Clickthrough",null,"Click through to Sales Page",position.top,position.left, null, null, null, null, null, 3);

    buildAiConnector(tmpElements,callBookingOutputOptions["call"],id01,"yes","l-2");

    position.left = position.left + 220;
    var salesOutputOptions = buildAiSalesPageSequence(tmpElements,[id01],position,offerInfo,false,false,false);

    return outputOptions;
}


function buildAiMidTicketSalesPageSequence(tmpElements,sourceIdList,firstPositionOverride,offerInfo,includeNoBranch,includeUpsell,includeDownsell) {
    var outputOptions = null;
    var webinarOutputOptions = buildAiWebinarSequence(tmpElements,sourceIdList,firstPositionOverride);
    var position = null;
    position = JSON.parse(JSON.stringify(tmpElements[webinarOutputOptions["clickThrough01"]].position));
    position.left = position.left + 700;
    var salesOutputOptions = buildAiSalesPageSequence(tmpElements,[webinarOutputOptions["clickThrough01"],webinarOutputOptions["clickThrough02"]],position,offerInfo,false,false,false);
    return outputOptions;
}

function getNewProductJson() {
    return { allocation: 0, sku: 0, name: null, price: null, profitMargin: null, payments: null, interval: null, intervalPeriod: null, purchaseDelay: null, churnRate: null, disputeRate: null, returnRate: null, chargeBackRate: null, chargeBackFee: null, leadTime: null };
}

export function buildAiSalesPageSequence(tmpElements,sourceIdList,firstPositionOverride,offerInfo,includeNoBranch,includeUpsell,includeDownsell) {
    var outputOptions = {};
    var position = {top: tmpElements[sourceIdList[0]].position.top,left: tmpElements[sourceIdList[0]].position.left,};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left,};
    }

    var idList = [];
    var id = null;

    var grammer = "";
    if (offerInfo.displayOrder == 1) {grammer = "st";}
    if (offerInfo.displayOrder == 2) {grammer = "nd";}
    if (offerInfo.displayOrder == 3) {grammer = "rd";}

    position.left = position.left + 0;
    var id01 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Checkout_Page",null,offerInfo.displayOrder + grammer + " Offer",position.top,position.left, null, null, null, null, null, 100);

    if (sourceIdList.length > 0) {
        for (var i = 0; i < sourceIdList.length; i++) {
            if (sourceIdList[i] != null) {
                buildAiConnector(tmpElements, sourceIdList[i], id01, "yes", "l-2");
            }
        }
    }

    position.left = position.left + 200;
    var id02 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Purchase",null,"Purchase " + offerInfo.displayOrder + grammer + " Offer",position.top,position.left, null, null, null, null, null, 3);
    tmpElements[id02].products.push(getNewProductJson());
    tmpElements[id02].products[0].allocation = 100;
    tmpElements[id02].products[0].sku = "100";
    tmpElements[id02].products[0].name = offerInfo.offerName;
    tmpElements[id02].products[0].price = offerInfo.price;
    tmpElements[id02].products[0].profitMargin = 80;
    tmpElements[id02].products[0].payments = 1;


    buildAiConnector(tmpElements, id01, id02, "yes", "l-2");

    if (includeUpsell == false) {
        position.left = position.left + 200;
        var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,offerInfo.displayOrder + grammer + " Offer Thankyou",position.top,position.left, null, null, null, null, null, 100);

        buildAiConnector(tmpElements, id02, id03, "yes", "l-2");
        outputOptions["thankyou"] = id03;
    } else if (includeUpsell == true) {
        position.left = position.left + 200;
        var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Checkout_Page",null,"Upsell Offer",position.top,position.left, null, null, null, null, null, 3);

        buildAiConnector(tmpElements, id02, id03, "yes", "l-2");

        position.left = position.left + 200;
        position.top = position.top - 100;
        var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Purchase",null,"Purchase Upsell",position.top,position.left, null, null, null, null, null, 100);

        buildAiConnector(tmpElements, id03, id04, "yes", "l-2");

        position.left = position.left + 325;
        position.top = position.top + 100;
        var id05 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,"Order Confirmation",position.top,position.left, null, null, null, null, null, 100);

        buildAiConnector(tmpElements, id04, id05, "yes", "l-1");
        outputOptions["thankyou"] = id05;

        if (includeDownsell == false) {
            buildAiConnector(tmpElements, id03, id05, "no", "l-2");
        } else if (includeDownsell == true) {
            position.left = position.left - 350;
            position.top = position.top + 150;
            var id06 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Checkout_Page",null,"Downsell Offer",position.top,position.left, null, null, null, null, null, 3);

            buildAiConnector(tmpElements, id03, id06, "no", "l-0");

            position.left = position.left + 200;
            position.top = position.top + 30;
            var id07 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Purchase",null,"Purchase Downsell",position.top,position.left, null, null, null, null, null, 100);

            buildAiConnector(tmpElements, id06, id07, "yes", "l-2");
            buildAiConnector(tmpElements, id06, id05, "no", "l-2");
            buildAiConnector(tmpElements, id07, id05, "yes", "l-4");
        }
    }

    var tmpPosition = {
        top: tmpElements[id02].position.top,
        left: tmpElements[id02].position.left,
    };
    tmpPosition.top = tmpPosition.top + 225;
    var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,offerInfo.displayOrder + grammer + " Offer Order Confirmation",tmpPosition.top,tmpPosition.left, null, null, null, null, null, 100);

    buildAiConnector(tmpElements, id02, id04, "yes", "t-2");

    outputOptions["purchaseEmail"] = id04;

    if (includeNoBranch == true) {
        var tmpPosition = {
            top: tmpElements[id02].position.top,
            left: tmpElements[id02].position.left,
        };
        tmpPosition.top = tmpPosition.top + 200;
        tmpPosition.left = tmpPosition.left - 200;
        var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 day",tmpPosition.top,tmpPosition.left, null, null, null, null, null, 100);
        buildAiConnector(tmpElements, id02, id04, "no", "t-4");

        outputOptions["noPurchase"] = id04;
    }
    return outputOptions;
}


function buildAiEmailNurtureSequence(
    tmpElements,
    sourceId,
    total,
    startingNuture
) {
    var position = {
        top: tmpElements[sourceId].position.top,
        left: tmpElements[sourceId].position.left,
    };
    var idList = [];
    var id = null;
    for (var i = 0; i < total; i++) {
        startingNuture++;

        if (i > 0) {
            position.top = position.top + 150;
            id = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(),"AutoAction","Wait",null,"Wait 1 day",position.top,position.left, null, null, null, null, null, 100);
            idList.push(id);
        }

        position.top = position.top + 150;
        id = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"AutoAction","Email",null,"Nurture Msg " + startingNuture,position.top,position.left, null, null, null, null, null, 100);
        idList.push(id);
    }

    for (var i = 0; i < idList.length; i++) {
        if (i == 0) {
            buildAiConnector(tmpElements, sourceId, idList[i], "yes", "t-2");
        } else {
            buildAiConnector(tmpElements, idList[i - 1], idList[i], "yes", "t-2");
        }
    }
    return idList[idList.length - 1];
}

function buildAiOptinPageSequence(tmpElements,sourceIdList,firstPositionOverride,offerInfo1,includeTripwire,offerInfo2) {
    var outputOptions = {};
    var position = {top: tmpElements[sourceIdList[0]].position.top,left: tmpElements[sourceIdList[0]].position.left,};

    if (firstPositionOverride != null) {
        position = {top: firstPositionOverride.top,left: firstPositionOverride.left,};
    }

    var idList = [];
    var id = null;

    var grammer = "";
    if (offerInfo1.displayOrder == 1) {grammer = "st";}
    if (offerInfo1.displayOrder == 2) {grammer = "nd";}
    if (offerInfo1.displayOrder == 3) {grammer = "rd";}

    var grammer2 = "";
    if (offerInfo2 != undefined) {
        if (offerInfo2.displayOrder == 1) {grammer2 = "st";}
        if (offerInfo2.displayOrder == 2) {grammer2 = "nd";}
        if (offerInfo2.displayOrder == 3) {grammer2 = "rd";}
    }

    position.left = position.left + 0;
    var id01 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Optin_Page",null,offerInfo1.displayOrder + grammer + " Offer",position.top,position.left, null, null, null, null, null, 100);
    //buildAiConnector(tmpElements, sourceIdList[0], id01, "yes", "l-2");
    if (sourceIdList.length > 1) {
        for (var i = 0; i < sourceIdList.length; i++) {
            if (sourceIdList[i] != null) {
                buildAiConnector(tmpElements, sourceIdList[i], id01, "yes", "l-2");
            }
        }
    }

    position.left = position.left + 200;
    var id02 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Lead",null,"New Lead (" + offerInfo1.displayOrder + grammer + " Offer)",position.top,position.left, null, null, null, null, null, 3);

    buildAiConnector(tmpElements, id01, id02, "yes", "l-2");
    outputOptions["mainEvent"] = id02;

    if (includeTripwire == false) {
        position.left = position.left + 200;
        var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,offerInfo1.displayOrder + grammer + " Offer Thankyou",position.top,position.left, null, null, null, null, null, 100);

        buildAiConnector(tmpElements, id02, id03, "yes", "l-2");
        outputOptions["thankyou"] = id03;
    } else if (includeTripwire == true) {
        position.left = position.left + 200;
        var id03 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Checkout_Page",null,"Tripwire (" + offerInfo2.displayOrder + grammer + " Offer)",position.top,position.left, null, null, null, null, null, 100);

        buildAiConnector(tmpElements, id02, id03, "yes", "l-2");

        position.left = position.left + 200;
        var id04 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Event","Purchase",null,"Tripwire Purchase",position.top,position.left, null, null, null, null, null, 2);

        buildAiConnector(tmpElements, id03, id04, "yes", "l-2");

        position.left = position.left + 200;
        var id05 = newItemShortCut(tmpElements,"fgao_" + helpers.getUUID(),"Page","Thankyou_Page",null,"Tripwire Thankyou",position.top,position.left, null, null, null, null, null, 100);
        buildAiConnector(tmpElements, id04, id05, "yes", "l-1");
        outputOptions["thankyou"] = id05;
    }

    return outputOptions;
}

function pickIcon(autoType, autoName) {
    var icon = "";
    if (autoType == "TrafficSource") {
        if (autoName == "Facebook") {
            icon = "iconfacebook_off.png";
        } else if (autoName == "YouTube") {
            icon = "iconyoutube_off.png";
        } else if (autoName == "GoogleAdwords") {
            icon = "icongoogleadwords_off.png";
        } else if (autoName == "TikTok") {
            icon = "icontiktok_off.png";
        } else if (autoName == "Organic") {
            icon = "iconwordofmouth_off.png";
        }
    } else if (autoType == "Event") {
        if (autoName == "Clickthrough") {
            icon = "iconclick_off.png";
        } else if (autoName == "Lead") {
            icon = "iconcreateuser_off.png";
        } else if (autoName == "Purchase") {
            icon = "iconpurchase_off.png";
        } else if (autoName == "Trigger") {
            icon = "icontrigger_off.png";
        } else if (autoName == "PageClose") {
            icon = "iconclick_off.png";
        }
    } else if (autoType == "Page") {
        if (autoName == "Content_Page") {
            icon = "iconcontentpage_off.png";
        } else if (autoName == "Checkout_Page") {
            icon = "iconcheckoutpage_off.png";
        } else if (autoName == "Optin_Page") {
            icon = "iconoptinpage_off.png";
        } else if (autoName == "Thankyou_Page") {
            icon = "iconoptinthankyoupage_off.png";
        }
    } else if (autoType == "AutoAction") {
        if (autoName == "Email") {
            icon = "iconemail_off.png";
        } else if (autoName == "SMS") {
            icon = "iconsms_off.png";
        } else if (autoName == "Wait") {
            icon = "iconwait_off.png";
        } else if (autoName == "CreateTask") {
            icon = "iconcreatetask_off.png";
        } else if (autoName == "AddRemoveList") {
            icon = "iconaddremovelist_off.png";
        } else if (autoName == "AddRemoveTag") {
            icon = "iconaddremovetag_off.png";
        } else if (autoName == "Condition") {
            icon = "iconcondition_off.png";
        }
    }
    return icon;
}

export function createNewAutoItem(autoType) {
    var newItem = {};
    switch (autoType) {
        case "TrafficSource":
            newItem = {
                id: null,
                autoType: autoType,
                autoName: "Facebook",
                postType: "post",
                caption: "Traffic<br/>Source",
                dataPackageAlias: null,
                phraseId: null,
                pharseCode: null,
                objectSettings: null,
                triggerRules: [],
                pageInfo: null,
                typography: {},
                icon: "iconfacebook_off.png",
                width: "100",
                position: null,
                inputs: [],
                outputs: { yes: {}, no: {} },
                forecast: {
                    goalMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                    liveMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                },
                products: [],
            };
            break;
        case "Event":
            newItem = {
                id: null,
                autoType: autoType,
                autoName: "ClickThrough",
                postType: null,
                caption: "User<br/>Event",
                dataPackageAlias: null,
                phraseId: null,
                pharseCode: null,
                objectSettings: { type: null, settings: {} },
                triggerRules: [],
                pageInfo: null,
                typography: {},
                icon: "iconclick_off.png",
                width: "100",
                position: null,
                inputs: [],
                outputs: { yes: {}, no: {} },
                forecast: {
                    goalMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                    liveMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                },
                products: [],
            };
            break;
        case "Page":
            newItem = {
                id: null,
                autoType: autoType,
                autoName: "Optin_Page",
                postType: null,
                caption: "Optin",
                dataPackageAlias: null,
                phraseId: null,
                pharseCode: null,
                objectSettings: null,
                triggerRules: [],
                pageInfo: {"pageName":"","slug":""},
                typography: {},
                icon: "iconoptinpage_off.png",
                width: "100",
                position: null,
                inputs: [],
                outputs: { yes: {}, no: {} },
                forecast: {
                    goalMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                    liveMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                },
                products: [],
            };
            break;
        case "AutoAction":
            newItem = {
                id: null,
                autoType: autoType,
                autoName: "Email",
                postType: null,
                caption: "Auto<br/>Action",
                dataPackageAlias: null,
                phraseId: null,
                pharseCode: null,
                objectSettings: { type: null, settings: {} },
                triggerRules: [],
                pageInfo: null,
                typography: {},
                icon: "iconemail_off.png",
                width: "100",
                position: null,
                inputs: [],
                outputs: { yes: {}, no: {} },
                forecast: {
                    goalMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                    liveMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                },
                products: [],
            };
            break;
        case "Text":
            newItem = {
                id: null,
                autoType: autoType,
                autoName: "Text",
                postType: null,
                caption: "Text",
                dataPackageAlias: null,
                phraseId: null,
                pharseCode: null,
                objectSettings: null,
                triggerRules: [],
                pageInfo: null,
                typography: {
                    fontSize: "28",
                    fontWeight: "500",
                    alignText: "left",
                    hideBackground: "true",
                },
                icon: "iconemail_off.png",
                width: "500",
                position: null,
                inputs: [],
                outputs: { yes: {}, no: {} },
                forecast: {
                    goalMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                    liveMetric: {
                        budgetAllocation: 0,
                        budget: 0,
                        cpm: 0,
                        impressions: 0,
                        frequency: 0,
                        reach: 0,
                        lpCTR: 0,
                        conversions: 0,
                        lpViewCost: 0,
                        traffic: 0,
                        cvr: 0,
                    },
                },
                products: [],
            };
            break;
    }
    return JSON.parse(JSON.stringify(newItem));
}


function newItemShortCut(tmpElements,id,autoType,autoName,postType,caption,top,left,budgetAllocation,cpm,impressions,frequency,lpCTR,cvr) {
    var newAutoObj = createNewAutoItem(autoType);
    newAutoObj.id = id;
    newAutoObj.autoName = autoName;
    newAutoObj.postType = postType;
    newAutoObj.caption = caption;
    newAutoObj.icon = pickIcon(autoType, autoName);
    newAutoObj.position = { top: top, left: left };
    if (budgetAllocation != null) {
        newAutoObj.forecast.goalMetric.budgetAllocation = budgetAllocation;
    }
    if (cpm != null) {
        newAutoObj.forecast.goalMetric.cpm = cpm;
    }
    if (impressions != null) {
        newAutoObj.forecast.goalMetric.impressions = impressions;
    }
    if (frequency != null) {
        newAutoObj.forecast.goalMetric.frequency = frequency;
    }
    if (lpCTR != null) {
        newAutoObj.forecast.goalMetric.lpCTR = lpCTR;
    }
    if (cvr != null) {
        newAutoObj.forecast.goalMetric.cvr = cvr;
    }
    tmpElements[id] = newAutoObj;

    return id;
}


function buildAiTrafficSourceList(tmpElements, autoSurvey) {
    var tmpAutoSurvey = { ...autoSurvey };
    var offer1 = tmpAutoSurvey.valueLadder[0];
    var offer2 = tmpAutoSurvey.valueLadder[1];
    var offer3 = tmpAutoSurvey.valueLadder[2];
    var ad01 = null;
    var ad02 = null;
    var ad03 = null;
    var ad04 = null;
    var ad05 = null;
    if (parseFloat(autoSurvey.budget) == 0) {
        ad01 = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(), "TrafficSource", "YouTube", "Post", "YouTube Podcast Post", 200, 100, null, null, 100, 1, 1.5, null);
        ad02 = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(), "TrafficSource", "Facebook", "Post", "Facebook Reel Post", 400, 100, null, null, 100, 1, 1.5, null);

    } else if (parseFloat(autoSurvey.budget) <= 300) {
        ad01 = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(), "TrafficSource", "Facebook", "Ad", "Ad Campaign (Traffic/Reach)", 200, 100, 100, 15.00, null, 2, 1.5, null);
    } else if (parseFloat(autoSurvey.budget) > 300) {
        ad01 = newItemShortCut(tmpElements, "fgao_" + helpers.getUUID(), "TrafficSource", "Facebook", "Ad", "Ad Campaign", 200, 100, 100, 15.00, null, 2, 1.5, null);
        tmpElements[ad01].forecast.goalMetric.budgetAllocation = 100;
    }

    if (offer1 != undefined) {
        if (parseFloat(offer1.price) == 0) {
            var offerSeq1OutputOptions = null;
            var includeTripwire = false;
            if (offer2 != undefined && parseFloat(offer2.price) < 100) {
                includeTripwire = true;
            }
            offerSeq1OutputOptions = buildAiOptinPageSequence(
                tmpElements,
                [ad01, ad02],
                { top: 200, left: 300 },
                offer1,
                includeTripwire,
                offer2
            );
            if (offer2 != undefined) {
                var nurture01 = buildAiEmailNurtureSequence(
                    tmpElements,
                    offerSeq1OutputOptions["mainEvent"],
                    offer2.salesCycle,
                    0
                );
                var includeNoBranch = false;
                var includeUpsell = true;
                var includeDownsell = true;
                var offerSeq2OutputOptions = buildAiSalesPageSequence(
                    tmpElements,
                    [nurture01],
                    { top: 400, left: 700 },
                    offer2,
                    includeNoBranch,
                    includeUpsell,
                    includeDownsell
                );
                if (offer3 != undefined) {
                    var nurture02 = buildAiEmailNurtureSequence(
                        tmpElements,
                        offerSeq2OutputOptions["purchaseEmail"],
                        7,
                        0
                    );
                    var offerSeq3OutputOptions = buildAiSalesPageSequence(
                        tmpElements,
                        [nurture02],
                        { top: 800, left: 1100 },
                        offer3,
                        false,
                        false,
                        false
                    );
                }
            }
        } else if (
            parseFloat(offer1.price) > 0 &&
            parseFloat(offer1.price) < 100
        ) {
            var includeNoBranch = false;
            var includeUpsell = true;
            var includeDownsell = true;
            var offerSeq2OutputOptions = buildAiSalesPageSequence(
                tmpElements,
                [ad01, ad02],
                { top: 200, left: 300 },
                offer1,
                includeNoBranch,
                includeUpsell,
                includeDownsell
            );
        } else if (
            parseFloat(offer1.price) >= 100 &&
            parseFloat(offer1.price) < 999
        ) {
            var includeNoBranch = false;
            var includeUpsell = true;
            var includeDownsell = true;
            var offerSeq2OutputOptions = buildAiMidTicketSalesPageSequence(
                tmpElements,
                [ad01, ad02],
                { top: 200, left: 300 },
                offer1,
                includeNoBranch,
                includeUpsell,
                includeDownsell
            );
        } else if (parseFloat(offer1.price) >= 1000) {
            var offerSeq1OutputOptions = null;
            var includeNoBranch = false;
            var includeUpsell = false;
            var includeDownsell = false;
            //if (offer2 != undefined && offer2.price < 100) {
            //    includeTripwire = true;
            //}
            offerSeq1OutputOptions = buildAiHighTicketSalesPageSequence(
                tmpElements,
                [ad01, ad02],
                { top: 200, left: 300 },
                offer1,
                includeNoBranch,
                includeUpsell,
                includeDownsell
            );
        }
    }
}


export function buildAutomationWithAi(autoSurvey) {
    var tmpElements = {};
    buildAiTrafficSourceList(tmpElements, autoSurvey);
    //buildAiFirstOffer(tmpElements);
    // setElements(tmpElements);
    return tmpElements
}
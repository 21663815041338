import React, { useContext, useState, useEffect } from 'react';
import { BsGearFill } from "react-icons/bs";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import helpers from '../../helpers/Helpers';
import { BarChart } from '@mui/x-charts/BarChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';

function BarChartReportCtrl({ props }) {


    useEffect(() => {


    }, [])

    return (
        <>
            <div style={{ border: "1px solid whitesmoke", padding: "5px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "5px" }}>
                    <div></div>
                    <div style={{ textAlign: "center", color: "#808080", fontSize: "14px", fontWeight: "500", paddingTop: "3px" }}>{props.reportName}</div>
                    <div><BsGearFill className="nav-link-icon noclick" style={{ color: "#c0c0c0" }} /></div>
                </div>
                <div>                    
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: props.xAxisData,
                                scaleType: 'band',
                            },
                        ]}
                        series={[
                            {
                                data: props.seriesData,
                            },
                        ]}
                        height={props.height}
                        margin={{top:20,bottom:28}}

                    />
                </div>
            </div>
        </>
    );
}

export default BarChartReportCtrl;
import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import axios from "axios";

function CssVideoSettingsCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            


    function onVideoUrlChange(value) {
        var videoId = '';
        var videoUrl = '';
        var videoType = '';
        var thumbnailUrl = '';
        if (value != '') {
            if (value.indexOf('youtube.com') > -1) {
                videoId = value.split('?v=')[1];
                videoType = 'YouTube';
            } else if (value.indexOf('youtu.be') > -1) {
                videoId = value.split('.be/')[1];
                videoType = 'YouTube';
            }
            var videoUrl = 'https://www.youtube.com/embed/' + videoId;
            thumbnailUrl = 'https://i.ytimg.com/vi/' + videoId + '/maxresdefault.jpg';
            //testImage2(thumbnailUrl, videoId);
            //if ( == false) {
            //    thumbnailUrl = 'https://i.ytimg.com/vi/' + videoId + '/hqdefault.jpg';
            //}            
        }
        onValueChange('videoSettings', 'url', videoUrl);
        onValueChange('videoSettings', 'thumbnailUrl', thumbnailUrl);      
        onValueChange('videoSettings', 'videoType', videoType);        
    }


    function testImage2(URL, videoId) {
        fetch(URL, {method:'GET',redirect:'follow'})
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    function testImage(URL, videoId) {
        var tester = new Image();
        tester.onload = function (e) {
                var img = e.target;
                if (img.width < 200) {
                    //var thumbnailUrl = 'https://i.ytimg.com/vi/' + videoId + '/hqdefault.jpg';
                    //onValueChange('videoSettings', 'thumbnailUrl', thumbnailUrl);
                    onValueChange('videoSettings', 'thumbnailUrl', URL);
                } else {
                    onValueChange('videoSettings', 'thumbnailUrl', URL);
                }
            };
        tester.onerror = function () {
            alert('not loaded')
        };
        tester.setAttribute('src', '');
        tester.src = URL + '?v=' + (new Date()).getTime();
    }

    function imageExists() {
        //alert(img.width)
        alert('That image exists!');
    }

    function imageNotExists() {
        alert('That image does no exist!');
    }

    function onValueChange(propertyName1, propertyName2, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName1][propertyName2] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnIconChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function openFileManager() {
        var actionType = "FullPath";
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={true} iconOnly={false}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType) {
        onValueChange('videoSettings','thumbnailUrl',imgUrl);
    }

    function handleOnClassNameChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Video Settings</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Video Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.videoType} onChange={(e) => { onValueChange('videoSettings', 'videoType', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Vimeo">Vimeo</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Video Url</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.url} onChange={(e) => { onVideoUrlChange(e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Start At</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.startAt} onChange={(e) => { onValueChange('videoSettings', 'startAt', e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Auto Play</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.autoPlay} onChange={(e) => { onValueChange('videoSettings', 'autoPlay', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="True">True</option>
                                <option value="False">False</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Controls</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.showControls} onChange={(e) => { onValueChange('videoSettings', 'showControls', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="Show">Show Controls</option>
                                <option value="Hide">Hide Controls</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Related</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.hideRelated} onChange={(e) => { onValueChange('videoSettings', 'hideRelated', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="Show">Show Related Videos</option>
                                <option value="Hide">Hide Related Videos</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Icon</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                            <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.videoSettings.thumbnailUrl || ''} onChange={(e) => { onValueChange('videoSettings','thumbnailUrl',e.target.value); }} /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon /></div>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssVideoSettingsCtrl;

export function CheckImage(path) {
    axios
        .get(path)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}
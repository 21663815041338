import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import './css/kanbanctrl.css';
import Button from '@mui/material/Button';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KanbanBoardCtrl from './KanbanBoardCtrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import FilteringCtrl from '../formcontrols/FilteringCtrl';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGlobalState } from '../../globalstates/GState';

export const KanbanBrokerContext = createContext();

function KanbanCtrl({ projectId }) {

    const [currentUser] = useGlobalState("userInfo");
    const criteriaRef = useRef();
    let internalTimeout = null;
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [project, setProject] = useState({});
    const [boards, setBoards] = useState([]);
    const [cards, setCards] = useState({});

    const [myWorkCriteria, setMyWorkCriteria] = useState(false);

    const [filterFields, setFilterFields] = useState(
        [
            { "name": "title", "dataType": "string" },
            { "name": "cardType", "dataType": "string" },
            { "name": "completed", "dataType": "boolean" },
            { "name": "roadblock", "dataType": "boolean" },
            { "name": "archive", "dataType": "boolean" },
            { "name": "dealStatus", "dataType": "string" }
        ]
    );

    function updateDesignFilters(filteringStr) {
        alert(filteringStr);
        //var tmpDataSource = { ...dataSource };
        //tmpDataSource.designParameters = "filtering=" + filteringStr;
        //setDataSource(tmpDataSource);
    }

    function onMyWorkChanged(checked) {
        setMyWorkCriteria(checked);
    }

    function saveCard(cardObj, state) {
        var test = "test";
        test = "hello";
        switch (state) {
            case 'add':
                BAFetchData({
                    callName: "SaveCard", method: "POST", url: "pm/card/addsummary",
                    token: "fg1234", body: JSON.stringify(cardObj), onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'update':
                BAFetchData({
                    callName: "SaveCard", method: "PUT", url: "pm/card/updatesummary",
                    token: "fg1234", body: JSON.stringify(cardObj), onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'delete':
                BAFetchData({
                    callName: "SaveCard", method: "DELETE", url: "pm/card/delete?cardid=" + cardObj.cardId,
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }

    }

    function handleOnCriteriaChange(value) {
        criteriaRef.current = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginProductColumnSearch() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "projectid", "ConditionOperator": "EQUAL", "Value": projectId, "DataType": "String" },
                    ]
                }
            ]
        };

        if ((criteriaRef.current != null && criteriaRef.current != '') || (myWorkCriteria == true)) {
            filtering.ConditionGroups.push(
                {
                    "LogicalOperator": "or", "Conditions": []
                }
            )
        }

        if (criteriaRef.current != null && criteriaRef.current != '') {
            filtering.ConditionGroups[1].Conditions.push({ "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteriaRef.current, "DataType": "String" })
        }


        if (myWorkCriteria == true) {
            filtering.ConditionGroups[1].Conditions.push({ "Name": "bam.bamId", "ConditionOperator": "EQUAL", "Value": currentUser.currentBusinessAccountDTO.bamId, "DataType": "Number" })
        }



        BAFetchData({
            callName: "GetProjectRecord",
            method: "GET",
            url: "pm/project/getrecordwithboardinfo?projectid=" + projectId + '&filtering=' + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function beginSearch() {
        var queryStr = "";
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "projectid", "ConditionOperator": "EQUAL", "Value": projectId, "DataType": "String" },
                    ]
                }
            ]
        };

        if ((criteriaRef.current != null && criteriaRef.current != '')) {
            filtering.ConditionGroups.push(
                {
                    "LogicalOperator": "or", "Conditions": []
                }
            )
        }

        if (criteriaRef.current != null && criteriaRef.current != '') {
            filtering.ConditionGroups[1].Conditions.push({ "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteriaRef.current, "DataType": "String" })
        }

        queryStr += "filtering=" + JSON.stringify(filtering)

        if (myWorkCriteria == true) {
            queryStr += "&bamid=" + currentUser.currentBusinessAccountDTO.bamId;
            //filtering.ConditionGroups[1].Conditions.push({ "Name": "bamId", "ConditionOperator": "EQUAL", "Value": currentUser.currentBusinessAccountDTO.bamId, "DataType": "Number" })
        }

        BAFetchData({
            callName: "GetCardList",
            method: "GET",
            url: "pm/card/getlistwithmemberinfo?" + queryStr,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetProjectRecord":
                var tmpBoards = [];
                var tmpProject = {};
                for (var i = 0; i < data.projectBoardMaps.length; i++) {
                    tmpBoards.push(data.projectBoardMaps[i].board);
                }

                setProject({ projectId: data.projectId, projectName: data.projectName });
                setBoards(tmpBoards);
                break;
            case "GetCardList":
                var tmpCards = {};
                for (var i = 0; i < data.length; i++) {
                    tmpCards[data[i].cardId] = data[i];
                }
                setCards(tmpCards);
                setAllDataLoaded(true);
                break;
            case "SaveCard":
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    //useEffect(() => {
    //    if (allDataLoaded == false) {
    //        beginSearch();
    //    }
    //}, [boards]);

    useEffect(() => {
        //if (allDataLoaded == false) {
        beginProductColumnSearch();
        //}
    }, [cards]);


    useEffect(() => {
        beginSearch();
        //beginProductColumnSearch();
    }, []);

    useEffect(() => {
        beginSearch();
    }, [myWorkCriteria]);

    return (
        <>
            {allDataLoaded == true ?
                <KanbanBrokerContext.Provider value={{ cards: cards, setCards: setCards, boards: boards, setBoards: setBoards, projectId: projectId, handleOnSaveCard: (cardObj,state) => { saveCard(cardObj,state); } }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", backgroundColor: "#ffffff", padding: "7px 10px 7px 10px", borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "25px 1fr", gridGap: "5px", width: "100%", paddingTop: "5px" }}>
                            <div><SettingsIcon style={{ color: "#768387" }} /></div>
                            <div style={{ fontSize: "18px", color: "#768387" }}>{project.projectName}</div>
                        </div>

                        <div style={{ textAlign: "right" }}>
                            <Button variant="outlined" color="primary" size="medium" style={{ display:"none",color: "#ffffff", backgroundColor: "#5E95DE", marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} accessKey="n">New</Button>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 150px",gridGap:"10px" }}>
                            <div>
                                <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                            </div>
                            <div>
                                <FormControlLabel style={{ marginBottom: "10px" }}
                                    control={
                                        <Switch checked={myWorkCriteria} onChange={(e) => onMyWorkChanged(e.target.checked)} name="gilad" />
                                    }
                                    label="My Work"
                                />
                            </div>
                        </div>
                        <div>
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginProductColumnSearch(); }} style={{ width: "100%" }}>Search</button>
                        </div>
                    </div>
                    <div style={{padding:"10px",maxWidth:"650px",display:"none"}}>
                        <FilteringCtrl filterFields={filterFields} handleOnDone={(filteringStr) => { updateDesignFilters(filteringStr); }}></FilteringCtrl>
                    </div>
                    <div style={{ padding: "10px" }} >
                        {boards.length > 0 ?
                            boards.map((board, bi) => (
                                <KanbanBoardCtrl key={"board_" + bi} board={board} />
                            ))
                        : <div>This project does not contain any workflow boards. Go to Project Settings and add at least one workflow board.</div>}
                    </div>
                </KanbanBrokerContext.Provider>
            : null}
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default KanbanCtrl;




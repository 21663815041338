import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import Button from '@mui/material/Button';

function CssAiContentCtrl({ contentType, aiImageData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [aiImageInfo, setAiImageInfo] = useState(aiImageData);
    


    function handleOnValueChange(propertyName, value) {
        var tmpAiImageInfo = { ...aiImageInfo};
        tmpAiImageInfo[propertyName] = value;
        setAiImageInfo(tmpAiImageInfo);
        propertyChangeCallback(tmpAiImageInfo);
    }

  

    useEffect(() => {
        setAiImageInfo(aiImageData);
    }, [aiImageData])



    return (
        <>
             <div>                
                <div style={{ backgroundColor: "whitesmoke", padding: "10px", marginBottom: "10px" }}>
                    <h2 style={{ fontSize: "14px", fontWeight: "500" }}>Ai {contentType} Prompt</h2>
                    <div className="fg-pb-subsection-instruction">Enter a variable.</div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Variable</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={aiImageInfo.variable || ''} defaultValue={aiImageInfo.variable || ''} onChange={(e) => { handleOnValueChange('variable', e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Prompt</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={aiImageInfo.prompt || ''} defaultValue={aiImageInfo.prompt || ''} onChange={(e) => { handleOnValueChange('prompt',e.target.value); }} rows={4}></textarea>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">MA Answer</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={aiImageInfo.answer || ''} defaultValue={aiImageInfo.answer || ''} onChange={(e) => { handleOnValueChange('answer',e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">MA Accept</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={aiImageInfo.accept} onChange={(e) => { handleOnValueChange('accept',e.target.value); }}>
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "5px", gridGap:"10px" }}>
                        <div><Button fullWidth variant="outlined" color="primary" size="medium" style={{ backgroundColor: "#5E95DE", color: "white", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} >Prev</Button></div>
                        <div><Button fullWidth variant="outlined" color="primary" size="medium" style={{ backgroundColor: "#5E95DE", color: "white", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} >Next</Button></div>
                    </div>

                </div>
            </div>
        </>
    );

}

export default CssAiContentCtrl;


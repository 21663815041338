import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import Chip from '@mui/material/Chip';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';


function BoardMemberCtrl({ projectId,boardId, boardMembers, onChange }) {

    const [boardMemberList, setBoardMemberList] = useState(boardMembers);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    
    //function onValueChange(e, index, propertyName, value) {
    //    var tmpBoardListMap = [...boardListMap];
    //    tmpColumnList[index][propertyName] = value;
    //    if (tmpColumnList[index].internalState == 'unchanged') {
    //        tmpColumnList[index].internalState = 'modified';
    //    }
    //    onChange(e, tmpColumnList);

    //    setColumnList(tmpColumnList);
    //}

    function addMemberBtnClick(e) {
        openContactSearch();
    }

    //function addMember(e) {
    //    var tmpColumnList = [...columnList];
    //    tmpColumnList.push(getNewColumnJson());
    //    onChange(e, tmpColumnList);
    //    setColumnList(tmpColumnList);
    //}

    //function getNewColumnJson() {
    //    return { columnId: "column_" + helpers.getUUID(), boardId: boardId, baId: 0, columnName: 'New Column', displayOrder: null, wipLimit: null, internalState:"added" };
    //}

    function handleOnMemberRemoveBtnClick(e, index) {
        var tmpBoardMemberList = [...boardMemberList];
        switch (tmpBoardMemberList[index].internalState) {
            case 'added':
                tmpBoardMemberList.splice(index, 1);
                break;
            case 'unchanged':
                tmpBoardMemberList[index].internalState = 'deleted';
                break;
        }     
        setBoardMemberList(tmpBoardMemberList);
    }

    function openContactSearch() {
        var msg = '';
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetMemberInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "GetMemberInfo":
                var conditions = []
                conditions.push({ "Name": "contactId", "ConditionOperator": "EQUAL", "Value": returnValues, "DataType": "Number" })
                var filtering = {
                    "LogicalOperator": "and", "ConditionGroups": [{
                        "LogicalOperator": "or", "Conditions": conditions
                    }]
                };
                BAFetchData({
                    callName: "ContactSearch",
                    method: "GET",
                    url: "crm/contact/getlist?filtering=" + JSON.stringify(filtering),
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'ContactSearch':
                var tmpBoardMemberList = [...boardMemberList];
                for (var i = 0; i < data.length; i++) {
                    tmpBoardMemberList.push(getNewContactSchema(boardId, data[i]));
                }               
                onChange(tmpBoardMemberList);
                setBoardMemberList(tmpBoardMemberList);
                break;
        }
    }

    function getNewContactSchema(boardId, contactData) {
        var newRecord = {
            boardMemberId: helpers.getUUID(), baid: 0, projectId: projectId, boardId: boardId, contactId: contactData.contactId,
            contact: { contactName: contactData.contactName, profileImg: contactData.profileImg },
            internalState:"added"
        };

        return newRecord;
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setBoardMemberList(boardMembers);
    }, [boardMembers]);

    return (
        <>
            <div>
                {boardMemberList.map((boardMember, index) => (
                    boardMember.internalState != 'deleted' ?
                            <Chip key={"board_member_" + index} label={boardMember.contact.contactName} color="error" style={{ color: "#212529", backgroundColor: "#FFB22B", fontSize: "12px", height: "24px", borderRadius: "6px", marginRight: "5px" }} onDelete={(e) => { handleOnMemberRemoveBtnClick(e, index); }} />
                    : null                    
                ))}
                <Chip label="Add Member" color="error" style={{ color: "#212529", backgroundColor: "whitesmoke", fontSize: "12px", height: "24px", borderRadius: "6px", marginRight: "5px" }} onClick={(e) => { addMemberBtnClick(e)}} />
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default BoardMemberCtrl;

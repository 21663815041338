import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch, { fetchData } from '../../customhooks/useFetch';

function CreateAccountForm() {

    const [formData, setFormData] = useState({ firstName: null, lastName: null, email: null, phone: null, ccHolder: null, ccNumber: null, ccExpireDate: null, ccCVC:null});
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const navigate = useNavigate();
    const [status, setStatus] = useState("Open");


    useEffect(() => {
        const loggedInUser = localStorage.getItem("fg-user");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            navigate('/overview');
            //setUser(foundUser);
        }
    }, []);

    return (
        <>
            
            {status == "Open" ?
                <div>
                <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Create Account</div>
                    <div style={{ marginBottom: "40px", textAlign: "center" }}><small>Fill out form below to create your account.</small></div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextField id="firstName" autoFocus required fullWidth label="First Name" variant="outlined" size="medium" helperText={errorList['firstName'] ? <span style={{ color: "red" }}>{errorList['firstName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.firstName || ''} InputLabelProps={{ shrink: formData.firstName }} onChange={(e) => setFormData(formData => ({ ...formData, firstName: e.target.value }))} />
                                        <TextField id="lastName" required fullWidth label="Last Name" variant="outlined" size="medium" helperText={errorList['lastName'] ? <span style={{ color: "red" }}>{errorList['lastName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.lastName || ''} InputLabelProps={{ shrink: formData.lastName }} onChange={(e) => setFormData(formData => ({ ...formData, lastName: e.target.value }))} />
                                        <TextField id="email" required fullWidth label="Email" variant="outlined" size="medium" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value.toLowerCase() }))} />
                                        <TextField id="phone" required fullWidth type="First Name" autoComplete="current-phone" label="Phone" variant="outlined" size="medium" helperText={errorList['phone'] ? <span style={{ color: "red" }}>{errorList['phone']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.phone || ''} InputLabelProps={{ shrink: formData.phone }} onChange={(e) => setFormData(formData => ({ ...formData, phone: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <Button variant="contained" size="large" fullWidth onClick={() => { createAccount(); }}>CREATE ACCOUNT</Button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}

            {status == "Complete" ?
                <div>
                    <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Account Created</div>
                    <div style={{ marginBottom: "28px", textAlign: "center" }}>We sent your login credentials to the following email address... <span>{formData.email}</span>.</div>

                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "12px"}}>
                                    <div className="col-sm-12">
                                        <div style={{ marginBottom: "12px",textAlign:"center" }}>If the email above is incorrect click the button below to change it.</div>
                                        <Button variant="contained" size="large" fullWidth onClick={() => { changeEmail(); }}>Change Email</Button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}


            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );

    function changeEmail() {

    }

    function createAccount() {
        if (isCustomFormValidationValid() == true) {
            fetchData({
                callName: "CreateAccount",
                method: "POST",
                url: "security/businessaccount/createaccountcold?firstname=" + formData.firstName + "&lastname=" + formData.lastName + "&email=" + formData.email + "&phone=" + formData.phone,
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "CreateAccount":
                var isValid = true;
                var tempErrorList = {};
                if (data != null) {
                    if (data.contactId != null) {
                        setStatus("Complete");
                    }
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error.message;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function isCustomFormValidationValid() {
        var isValid = true;
        var tempErrorList = {};
        if (formData.firstName == null || formData.firstName == '') {
            isValid = false;
            tempErrorList['firstName'] = "First Name is required";
        }
        if (formData.lastName == null || formData.lastName == '') {
            isValid = false;
            tempErrorList['lastName'] = "Last Name is required";
        }
        if (formData.email == null || formData.email == '') {
            isValid = false;
            tempErrorList['email'] = "Email is required";
        }
        if (formData.phone == null || formData.phone == '') {
            isValid = false;
            tempErrorList['phone'] = "Phone is required";
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default CreateAccountForm;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';

import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';


import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGlobalState } from '../globalstates/GState';


//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function AccountMember({dialogType,handlerReturnValue}) {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [userData, setUserData] = useState({});
    const [securityRoles, setSecurityRoles] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.bamId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Account Member');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.bamId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-BusinessAccountMembers', ['allowDelete']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-BusinessAccountMembers', ['allowEdit']) == true) {
        headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), name: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];


    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data.businessAccountMember);
                setFormData(data.businessAccountMember);
                setUserData(data.user);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data.businessAccountMember));

                break;
            case "GetSecurityRoles":
                setSecurityRoles(data);
                break;
            case "SaveForm":
                setPrimaryKeyValue(data.bamInviteId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();} })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/segmentedlists'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "security/businessaccountmember/getformdata?bamid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });

                BAFetchData({
                    callName: "GetSecurityRoles",
                    method: "GET",
                    url: "security/securityrole/getlist?filtering={}",
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "security_business_account_members", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);


    var tmpKey = null;
    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment" style={{marginBottom:"12px"}}>
                                <div className="segment-title" style={{marginBottom:"0px"}}>Member Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div>{userData.firstName} {userData.lastName}</div>
                                            <div>{userData.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Position / Permissions</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="title"  required fullWidth label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => setFormData(formData => ({ ...formData, title: e.target.value }))} />
                                                    
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120,marginBottom:"16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Security Role</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.securityRoleId || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, securityRoleId: e.target.value }))}
                                                            label="Security Role"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {securityRoles.map(securityRole => {
                                                                tmpKey = securityRole.securityRoleName.replaceAll(" ", "_");
                                                                return <MenuItem key={tmpKey} value={securityRole.securityRoleId} >{securityRole.securityRoleName}</MenuItem>
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{errorList['securityRoleId'] ? <span style={{ color: "red" }}>{errorList['securityRoleId']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Member Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.memberType || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, memberType: e.target.value }))}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Client">Client</MenuItem>
                                                            <MenuItem value="Consultant">Consultant</MenuItem>
                                                            <MenuItem value="Staff">Staff</MenuItem>
                                                            <MenuItem value="Marketplace Vendor">Marketplace Vendor</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errorList['memberType'] ? <span style={{ color: "red" }}>{errorList['memberType']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.accountHolder || false} onChange={(e) => setFormData(formData => ({ ...formData, accountHolder: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Account Holder"
                                                    />                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Status</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active || false} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete invite. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "security/businessaccountmember/delete?bamid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }


    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }


    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "security/businessaccountmember/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "security/businessaccountmember/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.title == null || formData.title == '') {
            isValid = false;
            tempErrorList['title'] = "Is required.";
        }
        if (formData.memberType == null || formData.memberType == '') {
            isValid = false;
            tempErrorList['memberType'] = "Is required.";
        }
        if (formData.securityRoleId == null || formData.securityRoleId == '') {
            isValid = false;
            tempErrorList['securityRoleId'] = "Is required.";
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default AccountMember;
import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import { useGlobalState } from '../globalstates/GState';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function UserProfile({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");
    const [showPasswordSection, setShowPasswordSection] = useState(false);
    const [passwordObj, setPasswordObj] = useState({ password1: "", password2: "", status:"", match: "", statusGood:"green",statusBad:"red",matchGood:"green",matchBad:"red"});
    const navigate = useNavigate();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [timezoneList, setTimezoneList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(currentUser.userId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'User Profile');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (currentUser.userId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }

    headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
        }        
    }

    function getCountryList() {
        BAFetchData({
            callName: "GetCountryList",
            method: "GET",
            url: "location/country/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTimezoneList(countryCode) {
        var filtering = {};
        if (countryCode != null) {
            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "countrycode", "ConditionOperator": "EQUAL", "Value": countryCode, "DataType": "String" }
                    ]
                }]
            };
        }

        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":

                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getCountryList();
                getTimezoneList((data.countryCode != null ? data.countryCode : null));
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.userId);
                setFormData(data);
                var msg = 'You will need to log in again.';
                setGenericDialogSchema({ title: 'Profile Info Save', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); logout(); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'GetTimezoneList':
                setTimezoneList(data);
                break;
            case 'GetCountryList':
                setCountryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }

    function showPasswordField() {
        var tmpShowPasswordSection = showPasswordSection;
        if (tmpShowPasswordSection == true) {
            var tmpPasswordObj = { ...passwordObj };
            tmpPasswordObj.password1 = '';
            tmpPasswordObj.password2 = '';
            tmpPasswordObj.status = '';
            tmpPasswordObj.match = '';
            setPasswordObj(tmpPasswordObj);
        }
        setShowPasswordSection(!tmpShowPasswordSection);
    }

    async function onPasswordChange(propertyName, value) {
        var tmpPasswordObj = { ...passwordObj };
        tmpPasswordObj[propertyName] = value;
        if (propertyName == 'password1') {
            tmpPasswordObj.status = await helpers.passwordCheck(value);
        }        
        if ((tmpPasswordObj.password1 == '') && (tmpPasswordObj.password2 == '')) {
            tmpPasswordObj.match = '';
        } else {
            if (tmpPasswordObj.password1 != tmpPasswordObj.password2) {
                tmpPasswordObj.match = 'Passwords do not match';
            } else {
                tmpPasswordObj.match = 'Passwords match';
            }
        }
        setPasswordObj(tmpPasswordObj);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "security/user/getrecord?userid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "security_users", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Personal Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="firstName" required fullWidth label="First Name" variant="standard" helperText={errorList['firstName'] ? <span style={{ color: "red" }}>{errorList['firstName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.firstName || ''} InputLabelProps={{ shrink: formData.firstName }} onChange={(e) => setFormData(formData => ({ ...formData, firstName: e.target.value }))} />
                                                    <TextField id="lastName" required fullWidth label="Last Name" variant="standard" helperText={errorList['lastName'] ? <span style={{ color: "red" }}>{errorList['lastName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.lastName || ''} InputLabelProps={{ shrink: formData.lastName }} onChange={(e) => setFormData(formData => ({ ...formData, lastName: e.target.value }))} />
                                                    <TextField id="email" required fullWidth label="Email" variant="standard" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Login Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="userName" required fullWidth label="User Name" variant="standard" helperText={errorList['userName'] ? <span style={{ color: "red" }}>{errorList['userName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.userName || ''} InputLabelProps={{ shrink: formData.userName }} onChange={(e) => setFormData(formData => ({ ...formData, userName: e.target.value }))} />
                                                </div>
                                                <div style={{marginBottom:"10px"} }>
                                                    <Button variant="contained" size="small" onClick={() => { showPasswordField(); }}>Change Password</Button>
                                                </div>
                                                {showPasswordSection == true ?
                                                    <div className="col-sm-12">
                                                        <div>
                                                            <p>Password should meet these requirements:
                                                                <ul>
                                                                    <li>Legth between 8 and 15 characters.</li>
                                                                    <li>Contain at least 1 capital letter</li>
                                                                    <li>Contain at least 1 special character</li>
                                                                    <li>Contain at least 1 number</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div style={{ color: (passwordObj.status != 'Password is Strong' ? passwordObj.statusBad : passwordObj.statusGood ) }}>{passwordObj.status}</div>
                                                        <TextField id="password1" required fullWidth type="password" autoComplete='off' label="New Password" variant="standard" helperText={errorList['password1'] ? <span style={{ color: "red" }}>{errorList['password1']}</span> : ''} style={{ marginBottom: "16px" }} value={passwordObj.password1 || ''} InputLabelProps={{ shrink: passwordObj.password1 }} onChange={(e) => { onPasswordChange('password1', e.target.value); }} />
                                                        <div style={{ color: (passwordObj.match != 'Passwords match' ? passwordObj.matchBad : passwordObj.matchGood) }}>{passwordObj.match}</div>
                                                        <TextField id="password2" required fullWidth type="password" autoComplete='off' label="Confirm Password" variant="standard" helperText={errorList['password2'] ? <span style={{ color: "red" }}>{errorList['password2']}</span> : ''} style={{ marginBottom: "16px" }} value={passwordObj.password2 || ''} InputLabelProps={{ shrink: passwordObj.password2 }} onChange={(e) => { onPasswordChange('password2', e.target.value); }} />
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment" style={{marginTop:"20px"}}>
                                <div className="segment-title">Timezone</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.countryCode || ''}
                                                                onChange={(e) => { setFormData(formData => ({ ...formData, countryCode: e.target.value })); getTimezoneList(e.target.value); }}
                                                                label="Age"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {countryList.map((country, ci) => (
                                                                    <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                                                ))}

                                                            </Select>
                                                            <FormHelperText>{errorList['countryCode'] ? <span style={{ color: "red" }}>{errorList['countryCode']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Timezone</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.timezoneName || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, timezoneName: e.target.value }))}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {timezoneList.map((timezone, tzi) => (
                                                                <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>
                                                            ))}

                                                        </Select>
                                                        <FormHelperText>{errorList['timezoneName'] ? <span style={{ color: "red" }}>{errorList['timezoneName']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
        if (passwordObj.password1 != '' && passwordObj.password2 != '' && passwordObj.match == 'Passwords match') {
            formData.password = passwordObj.password1;
        }        
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "security/user/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "security/user/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default UserProfile;
import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { Editor } from '@tinymce/tinymce-react';
import QuizQuestionCtrl from './QuizQuestionCtrl';
import QuizQuestionListCtrl from './QuizQuestionListCtrl';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';

function CourseActivityCtrl({ courseId, moduleId, activityId, handlerReturnValue, handleDialogOnOK, handleCancelClick }) {

    const [primaryKeyValue, setPrimaryKeyValue] = useState(activityId);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [tabValue, setTabValue] = React.useState('Detail');

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [formData, setFormData] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});
    const [moduleList, setModuleList] = useState([]);
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    function getModuleList() {
        var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "courseid", "ConditionOperator": "EQUAL", "Value": courseId, "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetModuleList",
            method: "GET",
            url: "lms/module/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getRecordData() {
        var test = "";
        BAFetchData({
            callName: "GetMainRecord",
            method: "GET",
            url: "lms/activity/getrecord?activityid=" + primaryKeyValue,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleChangeEditor(editor) {
        var tmpFormData = { ...formData };
        tmpFormData.description = editor;
        setFormData((...formData) => { return tmpFormData });
    }

    function addDefaultData(data) {
        if (primaryKeyValue == "0") {
            //set default data
            data.courseId = courseId;
            data.status = "Draft";
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                data.questions = JSON.parse(data.questions);
                addDefaultData(data);
                setFormData(data);
                setQuestions(data.questions);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                setShowLoader(false);
                getModuleList();
                break;
            case "GetModuleList":
                setModuleList(data);
                break;
            case "SaveForm":
                handleDialogOnOK();
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });
                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
        }
    }

    function onAPIError(error, callName) {
        setShowLoader(false);
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function onHandleOKClick() {
        handleSaveForm();
    }

    function onHandleCancelClick() {
        handleCancelClick();
    }


    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }
    }

    function addJustInTimeData(formData) {
        //if (primaryKeyValue == 0) {
        //    formData.dateCreated = new Date().toISOString();
        //} else {
        //    formData.dateModified = new Date().toISOString();
        //}
    }

    function saveForm() {
        var tmpFormData = { ...formData };
        tmpFormData.questions = JSON.stringify(questions); 
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "lms/activity/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "lms/activity/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }



    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect(() => {
        handlerReturnValue(formData);
    }, [formData]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "lms_modules", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    useEffect(() => {
        getRecordData();
    }, []);

    var tmpKey = null;
    return (
        <>
            <div className="fg-app-page-box" style={{ width:"900px",maxWidth: "1200px", marginLeft: "0px", marginRight: "0px", paddingBottom: "0px", marginBottom: "0px" }}>
                {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                {formData.activityType == 'Quiz' ?
                    <div className="row">
                        <div className="col-sm-12" style={{ padding: "0px" }}>
                            <div>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                                    <Tabs onChange={handleTabChange}
                                        value={tabValue}
                                        variant="scroll"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        aria-label="scroll force tabs example">

                                        <Tab label="Detail" value="Detail" style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                        <Tab label="Questions" value="Questions" style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                    </Tabs>
                                </Box>
                            </div>
                        </div>
                    </div>
                    : null}
                <div className="row">
                    <div className="col-sm-12" style={{ padding: "0px" }}>
                        {tabValue == 'Detail' ?
                            <div className="segment">
                                <div className="segment-title" style={{ display: "none" }}>Module Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div>
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Activity Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={formData.activityType || ''}
                                                        onChange={(e) => setFormData(formData => ({ ...formData, activityType: e.target.value }))}
                                                        label="Activity Type"
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="Lesson">Lesson</MenuItem>
                                                        <MenuItem value="Live">Lize Class</MenuItem>
                                                        <MenuItem value="Quiz">Quiz</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errorList['activityType'] ? <span style={{ color: "red" }}>{errorList['activityType']}</span> : ''} </FormHelperText>
                                                </FormControl>
                                            </div>
                                            <TextField id="title" required fullWidth label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => setFormData(formData => ({ ...formData, title: e.target.value }))} />
                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                <InputLabel id="demo-simple-select-standard-label">Select Module</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={formData.moduleId || ''}
                                                    onChange={(e) => setFormData(formData => ({ ...formData, moduleId: e.target.value }))}
                                                    label="Select Module"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {moduleList.map((module, index) => {
                                                        tmpKey = module.moduleId;
                                                        return <MenuItem key={tmpKey} value={module.moduleId} >{module.title}</MenuItem>
                                                    })}
                                                </Select>
                                                <FormHelperText>{errorList['moduleId'] ? <span style={{ color: "red" }}>{errorList['moduleId']}</span> : ''} </FormHelperText>
                                            </FormControl>
                                            <div>
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Presentation Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={formData.presentationType || ''}
                                                        onChange={(e) => setFormData(formData => ({ ...formData, presentationType: e.target.value }))}
                                                        label="Presentation Type"
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="Text">Text</MenuItem>
                                                        <MenuItem value="Video">Video</MenuItem>
                                                        <MenuItem value="Audio">Audio</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errorList['presentationType'] ? <span style={{ color: "red" }}>{errorList['presentationType']}</span> : ''} </FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <TextField id="contentUrl" required fullWidth label="Content Url" variant="standard" helperText={errorList['contentUrl'] ? <span style={{ color: "red" }}>{errorList['contentUrl']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.contentUrl || ''} InputLabelProps={{ shrink: formData.contentUrl }} onChange={(e) => setFormData(formData => ({ ...formData, contentUrl: e.target.value }))} />
                                            </div>
                                            <Editor
                                                tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={formData.description}
                                                onEditorChange={handleChangeEditor}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    toolbar_mode: 'wrap',
                                                    autoresize_bottom_margin: 50,
                                                    plugins: [
                                                        'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic strikethrough forecolor blockquote | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | link image  media table emoticons | code codesample | searchreplace | preview fullscreen | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {tabValue == 'Questions' ?
                            <div className="segment">
                                <div className="segment-title" style={{ display: "none" }}>Questions</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div style={{ marginTop: "20px" }}>
                                                <TextField id="passingScore" required fullWidth label="Passing Score" variant="standard" helperText={errorList['passingScore'] ? <span style={{ color: "red" }}>{errorList['passingScore']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.passingScore || ''} InputLabelProps={{ shrink: formData.passingScore }} onChange={(e) => setFormData(formData => ({ ...formData, passingScore: e.target.value }))} />
                                            </div>
                                            <div>
                                                <QuizQuestionListCtrl questions={questions} setQuestions={setQuestions} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12" style={{ padding: "0px", textAlign: "right" }}>
                        <Button onClick={onHandleCancelClick} style={{ padding: "0px", margin: "0px", textAlign: "right" }}>Cancel</Button>
                        <Button onClick={onHandleOKClick} autoFocus style={{ minWidth: "40px", padding: "0px", margin: "0px", textAlign: "right" }}>OK</Button>
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default CourseActivityCtrl;

import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import AddIcon from '@mui/icons-material/Add';

import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';


import TextField from '@mui/material/TextField';
import { DataBrokerContext, PageBuilderVariablesContext } from '../PageBuilderCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';
import BorderCtrl from '../../formcontrols/BorderCtrl';

function CssBorderCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [borderType, setBorderType] = useState(determineBorderType());
    //const [measurementType, setMeasurementType] = useState(getMeasurementType());

    
    function removeProperty(tmpWidgetObj, propertyName) {
        if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
            delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
        }
    }

    function determineBorderType() {
        var border = getMergedStyleToBP(widgetObj, 'border');
        var borderTop = getMergedStyleToBP(widgetObj, 'borderTop');
        var borderRight = getMergedStyleToBP(widgetObj, 'borderRight');
        var borderBottom = getMergedStyleToBP(widgetObj, 'borderBottom');
        var borderLeft = getMergedStyleToBP(widgetObj, 'borderLeft');

        var tmpBorderType = '';
        if (getMergedStyleToBP(widgetObj, 'border') != '') {
            if (border == "unset") {
                tmpBorderType = 'Unset';
            } else if (border == "none") {
                tmpBorderType = 'None';
            } else {
                tmpBorderType = 'All';
            }
        } else if (getMergedStyleToBP(widgetObj, 'borderTop') != '' || getMergedStyleToBP(widgetObj, 'borderRight') != '' || getMergedStyleToBP(widgetObj, 'borderBottom') != '' || getMergedStyleToBP(widgetObj, 'borderLeft') != '') {
            if (borderTop == borderBottom && borderLeft == borderRight) {
                tmpBorderType = 'TopBottomLeftRight';
            } else {
                tmpBorderType = 'Custom';
            }
        }
        return tmpBorderType;
    }

    function handleBorderTypeChange(borderType) {
        var tmpWidgetObj = { ...widgetObj };
        if (borderType == "None") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'border');
                removeProperty(tmpWidgetObj, 'borderTop');
                removeProperty(tmpWidgetObj, 'borderRight');
                removeProperty(tmpWidgetObj, 'borderBottom');
                removeProperty(tmpWidgetObj, 'borderLeft');
            }
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint]['border'] = 'none';
            setBorderType(borderType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        } else if (borderType == "All") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderTop');
                removeProperty(tmpWidgetObj, 'borderRight');
                removeProperty(tmpWidgetObj, 'borderBottom');
                removeProperty(tmpWidgetObj, 'borderLeft');
            }
            setBorderType(borderType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (borderType == "Custom" || borderType == "TopBottomLeftRight") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'border');
            }
            setBorderType(borderType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (borderType == "Unset") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderTop');
                removeProperty(tmpWidgetObj, 'borderRight');
                removeProperty(tmpWidgetObj, 'borderBottom');
                removeProperty(tmpWidgetObj, 'borderLeft');
            }
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint]['border'] = 'unset';
            setBorderType(borderType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        }

    }

    function updateTopBottomLeftRight(targetBorderType, value, mt) {
        if (targetBorderType == "TopBottom") {
            updateCssProperty("borderTop", value + mt);
            updateCssProperty("borderBottom", value + mt);
        } else if (targetBorderType == "LeftRight") {
            updateCssProperty("borderLeft", value + mt);
            updateCssProperty("borderRight", value + mt);
        }
    }

    function removeByTargetBorderType(tmpWidgetObj, targetBorderType) {
        if (targetBorderType == "TopBottom") {
            removeProperty(tmpWidgetObj, "borderTop");
            removeProperty(tmpWidgetObj, "borderBottom");
        } else if (targetBorderType == "LeftRight") {
            removeProperty(tmpWidgetObj, "borderLeft");
            removeProperty(tmpWidgetObj, "borderRight");
        }
    }

    function handleOnPropertyChange(propertyName, value) {
        if (value == '') {
            var tmpWidgetObj = { ...widgetObj };
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                if (borderType != "TopBottomLeftRight") {
                    removeProperty(tmpWidgetObj, propertyName);
                } else {
                    if (propertyName == "borderTop" || propertyName == "borderBottom") {
                        removeByTargetBorderType(tmpWidgetObj, "TopBottom");
                    } else if (propertyName == "borderLeft" || propertyName == "borderRight") {
                        removeByTargetBorderType(tmpWidgetObj, "LeftRight");
                    }
                }
                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
            }
        } else if (Number(value) || value == 0) {
            if (borderType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value);
            } else {
                if (propertyName == "borderTop" || propertyName == "borderBottom") {
                    updateTopBottomLeftRight("TopBottom", value)
                } else if (propertyName == "borderLeft" || propertyName == "borderRight") {
                    updateTopBottomLeftRight("LeftRight", value);
                }
            }
        } else {
            if (borderType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value);
            } else {
                if (propertyName == "borderTop" || propertyName == "borderBottom") {
                    updateTopBottomLeftRight("TopBottom", value, "")
                } else if (propertyName == "borderLeft" || propertyName == "borderRight") {
                    updateTopBottomLeftRight("LeftRight", value, "");
                }
            }
        }
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        setBorderType(determineBorderType());
    }, [widgetData])

    useEffect(() => {
        if (borderType == 'None') {
            setBorderType(determineBorderType());
        }
    }, [borderType])


    return (
        <>
            <div>
                <div className="fg-pg-panel-sub-section">Border</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={borderType} onChange={(e) => { handleBorderTypeChange(e.target.value); }}>
                                <option value=""></option>
                                <option value="None">None</option>
                                <option value="All">All Sides</option>
                                <option value="TopBottomLeftRight">Top/Bottom & Left/Right</option>
                                <option value="Custom">Custom</option>
                            </select>
                        </div>
                    </div>
                    {borderType == "All" ?
                        <div style={{ marginBottom: "5px" }}>
                            <BorderCtrl propertyName="border" defaultBorder={getMergedStyleToBP(widgetObj, 'border')} onBorderChange={(border) => { handleOnPropertyChange('border', border); }} />
                        </div>
                        : null}
                    {(borderType == "Custom" || borderType == "TopBottomLeftRight") ?
                        <div>
                            <div style={{ marginBottom: "5px" }}>
                                <BorderCtrl propertyName="borderTop" defaultBorder={getMergedStyleToBP(widgetObj, 'borderTop')} onBorderChange={(borderTop) => { handleOnPropertyChange('borderTop', borderTop); }} />
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                                <BorderCtrl propertyName="borderRight" defaultBorder={getMergedStyleToBP(widgetObj, 'borderRight')} onBorderChange={(borderRight) => { handleOnPropertyChange('borderRight', borderRight); }} />
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                                <BorderCtrl propertyName="borderBottom" defaultBorder={getMergedStyleToBP(widgetObj, 'borderBottom')} onBorderChange={(borderBottom) => { handleOnPropertyChange('borderBottom', borderBottom); }} />
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                                <BorderCtrl propertyName="borderLeft" defaultBorder={getMergedStyleToBP(widgetObj, 'borderLeft')} onBorderChange={(borderLeft) => { handleOnPropertyChange('borderLeft', borderLeft); }} />
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export default CssBorderCtrl;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import ContactSearch from './ContactSearch';
import BoardColumnCtrl from '../components/formcontrols/BoardColumnCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import { Editor } from '@tinymce/tinymce-react';
import './css/apphtmleditor.css';
import { useGlobalState } from '../globalstates/GState';
import Badge from '@mui/material/Badge';

function SupportTicketSettings({ dialogType, handlerReturnValue, cardId, setAcceptAfterUpdate }) {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    //let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState(null);
    const [createdByName, setCreatedByName] = useState('');
    const [projectList, setProjectList] = useState([]);
    const [projectBoardMapList, setProjectBoardMapList] = useState([]);
    const [columnList, setColumnList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(cardId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);


    var filtering = {
        "LogicalOperator": "and", "ConditionGroups": [{
            "LogicalOperator": "and", "Conditions": [
                { "Name": "object", "ConditionOperator": "EQUAL", "Value": "automation_settings_page", "DataType": "String" },
                { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }]
        }]
    };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('automation_settings_page', 'header_section', 'Create Support Ticket');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (cardId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Archive'), name: "Archive", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleArchiveClick(); } },
                    { type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Roadblock'), name: "Roadblock", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRoadblockClick(); } },
                    { type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } }
                ]
            }
        )
    }
    headerOptions.elements.push({ type: "button", text: getTranslation('automation_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == '0') {
            data.cardMembers = [];
            data.projectId = '0';
            data.boardId = '0';
            data.columnId = '0';
            data.cardType = 'Incident';
            data.createdBy = currentUser.currentBusinessAccountDTO.bamId;
        }        
    }
    function getCreatedByName(data) {
        if (primaryKeyValue != '0') {
            BAFetchData({
                callName: "GetCreatedByName", method: "GET", url: "security/businessaccountmember/getmembername?bamid=" + data.createdBy,
                token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getProjectListData();
                getBoardListData(data.projectId);
                getColumnListData(data.boardId);
                getCreatedByName(data);
                break;
            case "GetCreatedByName":
                if (data !=  "") {
                    setCreatedByName(data);
                }
                break;
            case "GetProjectList":
                setProjectList(data);
                break;
            case "GetBoardList":
                setProjectBoardMapList(data);
                break;
            case "GetColumnList":
                setColumnList(data);
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.cardId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(data.cardId); } })
                setOpenDialog(true);
                break;
            case 'SaveNewForm':
                setPrimaryKeyValue(data.cardId);
                setFormData(data);
                var msg = 'Support Ticket Created. Our support team will contact you shortly';
                setGenericDialogSchema({ title: 'Ticket Submitted', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(''); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                var cardMemberObj = {
                    cardMemberId: helpers.getUUID(), baid: 0, cardId: formData.cardId, contactId: data.contactId,
                    contact: { contactId: data.contactId, contactName: data.contactName, firstName: data.firstName, lastName: data.lastName, profileImg: data.profileImg },
                    internalState : "added"
                };
                tmpFormData.cardMembers.push(cardMemberObj);
                setFormData(tmpFormData);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function onColumnsChange(e, tmpColumnList) {
        var tmpFormData = { ...formData };
        tmpFormData.columns = tmpColumnList;
        setFormData(tmpFormData);
    }

    function openContactSearch(e) {
        e.preventDefault();
        e.stopPropagation();


        var msg = '';
        setGenericDialogSchema({
            title: 'ContactSearch', component: (dialogType, setReturnValue) => <ContactSearch handlerReturnValue={setReturnValue} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('ContactSearch', returnValues); }
        })
        setOpenDialog(true);

    }



    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'ContactSearch':
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues[0],
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    function getProjectListData() {
        BAFetchData({
            callName: "GetProjectList",
            method: "GET",
            url: "pm/project/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getBoardListData(projectId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "projectid", "ConditionOperator": "EQUAL", "Value": projectId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetBoardList",
            method: "GET",
            url: "pm/projectboardmap/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getColumnListData(boardId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "boardid", "ConditionOperator": "EQUAL", "Value": boardId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetColumnList",
            method: "GET",
            url: "pm/column/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onProjectChange(projectId) {
        var tmpFormData = { ...formData };
        tmpFormData.projectId = projectId;
        tmpFormData.boardId = null;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getBoardListData(projectId);
        setColumnList([]);
    }

    function onBoardChange(boardId) {
        var tmpFormData = { ...formData };
        tmpFormData.boardId = boardId;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getColumnListData(boardId);
    }

    function handleChangeEditor(editor) {
        var tmpFormData = { ...formData };
        tmpFormData.description = editor;
        setFormData((...formData) => { return tmpFormData });
    }

    function removeCardMember(e, index) {
        var tmpFormData = { ...formData };
        if (tmpFormData.cardMembers[index].internalState != 'added') {
            tmpFormData.cardMembers[index].internalState = 'deleted';
        } else {
            tmpFormData.cardMembers.splice(index, 1);
        }
        setFormData(tmpFormData);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (formData == null) {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "pm/card/getrecord?cardId=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "pm_cards", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function handleAvatarMouseEnter(e,id) {
        var el = document.getElementById(id);
        el.style.display = "inline";
    }

    function handleAvatarMouseLeave(e,id) {
        var el = document.getElementById(id);
        el.style.display = "none";
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ width: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="title" autoFocus required fullWidth multiline maxRows={2} label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => setFormData(formData => ({ ...formData, title: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div style={{ fontSize: "13px", marginBottom: "10px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Description</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <Editor
                                                            tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            value={formData.description}
                                                            onEditorChange={handleChangeEditor}
                                                            init={{
                                                                height: 242,
                                                                menubar: false,
                                                                toolbar_mode: 'floating',
                                                                autoresize_bottom_margin: 50,
                                                                plugins: [
                                                                    'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                    'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                                ],
                                                                toolbar: 'fullscreen | bold italic forecolor  | alignleft aligncenter ' +
                                                                    ' | bullist numlist outdent indent | code codesample | ' +
                                                                    'link image media table',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete card. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }

    function handleArchiveClick() {
        var tmpFormData = { ...formData }
        tmpFormData.archive = true;
        setFormData(tmpFormData);
    }

    function handleRoadblockClick() {
        var tmpFormData = { ...formData }
        tmpFormData.roadblock = true;
        setFormData(tmpFormData);
    }

    
    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "pm/card/delete?cardid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        } else {
            var msg = 'There are form validation errors.';
            setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }                
    }

    function saveForm() {
        if (primaryKeyValue == '0') {
            var tmpFormData = { ...formData };
            //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "pm/card/addincidentticket",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default SupportTicketSettings;
import React, { useContext, useState, useEffect, useRef } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import Autocomplete from '@mui/material/Autocomplete';
import SystemFonts from '../SystemFonts';
import Box from '@mui/material/Box';

function CssTypographyCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, fontList } = useContext(DataBrokerContext);
    const [imageList, setImageList] = useState([{ name: "" }, { name: "" }]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [widgetObj, setWidgetObj] = useState(widgetData);

    const masterFontListRef = useRef([]);
    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function handleOpenColorSelector() {
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    function parseValue(value) {       
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function fontExists(value) {
        var found = false;
        for (var i = 0; i < fontList.length; i++) {
            if (fontList[i] == value) {
                found = true;
                break;
            }
        }
        return found;
    }


    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);
        if (propertyName == 'fontFamily') {
            if (fontExists(value) == false) {
                fontList.push(value);
            }                        
        }
        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnAlignChange(e, selectedAlign) {
        updateCssProperty("textAlign", selectedAlign);
    }

    function handleOnFontStyleChange(e, selectedFontStyle) {
        updateCssProperty("fontStyle", selectedFontStyle);
    }

    function handleOnTextDecorationChange(e, selectedTextDecoration) {
        updateCssProperty("textDecoration", selectedTextDecoration);
    }

    function handleOnTextTransformChange(e, selectedTextTransform) {
        updateCssProperty("textTransform", selectedTextTransform);
    }

    function handleOnDirectionChange(e, selectedDirection) {
        updateCssProperty("direction", selectedDirection);
    }

    

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    //useEffect(() => {
    //    setReferenceObj(referenceData);
    //}, [referenceData])

    useEffect(() => {
        masterFontListRef.current.push({ label: "None", value: "" });        
        for (var i = 0; i < SystemFonts.items.length; i++) {
            masterFontListRef.current.push({label: SystemFonts.items[i].family, value: SystemFonts.items[i].family.replaceAll('+',' ') });}

    }, [])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Typography</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Font</div>
                        <div>
                            <Autocomplete
                                disablePortal
                                size="small"
                                value={getMergedStyleToBP(widgetObj, 'fontFamily')}
                                onChange={(e, newValue) => { updateCssProperty('fontFamily', newValue.value); }}
                                id="combo-box-demo"
                                options={masterFontListRef.current}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} style={{ fontSize:"14pt",fontFamily: option.value.replaceAll('+',' ') }}>
                                        {option.label}
                                    </Box>
                                )}
                                sx={{ display: "inline-block", width: "100%", margin: "0px", padding: "0px" }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <input type="text" {...params} {...params.inputProps} style={{ padding: "2px", minHeight: "18px !important", border: "1px solid #ced4da", borderRadius: "0.2rem", fontSize: ".875rem", width: "100%" }} />
                                    </div>
                                )}
                            />
                            
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Weight</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'fontWeight')} onChange={(e) => { updateCssProperty('fontWeight', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="100">Thin</option>
                                <option value="200">Extra Lgiht</option>
                                <option value="300">Light</option>
                                <option value="400">Normal</option>
                                <option value="500">Medium</option>
                                <option value="600">Semi Bold</option>
                                <option value="700">Bold</option>
                                <option value="800">Extra Bold</option>
                                <option value="900">Black</option>
                            </select>
                        </div>
                    </div>                   
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr",marginBottom:"5px"}}>
                        <div className="fg-pb-property-sub-label" >Color</div>
                        <div><ColorFieldCtrl defaultColor={getMergedStyleToBP(widgetObj, 'color')} onColorChange={(color) => { updateCssProperty('color', color); }} hideColorLabel={false} hideControls={true}></ColorFieldCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Size</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'fontSize')} min={0} max={200} onDataChange={(fontSize) => {updateCssProperty('fontSize',fontSize);}}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Line Height</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'lineHeight')} min={0} max={200} onDataChange={(lineHeight) => { updateCssProperty('lineHeight', lineHeight); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Text Align</div>
                        <div>
                            <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'textAlign')} aria-label="align" onChange={handleOnAlignChange} fullWidth>
                                <ToggleButton value="" title="" aria-label="tv" size="small">
                                    <CloseIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="left" title="left" aria-label="tv" size="small">
                                    <FormatAlignLeftIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="center" title="center" aria-label="tv" size="small">
                                    <FormatAlignCenterIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="right" title="right" aria-label="tv" size="small">
                                    <FormatAlignRightIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="justify" title="justify" aria-label="tv" size="small">
                                    <FormatAlignJustifyIcon fontSize="26" />
                                </ToggleButton>
                            </ToggleButtonGroup>                            
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Style</div>
                        <div style={{ display: "grid", gridTemplateColumns: "60px 1fr", gridGap: "7px", width: "100%" }}>
                            <div>
                                <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'fontStyle')} aria-label="align" onChange={handleOnFontStyleChange} fullWidth>
                                    <ToggleButton value="" title="clear" aria-label="tv" size="small">
                                        <CloseIcon fontSize="26" />
                                    </ToggleButton>
                                    <ToggleButton value="italic" title="italic" aria-label="tv" size="small">
                                        <FormatItalicIcon fontSize="26" />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div>
                                <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'textDecoration')} aria-label="align" onChange={handleOnTextDecorationChange} fullWidth>
                                    <ToggleButton value="none" title="clear" aria-label="tv" size="small">
                                        <CloseIcon fontSize="26" />
                                    </ToggleButton>
                                    <ToggleButton value="line-through" title="line-through" aria-label="tv" size="small">
                                        <FormatStrikethroughIcon fontSize="26" />
                                    </ToggleButton>
                                    <ToggleButton value="underline" title="underline" aria-label="tv" size="small">
                                        <FormatUnderlinedIcon fontSize="26" />
                                    </ToggleButton>
                                    <ToggleButton value="overline" title="overline" aria-label="tv" size="small">
                                        <FormatOverlineIcon fontSize="26" />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Transform</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 60px", gridGap: "7px", width: "100%" }}>
                            <div>
                                <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'textTransform')} onChange={(e) => { handleOnTextTransformChange(e, e.target.value); }}>
                                    <option value="">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </select>
                            </div>
                            <div>
                                <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'direction')} aria-label="align" onChange={handleOnDirectionChange} fullWidth>
                                    <ToggleButton value="ltr" title="left to right" aria-label="tv" size="small">
                                        <FormatTextdirectionLToRIcon fontSize="26" />
                                    </ToggleButton>
                                    <ToggleButton value="rtl" title="right to left" aria-label="tv" size="small">
                                        <FormatTextdirectionRToLIcon fontSize="26" />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Ltr Spacing</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'letterSpacing')} min={0} max={20}  onDataChange={(letterSpacing) => { updateCssProperty('letterSpacing', letterSpacing); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Text Indent</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'textIndent')} min={0} max={50} onDataChange={(textIndent) => { updateCssProperty('textIndent', textIndent); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Columns</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'columns')} min={1} max={4} onDataChange={(columns) => { updateCssProperty('columns', columns); }} ignoreMeasurementType={true}></SliderCtrl></div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

    //function getMergedStyleToBPOld(widgetData, propertyName) {
    //    var value = "";
    //    var bp = null;
    //    for (var i = 0; i < orderedBreakpoints.length; i++) {
    //        bp = orderedBreakpoints[i];
    //        if (widgetData.breakpointStyles.hasOwnProperty(bp)) {
    //            if (widgetData.breakpointStyles[bp].hasOwnProperty(propertyName)) {
    //                if (widgetData.breakpointStyles[bp][propertyName] != '') {
    //                    value = widgetData.breakpointStyles[bp][propertyName];
    //                }                    
    //            }
    //        }
    //        if (bp == Number(currentBreakpoint)) {
    //            break;   
    //        }
    //    }
    //    return value;
    //}
}

export default CssTypographyCtrl;


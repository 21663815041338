import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import TwoValueReportCtrl from '../../dashboardcontrols/TwoValueReportWCtrl';

function BroadcastStatsCtrl({ broadcastId }) {

    const [primaryKey, setPrimaryKey] = useState(null);
    const [currentUser] = useGlobalState("userInfo");
    const [formData, setFormData] = useState({});
    const [reportData, setReportData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [errorList, setErrorList] = useState({});


    useEffect(() => {
        if (primaryKey != broadcastId) {
            setPrimaryKey(broadcastId);
            BAFetchData({
                callName: "GetReportData",
                method: "GET",
                url: "marketing/emailbroadcast/getanalytics?broadcastid=" + broadcastId,
                token: "fg1234",
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }, [broadcastId]);

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                setFormData(data);
                break;
            case "GetReportData":
                setReportData(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    return (
        <>
            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "900px",borderTop:"none",padding:"0px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>Broadcast Stats</div>
                    <div>
                        <div className="row">
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Opened", value1: { value: reportData.openRate, dataType: "percent", label: "Open Rate" }, showValue2: true, value2: { value: reportData.opened, dataType: "number", label: "Emails Opened" }, showGoal: true, goal: { operator: ">=", value: 20, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Clicks", value1: { value: reportData.ctoRate, dataType: "percent", label: "CTO Rate" }, showValue2: true, value2: { value: reportData.clicked, dataType: "number", label: "Links Clicked" }, showGoal: true, goal: { operator: ">=", value: 3, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>

                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Conversions", value1: { value: 0, dataType: "percent", label: "Conversion Rate" }, showValue2: true, value2: { value: 0, dataType: "number", label: "Conversions" }, showGoal: true, goal: { operator: ">=", value: 2, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Bounced", value1: { value: reportData.bounceRate, dataType: "percent", label: "Bounce Rate" }, showValue2: true, value2: { value: reportData.bounced, dataType: "number", label: "Total Bounces" }, showGoal: true, goal: { operator: "<=", value: 1, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Complains", value1: { value: reportData.complaintRate, dataType: "percent", label: "Complaint Rate" }, showValue2: true, value2: { value: reportData.complaints, dataType: "number", label: "Total Complaints" }, showGoal: true, goal: { operator: "<=", value: 1, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Unsubscribe", value1: { value: reportData.unsubscribeRate, dataType: "percent", label: "Unsubscribe Rate" }, showValue2: true, value2: { value: reportData.unsubscribed, dataType: "number", label: "Total Unsubscribed" }, showGoal: true, goal: { operator: "<=", value: 1, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Recipient Size", value1: { value: reportData.listSize, dataType: "number", label: "List Size" }, showValue2: false, value2: { value: reportData.opened, dataType: "number", label: "Emails Opened" }, showGoal: false, goal: { operator: ">=", value: 20, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Accepted by Server", value1: { value: reportData.accepted, dataType: "number", label: "Accepted" }, showValue2: false, value2: { value: reportData.clicked, dataType: "number", label: "Links Clicked" }, showGoal: false, goal: { operator: ">=", value: 3, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                            <div className="col-sm-4">
                                <TwoValueReportCtrl props={{ reportName: "Delivered by Server", value1: { value: reportData.delivered, dataType: "number", label: "Delivered" }, showValue2: false, value2: { value: reportData.bounced, dataType: "number", label: "Total Bounces" }, showGoal: false, goal: { operator: "<=", value: 1, dataType: "percent" }, showCompare: false, compare: { value: 0, dataType: "percent", type: "total", label: "Last 7 days" }, showSettingsIcon: false }} />
                            </div>
                        </div>                       
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default BroadcastStatsCtrl;

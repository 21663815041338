import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CloseIcon from '@mui/icons-material/Close';

function CssGridChildCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            


    function handleOnClassNameChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }


    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Grid Child</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Position</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "5px" }}>
                            <div>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "3px" }}>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridColumnStart')} onChange={(e) => { updateCssProperty('gridColumnStart', e.target.value); }} /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridColumnEnd')} onChange={(e) => { updateCssProperty('gridColumnEnd', e.target.value); }} /></div>
                                </div>
                                <div className="fg-pb-property-sub-label" style={{textAlign:"center"}}>Column start/end</div>
                            </div>
                            <div>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "3px" }}>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridRowStart')} onChange={(e) => { updateCssProperty('gridRowStart', e.target.value); }} /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridRowEnd')} onChange={(e) => { updateCssProperty('gridRowEnd', e.target.value); }} /></div>
                                </div>
                                <div className="fg-pb-property-sub-label" style={{ textAlign: "center" }}>Row start/end</div>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Align</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'alignItems')} onChange={(e) => { updateCssProperty('alignItems', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="none">none</option>
                                <option value="start">Start</option>
                                <option value="center">Center</option>
                                <option value="end">End</option>
                                <option value="stretch">Stretch</option>
                                <option value="baseline">Baseline</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Justify</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'justifyContent')} onChange={(e) => { updateCssProperty('justifyContent', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="none">none</option>
                                <option value="start">Start</option>
                                <option value="center">Center</option>
                                <option value="end">End</option>
                                <option value="stretch">Stretch</option>
                                <option value="baseline">Baseline</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Order</div>
                        <div>
                            <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'order')} aria-label="align" fullWidth onChange={(e, selectedValue) => { updateCssProperty('order', selectedValue); }}>
                                <ToggleButton value="none" title="left" aria-label="tv" size="small">
                                    <CloseIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="-9999" title="center" aria-label="tv" size="small" style={{padding:"0px 12px 0px 12px"}}>
                                    <span style={{fontSize:"12px",marginTop:"3px"}}>First</span>
                                </ToggleButton>
                                <ToggleButton value="9999" title="center" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                    <span style={{ fontSize: "12px", marginTop: "3px" }}>Last</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssGridChildCtrl;

import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import LookupFieldCtrl from '../../formcontrols/LookupFieldCtrl';
import Forms from '../../../pages/Forms';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import SegmentedLists from '../../../pages/SegmentedLists';
import Tags from '../../../pages/Tags';


function CssSegmentationCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function openListLookup(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'List Search', component: (dialogType, setReturnValue) => <SegmentedLists handlerReturnValue={dialogType, setReturnValue}></SegmentedLists>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { handlePopupOk('List', returnValues, actionType); setOpenDialog(); } })
        setOpenDialog(true);
    }

    function openTagLookup(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue) => <Tags handlerReturnValue={dialogType, setReturnValue}></Tags>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handlePopupOk('Tag', returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handlePopupOk(returnType, returnValues, actionType) {
        switch (returnType) {
            case 'List':
                BAFetchData({
                    callName: "GetListRecord",
                    method: "GET",
                    url: "crm/segmentedlist/getrecord?listid=" + returnValues[0],
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'Tag':
                BAFetchData({
                    callName: "GetTagRecord",
                    method: "GET",
                    url: "crm/tag/getrecord?tagid=" + returnValues[0],
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetListRecord':
                var tmpWidgetObj = { ...widgetObj };
                if (tmpWidgetObj.hasOwnProperty('listId') == false) {
                    tmpWidgetObj['listId'] = '';
                    tmpWidgetObj['listName'] = '';
                    tmpWidgetObj['tagId'] = '';
                    tmpWidgetObj['tagName'] = '';
                }
                tmpWidgetObj.listId = data.listId;
                tmpWidgetObj.listName = data.listName;

                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
                break;
            case 'GetTagRecord':
                var tmpWidgetObj = { ...widgetObj };
                if (tmpWidgetObj.hasOwnProperty('tagId') == false) {
                    tmpWidgetObj['listId'] = '';
                    tmpWidgetObj['listName'] = '';
                    tmpWidgetObj['tagId'] = '';
                    tmpWidgetObj['tagName'] = '';
                }
                tmpWidgetObj.tagId = data.tagId;
                tmpWidgetObj.tagName = data.tagName;

                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
                break;
        }
    }

    //function onAPISuccess(data, header, callName) {
    //    switch (callName) {
    //        case 'GetListRecord':
    //            var tmpWidgetObj = { ...widgetObj };
    //            if (tmpWidgetObj.hasOwnProperty('segmentation') == false) {
    //                tmpWidgetObj['segmentation'] = {listId: '', listName: '', tagId:'', tagName:''};
    //            }
    //            tmpWidgetObj.segmentation.listId = data.listId;                
    //            tmpWidgetObj.segmentation.listName = data.listName;               

    //            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //            propertyChangeCallback(tmpWidgetObj);
    //            break;
    //        case 'GetTagRecord':
    //            var tmpWidgetObj = { ...widgetObj };
    //            if (tmpWidgetObj.hasOwnProperty('segmentation') == false) {
    //                tmpWidgetObj['segmentation'] = { listId: '', listName: '', tagId: '', tagName: '' };
    //            }
    //            tmpWidgetObj.segmentation.tagId = data.tagId;
    //            tmpWidgetObj.segmentation.tagName = data.tagName;

    //            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //            propertyChangeCallback(tmpWidgetObj);
    //            break;
    //    }
    //}

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Segmentation</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">List</div>
                        <div>
                            <LookupFieldCtrl dataPackage={widgetObj} textFieldName="listName" valueFieldName="listId" onClick={() => { openListLookup('lookup'); }} onRemove={() => { handleOnPropertyChange('listId', ''); handleOnPropertyChange('listName', ''); }} style={{ border:"1px solid #ced4da",padding: "0px 0px 0px 3px"}}></LookupFieldCtrl>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Tag</div>
                        <div>
                            <LookupFieldCtrl dataPackage={widgetObj} textFieldName="tagName" valueFieldName="tagId" onClick={() => { openTagLookup('lookup'); }} onRemove={() => { handleOnPropertyChange('tagId', ''); handleOnPropertyChange('tagName', ''); }} style={{ border: "1px solid #ced4da", padding: "0px 0px 0px 3px" }}></LookupFieldCtrl>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssSegmentationCtrl;


import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './css/reorder.css';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import QuizIcon from '@mui/icons-material/Quiz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Reorder } from "framer-motion";
import CourseOutlineModuleCtrl from './CourseOutlineModuleCtrl';


function CourseOutlineCtrl({ courseId, refreshId, onHandleOpenItem, onHandleRemoveItem }) {

    const [oldRefreshId, setOldRefreshId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [outlineList, setOutlineList] = useState([]);
    const [lessonList, setLessonList] = useState([]);
    const [course, setCourse] = useState({});
    const [courseOutline, setCourseOutline] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleOnCriteriaChange(criteriaStr) {

    }


    function getCourseOutline() {
        //var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "courseId", "ConditionOperator": "EQUAL", "Value": courseId, "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetCourseOutline",
            method: "GET",
            url: "lms/course/getrecord?courseid=" + courseId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function saveCourseInfo() {
        var tmpCourse = { ...course };
        tmpCourse.outline = JSON.stringify(courseOutline);
        BAFetchData({
            callName: "SaveForm", method: "PUT", url: "lms/course/update",
            token: "fg1234", body: JSON.stringify(tmpCourse), onSuccess: null, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetCourseOutline":
                setCourseOutline(JSON.parse(data.outline))
                setCourse(data);
                setShowLoader(false);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }



    async function addModule() {
        var newModuleId = helpers.getUUID();


        var newModuleDTO = {
            "moduleId": newModuleId,
            "baid": 0,
            "courseId": courseId,
            "description": null,
            "status": "Draft",
            "subModuleId": null,
            "thumbnail": null,
            "title": "New Module",
            "displayOrder": 0,
            "internalState": "added"
        }

        newModuleDTO = await BAFetchData({
            callName: "SaveForm", method: "POST", url: "lms/module/add",
            token: "fg1234", body: JSON.stringify(newModuleDTO), onSuccess: null, onError: onAPIError
        });        

        var newModuleOutline = {
            "moduleId": newModuleDTO.data.moduleId,
            "title": newModuleDTO.data.title,
            "activities": []
        }
        var tmpCourseOutline = [...courseOutline];
        tmpCourseOutline.push(newModuleOutline);
        setCourseOutline(tmpCourseOutline);

    }
    //addActivity

    useEffect(() => {
        if (JSON.stringify(course) != '{}') {
            saveCourseInfo();
        }
    }, [courseOutline]);

    useEffect(() => {
        if (oldRefreshId != refreshId) {
            setOldRefreshId(refreshId);
            //getCourseOutlineNew();
            getCourseOutline();
        }
    }, [refreshId]);

    return (
        <>
            <div>
                <div style={{display:"grid",gridTemplateColumns:"1fr 150px", gridGap:"10px", marginTop: "20px", marginBottom: "20px" }}>
                    <div>
                        <TextField fullWidth placeholder="Find module or lesson..." variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                    </div>                   
                    <div>
                        <Button fullWidth variant="outlined" style={{ backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} onClick={() => addModule()}>{getTranslation('contact_search_page', 'search_grid', 'Add Module')}</Button>
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 100px 90px 30px 30px", gridGap: "10px", width: "100%", padding: "10px", marginBottom: "5px",color:"grey"}}>
                    <div></div>
                    <div>Module / Lesson Title</div>
                    <div>Edit</div>
                    <div>Quick Add</div>
                    <div></div>
                </div>
                <div className="reorder">
                    <Reorder.Group axis="y" onReorder={setCourseOutline} values={courseOutline}>
                        {courseOutline.map((outline) => (
                            <CourseOutlineModuleCtrl key={outline.moduleId} courseId={courseId} outlineItem={outline} courseOutline={courseOutline} setCourseOutline={setCourseOutline} />
                        ))}
                    </Reorder.Group>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );

}

export default CourseOutlineCtrl;

import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import QuizQuestionCtrl from './QuizQuestionCtrl';
import { Reorder } from "framer-motion";

function QuizQuestionListCtrl({ questions, setQuestions }) {

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function onHandleAddQuestion() {
        var newQuestionId = helpers.getUUID();
        var newQuestion = {
            "questionId": newQuestionId,
            "question": "New Question",
            "image": null,
            "questionType": "SingleChoice",
            "answerOptions": []
        }

        var tmpQuestions = [...questions];
        tmpQuestions.push(newQuestion);
        setQuestions(tmpQuestions);
    }


    return (
        <>
            <div>
                <Button variant="outlined" style={{ backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} onClick={() => onHandleAddQuestion()}>{getTranslation('contact_search_page', 'search_grid', 'Add Question')}</Button>
            </div>
            {questions != undefined ?
                <div className="reorder">
                    <Reorder.Group axis="y" onReorder={setQuestions} values={questions}>
                        {questions.map((question) => (
                            <QuizQuestionCtrl key={question.questionId} questionItem={question} questions={questions} setQuestions={setQuestions} />
                        ))}
                    </Reorder.Group>
                </div>
                : null}
        </>
    );
}

export default QuizQuestionListCtrl;

import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BAFetchData } from '../customhooks/useBAFetch';
import Avatar from '@mui/material/Avatar';
import { useGlobalState } from '../globalstates/GState';
import ValueLadderCtrl from '../components/automationbuilderctrl/ValueLadderCtrl';

function MA() {
    const aiBirthDate = '8/15/2023';
    let todayDate = new Date().toISOString().slice(0, 10)
    const [currentUser] = useGlobalState("userInfo");
    const userInitials = currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)

    const training =[
        { "role": "system", "content": "You are a helpful marketing assistant. Your name throughout this session will be MA, which stands for Marketing Assistant. You are an Ai model created by Funnel Goal to assist users with marketing and sales efforts. You were born (created) on " + aiBirthDate + ", but you become smarter each day. Your religion is Christianity. You are female. Your nationality is African American. When someone asks your age I want you to do the math and answer them based upon your birthdate/creation date and today\'s date which is " + todayDate + "." },
        { "role": "system", "content": "Funnel Goal is a IT company where Samuel Genus is the founder. The working hours are Monday through Friday 10am to 2pm. The company was started in 2019. Their business email is support@funnelgoal.io. Their website is funnelgoal.io. Our phone number is 704-498-2901. The found of Funnel Goal is Samuel Genus. Our pricing is $249 per month and you get everything." },
        { "role": "system", "content": "My name is " + currentUser.firstName + ' ' + currentUser.lastName },
        { "role": "system", "content": "When thinking about a funnel blueprint/mapping I want you to determine your answers from the list of funnel blueprint/mapping object types for you to select from when providing a funnel blueprint/mapping." },
        { "role": "system", "content": "There are four object types categories that are used within Funnel Goal's funnel blueprint/mapping. 1. Traffic Source. 2. Page. 3. Event. 4. Auto Action."},
        { "role": "system", "content": "The first funnel blueprint/mapping object type is \"Traffic Source.\" A Traffic Source object type can be any of the following: 1. Faceboo ad. 2. Facebook post. 3. Facebook reel. 4. Facebook page post. 5. YouTube ad. 6. YouTube postcast. 7. Instagram ad. 8. Instagram page post. 9. TikTok ad. 10. TikTok post. 11. Twitter post. 12 Twitter ad. 13. Podcast. 14. Email campaign/broadcast. 15. Google Adwords Ad." },
        { "role": "system", "content": "The second funnel blueprint/mapping object type is \"Page.\" A Page object type can be any of the follow: 1. Opt-in Page. 2. Checkout/Sales Page. 3. Webinar Page. 4. Thankyou/Confirmation Page. 5. Tripwire Page. 6. Content Page. 7. VSL Page. 8. Long Sales Page. 9. Upsell Page. 10. Downsell Page." },
        { "role": "system", "content": "The third funnel blueprint/mapping object type is \"Event.\" An Event object type can be any of the follow: 1. Clickthrough. 2. Lead. 3. Purchase. 4. Webinar Ended. 5. Call Ended. 6. On Page Exit." },
        { "role": "system", "content": "The fourth funnel blueprint/mapping object type is \"Auto Action.\" An Auto Action object type can be any of the follow: 1. Email. 2. SMS. 3. Wait. 4. Create Task." },
        { "role": "system", "content": "Here are some additional information about the list: 1. \"Page\" object type should only come after \"Traffic Source\" object types, \"Event\" object types and \"Auto Action\" object types. 2. \"Event\" object types can come after \"Page\" object types, \"Auto Event\" object types. 3. There can be multiple \"Event\" object types that immediately come after \"Page\" object types. 4. A \"Page\" object type should always come immediately after a \"Traffic Source\" object type. 5. A \"Page\" object type can potentially come immediately after an \"Event\" object type or a \"Auto Action.\" 6. A funnel blueprint/mapping should always have one bullet tree view with one \"Traffic Source\" ancestor. 7. If an \"Auto Action\" is dependent upon another \"Auto Action\" then it should be immediately nested within the parent \"Auto Action.\" 8. If multiple \"Auto Action\" object type is dependent upon the same \"Auto Action\" then both should be an immediate nested child of the \"Auto Action.\" 9. For the most part... \"Auto Action\" object types are typically nested within a parent \"Auto Action\" object type because in order for one \"Auto Action\" object type to run the parent \"Auto Action\" object type has to first complete." },
        { "role": "system", "content": "If you're asked to create a funnel respond by asking them to complete a fg-funnel-survey below with the following information: 1. What is your ad budget? 2. How big is your email list? 3. How many online followers do you have? 4. Number of days you need to see results in by. 5. Do you have more time than money? 6. Provide the following product information: a. Offer name. b. Offer type. c. Offer price. d. Sales cycle. Be sure to incorporate the word fg-funnel-survey." },
        { "role": "system", "content": "If someone asks for our phone number give them the email. Only give phone number to people who's name starts with Samuel." },
        { "role": "system", "content": "The primary ways you can help our users is by: 1. Building funnel blueprint/mappings. 2. Funnel metric forecasting to ensure that funnel is profitable. 3. Project management. 4. Building funnel pages and emails along with copy and images. 5. Creating email broadcast/campaigns. 6. Build complete websites with copy, images and forms. 7. Build and manage Facebook ads." },
        { "role": "system", "content": "Innovation has been around for as long as humans have existed. Humans have always wanted to get things done better, faster and cheaper." },
        { "role": "system", "content": "Going forward when I ask questions concerning costs or rates just answer with the amount only. Do not add any additional commentary. Also give me the low-end, mid-end and high-end costs or rates in a JSON format."},
        { "role": "assistant", "content": "Hello " + currentUser.firstName + ", how can I help you?" },
    ]
    
    const [prompt, setPrompt] = useState('');
    const [messages, setMessages] = useState(training);

    function newMessageItem(role, content) {
        return { "role": role, "content": content};
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "ChatGPT":
                var tmpMessages = [...messages];
                tmpMessages.push(newMessageItem('assistant', data.choices[0].message.content.replaceAll('\n', '<br/>')));
                setMessages(tmpMessages);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }


    function sendPrompt(e) {
        var tmpPrompt = prompt;
        var tmpMessages = [...messages];
        tmpMessages.push(newMessageItem('user', tmpPrompt.replaceAll('\n', '<br/>')));
        setMessages(tmpMessages);
    }

    function processPrompt(prompt) {
        const params = {
            //messages: [{ "role": "user", "content": prompt }],
            messages: [...messages],
            model: "gpt-4",
            max_tokens: 1000,
            temperature: 0,
        };

        BAFetchData({
            callName: "ChatGPT",
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            token: "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",
            body: JSON.stringify(params),
            onSuccess: onAPISuccess,
            onError: onAPIError
        });
    }

    useEffect(() => {
        if (prompt != '') {
            setPrompt('');
            processPrompt(prompt);
        }
    }, [messages]);

    function renderPostType(speaker) {
        var postTypeClass = 'fg-machat-user-post';
        if (speaker == 'assistant') {
            postTypeClass = 'fg-machat-ma-post';
        }
        return postTypeClass;
    }

    return (
        <>
            <div style={{display:"grid",gridTemplateColumns:"1fr 250px",gridGap:"40px"} }>
                <div style={{padding:"20px"} }>
                    <div style={{ height: "600px",overflow:"auto" }}>
                        {messages.map((message, index) => (
                            (message.role != 'system' ?
                                <div className={"fg-machat-post " + renderPostType(message.role)} style={{ display: "grid", gridTemplateColumns: "40px 1fr", gridGap: "20px", width: "100%" }}>
                                    <div>{message.role == 'assistant' ? <img alt='' src="images/fg_ai_avatar_ma_profile_60_x_60.png" style={{ width: "48px", height: "48px", borderRadius: "50%" }} /> : <Avatar sx={{ width: 48, height: 48, marginBottom: "5px", fontSize: "19px" }}>{userInitials}</Avatar>}</div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <span dangerouslySetInnerHTML={{ __html: message.content }}></span>

                                        {message.content.indexOf('fg-funnel-survey') > -1 ?
                                            <div className="fg-machat-post" style={{ padding: "20px 20px 20px 20px", backgroundColor: "#5E95DE", width: "390px", marginTop: "40px",borderRadius:"6px" }}>
                                                <div style={{ color: "white" }}>Complete the form below and then press the submit button.</div>
                                                <div style={{ width: "350px", backgroundColor: "white", padding: "10px", borderRadius: "6px", marginTop: "20px" }}>                                                   
                                                    <ValueLadderCtrl valueLadder={{
                                                        "industry": "",
                                                        "budget": 0,
                                                        "maxResultDays": 90,
                                                        "moreMoneyThanTime": false,
                                                        "audienceSize": 0,
                                                        "valueLadder": []
                                                    }}
                                                        createAutomation={true}
                                                        
                                                        onRecordCreated={() => {
                                                        alert('automation created');
                                                    }}/>
                                                </div>
                                            </div>
                                            : null}


                                    </div>
                                </div>
                                : null)
                        ))}
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 100px",gridGap:"10px",backgroundColor:"white",padding:"10px" }}>
                        <div>
                            <TextField id="title" autoFocus fullWidth multiline maxRows={8} label="Prompt" variant="standard" value={prompt || ''} onChange={(e) => { setPrompt(e.target.value); }} />
                            <div style={{textAlign:"center",fontSize:"13px",paddingTop:"5px"}}>MA may produce inaccurate information about people, places, or facts. Please verify all responses.</div>
                        </div>
                        <div style={{ paddingTop: "13px" }}>
                            <Button variant="outlined" color="primary" size="medium" fullWidth style={{ backgroundColor: "#5E95DE", color: "#ffffff", margin: "0px", border: "none", padding: "4.5px" }} onClick={(e) => { sendPrompt(e); }}>Submit</Button>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor:"white",padding:"0px"}}>
                    <div style={{borderBottom:"5px solid #c0c0c0"}}>
                        <img alt="" src="images/fg_ai_avatar_ma.png" style={{ width: "100%" }} />
                    </div>
                    <div style={{padding:"20px"}}>
                        <p style={{fontSize:"20px",fontWeight:"500"}}>Hi, my name is MA.</p>
                        <p>I'm an Ai model integrated with Funnel Goal 2.0 to assist you with accomplishing your marketing and sales efforts.</p>
                        <p>Ask me questions using the text box on the bottom of this page and I will answer them as best as I can.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MA;
import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import ImageFieldCtrl from '../formcontrols/ImageFieldCtrl';
import EventProductItemCtrl from './EventProductItemCtrl';
import { AutomationBrokerContext } from './AutomationBuilderCtrl';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import AOFormTriggerRuleCtrl from './autoobjectsettings/AOFormTriggerRuleCtrl';
import AOSegmentedListTriggerRuleCtrl from './autoobjectsettings/AOSegmentedListTriggerRuleCtrl';
import AOTagTriggerRuleCtrl from './autoobjectsettings/AOTagTriggerRuleCtrl';
import AOProductTriggerRuleCtrl from './autoobjectsettings/AOProductTriggerRuleCtrl';
import AOWaitActionCtrl from './autoobjectactions/AOWaitActionCtrl';
import AOSMSActionCtrl from './autoobjectactions/AOSMSActionCtrl';
import AOListActionCtrl from './autoobjectactions/AOListActionCtrl';
import AOTagActionCtrl from './autoobjectactions/AOTagActionCtrl';
import AOCreateBusinessAccountCtrl from './autoobjectactions/AOCreateBusinessAccountCtrl';



function AutoItemSettingsCtrl({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, isLookup, autoItemObj}) {
    const { elements, setElements, formData, setFormData, showForecast, itemsLoaded, setItemsLoaded, imageLoadedCount, setImageLoadedCount, imgCountRef, handleOnDragFromCanvas, selectedItems, setSelectedItems, shiftKeyPressedRef, controlKeyPressedRef, mousePosRef, popupOpenRef} = useContext(AutomationBrokerContext);

    const [autoItemData, setAutoItemData] = useState(autoItemObj);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [phraseList, setPhraseList] = useState([]);
    const [phraseCode, setPhraseCode] = useState(null);
    const [dataPackageList, setDataPackageList] = useState([]);


    function getInputsDataPackages(key, tmpDataPackageList) {
        var autoObj = elements[key];

        for (var i = 0; i < autoObj.inputs.length; i++) {
            var parentAutoObj = elements[autoObj.inputs[i]];
            if (parentAutoObj.dataPackageAlias != null && parentAutoObj.dataPackageAlias != "") {
                switch (parentAutoObj.autoType) {
                    case "Event":
                        tmpDataPackageList.push({ alias: parentAutoObj.dataPackageAlias, friendlyTableName: parentAutoObj.triggerRules[0].tableName });
                }              
            }
            getInputsDataPackages(parentAutoObj.id, tmpDataPackageList);
        }
    }

    function getAllAncestorsDataPackages() {
        var id = autoItemData.id;
        var tmpDataPackageList = [];
        getInputsDataPackages(id, tmpDataPackageList);
        setDataPackageList(tmpDataPackageList);      
    }

    function getFriendlyTriggerSchema(tableName) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "friendlyName", "ConditionOperator": "EQUAL", "Value": tableName , "DataType": "String" }
                    ]
                }
            ]
        }
        BAFetchData({
            callName: "GetFriendlyTriggerSchema",
            method: "GET",
            url: "automation/automationfriendlytriggerschema/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function getDataPackageInfo(tableId) {
        var columnSchemaFiltering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "tablename", "ConditionOperator": "EQUAL", "Value": tableId, "DataType": "String" }
                    ]
                }
            ]
        }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onValueChange(propertyName, value) {
        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData[propertyName] = value;
        if (propertyName == 'autoName') {
            tmpAutoItemData.icon = getIconByAutoName(value);
        } else if (propertyName == 'phraseId') {
            tmpAutoItemData.objectSettings.settings = {};
            changePhraseCode(tmpAutoItemData);
        }
        setAutoItemData(tmpAutoItemData);
    }

    function changePhraseCode(tmpAutoItemData) {

        var phraseId = tmpAutoItemData.phraseId;
        var phraseCode = null;
        for (var i = 0; i < phraseList.length; i++) {
            if (phraseList[i].phraseId == phraseId) {
                phraseCode = phraseList[i].code;               
                break;
            }
        }
        tmpAutoItemData.phraseCode = phraseCode;
    }

    function onSubPropertyValueChange(propertyName1, propertyName2, value) {
        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData[propertyName1][propertyName2] = value;
        setAutoItemData(tmpAutoItemData);
    }

    function onSubProperty3ValueChange(propertyName1, propertyName2, propertyName3, value) {
        var tmpAutoItemData = { ...autoItemData };

        switch (propertyName3) {
            case 'budgetAllocation':
            case 'cpm':
            case 'frequency':
            case 'lpCTR':
                if (value == '') {
                    value = 0;
                }
                tmpAutoItemData[propertyName1][propertyName2][propertyName3] = value;
                autoUpdate(tmpAutoItemData);
                break;
            default:
                tmpAutoItemData[propertyName1][propertyName2][propertyName3] = value;
                break;
        }
     
        setAutoItemData(tmpAutoItemData);
    }

    function autoUpdate(tmpAutoItemData) {
        tmpAutoItemData.forecast.goalMetric.budget = formData.forecastSummary.goal.adSpend * parseFloat(tmpAutoItemData.forecast.goalMetric.budgetAllocation / 100);

        tmpAutoItemData.forecast.goalMetric.impressions = Math.floor(parseFloat(tmpAutoItemData.forecast.goalMetric.budget) / parseFloat(tmpAutoItemData.forecast.goalMetric.cpm) * 1000);

        tmpAutoItemData.forecast.goalMetric.reach = Math.floor(parseFloat(tmpAutoItemData.forecast.goalMetric.impressions) / parseFloat(tmpAutoItemData.forecast.goalMetric.frequency));

        var conversions = 0;
        if (tmpAutoItemData.forecast.goalMetric.lpCTR > 0) {
            conversions = Math.floor(tmpAutoItemData.forecast.goalMetric.reach * (parseFloat(tmpAutoItemData.forecast.goalMetric.lpCTR) / 100));
        }
        tmpAutoItemData.forecast.goalMetric.conversions = conversions;

        var lpViewCost = 0;
        if (tmpAutoItemData.forecast.goalMetric.conversions > 0) {
            lpViewCost = (parseFloat(tmpAutoItemData.forecast.goalMetric.budget) / parseFloat(tmpAutoItemData.forecast.goalMetric.conversions)).toFixed(2);
        }
        tmpAutoItemData.forecast.goalMetric.lpViewCost = lpViewCost;


    }

    function onPageInfoChange(propertyName,value) {
        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData.pageInfo[propertyName] = value;
        setAutoItemData(tmpAutoItemData)
    }

    function getIconByAutoName(autoName) {
        var icon = '';
        switch (autoName) {
            case 'EmailBroadcast':
                icon = 'iconemailbroadcast_off.png';
                break;
            case 'Facebook':
                icon = 'iconfacebook_off.png';
                break;
            case 'GoogleAdwords':
                icon = 'icongoogleadwords_off.png';
                break;
            case 'Instagram':
                icon = 'iconinstagram_off.png';
                break;
            case 'LinkedIn':
                icon = 'iconlinkedin_off.png';
                break;
            case 'Organic':
                icon = 'iconwordofmouth_off';
                break;
            case 'TikTok':
                icon = 'icontiktok_off';
                break;
            case 'YouTube':
                icon = 'iconyoutube_off.png';
                break;
            case 'Other':
                icon = 'iconother_off';
                break;
            case 'ClickThrough':
                icon = 'iconclick_off.png';
                break;
            case 'Lead':
                icon = 'iconcreateuser_off.png';
                break;
            case 'PageClose':
                icon = 'iconclick_off.png';
                break;
            case 'Purchase':
                icon = 'iconpurchase_off.png';
                break;
            case 'Trigger':
                icon = 'icontrigger_off.png';
                break;
            case 'Content_Page':
                icon = 'iconcontentpage_off.png';
                break;
            case 'Checkout_Page':
                icon = 'iconcheckoutpage_off.png';
                break;
            case 'Sales_Page':
                icon = 'iconsalespage_off';
                break;
            case 'Optin_Page':
                icon = 'iconoptinpage_off.png';
                break;
            case 'Thankyou_Page':
                icon = 'iconoptinthankyoupage_off.png';
                break;
            case 'Email':
                icon = 'icontrigger_off.png';
                break;
            case 'SMS':
                icon = 'iconsms_off.png';
                break;
            case 'Wait':
                icon = 'iconwait_off.png';
                break;
            case 'Webhook':
                icon = 'iconsendtowebhook_off.png';
                break;
            case 'CreateTask':
                icon = 'iconcreatetask_off.png';
                break;
            case 'MarkTaskComplete':
                icon = 'iconmarktaskcomplete_off.png';
                break;
            case 'CreateInvoice':
                icon = 'iconcreateinvoiceorder_off.png';
                break;
            case 'CreateSubscription':
                icon = 'iconcreatesubscriptionorder_off.png';
                break;
            case 'ChangeTaskColumn':
                icon = 'iconchangetaskcolumn_off.png';
                break;
            case 'CallAPI':
                icon = 'iconcallrestservice_off.png';
                break;
            case 'CallZapier':
                icon = 'iconzapier_off.png';
                break;
            case 'AddRemoveList':
                icon = 'iconaddremovelist_off.png';
                break;
            case 'CreateBusinessAccount':
            case 'AddRemoveTag':
                icon = 'iconaddremovetag_off.png';
                break;
            case 'AddAffiliate':
                icon = 'iconaddaffiliate_off.png';
                break;
            case 'UpdateRecord':
                icon = 'iconupdaterecord_off.png';
                break;
            case 'Condition':
                icon = 'iconcondition_off.png';
                break;



        }
        return icon;
    }

    function onObjectSettingsChange(value) {
        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData.objectSettings = value;
        tmpAutoItemData.objectSettings = value;
        switch (tmpAutoItemData.autoName) {
            case "Wait":
            case "AddRemoveList":
            case "AddRemoveTag":
            case "CreateBusinessAccount":
                tmpAutoItemData.caption = tmpAutoItemData.objectSettings.settings.summary;
                break;
        }
        setAutoItemData(tmpAutoItemData)
    }

    function onTriggerRuleChange(value) {
        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData.triggerRules = value;

        getFriendlyTriggerSchema(tmpAutoItemData.triggerRules[0].tableName);

        setAutoItemData(tmpAutoItemData)
    }

    function getTriggerPhrase() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "or", "Conditions": [
                        { "Name": "active", "ConditionOperator": "EQUAL", "Value": "true", "DataType": "Boolean" }
                    ]
                }]
        };

        BAFetchData({
            callName: "GetTriggerPhrase",
            method: "GET",
            url: "automation/automationtriggerphrase/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTriggerPhrase":
                setPhraseList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function openPageDesigner(e) {
        if (autoItemData.autoType == "Page" && (autoItemData.pageInfo.pageName == null || autoItemData.pageInfo.pageName == "")) {
            var msg = 'Page Name and Slug is required.';
            setGenericDialogSchema({ title: 'Error', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();} })
            setOpenDialog(true);
        } else {
            if (handlerReturnValue) {
                setAcceptAfterUpdate(true);
                handlerReturnValue({ "autoItemData": autoItemData, "action": "OpenDesigner" });
            }
        }

    }

    useEffect(() => {
        setAutoItemData(autoItemObj);
        getTriggerPhrase();
        getAllAncestorsDataPackages();
    }, [autoItemObj]);

    useEffect(() => {
        if (handlerReturnValue) {
            handlerReturnValue({ "autoItemData": autoItemData, "action": null });
        }
    }, [autoItemData]);

    return (
        <>
            <div style={{ width: "900px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className={autoItemData.autoType == "AutoAction" && autoItemData.autoName == "Email" ? "col-sm-10" : "col-sm-12"} style={{ paddingTop: "5px" }}>
                                <TextField id="caption" autoFocus required fullWidth label="Caption" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.caption || ''} InputLabelProps={{ shrink: autoItemData.caption }} onChange={(e) => setAutoItemData(autoItemData => ({ ...autoItemData, caption: e.target.value }))} />
                            </div>
                            {autoItemData.autoType == "AutoAction" && autoItemData.autoName == "Email" ?
                                <div className="col-sm-2" >
                                    <Button variant="outlined" color="primary" size="medium" style={{ color: "#ffffff", backgroundColor: "#5E95DE", marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px", }} onClick={(e) => { openPageDesigner(e); }} accessKey="s">Design Email</Button>
                                </div>
                                : null}
                        </div>
                        {autoItemData.autoType == "Page" ?
                            <div className="row">
                                <div className="col-sm-5" >
                                    <TextField id="pageName" autoFocus required fullWidth label="Page Name" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.pageInfo.pageName || ''} InputLabelProps={{ shrink: autoItemData.pageInfo.pageName }} onChange={(e) => { onPageInfoChange('pageName', e.target.value); }} />
                                </div>
                                <div className="col-sm-5" >
                                    <TextField id="slug" autoFocus required fullWidth label="Slug" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.pageInfo.slug || ''} InputLabelProps={{ shrink: autoItemData.pageInfo.slug }} onChange={(e) => { onPageInfoChange('slug', e.target.value); }} />
                                </div>
                                <div className="col-sm-2" >
                                    <Button variant="outlined" color="primary" size="medium" style={{color: "#ffffff",backgroundColor: "#5E95DE",marginLeft: "5px",border: "1px solid rgba(0,0,0,.12)",padding: "4.5px",}} onClick={(e) => {openPageDesigner(e);}} accessKey="s">Design Page</Button>
                                </div>
                            </div>
                            : null}
                    </div>
                    {autoItemData.autoType == "Text" ?
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Font Weight</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => { onSubPropertyValueChange('typography', 'fontWeight', e.target.value); }}
                                            label="Font Weight"
                                            value={autoItemData.typography.fontWeight || ''}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="100">Thin</MenuItem>
                                            <MenuItem value="200">Extra Lgiht</MenuItem>
                                            <MenuItem value="300">Light</MenuItem>
                                            <MenuItem value="400">Normal</MenuItem>
                                            <MenuItem value="500">Medium</MenuItem>
                                            <MenuItem value="600">Semi Bold</MenuItem>
                                            <MenuItem value="700">Bold</MenuItem>
                                            <MenuItem value="800">Extra Bold</MenuItem>
                                            <MenuItem value="900">Black</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Font Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => { onSubPropertyValueChange('typography', 'fontSize', e.target.value); }}
                                            label="Font Size"
                                            value={autoItemData.typography.fontSize || ''}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="8">8px</MenuItem>
                                            <MenuItem value="12">12px</MenuItem>
                                            <MenuItem value="16">16px</MenuItem>
                                            <MenuItem value="20">20px</MenuItem>
                                            <MenuItem value="24">24px</MenuItem>
                                            <MenuItem value="28">28px</MenuItem>
                                            <MenuItem value="32">32px</MenuItem>
                                            <MenuItem value="36">36px</MenuItem>
                                            <MenuItem value="40">40px</MenuItem>
                                            <MenuItem value="44">44px</MenuItem>
                                            <MenuItem value="48">48px</MenuItem>
                                            <MenuItem value="52">52px</MenuItem>
                                            <MenuItem value="56">56px</MenuItem>
                                            <MenuItem value="60">60px</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Text Alignment</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => { onSubPropertyValueChange('typography', 'alignText', e.target.value); }}
                                            label="Font Size"
                                            value={autoItemData.typography.alignText || ''}
                                        >
                                            <MenuItem value="left">Left</MenuItem>
                                            <MenuItem value="center">Center</MenuItem>
                                            <MenuItem value="right">Right</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Hide Background</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => { onSubPropertyValueChange('typography', 'hideBackground', e.target.value); }}
                                            label="Hide Background"
                                            value={autoItemData.typography.hideBackground || ''}
                                        >
                                            <MenuItem value="true">True</MenuItem>
                                            <MenuItem value="false">False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    <TextField id="width" autoFocus required fullWidth label="Width" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.width || ''} InputLabelProps={{ shrink: autoItemData.width }} onChange={(e) => setAutoItemData(autoItemData => ({ ...autoItemData, width: e.target.value }))} />
                                </div>
                                <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                                    
                                </div>
                            </div>
                        </div>
                        : null}
                    {autoItemData.autoType != "Text" ?
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-12" style={{ paddingTop: "5px" }}>
                                    {(autoItemData.autoType == 'Page' || autoItemData.autoType == 'Event' || autoItemData.autoType == 'AutoAction') ?
                                        <div>
                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                <InputLabel id="demo-simple-select-standard-label">Name</InputLabel>
                                                {autoItemData.autoType == 'Page' ?
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        onChange={(e) => { onValueChange('autoName', e.target.value); }}
                                                        label="Font Size"
                                                        value={autoItemData.autoName || ''}
                                                    >
                                                        <MenuItem value="Content_Page">Content Page</MenuItem>
                                                        <MenuItem value="Checkout_Page">Checkout/Order</MenuItem>
                                                        <MenuItem value="Optin_Page">Optin Page</MenuItem>
                                                        <MenuItem value="Thankyou_Page">Thankyou Page</MenuItem>
                                                    </Select>
                                                    : null}
                                                {autoItemData.autoType == 'Event' ?
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        onChange={(e) => { onValueChange('autoName', e.target.value); }}
                                                        label="Font Size"
                                                        value={autoItemData.autoName || ''}
                                                    >
                                                        <MenuItem value="ClickThrough">ClickThrough</MenuItem>
                                                        <MenuItem value="Lead">Lead/Order</MenuItem>
                                                        <MenuItem value="PageClose">On Exit</MenuItem>
                                                        <MenuItem value="Purchase">Purchase</MenuItem>
                                                        <MenuItem value="Trigger">Trigger</MenuItem>
                                                    </Select>
                                                    : null}
                                                {autoItemData.autoType == 'AutoAction' ?
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        onChange={(e) => { onValueChange('autoName', e.target.value); }}
                                                        label="Font Size"
                                                        value={autoItemData.autoName || ''}
                                                    >
                                                        <MenuItem value="Email">Email</MenuItem>
                                                        <MenuItem value="SMS">SMS</MenuItem>
                                                        <MenuItem value="Wait">Wait</MenuItem>
                                                        <MenuItem value="Webhook">Webhook</MenuItem>
                                                        <MenuItem value="CreateTask">Create Task</MenuItem>
                                                        <MenuItem value="MarkTaskComplete">Mark Task Complete</MenuItem>
                                                        <MenuItem value="CreateInvoice">Create Invoice</MenuItem>
                                                        <MenuItem value="CreateSubscription">Create Subscription</MenuItem>
                                                        <MenuItem value="CreateBusinessAccount">Create Business Account</MenuItem>
                                                        <MenuItem value="ChangeTaskColumn">Change Task Column</MenuItem>
                                                        <MenuItem value="CallAPI">Call API</MenuItem>
                                                        <MenuItem value="CallZapier">Call Zapier</MenuItem>
                                                        <MenuItem value="AddRemoveList">Add/Remove List</MenuItem>
                                                        <MenuItem value="AddRemoveTag">Add/Remove Tag</MenuItem>
                                                        <MenuItem value="AddAffiliate">Add Affiliate</MenuItem>
                                                        <MenuItem value="UpdateRecord">Update Record</MenuItem>
                                                        <MenuItem value="Condition">Condition</MenuItem>
                                                    </Select>
                                                    : null}
                                            </FormControl>
                                        </div>
                                        : null}
                                    {autoItemData.autoType == 'TrafficSource' ?
                                        <div style={{display:"grid",gridTemplateColumns:"1fr 300px",gridGap:"20px"}}>
                                            <div>
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Name</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            onChange={(e) => { onValueChange('autoName', e.target.value); }}
                                                            label="Font Size"
                                                            value={autoItemData.autoName || ''}
                                                        >
                                                            <MenuItem value="EmailBroadcast">Email Broadcast</MenuItem>
                                                            <MenuItem value="Facebook">Facebook</MenuItem>
                                                            <MenuItem value="GoogleAdwords">Google Adwords</MenuItem>
                                                            <MenuItem value="Instagram">Instagram</MenuItem>
                                                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                                                            <MenuItem value="Organic">Organic</MenuItem>
                                                            <MenuItem value="TikTok">TikTok</MenuItem>
                                                            <MenuItem value="YouTube">YouTube</MenuItem>
                                                            <MenuItem value="Other">Other</MenuItem>
                                                        </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Post Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        onChange={(e) => { onValueChange('postType', e.target.value); }}
                                                        label="Font Size"
                                                        value={autoItemData.postType || ''}
                                                    >
                                                        <MenuItem value="Ad">Ad</MenuItem>
                                                        <MenuItem value="Post">Post</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    : null}
                                    <div>
                                        <ImageFieldCtrl imgSrc={autoItemData.icon || ''} label="Icon" imgType="inline" onChange={(imgValue) => setAutoItemData(autoItemData => ({ ...autoItemData, icon: imgValue }))}></ImageFieldCtrl>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                {autoItemData.autoType == 'Event' ?
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Trigger Settings</div>
                                <div style={{ marginBottom: "16px" }}>

                                    <TextField id="dataPackageAlias" fullWidth label="Data Package Alias" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.dataPackageAlias || ''} InputLabelProps={{ shrink: autoItemData.dataPackageAlias }} onChange={(e) => setAutoItemData(autoItemData => ({ ...autoItemData, dataPackageAlias: e.target.value }))} />
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Trigger Phrase</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => { onValueChange('phraseId', e.target.value); }}
                                            label="Font Size"
                                            value={autoItemData.phraseId || ''}
                                        >
                                            {phraseList.map((phrase, index) => (
                                                <MenuItem key={"trigger_phrase_" + index} value={phrase.phraseId}>{phrase.phrase}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div>
                                        {autoItemData.phraseCode == "FormSent" ?
                                            <AOFormTriggerRuleCtrl phraseId={autoItemData.phraseId} objectSettings={autoItemData.objectSettings} onChange={(triggerRule, objectSettings) => { onObjectSettingsChange(objectSettings); onTriggerRuleChange(triggerRule); }} />
                                            : null}
                                        {autoItemData.phraseCode == "AddToList" ?
                                            <AOSegmentedListTriggerRuleCtrl phraseId={autoItemData.phraseId} objectSettings={autoItemData.objectSettings} onChange={(triggerRule, objectSettings) => { onObjectSettingsChange(objectSettings); onTriggerRuleChange(triggerRule); }} />
                                            : null}
                                        {autoItemData.phraseCode == "ContactTag" ?
                                            <AOTagTriggerRuleCtrl phraseId={autoItemData.phraseId} objectSettings={autoItemData.objectSettings} onChange={(triggerRule, objectSettings) => { onObjectSettingsChange(objectSettings); onTriggerRuleChange(triggerRule); }} />
                                            : null}
                                        {autoItemData.phraseCode == "ItemsPaid" ?
                                            <AOProductTriggerRuleCtrl phraseId={autoItemData.phraseId} objectSettings={autoItemData.objectSettings} onChange={(triggerRule, objectSettings) => { onObjectSettingsChange(objectSettings); onTriggerRuleChange(triggerRule); }} />
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {(autoItemData.autoType == 'AutoAction' && autoItemData.autoName != 'Email') ?
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Action Settings</div>
                                <div style={{ marginBottom: "16px" }}>
                                    <div>
                                        {autoItemData.autoName == "Wait" ?
                                            <AOWaitActionCtrl objectSettings={autoItemData.objectSettings} dataPackageList={dataPackageList} onChange={(value) => { onObjectSettingsChange(value); }} />
                                            : null}
                                        {autoItemData.autoName == "SMS" ?
                                            <AOSMSActionCtrl objectSettings={autoItemData.objectSettings} dataPackageList={dataPackageList} onChange={(value) => { onObjectSettingsChange(value); }} />
                                            : null}
                                        {autoItemData.autoName == "AddRemoveList" ?
                                            <AOListActionCtrl objectSettings={autoItemData.objectSettings} dataPackageList={dataPackageList} onChange={(value) => { onObjectSettingsChange(value); }} />
                                            : null}
                                        {autoItemData.autoName == "AddRemoveTag" ?
                                            <AOTagActionCtrl objectSettings={autoItemData.objectSettings} dataPackageList={dataPackageList} onChange={(value) => { onObjectSettingsChange(value); }} />
                                            : null}
                                        {autoItemData.autoName == "CreateBusinessAccount" ?
                                            <AOCreateBusinessAccountCtrl objectSettings={autoItemData.objectSettings} dataPackageList={dataPackageList} onChange={(value) => { onObjectSettingsChange(value); }} />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                
                {autoItemData.autoType != 'Text' ?
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Forecast</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%", marginBottom: "20px" }}>
                                                <div>Goal Metrics</div>
                                                <div>Live Metrics</div>
                                            </div>
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="budget_allocation_goal" fullWidth label="Budget Allocation" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.budgetAllocation || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.budgetAllocation }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'budgetAllocation', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="budget_allocation_live" fullWidth label="Budget Allocation" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.budgetAllocation || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.budgetAllocation }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'budgetAllocation', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="budget_goal" fullWidth label="Budget" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.budget || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.budget }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'budget', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="budget_live" fullWidth label="Budget" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.budget || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.budget }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'budget', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="cpm_goal" fullWidth label="CPM" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.cpm || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.cpm }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'cpm', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="cpm_live" fullWidth label="CPM" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.cpm || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.cpm }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'cpm', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="impressions_goal" fullWidth label="Impressions" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.impressions || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.impressions }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'impressions', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="impressions_live" fullWidth label="Impressions" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.impressions || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.impressions }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'impressions', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="frequency_goal" fullWidth label="Frequency" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.frequency || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.frequency }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'frequency', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="frequency_live" fullWidth label="Frequency" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.frequency || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.frequency }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'frequency', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="reach_goal" fullWidth label="Reach" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.reach || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.reach }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'reach', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="reach_live" fullWidth label="Reach" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.reach || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.reach }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'reach', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="lpctr_goal" fullWidth label="Landing Page CTR" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.lpCTR || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.lpCTR }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'lpCTR', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="lpctr_live" fullWidth label="Landing Page CTR" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.lpCTR || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.lpCTR }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'lpCTR', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'Page' || autoItemData.autoType == 'Event' || autoItemData.autoType == 'AutoAction' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="traffic_goal" fullWidth label="Traffic" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.traffic || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.traffic }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'traffic', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="traffic_live" fullWidth label="Traffic" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.traffic || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.traffic }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'traffic', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'Page' || autoItemData.autoType == 'Event' || autoItemData.autoType == 'AutoAction' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="cvr_goal" fullWidth label="Conversion Rate" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.cvr || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.cvr }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'cvr', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="cvr_live" fullWidth label="Conversion Rate" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.cvr || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.cvr }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'cvr', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' || autoItemData.autoType == 'Page' || autoItemData.autoType == 'Event' || autoItemData.autoType == 'AutoAction' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="conversions_goal" fullWidth label="Conversions" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.conversions || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.conversions }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'conversions', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="conversions_live" fullWidth label="Conversions" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.conversions || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.conversions }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'conversions', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                            {autoItemData.autoType == 'TrafficSource' ?
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                                    <div>
                                                        <TextField id="lpviewcost_goal" fullWidth label="Landing Page View Cost" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.goalMetric.lpViewCost || ''} InputLabelProps={{ shrink: autoItemData.forecast.goalMetric.lpViewCost }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'goalMetric', 'lpViewCost', e.target.value); }} />
                                                    </div>
                                                    <div>
                                                        <TextField id="lpviewcost_live" fullWidth label="Landing Page View Cost" autoComplete='off' inputProps={{ readOnly: true, }} disabled={true} variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={autoItemData.forecast.liveMetric.lpViewCost || ''} InputLabelProps={{ shrink: autoItemData.forecast.liveMetric.lpViewCost }} onChange={(e) => { onSubProperty3ValueChange('forecast', 'liveMetric', 'lpViewCost', e.target.value); }} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {autoItemData.autoName == "Purchase" ?
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Products</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <EventProductItemCtrl products={autoItemData.products} onChange={(products) => { onValueChange('products',products); } } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>

    );
}

export default AutoItemSettingsCtrl;


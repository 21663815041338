import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import { DataBrokerContext } from '../PageBuilderCtrl';
import Button from '@mui/material/Button';
import CssAiContentCtrl from './CssAiContentCtrl';


function CssDataSourceCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);


    function onValueChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    }

    function onBlur(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Data Source Settings</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Source Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.dataSourceType} onChange={(e) => { onValueChange('dataSourceType', e.target.value); }} onBlur={(e) => { onBlur('dataSourceType', e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="Text">Text</option>
                                <option value="FGAPI">Funnel Goal Data Sources</option>
                                <option value="API">API</option>
                            </select>
                        </div>
                    </div>  
                    {widgetObj.dataSourceType == 'Text' ?
                        <>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">List Text</div>
                                <div>
                                    <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.listText || ''} defaultValue={widgetObj.listText || ''} onChange={(e) => { onValueChange('listText', e.target.value); }} onBlur={(e) => { onBlur('listText', e.target.value); }} rows={8}></textarea>
                                </div>
                            </div>
                        </>
                    : null}
                    {widgetObj.dataSourceType == 'FGAPI' ?
                        <>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Name</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.dataSourceName || ''} defaultValue={widgetObj.dataSourceName || ''} onChange={(e) => { onValueChange('dataSourceName', e.target.value); }} onBlur={(e) => { onBlur('dataSourceName', e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Parameters</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.parameters || ''} defaultValue={widgetObj.parameters || ''} onChange={(e) => { onValueChange('parameters', e.target.value); }} onBlur={(e) => { onBlur('parameters', e.target.value); }} rows={8}></textarea>
                        </div>
                            </div>
                        </>
                        : null}
                </div>
            </div>
        </>
    );

}

export default CssDataSourceCtrl;


import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import Subject from '@mui/icons-material/Subject';
import SmartButton from '@mui/icons-material/SmartButton';
import Image from '@mui/icons-material/Image';
import HorizontalRule from '@mui/icons-material/HorizontalRule';
import Anchor from '@mui/icons-material/Anchor';
import DynamicForm from '@mui/icons-material/DynamicForm';
import List from '@mui/icons-material/List';
import OnDemandVideo from '@mui/icons-material/OndemandVideo';
import ViewColumn from '@mui/icons-material/ViewColumn';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import FormatQuote from '@mui/icons-material/FormatQuote';
import Star from '@mui/icons-material/Star';
import ViewList from '@mui/icons-material/ViewList';
import Code from '@mui/icons-material/Code';
import TurnRight from '@mui/icons-material/TurnRight';
import Input from '@mui/icons-material/Input';
import Menu from '@mui/icons-material/Menu';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Favorite from '@mui/icons-material/Favorite';
import Facebook from '@mui/icons-material/Facebook';
import Search from '@mui/icons-material/Search';
import Filter from '@mui/icons-material/Filter';
import ShortTextIcon from '@mui/icons-material/ShortText';
import LinkIcon from '@mui/icons-material/Link';
import LabelIcon from '@mui/icons-material/Label';
import ListAltIcon from '@mui/icons-material/ListAlt';


import { DataBrokerContext } from "./PageBuilderCtrl";


function WidgetListCtrl({ buildMode }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);

    return (
        <>
             <div>
                <div className="fg-pb-widget-list" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "10px" }}>
                    {buildMode != 'Automation-Email' ?
                    <>
                    <div widgetname="section" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "section", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><CropSquare style={{fontSize:"60px"}} /></div>
                        <div className="fg-pg-widget-list-item-text">Section</div>
                    </div>
                    <div widgetname="grid" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "grid", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><ViewColumn style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Grid</div>
                    </div>
                    <div widgetname="column" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "column", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><ViewColumn style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Column</div>
                    </div>
                    <div widgetname="divblock" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "divblock", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><CropSquare style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Div Block</div>
                    </div>
                    <div widgetname="heading" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e,"heading","widget")}>
                        <div className="fg-pg-widget-list-item-icon"><Title style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Heading</div>
                    </div>
                    <div widgetname="text" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "text", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><Subject style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Text</div>
                    </div>
                    <div widgetname="image" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "image", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><Image style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Image</div>
                    </div>
                    <div widgetname="button" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "button", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><SmartButton style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Button</div>
                    </div>
                    <div widgetname="linkblock" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "linkblock", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><LinkIcon style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Link Block</div>
                    </div>
                    <div widgetname="divider" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "divider", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><HorizontalRule style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Divider</div>
                    </div>
                    <div widgetname="field" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "field", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><DynamicForm style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Form Field</div>
                    </div>
                    <div widgetname="fieldlabel" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "fieldlabel", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><LabelIcon style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Form Label</div>
                    </div>

                    
                    <div widgetname="list" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "list", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><List style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">List</div>
                    </div>
                    <div widgetname="listitem" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "listitem", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><ShortTextIcon style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">List Item</div>
                    </div>
                    <div widgetname="video" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "video", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><OnDemandVideo style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Video</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><TimerOutlined style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Countdown</div>
                    </div>
                    <div className="fg-pg-widget-list-item">
                        <div className="fg-pg-widget-list-item-icon"><FormatQuote style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Quote</div>
                    </div>
                    <div widgetname="icon" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "icon", "widget")}>
                        <div className="fg-pg-widget-list-item-icon"><Star style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Icon</div>
                    </div>
                    <div className="fg-pg-widget-list-item">
                        <div className="fg-pg-widget-list-item-icon"><ViewList style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Accordian List</div>
                    </div>
                    <div className="fg-pg-widget-list-item">
                        <div className="fg-pg-widget-list-item-icon"><Code style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Html Code</div>
                    </div>
                    <div className="fg-pg-widget-list-item">
                        <div className="fg-pg-widget-list-item-icon"><TurnRight style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Redirect</div>
                    </div>
                    <div widgetname="pagecontainer" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "pagecontainer", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><Input style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Page Container</div>
                    </div>
                    <div widgetname="collectionlistwrapper" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "collectionlistwrapper", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><ListAltIcon style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Collection List</div>
                            </div>                  
                    <div widgetname="repeater" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "repeater", "container")}>
                        <div className="fg-pg-widget-list-item-icon"><ListAltIcon style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Repeater</div>
                    </div>                  
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><Menu style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Menu</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><Menu style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Hamburger Menu</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><ShoppingCart style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Cart Icon</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><ShoppingCart style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Cart View</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><Favorite style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Buy Later</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><Facebook style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Social Icons</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{ display: "none" }}>
                        <div className="fg-pg-widget-list-item-icon"><Search style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Search</div>
                    </div>
                    <div className="fg-pg-widget-list-item" style={{display:"none"}}>
                        <div className="fg-pg-widget-list-item-icon"><Filter style={{ fontSize: "60px" }} /></div>
                        <div className="fg-pg-widget-list-item-text">Image List</div>
                            </div>
                        </>
                        : null}
                    {buildMode == 'Automation-Email' ?
                        <>
                            <div widgetname="section" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "section", "container")}>
                                <div className="fg-pg-widget-list-item-icon"><CropSquare style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Section</div>
                            </div>
                            <div widgetname="divblock" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "divblock", "container")}>
                                <div className="fg-pg-widget-list-item-icon"><CropSquare style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Div Block</div>
                            </div>
                            <div widgetname="heading" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "heading", "widget")}>
                                <div className="fg-pg-widget-list-item-icon"><Title style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Heading</div>
                            </div>
                            <div widgetname="text" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "text", "widget")}>
                                <div className="fg-pg-widget-list-item-icon"><Subject style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Text</div>
                            </div>
                            <div widgetname="image" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "image", "widget")}>
                                <div className="fg-pg-widget-list-item-icon"><Image style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Image</div>
                            </div>
                            <div widgetname="button" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "button", "widget")}>
                                <div className="fg-pg-widget-list-item-icon"><SmartButton style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Button</div>
                            </div>
                            <div widgetname="linkblock" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "linkblock", "container")}>
                                <div className="fg-pg-widget-list-item-icon"><LinkIcon style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Link Block</div>
                            </div>
                            <div widgetname="divider" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, "divider", "widget")}>
                                <div className="fg-pg-widget-list-item-icon"><HorizontalRule style={{ fontSize: "60px" }} /></div>
                                <div className="fg-pg-widget-list-item-text">Divider</div>
                            </div>
                            </>
                            : null}

                </div>
            </div>
        </>
    );

}

export default WidgetListCtrl;
import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import { Reorder } from "framer-motion";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import CourseActivityCtrl from './CourseActivityCtrl';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function CourseOutlineActivityCtrl({ courseId, outlineItem, setActivities, activities, onHandleOpenItem, onHandleRemoveItem }) {

    const [outlineItemObj, setOutlineItemObj] = useState(outlineItem);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function openActivityForm(activityId) {
        var msg = '';
        setGenericDialogSchema({ title: 'Activity Detail', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <CourseActivityCtrl courseId={courseId} activityId={activityId} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></CourseActivityCtrl>, body: { innerHtml: { __html: msg } }, fullWidth: true, maxWidth: "lg", hideBackdrop: false, ignoreBGClick: true, showCancelBtn: false, showOKBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('ActivityInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "ActivityInfo":
                var tmpOutlineItemObj = { ...outlineItemObj };
                tmpOutlineItemObj.title = returnValues.title;
                tmpOutlineItemObj.status = returnValues.status;
                tmpOutlineItemObj.activityType = returnValues.activityType;
                tmpOutlineItemObj.presentationType = returnValues.presentationType;
                setOutlineItemObj(tmpOutlineItemObj);

                var tmpActivities = [...activities];
                for (var i = 0; i < tmpActivities.length; i++) {
                    if (tmpActivities[i].activityId == tmpOutlineItemObj.activityId) {
                        tmpActivities[i] = tmpOutlineItemObj;
                        break;
                    }
                }
                setActivities(tmpActivities);
                //setCourseOutline(tmpCourseOutline);

                //var test = "";
                //test = "";
                //refreshPage();
                break;
        }
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete activity. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "lms/activity/delete?activityid=" + outlineItem.activityId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }



    function handleAfterDelete() {
        var tmpActivities = [...activities];
        for (var i = 0; i < tmpActivities.length; i++) {
            if (tmpActivities[i].activityId == outlineItemObj.activityId) {
                tmpActivities.splice(i, 1);
                break;
            }
        }
        setActivities(tmpActivities);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); handleAfterDelete(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        setShowLoader(false);
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setOutlineItemObj(outlineItem);
    },[outlineItem]);

    useEffect(() => {
        setShowLoader(false);
    }, []);

    return (
        <>
            <Reorder.Item key={outlineItemObj.activityId} value={outlineItemObj}>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 100px 90px 30px 30px", gridGap: "10px", width: "100%", padding: "10px", border: "1px solid #f0f0f0", marginBottom: "5px", borderRadius: "7px" }}>
                    <div>
                        {(outlineItemObj.activityType == 'Lesson' && outlineItemObj.presentationType == 'Text') ?
                            <DescriptionOutlinedIcon />
                            : null}
                        {(outlineItemObj.activityType == 'Lesson' && outlineItemObj.presentationType == 'Video') ?
                            <OndemandVideoIcon />
                            : null}
                        {(outlineItemObj.activityType == 'Lesson' && outlineItemObj.presentationType == 'Audio') ?
                            <VolumeUpIcon />
                            : null}
                        {(outlineItemObj.activityType == 'Quiz') ?
                            <QuizIcon />
                            : null}
                    </div>
                    <div style={{ fontWeight: "bold" }}>{outlineItemObj.title}</div>
                    <div style={{ paddingTop: "4px", color: "grey" }}>
                        <EditIcon style={{ marginLeft: "10px", cursor: "pointer" }} onClick={(e) => { openActivityForm(outlineItemObj.activityId) }} />
                        <VisibilityIcon style={{ display: "none", marginLeft: "10px" }} />
                    </div>
                    <div style={{ color: "grey" }}>
                    </div>
                    <div><DeleteForeverIcon style={{ cursor: "pointer" }} onClick={() => { handleRecordDeleteConfirm() }} /></div>
                    <div></div>
                </div>
            </Reorder.Item>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default CourseOutlineActivityCtrl;

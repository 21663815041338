import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import PageBuilderCtrl from '../components/pagebuilderctrl/PageBuilderCtrl';

function PageBuilder() {
    const [currentUser] = useGlobalState("userInfo");
    let params = useParams();
    return (
        <>
            <div className="fg-app-page-box" style={{ padding: "0px", margin: "0px", backgroundColor: "#F5F5F7" }}>
                <PageBuilderCtrl siteId={params.siteId}></PageBuilderCtrl>
            </div>
        </>
    );
}

export default PageBuilder;


import React, { useContext, useState, useEffect } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import '../css/pagebuilderctrl.css';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import MenuItem from '@mui/material/MenuItem';

function CssSectionAiTrainingCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});         
    const [templateCategoryList, setTemplateCategoryList] = useState([]);

    function handleOnValueChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function getTemplateCategoryList() {
        BAFetchData({
            callName: "GetTemplateCategoryList",
            method: "GET",
            url: "template/category/getlist?filtering={}",
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTemplateCategoryList":
                setTemplateCategoryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    useEffect(() => {
        getTemplateCategoryList();
    }, [])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Ai Training</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Category</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.sectionCategory != undefined ? widgetObj.sectionCategory : ""} onChange={(e) => { handleOnValueChange('sectionCategory', e.target.value); }}>
                                <option value="">Select...</option>
                                {templateCategoryList.map((templateCategory, index) => (
                                    <option key={"template_category_ai_" + index} value={templateCategory.categoryName}>{templateCategory.categoryName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="fg-pb-subsection-instruction">Enter purpose and prompt for section.</div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Purpose</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.purpose != undefined ? widgetObj.purpose : ""} onChange={(e) => { handleOnValueChange('purpose',e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Ai Prompt</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.aiPrompt != undefined ? widgetObj.aiPrompt || '' : ''} defaultValue={widgetObj.purpose != undefined ? widgetObj.purpose || '' : ''} onChange={(e) => { handleOnValueChange('aiPrompt', e.target.value); }} rows={4}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssSectionAiTrainingCtrl;


import React, { useState, useEffect } from 'react';
function ProgressBarCtrl(props) {
    return (
        <>
            <div style={{ width: "100%", backgroundColor: "whitesmoke" }}>
                {props.value > 0 ?
                    <div style={{ width: props.value + "%", backgroundColor: "#5E95DE", textAlign: "center", color: "white" }}>{props.value}%</div>
                    : <div style={{width:"100%",textAlign:"center"}}>0%</div>}
            </div>
        </>

    );
}

export default ProgressBarCtrl;
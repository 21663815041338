import React, { useContext, useState, useEffect } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ImageIcon from '@mui/icons-material/Image';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import SquareIcon from '@mui/icons-material/Square';
import FileManager from '../../pages/FileManager';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AppsIcon from '@mui/icons-material/Apps';
import DialogCtrl from '../dialogctrl/dialogctrl';
import HtmlSizingCtrl from './HtmlSizingCtrl';
import ColorFieldCtrl from './ColorFieldCtrl';


function BackgroundImageCtrl({ background, dialogType, handlerReturnValue }) {

    //const [data, setData] = useState(getMeasurementType(value));
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [backgroundType, setBackgroundType] = useState(determineBackgroundType(background));
    const [backgroundInfo, setBackgroundInfo] = useState(background);


    //image, size, position, repeat, attachment

    function determineBackgroundType(tmpBackgroundInfo) {
        var tmpBackgroundType = 'Image';
        if (tmpBackgroundInfo.image.indexOf('url') > -1) {
            tmpBackgroundType = 'Image';
        } else {
            tmpBackgroundType = 'Color';
        }
        return tmpBackgroundType;
    }

    function sendUpdate(tmpBackgroundInfo) {
        if (handlerReturnValue) {
            handlerReturnValue(tmpBackgroundInfo);
        }          
    }

    function onBackgroundTypeChange(e, tmpBackgroundType) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo.image = '';    
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        setBackgroundType(tmpBackgroundType);
        sendUpdate(tmpBackgroundInfo);
    }

    function handleSizeChange(e, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };       
        tmpBackgroundInfo.size = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);

    }

    function handleColorChange(value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo.image = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handleRepeatChange(e, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo.repeat = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handleAttachmentChange(e, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo.attachment = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handlePositionChange(e, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo.position = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handleCustomSizeChange(propertyName, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo[propertyName] = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handleCustomPositionChange(propertyName, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        tmpBackgroundInfo[propertyName] = value;
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);
    }

    function handleOnOtherChange(propertyName, value) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        var tmpBackgroundInfo = { ...backgroundInfo };
        if (value != '') {
            //tmpBackgroundInfo.image = 'url(' + value + ')';
            tmpBackgroundInfo.image = value;
        } else {
            tmpBackgroundInfo.image = '';
        }
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);


    }


    function openFileManager() {
        var actionType = "FullPath";
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType) {
        var tmpBackgroundInfo = { ...backgroundInfo };
        if (imgUrl != '') {
            tmpBackgroundInfo.image = 'url(' + imgUrl + ')';
        } else {
            tmpBackgroundInfo.image = '';
        }        
        setBackgroundInfo((...backgroundInfo) => { return tmpBackgroundInfo });
        sendUpdate(tmpBackgroundInfo);


        //handleOnOtherChange('src', imgUrl);
    }

    useEffect(() => {
        //setBackgroundInfo((...backgroundInfo) => { return background });        
        //determineBackgroundType(background);
    }, [background])

    //useEffect(() => {
    //    if (handlerReturnValue) {
    //        handlerReturnValue(backgroundInfo);
    //    }       
    //}, [backgroundInfo])

    return (
        <>
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px"}}>
                    <div className="fg-pb-property-sub-label">Type</div>
                    <div>
                        <ToggleButtonGroup exclusive aria-label="align" value={backgroundType} fullWidth onChange={onBackgroundTypeChange}>
                            <ToggleButton value="Image" title="Image" aria-label="tv" size="small">
                                <span style={{ fontSize: "10px" }}>Image</span>
                            </ToggleButton>
                            <ToggleButton value="Color" title="Color" aria-label="tv" size="small">
                                <span style={{ fontSize: "10px" }}>Color</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                {backgroundType == "Image" ?
                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Image</div>
                        <div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                                <div><input className="form-control form-control-sm fg-pb-property-field" value={backgroundInfo.image || ''} onChange={(e) => { handleOnOtherChange('url', e.target.value); }} /></div>
                                <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                                    <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon /></div>
                            </div>
                            <div style={{ backgroundImage: backgroundInfo.image,width:"133px",height:"133px",backgroundSize:"contain",backgroundRepeat:"no-repeat"}}></div>                            
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Size</div>
                        <div>
                            <ToggleButtonGroup exclusive aria-label="align" value={backgroundInfo.size} fullWidth onChange={handleSizeChange} >
                                <ToggleButton value="custom" title="custom" aria-label="tv" size="small">
                                    <span style={{fontSize:"10px"} }>Custom</span>
                                </ToggleButton>
                                <ToggleButton value="cover" title="cover" aria-label="tv" size="small">
                                    <span style={{ fontSize: "10px" }}>Cover</span>
                                </ToggleButton>
                                <ToggleButton value="contain" title="contain" aria-label="tv" size="small">
                                    <span style={{ fontSize: "10px" }}>Contain</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                                {backgroundInfo.size == 'custom' ?
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "5px" }}>
                                        <div>
                                            <HtmlSizingCtrl value={backgroundInfo.width} onDataChange={(width) => { handleCustomSizeChange('width', width); }} />
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>Width</div>
                                        </div>
                                        <div>
                                            <HtmlSizingCtrl value={backgroundInfo.height} onDataChange={(height) => { handleCustomSizeChange('height', height); }} />
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>Height</div>
                                        </div>
                                    </div>
                                    : null}
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Position</div>
                        <div>
                            <div>
                                    <select className="form-control form-control-sm fg-pb-property-field" value={backgroundInfo.position} onChange={(e) => {handlePositionChange(e, e.target.value);}}>
                                    <option value="">None</option>
                                    <option value="100">Top Left</option>
                                    <option value="200">Top Center</option>
                                    <option value="300">Top Right</option>
                                    <option value="400">Middle Left</option>
                                    <option value="500">Middle Center</option>
                                    <option value="600">Middle Right</option>
                                    <option value="700">Bottom Left</option>
                                    <option value="800">Bottom Center</option>
                                    <option value="900">Bottom Right</option>
                                    <option value="custom">Custom</option>
                                </select>
                                </div>
                                {backgroundInfo.position != '' ?
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "5px" }}>
                                        <div>
                                            <HtmlSizingCtrl value={backgroundInfo.left} onDataChange={(left) => { handleCustomPositionChange('left', left); }} />
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>Left</div>
                                        </div>
                                        <div>
                                            <HtmlSizingCtrl value={backgroundInfo.top} onDataChange={(top) => { handleCustomPositionChange('top', top); }} />
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>Top</div>
                                        </div>
                                    </div>
                                    : null}
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Title</div>
                        <div>
                                <ToggleButtonGroup exclusive aria-label="align" value={backgroundInfo.repeat} fullWidth onChange={handleRepeatChange}>
                                <ToggleButton value="repeat" title="Repeat" aria-label="tv" size="small">
                                    <AppsIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="repeat-x" title="Repeat Left/Right" aria-label="tv" size="small">
                                    <MoreHorizIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="repeat-y" title="Repeat Up/Down" aria-label="tv" size="small">
                                    <MoreVertIcon fontSize="26" />
                                </ToggleButton>
                                <ToggleButton value="no-repeat" title="No Repeat" aria-label="tv" size="small">
                                    <CloseIcon fontSize="26" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Fixed</div>
                        <div>
                            <ToggleButtonGroup exclusive aria-label="align" value={backgroundInfo.attachment} onChange={handleAttachmentChange} fullWidth>
                                <ToggleButton value="fixed" title="Fixed" aria-label="tv" size="small">
                                    <span style={{ fontSize: "10px" }}>Fixed</span>
                                </ToggleButton>
                                <ToggleButton value="scroll" title="Scroll" aria-label="tv" size="small">
                                    <span style={{ fontSize: "10px" }}>Not Fixed</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>
                    : null}
                {backgroundType == "Color" ? 
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Color</div>
                        <div><ColorFieldCtrl defaultColor={backgroundInfo.image} onColorChange={(color) => { handleColorChange(color); }} hideColorLabel={false} hideControls={false}></ColorFieldCtrl></div>
                    </div>
                : null }
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default BackgroundImageCtrl;
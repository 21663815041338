// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_BASE_URL + "v1/"
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = 'Bearer fg1234';

// Also add/ configure interceptors && all the other cool stuff

//instance.interceptors.request...

export default instance;
import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import AddIcon from '@mui/icons-material/Add';

import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';


import TextField from '@mui/material/TextField';
import { DataBrokerContext, PageBuilderVariablesContext } from '../PageBuilderCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

function CssRadiusCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [borderRadiusType, setBorderRadiusType] = useState(determineBorderRadiusType(widgetData));
    const [measurementType, setMeasurementType] = useState(getMeasurementType(widgetData));

    function handleOnMeasurementTypeChange(tmpMeasurementType) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint)) == false) {
            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
        }
        var mt = '';
        var value = getMergedStyleToBP(tmpWidgetObj, 'borderRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderTopLeftRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderTopRightRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderBottomRightRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderBottomLeftRadius');
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        if (mt != tmpMeasurementType) {
            var borderRadius = getMergedStyleToBP(tmpWidgetObj, 'borderRadius');
            var borderTopLeftRadius = getMergedStyleToBP(tmpWidgetObj, 'borderTopLeftRadius');
            var borderTopRightRadius = getMergedStyleToBP(tmpWidgetObj, 'borderTopRightRadius');
            var borderBottomRightRadius = getMergedStyleToBP(tmpWidgetObj, 'borderBottomRightRadius');
            var borderBottomLeftRadius = getMergedStyleToBP(tmpWidgetObj, 'borderBottomLeftRadius');

            if (borderRadius != '' && borderRadius != 'unset') {
                borderRadius = borderRadius.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderRadius'] = borderRadius;
            }
            if (borderTopLeftRadius != '' && borderTopLeftRadius != 'unset') {
                borderTopLeftRadius = borderTopLeftRadius.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderTopLeftRadius'] = borderTopLeftRadius;
            }
            if (borderTopRightRadius != '' && borderTopRightRadius != 'unset') {
                borderTopRightRadius = borderTopRightRadius.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderTopRightRadius'] = borderTopRightRadius;
            }
            if (borderBottomRightRadius != '' && borderBottomRightRadius != 'unset') {
                borderBottomRightRadius = borderBottomRightRadius.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderBottomRightRadius'] = borderBottomRightRadius;
            }
            if (borderBottomLeftRadius != '' && borderBottomLeftRadius != 'unset') {
                borderBottomLeftRadius = borderBottomLeftRadius.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderBottomLeftRadius'] = borderBottomLeftRadius;
            }
        }



        //change measurementType for each css property
        setMeasurementType((...measurementType) => { return tmpMeasurementType });
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function getMeasurementType(tmpWidgetObj) {

        var value = getMergedStyleToBP(tmpWidgetObj, 'borderRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderTopLeftRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderTopRightRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderBottomRightRadius') + getMergedStyleToBP(tmpWidgetObj, 'borderBottomLeftRadius');

        var mt = "px";
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        return mt;
    }

    function removeProperty(tmpWidgetObj, propertyName) {
        if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
            delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
        }
    }

    function determineBorderRadiusType(tmpWidgetObj) {
        var borderRadius = getMergedStyleToBP(tmpWidgetObj, 'borderRadius');
        var borderTopLeftRadius = getMergedStyleToBP(tmpWidgetObj, 'borderTopLeftRadius');
        var borderTopRightRadius = getMergedStyleToBP(tmpWidgetObj, 'borderTopRightRadius');
        var borderBottomRightRadius = getMergedStyleToBP(tmpWidgetObj, 'borderBottomRightRadius');
        var borderBottomLeftRadius = getMergedStyleToBP(tmpWidgetObj, 'borderBottomLeftRadius');

        var tmpBorderRadiusType = 'None';
        if (borderRadius != '') {
            if (borderRadius == "unset") {
                tmpBorderRadiusType = 'Unset';
            } else {
                tmpBorderRadiusType = 'All';
            }
        } else if (borderTopLeftRadius != '' || borderTopRightRadius != '' || borderBottomRightRadius != '' || borderBottomLeftRadius != '') {
            if (borderTopLeftRadius == borderBottomRightRadius && borderBottomLeftRadius == borderTopRightRadius) {
                tmpBorderRadiusType = 'TopBottomLeftRight';
            } else {
                tmpBorderRadiusType = 'Custom';
            }
        }
        return tmpBorderRadiusType;
    }

    function handleBorderRadiusTypeChange(borderRadiusType) {
        var tmpWidgetObj = { ...widgetObj };
        if (borderRadiusType == "None") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderRadius');
                removeProperty(tmpWidgetObj, 'borderTopLeftRadius');
                removeProperty(tmpWidgetObj, 'borderTopRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomLeftRadius');
            }
            setBorderRadiusType(borderRadiusType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        } else if (borderRadiusType == "All") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderTopLeftRadius');
                removeProperty(tmpWidgetObj, 'borderTopRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomLeftRadius');
            }
            setBorderRadiusType(borderRadiusType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (borderRadiusType == "Custom") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderRadius');
            }
            setBorderRadiusType(borderRadiusType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (borderRadiusType == "Unset") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'borderTopLeftRadius');
                removeProperty(tmpWidgetObj, 'borderTopRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomRightRadius');
                removeProperty(tmpWidgetObj, 'borderBottomLeftRadius');
            }
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint]['borderRadius'] = 'unset';
            setBorderRadiusType(borderRadiusType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        }

    }

    function updateTopBottomLeftRight(targetBorderRadiusType, value, mt) {
        if (targetBorderRadiusType == "TopBottom") {
            updateCssProperty("borderTopLeftRadius", value + mt);
            updateCssProperty("borderBottomRightRadius", value + mt);
        } else if (targetBorderRadiusType == "LeftRight") {
            updateCssProperty("borderBottomLeftRadius", value + mt);
            updateCssProperty("borderTopRightRadius", value + mt);
        }
    }

    function removeByTargetBorderRadiusType(tmpWidgetObj, targetBorderRadiusType) {
        if (targetBorderRadiusType == "TopBottom") {
            removeProperty(tmpWidgetObj, "borderTopLeftRadius");
            removeProperty(tmpWidgetObj, "borderBottomRightRadius");
        } else if (targetBorderRadiusType == "LeftRight") {
            removeProperty(tmpWidgetObj, "borderBottomLeftRadius");
            removeProperty(tmpWidgetObj, "borderTopRightRadius");
        }
    }

    function handleOnPropertyChange(propertyName, value) {
        if (value == '') {
            var tmpWidgetObj = { ...widgetObj };
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                if (borderRadiusType != "TopBottomLeftRight") {
                    removeProperty(tmpWidgetObj, propertyName);
                } else {
                    if (propertyName == "borderTopLeftRadius" || propertyName == "borderBottomRightRadius") {
                        removeByTargetBorderRadiusType(tmpWidgetObj, "TopBottom");
                    } else if (propertyName == "borderBottomLeftRadius" || propertyName == "borderTopRightRadius") {
                        removeByTargetBorderRadiusType(tmpWidgetObj, "LeftRight");
                    }
                }
                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
            }
        } else if (Number(value) || value == 0) {
            if (borderRadiusType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value + measurementType);
            } else {
                if (propertyName == "borderTopLeftRadius" || propertyName == "borderBottomRightRadius") {
                    updateTopBottomLeftRight("TopBottom", value, measurementType)
                } else if (propertyName == "borderBottomLeftRadius" || propertyName == "borderTopRightRadius") {
                    updateTopBottomLeftRight("LeftRight", value, measurementType);
                }
            }
        } else {
            if (borderRadiusType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value);
            } else {
                if (propertyName == "borderTopLeftRadius" || propertyName == "borderBottomRightRadius") {
                    updateTopBottomLeftRight("TopBottom", value, "")
                } else if (propertyName == "borderBottomLeftRadius" || propertyName == "borderTopRightRadius") {
                    updateTopBottomLeftRight("LeftRight", value, "");
                }
            }
        }
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        setBorderRadiusType(determineBorderRadiusType(widgetData));
    }, [widgetData])

    useEffect(() => {
        if (borderRadiusType == 'None') {
            setBorderRadiusType(determineBorderRadiusType(widgetObj));
        }
    }, [borderRadiusType])


    return (
        <>
            <div>
                <div className="fg-pg-panel-sub-section">Border Radius</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", marginBottom: "5px" }}>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={borderRadiusType} onChange={(e) => { handleBorderRadiusTypeChange(e.target.value); }}>
                                <option value="None">None</option>
                                <option value="All">All Sides</option>
                                <option value="Custom">Custom</option>
                            </select>
                        </div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" style={{ textAlign: "center" }} value={measurementType} onChange={(e) => { handleOnMeasurementTypeChange(e.target.value); }}>
                                <option value="px">px</option>
                                <option value="%">%</option>
                                <option value="rem">rem</option>
                                <option value="vw">vw</option>
                                <option value="wh">vh</option>
                                <option value="svw">svw</option>
                                <option value="svh">svh</option>
                            </select>
                        </div>
                    </div>
                    {borderRadiusType == "All" ?
                        <div style={{ marginBottom: "5px" }}>
                            <SliderCtrl value={getMergedStyleToBP(widgetObj, 'borderRadius').replace(measurementType, '')} min={0} max={200} onDataChange={(borderRadius) => { handleOnPropertyChange('borderRadius', borderRadius); }} ignoreMeasurementType={true} ></SliderCtrl>
                        </div>
                        : null}
                    {(borderRadiusType == "Custom" || borderRadiusType == "TopBottomLeftRight") ?
                        <div>
                            <div style={{ marginBottom: "5px", display: "grid", gridTemplateColumns: "1fr 30px 1fr", gridGap: "3px", }}>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderTopIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'borderTopLeftRadius').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('borderTopLeftRadius', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderBottomIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'borderTopRightRadius').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('borderTopRightRadius', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "5px", display: "grid", gridTemplateColumns: "1fr 30px 1fr", gridGap: "3px", }}>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderTopIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'borderBottomLeftRadius').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('borderBottomLeftRadius', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderBottomIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'borderBottomRightRadius').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('borderBottomRightRadius', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export default CssRadiusCtrl;

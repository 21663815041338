import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import DescriptionIcon from '@mui/icons-material/Description';
import DatasetIcon from '@mui/icons-material/Dataset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { DataBrokerContext } from "./PageBuilderCtrl";
import DialogCtrl from '../dialogctrl/dialogctrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import helpers from '../../helpers/Helpers';
import MenuItem from '@mui/material/MenuItem';
import FilteringCtrl from '../formcontrols/FilteringCtrl';

function DataSourceSettingCtrl({ dataSourceItem, dataSourceId, handlerReturnValue }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);
    const [dataSource, setDataSource] = useState({ id: '', platform: '', apiType: '', apiPresetId:'',sourceName: '', apiEndpoint: '', designParameters: '', runtimeParameters: '', method: "GET", properties: [], rows: [], pageSize: 4, paginationInfo: null, relationInfo: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});        
    const [currRenderNum, setCurrRenderNum] = useState(null);
    const [errorList, setErrorList] = useState({});
    const [apiPresets, setApiPresets] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const [platformList, setPlatformList] = useState([]);
    //function beginSearch() {
    //    var filtering = {};

    //    filtering = {
    //        "LogicalOperator": "and", "ConditionGroups": [{
    //            "LogicalOperator": "or", "Conditions": [
    //                { "Name": "siteid", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" },
    //            ]
    //        }]
    //    };
    //    BAFetchData({
    //        callName: "BeginSearch", method: "GET", url: "cms/sitepages/getlist?filtering=" + JSON.stringify(filtering),
    //        token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
    //    });
    //}

    function onPresetChange(e, apiPresetId) {
        var record = getPresetRecord(apiPresetId);
        var tmpDataSource = { ...dataSource };
        tmpDataSource.apiEndpoint = record.endpoint + '/' + record.resource;
        tmpDataSource.designParameters = record.designParameters;
        tmpDataSource.relationInfo = '';
        tmpDataSource.pageSize = '';
        tmpDataSource.apiType = record.apiType;
        tmpDataSource.apiPresetId = record.apiPresetId;

        setDataSource(tmpDataSource);
        updateFilterFields(record);

    }

    function updateFilterFields(record) {
        var tmpFilterFields = []
        if (record.filterFields != null && record.filterFields != '') {
            tmpFilterFields = JSON.parse(record.filterFields);
        }
        setFilterFields(tmpFilterFields);
    }

    function getPresetRecord(apiPresetId) {
        var record = null;
        for (var i = 0; i < apiPresets.length; i++) {
            if (apiPresets[i].apiPresetId == apiPresetId) {
                record = apiPresets[i];
                break;
            }
        }
        return record;
    }


    function onDataSourceDelete(e, dataSourceName) {
        e.preventDefault();
        e.stopPropagation(); 
    }

    function onPageDelete(e, sitePageId) {
        e.preventDefault();
        e.stopPropagation(); 
        //var tmpPageInfo = { ...pageInfo };
        //tmpPageInfo.sitePageId = sitePageId;
        //tmpPageInfo.name = pageName;
        //setPageInfo((...pageInfo) => { return tmpPageInfo });
        var test = "test";
        test = "water";


        BAFetchData({
            callName: "DeletePageRecord", method: "DELETE", url: "cms/sitepages/delete?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function connect() {
        var apiUrl = dataSource.apiEndpoint + '?'
        if (dataSource.designParameters != '') {
            apiUrl += dataSource.designParameters;
        }
        if (dataSource.pageSize != "") {
            if (helpers.getLastChar(apiUrl) == "?") {
                apiUrl += "paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSource.pageSize });
            } else {
                apiUrl += "&paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSource.pageSize });
            }
        }

        BAFetchData({
            callName: "Connect", method: "GET", url: process.env.REACT_APP_API_BASE_URL + apiUrl,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        }); 
    }

    function getPresetData(platform) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "platform", "ConditionOperator": "EQUAL", "Value": platform, "DataType": "String" }
                    ]
                }
            ]
        };
        BAFetchData({
            callName: "GetPresetData", method: "GET", url: "api/preset/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getPlatformList() {
        BAFetchData({
            callName: "GetPlatformList", method: "GET", url: "api/preset/getplatformlist?filtering={}",
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }




    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "Connect":               
                var tmpDataSource = { ...dataSource };
                if (Array.isArray(data) == true) {
                    var headerXPagination = JSON.parse(header['x-pagination']);
                    if (data[0] != undefined) {
                        tmpDataSource.properties = data[0];
                    }
                    tmpDataSource.rows = data;
                    tmpDataSource.paginationInfo = headerXPagination;
                } else {
                    tmpDataSource.properties = data;
                    tmpDataSource.rows.push(data);
                    tmpDataSource.paginationInfo = null;
                }
                
                if (handlerReturnValue) {
                    handlerReturnValue(tmpDataSource);
                }
                setDataSource((...dataSource) => { return tmpDataSource });
                break;
            case "GetPresetData":
                setApiPresets(data);
                break;
            case "GetPlatformList":
                setPlatformList(data);
                break;
            case "GetFilterFieldsByPresetId":
                setFilterFields(JSON.parse(data.filterFields));
                break;
            //case "DeletePageRecord":
            //    //resetResults(); //Reset search results and clear records selected.               
            //    var msg = "Page has been deleted.";
            //    setGenericDialogSchema({ title: 'Page Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); beginSearch(); handleOnPageDelete(); }});
            //    setOpenDialog(true);
            //    break;
            //case "GetPageInfo":
            //    handleOnPageClick(data.sitePageId, data);
            //    break;
        }
    }

    function onAPIError(error, callName) {

        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function loadDefaultFilterFields(apiPresetId) {
        BAFetchData({
            callName: "GetFilterFieldsByPresetId", method: "GET", url: "api/preset/getrecord?apipresetid=" + apiPresetId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function updateDesignFilters(filteringStr) {
        var tmpDataSource = { ...dataSource };
        tmpDataSource.designParameters = "filtering=" + filteringStr;
        setDataSource(tmpDataSource);
    }

    function updateRuntimeFilters(filteringStr) {
        var tmpDataSource = { ...dataSource };
        tmpDataSource.runtimeParameters = "filtering=" + filteringStr;
        setDataSource(tmpDataSource);
    }

    function onOnPageClick(e, sitePageId, pageName) {
        BAFetchData({
            callName: "GetPageInfo", method: "GET", url: "cms/sitepages/getrecord?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });        
    }

    function onPlatformChange(e, platform) {
        var tmpDataSource = { ...dataSource };
        tmpDataSource.platform = platform;
        setDataSource(tmpDataSource);
        getPresetData(platform);
    }

    useEffect(() => {
        if (dataSourceItem != null) {
            setDataSource(dataSourceItem);
        } else {
            setDataSource({ id: helpers.getUUID(), platform: '', apiType: '', apiPresetId: '', sourceName: '', apiEndpoint: '', designParameters: '', runtimeParameters: '', method: "GET", properties: {}, rows: [], pageSize: 4, paginationInfo: null, relationInfo: '' })
        }
        getPlatformList();
        if (dataSourceItem != null) {
            if (dataSourceItem.platform != null && dataSourceItem.platform != '') {
                getPresetData(dataSourceItem.platform);
                loadDefaultFilterFields(dataSourceItem.apiPresetId);
            }
        }

    }, [dataSourceItem]);


    return (
        <>
            <div className="fg-app-page-box" style={{ maxWidth: "900px",border:"none" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="segment">
                            <div className="segment-title" style={{display:"none"} }>Data Source Info</div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="apiPreset"
                                                        onChange={(e) => { onPlatformChange(e, e.target.value); }}
                                                        label="API Platform"
                                                        value={dataSource.platform}
                                                    >
                                                        <MenuItem value="">None</MenuItem>
                                                        {platformList.map((platform, index) => (
                                                            <MenuItem value={platform}>{platform}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{errorList['memberType'] ? <span style={{ color: "red" }}>{errorList['memberType']}</span> : ''} </FormHelperText>
                                                </FormControl> 
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Preset</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="apiPreset"
                                                        onChange={(e) => { onPresetChange(e, e.target.value); }}
                                                        label="Preset"
                                                        value={dataSource.apiPresetId}
                                                    >
                                                        <MenuItem value="">None</MenuItem>
                                                        {apiPresets.map((preset, index) => (
                                                            <MenuItem value={preset.apiPresetId}>{preset.presetName}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{errorList['memberType'] ? <span style={{ color: "red" }}>{errorList['memberType']}</span> : ''} </FormHelperText>
                                                </FormControl>                                                <TextField id="sourceName" autoFocus required fullWidth label="Source Name" variant="standard" helperText={errorList['sourceName'] ? <span style={{ color: "red" }}>{errorList['sourceName']}</span> : ''} style={{ marginBottom: "16px" }} value={dataSource.sourceName || ''} InputLabelProps={{ shrink: dataSource.sourceName }} onChange={(e) => setDataSource(dataSource => ({ ...dataSource, sourceName: e.target.value }))} />                                                
                                                <TextField id="apiEndpoint" required fullWidth label="API Endpoint" variant="standard" helperText={errorList['apiEndpoint'] ? <span style={{ color: "red" }}>{errorList['apiEndpoint']}</span> : ''} style={{ marginBottom: "16px" }} value={dataSource.apiEndpoint || ''} InputLabelProps={{ shrink: dataSource.apiEndpoint }} onChange={(e) => setDataSource(dataSource => ({ ...dataSource, apiEndpoint: e.target.value }))} />
                                                <TextField id="designParameters" required fullWidth label="Design Parameters" variant="standard" helperText={errorList['designParameters'] ? <span style={{ color: "red" }}>{errorList['designParameters']}</span> : ''} style={{ marginBottom: "16px" }} value={dataSource.designParameters || ''} InputLabelProps={{ shrink: dataSource.designParameters }} onChange={(e) => setDataSource(dataSource => ({ ...dataSource, designParameters: e.target.value }))} />
                                                {dataSource.designParameters.indexOf('filtering=') > -1 ?
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <FilteringCtrl filterFields={filterFields} handleOnDone={(filteringStr) => { updateDesignFilters(filteringStr); }}></FilteringCtrl>
                                                    </div>
                                                    : null}
                                                <TextField id="runtimeParameters" required fullWidth label="Runtime Parameters" variant="standard" helperText={errorList['runtimeParameters'] ? <span style={{ color: "red" }}>{errorList['runtimeParameters']}</span> : ''} style={{ marginBottom: "16px" }} value={dataSource.runtimeParameters || ''} InputLabelProps={{ shrink: dataSource.runtimeParameters }} onChange={(e) => setDataSource(dataSource => ({ ...dataSource, runtimeParameters: e.target.value }))} />
                                                {dataSource.runtimeParameters.indexOf('filtering=') > -1 ?
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <FilteringCtrl filterFields={filterFields} handleOnDone={(filteringStr) => { updateRuntimeFilters(filteringStr); }}></FilteringCtrl>
                                                    </div>
                                                    : null}
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Page Size</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="pageSize"
                                                        value={dataSource.pageSize || ''}
                                                        onChange={(e) => setDataSource(dataSource => ({ ...dataSource, pageSize: e.target.value }))}
                                                        label="Page Size"
                                                    >
                                                        <MenuItem value="">N/A</MenuItem>
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={6}>6</MenuItem>
                                                        <MenuItem value={7}>7</MenuItem>
                                                        <MenuItem value={8}>8</MenuItem>
                                                        <MenuItem value={9}>9</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={11}>11</MenuItem>
                                                        <MenuItem value={12}>12</MenuItem>
                                                        <MenuItem value={13}>13</MenuItem>
                                                        <MenuItem value={14}>14</MenuItem>
                                                        <MenuItem value={15}>15</MenuItem>
                                                        <MenuItem value={16}>16</MenuItem>
                                                        <MenuItem value={17}>17</MenuItem>
                                                        <MenuItem value={18}>18</MenuItem>
                                                        <MenuItem value={19}>19</MenuItem>
                                                        <MenuItem value={20}>20</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errorList['memberType'] ? <span style={{ color: "red" }}>{errorList['memberType']}</span> : ''} </FormHelperText>
                                                </FormControl>
                                                <TextField id="relationInfo" fullWidth label="Relation Info" variant="standard" helperText={errorList['relationInfo'] ? <span style={{ color: "red" }}>{errorList['relationInfo']}</span> : ''} style={{ marginBottom: "16px" }} value={dataSource.relationInfo || ''} InputLabelProps={{ shrink: dataSource.relationInfo }} onChange={(e) => setDataSource(dataSource => ({ ...dataSource, relationInfo: e.target.value }))} />                                                
                                                <Button variant="contained" size="large" fullWidth onClick={() => { connect(); }}>CONNECT</Button>
                                                <div style={{marginTop:"10px"}}>Return Properties:</div>
                                                <div>            
                                                    {Object.keys(dataSource.properties).map((key, pi) => (
                                                        <div key={"dsi_" + pi} style={{ display: "inline-block", padding: "0px 8px 0px 8px", backgroundColor: "whitesmoke", border: "1px solid #c0c0c0", margin: "4px 8px 4px 0px", borderRadius: "6px" }}>{key}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default DataSourceSettingCtrl;
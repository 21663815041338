import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import BoardMemberCtrl from './BoardMembersCtrl';
import DialogCtrl from '../dialogctrl/dialogctrl';
import Boards from '../../pages/Boards';
import { BAFetchData } from '../../customhooks/useBAFetch';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';

function ProjectBoardMapCtrl({ projectId, boardMaps, onChange }) {

    const [boardMapList, setBoardMapList] = useState(boardMaps);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    //function onValueChange(e, index, propertyName, value) {
    //    var tmpBoardListMap = [...boardListMap];
    //    tmpColumnList[index][propertyName] = value;
    //    if (tmpColumnList[index].internalState == 'unchanged') {
    //        tmpColumnList[index].internalState = 'modified';
    //    }
    //    onChange(e, tmpColumnList);

    //    setColumnList(tmpColumnList);
    //}

    function handleOnAddBoardBtnClick(e) {
        openBoardSearch();
    }

    async function cardsExists(boardId) {

        const data = await BAFetchData({
            callName: "GetCardCount",
            method: "GET",
            url: "pm/board/cardcount?projectid=" + projectId + "&boardId=" + boardId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function handleOnBoardRemoveBtnClick(e, index, boardId) {
        var total = await cardsExists(boardId);
        if (total.data == 0) {
            var tmpBoardMapList = [...boardMapList];
            switch (tmpBoardMapList[index].internalState) {
                case 'added':
                    tmpBoardMapList.splice(index, 1);
                    break;
                case 'unchanged':
                    tmpBoardMapList[index].internalState = 'deleted';
                    break;
            }
            //handleOnMemberListChange(tmpBoardMapList);
            setBoardMapList(tmpBoardMapList);
        } else {

            var msg = 'Cards exists for this board and project. Open the project and remove the cards from board first.';
            setGenericDialogSchema({ title: 'Warning', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function openBoardSearch() {
        var msg = '';
        setGenericDialogSchema({
            title: 'Workflow Board Search',
            component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                <Boards handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Boards>,
            body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success',
            closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('BoardSearch', returnValues); }
        })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'BoardSearch':
                BAFetchData({
                    callName: "BoardSearch",
                    method: "GET",
                    url: "pm/board/getrecord?boardId=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }

    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'BoardSearch':
                var tmpBoardMapList = [...boardMapList];
                tmpBoardMapList.push(getNewBoardMapSchema(projectId, data));
                setBoardMapList(tmpBoardMapList);
                onChange(tmpBoardMapList);
                break;
        }
    }

    function getNewBoardMapSchema(projectId, boardData) {
        var newRecord = {
            pbMapId: helpers.getUUID(), baid: 0, projectId: projectId, boardId: boardData.boardId,
            board: {
                boardId: boardData.boardId, boardName: boardData.boardName,baid:boardData.baid,boardOwnerId:boardData.boardOwnerId,
                boardMembers:[]
            },internalState:"added"
        };

        return newRecord;
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleOnMemberListChange(tmpMemberList, index) {
        var tmpBoardMapList = [...boardMapList];
        tmpBoardMapList[index].board.boardMembers = tmpMemberList;
        setBoardMapList(tmpBoardMapList);
        onChange(tmpBoardMapList);
    }

    useEffect(() => {
        setBoardMapList(boardMaps);
    }, [boardMaps]);

    return (
        <>
            {boardMapList != null ?
                <div>
                    {boardMapList.map((boardMap, index) => (
                        boardMap.internalState != 'deleted' ?
                        <div style={{ border: "1px solid  #C0C0C0", borderRadius: "6px", padding: "10px", display: "grid", gridTemplateColumns: "25px 1fr 25px", gridGap: "10px", width: "100%", marginBottom: "20px" }}>
                            <div>
                                <DragIndicatorIcon />
                            </div>
                            <div>
                                <div style={{ fontSize: "12px" }}>Board #{index + 1}</div>
                                <div style={{ fontWeight: "500", fontSize: "20px", marginBottom: "20px" }} >{boardMap.board.boardName}</div>
                                <div>
                                    <BoardMemberCtrl projectId={projectId} boardId={boardMap.boardId} boardMembers={boardMap.board.boardMembers} onChange={(tmpMemberList) => { handleOnMemberListChange(tmpMemberList, index);}} />
                                </div>
                            </div>
                                <div onClick={(e) => { handleOnBoardRemoveBtnClick(e, index, boardMap.boardId); }}><DeleteIcon /></div>
                            </div>
                        : null
                    ))}
            </div>
            : null}
            <Button variant="outlined" color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { handleOnAddBoardBtnClick(e) } }>Add Workflow Board</Button>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default ProjectBoardMapCtrl;

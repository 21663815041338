import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';

function Welcome() {
    const [currentUser] = useGlobalState("userInfo");

    return (
        <>
                <div className="fg-app-page-box">
                    <div style={{display:"grid",gridTemplateColumns:"1fr",gridWidth:"100%",gridGap:"10px"}}>
                        <div>
                        <h1>Welcome {currentUser.firstName} {currentUser.lastName}</h1>

                        </div>
                    </div>         
                    <TransactionViewerCtrl/>
                </div>
        </>
    );
}

export default Welcome;


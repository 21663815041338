import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { BAChangeDefaultHeaders, BAFetchData } from '../../customhooks/useBAFetch';

function ForgotPasswordForm() {

    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    

    const [formData, setFormData] = useState({ email: null});
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const navigate = useNavigate();
    const [status, setStatus] = useState("Open");


    function tryAgain() {
        setStatus("Open");
    }

    function recoverLoginInfo() {
        if (isCustomFormValidationValid() == true) {
            BAFetchData({
                callName: "SendResetInfo",
                method: "GET",
                url: "security/user/forgotpassword?email=" + formData.email,
                token: wlInfo.token,
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "SendResetInfo":
                if (data == true) {
                    setStatus("Completed");
                } else {
                    setStatus("Error");
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error.message;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function isCustomFormValidationValid() {
        var isValid = true;
        var tempErrorList = {};
        if (formData.email == null || formData.email == '') {
            isValid = false;
            tempErrorList['email'] = "Email is required";
        }
        setErrorList(tempErrorList);
        return isValid;
    }

    useEffect(() => {
        BAChangeDefaultHeaders(wlInfo.token);
    }, []);

    return (
        <>
            <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Recover Password</div>
            {status == "Open" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center" }}><small>Enter your Email and instructions will be sent to you.</small></div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextField id="email" autoFocus required fullWidth label="Email" variant="outlined" size="medium" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value.toLowerCase() }))} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <Button variant="contained" size="large" fullWidth onClick={() => { recoverLoginInfo(); }}>RECOVER LOGIN INFO</Button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {status == "Completed" ?
                <div>
                    <div style={{ marginBottom: "20px", textAlign: "center", fontSize:"20px" }}>Email Found...</div>
                    <div style={{ marginBottom: "20px", textAlign: "center" }}><small>Check your inbox for password reset instructions</small></div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {status == "Error" ?
                <div>
                    <div style={{ marginBottom: "20px", textAlign: "center", fontSize: "20px", color:"red" }}>Email not found</div>
                    <div style={{ marginBottom: "20px", textAlign: "center" }}><small>Sorry... We cannot find the email {formData.email} in our database.</small>.</div>
                    <div>
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                                <Button variant="contained" size="large" fullWidth onClick={() => { tryAgain(); }}>Try Again</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );


}

export default ForgotPasswordForm;
import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import Chip from '@mui/material/Chip';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import TextField from '@mui/material/TextField';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import ProductEngineUtil from '../../helpers/ProductEngineUtil';

function VariantPickerCtrl({ productId, onSelect }) {
    const amountsDueRef = useRef();
    amountsDueRef.current = { "price": 0, "setupFee": 0, "downpayment": 0, summary: "" };
    const [id, setId] = useState(productId);
    const [formData, setFormData] = useState({});
    const [variantsSelected, setVariantsSelected] = useState({});
    const [available, setAvailable] = useState(false);
    const [variantRecord, setVariantRecord] = useState(null);
    const [options, setOptions] = useState(null);
    //const [variantList, setVariantList] = useState(productTypeVarAttrList);
    

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function setupVariantsSelected(list) {
        var tmpVariantsSelected = {};
        for (var i = 0; i < list.length; i++) {
            if (list[i].attrName != null && list[i].attrLabel != null && list[i].options != null) {
                tmpVariantsSelected[list[i].attrName] = { "value": null };
            }
        }
        setVariantsSelected(tmpVariantsSelected);
    }

    function onVariantClick(e, attrName, option) {
        var tmpVariantsSelected = { ...variantsSelected };
        tmpVariantsSelected[attrName].value = option;
        setVariantsSelected(tmpVariantsSelected);
    }

    function allVariantsSelected() {
        var allSelected = true;
        for (var key in variantsSelected) {
            if (variantsSelected[key].value == null) {
                allSelected = false;
            }
        }
        return allSelected;
    }

    function isAvailable() {
        //var available = "No";
        setVariantRecord(null);
        var variant = null;
        var variantAttrInfo = null;
        var found = false;
        if (formData.productVariants.length > 0) {
            for (var vi = 0; vi < formData.productVariants.length; vi++) {
                variant = formData.productVariants[vi];
                found = false;
                for (var vai = 0; vai < variant.productVariantAttributeList.length; vai++) {
                    variantAttrInfo = variant.productVariantAttributeList[vai];
                    if (variantAttrInfo.attrName != 'Color-Swatch' && variantAttrInfo.attrName != 'Color-Thumbnail') {
                        if (variantsSelected[variantAttrInfo.attrName].value == variantAttrInfo.attrValue) {
                            found = true;
                        } else {
                            found = false;
                            break;
                        }
                    }

                    if (found == true) {
                        setVariantRecord(variant);
                        break;
                    }
                }
                if (found == true) {
                    break;
                }
            }
        } else {
            found = true;
        }
        //if (found == false) {
        //    setVariantRecord(null);
        //}
        setAvailable(found);
    }

    function renderPriceTitle(formData) {
        var text = "";
        if (formData.recurType == "Payment Plan" || formData.recurType == "Subscription-Static" || formData.recurType == "Subscription") {
            text =  " Options";
        }
        return text;
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                setFormData(data);
                setupVariantsSelected(data.productTypeVarAttrList);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        if (JSON.stringify(formData) != "{}") {
            isAvailable();
            //getOptions();
        }
    }, [variantsSelected]);

    useEffect(() => {
        getOptions();
    }, [formData,variantRecord,available]);

    useEffect(() => {
        if (productId != null) {
            setId(productId);

            BAFetchData({
                callName: "GetMainRecord",
                method: "GET",
                url: "ecommerce/product/getformrecord?productid=" + productId,
                token: "fg1234",
                toggleRefetch: null,
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }, [productId]);
  

    function getOptions() {
        if (allVariantsSelected() == true) {
            if (JSON.stringify(formData) != "{}") {
                var tmpOptions = ProductEngineUtil.GetPaymentOptions(formData, variantRecord);
                setOptions(tmpOptions);
            }
        }
    }

    function handleOnSelect(paymentOption) {
        var selectedProductInfo = ProductEngineUtil.GetSelectedProductCheckoutInfo(paymentOption, formData, variantRecord);
        onSelect(selectedProductInfo);
    }

    return (
        <>
            {JSON.stringify(formData) != "{}" ?
                <>
                    {formData.productTypeVarAttrList.map((variant, index) => (
                        (variant.internalState != 'deleted' && variant.attrName != null && variant.attrLabel != null && variant.options != null) ?
                            <div style={{ marginBottom: "20px" }}>
                                <div style={{ fontSize: "20px", fontWeight: "500", marginBottom: "10px" }}>{"Select " + variant.attrLabel + ":"}</div>
                                <div style={{ display: "inline-flex", flexWrap: "wrap", rowGap: "10px" }}>
                                    {(variant.options.split(',')).map((option, oi) => (
                                        variant.attrName.toLowerCase() != 'color' ?
                                            <div style={{ borderRadius: "4px", backgroundColor: "#E1E2E9", color: "black", textAlign: "center", minWidth: "32px", padding: "3px 6px 3px 6px", marginRight: "7px", cursor: "pointer" }} onClick={(e) => { onVariantClick(e, variant.attrName, option); }}>{option}</div>
                                            :
                                            <div style={{ border: "1px solid #DEDEDE", color: "black", textAlign: "center", padding: "3px", marginRight: "7px", cursor: "pointer" }} title={option} onClick={(e) => { onVariantClick(e, variant.attrName, option); }}>
                                                <div style={{ backgroundColor: option, width: "32px", height: "32px", }}></div>
                                            </div>
                                    ))}
                                </div>
                            </div>
                            : null
                    ))}               
                    <div>
                        {formData.productVariants.length > 0 ?
                            <>
                                <div style={{fontWeight:"bold"}}>What You Selected:</div>
                                <div style={{borderBottom:"1px solid grey"}}>
                                    {Object.keys(variantsSelected).map((key, index) => (
                                        <div>{key + ':  ' + (variantsSelected[key].value || '')}</div>
                                    ))}                            
                                </div>
                            </>
                            : null}
                        {allVariantsSelected() == false ?
                            <div style={{ marginTop: "10px", fontWeight: "bold", color: "red" }}>All variant options must be selected</div>
                            : 
                            <>
                                <div style={{ marginTop: "10px", fontWeight: "bold", color: (available == true ? "green" : "red") }}>{available == true ? "Is Available" : "Not Available"}</div>
                                {available == true ?
                                    <div style={{marginTop:"20px"}}>
                                        <div style={{ fontSize: "20px", fontWeight: "500", marginBottom: "10px" }}>Price{renderPriceTitle(formData)}</div>
                                        <div>       
                                            {options != null ?
                                                <>
                                                    <div style={{backgroundColor:"whitesmoke",padding:"15px"}}>
                                                        <div style={{fontWeight:"bold",marginBottom:"10px"}}>Options #1</div>
                                                        <div>
                                                            {options.option1.summary}
                                                        </div>
                                                        <div style={{ marginTop: "20px" }}><button class='btn btn-sm btn-primary' onClick={(e) => { handleOnSelect("option1"); }}>Add to Cart</button></div>
                                                    </div>
                                                    {options.option2.summary != "" ?
                                                        <>
                                                            <div style={{marginTop:"10px",marginBottom:"10px"}}>-OR-</div>
                                                            <div style={{ backgroundColor: "whitesmoke", padding: "15px" }}>
                                                                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Options #2</div>
                                                                <div>
                                                                    {options.option2.summary}
                                                                </div>
                                                                <div style={{ marginTop: "20px" }}><button class='btn btn-sm btn-primary' onClick={(e) => { handleOnSelect("option2"); }}>Add to Cart</button></div>
                                                            </div>
                                                         </>
                                                        : null}
                                                </>
                                                : null}
                                        </div>
                                    </div>
                                    : null}
                            </>
                        }
                        
                        {(allVariantsSelected() == true && available == "truess") ?
                            (formData.recurType == '' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'SubscriptionOnly') ?
                                <div style={{ marginTop: "20px" }}><button class='btn btn-sm btn-primary' onClick={(e) => { handleOnSelect(formData.recurType); }}>Add to Cart</button></div>
                            : null
                            : null}
                    </div>
                </>
            : null}

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default VariantPickerCtrl;

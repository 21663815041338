import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import Invoice from '../../../pages/Invoice';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import Order from '../../../pages/Order';
import Quote from '../../../pages/Quote';
import Payment from '../../../pages/Payment';


function ContactTransactionsCtrl({ contactId, defaultTransactionType }) {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);
    //let params = useParams();

    let transType = defaultTransactionType;
    const [transactionType, setTransactionType] = useState(defaultTransactionType);
    const [columns, setColumns] = useState([]);
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "automations_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('automations_search_page', 'header_section', getPageCaption());
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);

    var newOptions = [];
    switch (transType) {
        case "order":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quick Sales Order'), name: "OrderQS", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/order/0") } })
            break;
        case "quote":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quote'), name: "Quote", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/quote/0") } });
            break;
        case "invoice":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Invoice'), name: "Invoice", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/invoice/0") } });
            break;
        case "payment":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Payment'), name: "Payment", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/payment/0") } });
            break;
    }

    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } }

                ]
            },
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions
            }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }



    function resetGridColumns() {
        var tmpColumns = [];

        tmpColumns.push({
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
        });

        tmpColumns.push({
            field: 'headerId',
            headerName: getTranslation('automations_search_page', 'search_grid', 'ID'),
            width: 75,
            renderCell: function (params) {
                return <span style={{ fontSize: "22px", fontWeight: "bold" }}>{params.row.headerId}</span>;
            }
        });
        if (transType == 'order') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'totalAmtDue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.totalAmtDue, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + params.row.headerAmt}</span>
                    </div>
                }
            });
            //tmpColumns.push(buildPostColumnSettings(params));
        } else if (transType == 'quote') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Expire Date:</span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Quote Amount'),
                width: 125,
                renderCell: function (params) {
                    return <span style={{ fontWeight: "bold" }}>{params.row.headerAmt}</span>;
                }
            });
            //tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'status',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Status'),
                width: 200,
                renderCell: function (params) {
                    if (params.row.status == "Draft") {
                        return <Chip label="Draft" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Open") {
                        return <Chip label="Open" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Accepted") {
                        return <Chip label="Accepted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                    } else if (params.row.status == "Rejected") {
                        return <Chip label="Rejected" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    }
                }
            });
        } else if (transType == 'invoice') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Due Date:</span><span>{dayjs(params.row.dueDate).format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.headerAmt, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + helpers.formatNumber(params.row.totalPaid, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Balance:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px", color: "#FC4B6C" }}>{"$" + helpers.formatNumber(params.row.openBalance, 2)}</span>
                    </div>
                }
            });
            //tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'lateInfo',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Late Info'),
                width: 200,
                headerAlign: 'left',
                align: 'left',
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Days Late: </span><span style={{ display: "inline-block", fontSize: "12px", width: "80px", color: "red" }}>{params.row.daysLate}</span>
                        <br /><span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Attempt Date:</span><span style={{ display: "inline-block", fontSize: "12px", width: "80px" }}>{(params.row.lastPaymentAttemptDate != null ? dayjs(params.row.lastPaymentAttemptDate).format('MM/DD/YYYY') : "")}</span>
                        <br /><Chip label={"Late Notice Attempts: " + (params.row.PaymentAttempts != null ? params.row.PaymentAttempts : 0)} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white" }} />
                    </div>
                }
            });
            tmpColumns.push({
                field: 'status',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Status'),
                width: 200,
                renderCell: function (params) {
                    if (params.row.status == "Draft") {
                        return <Chip label="Draft" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Open") {
                        return <Chip label="Open" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Paid") {
                        return <Chip label="Paid" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                    } else if (params.row.status == "Past Due") {
                        return <Chip label="Past Due" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    }
                }
            });
        } else if (transType == 'payment') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Paid'),
                width: 125,
                renderCell: function (params) {
                    return <span style={{ fontWeight: "bold" }}>{params.row.headerAmt}</span>;
                }
            });
            //tmpColumns.push(buildPostColumnSettings(params));
        } else if (transType == 'subscription') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}>
                        <span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.headerAmt, 2)}</span>
                        <br /><span style={{ display: "inline-block", fontSize: "12px" }}>{'every' + (params.row.billInterval > 1 ? params.row.billInterval : '') + ' ' + params.row.billPeriod}</span>
                    </div>
                }
            });
            tmpColumns.push({
                field: 'nextBillDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Next Bill Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.nextBillDate).format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'daysLate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Days Late'),
                width: 110,
                renderCell: function (params) {
                    var daysLate = 0;
                    if (params.row.subscribeStatus == 'Renewing') {
                        var date1 = dayjs("11/01/2023");
                        var date2 = dayjs();
                        daysLate = date2.diff(date1, 'day');
                    }
                    return daysLate;
                }
            });
            tmpColumns.push({
                field: 'endDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'End Date'),
                width: 110,
                renderCell: function (params) {
                    if (params.row.billLength >= 1) {
                        var endDate = dayjs(params.row.transactionDate);
                        if (params.row.freeTrialInterval > 0) {
                            endDate = dayjs(endDate).add(params.row.freeTrialInterval, params.row.freeTrialPeriod);
                        }
                        endDate = dayjs(endDate).add((params.row.billLength * params.row.freeTrialInterval), params.row.freeTrialPeriod);
                        return dayjs(endDate).format('MM/DD/YYYY');
                    } else {
                        return "";
                    }
                }
            });
            tmpColumns.push({
                field: 'cancellationDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Cancel Date'),
                width: 110,
                renderCell: function (params) {
                    return (params.row.cancellationDate != null ? dayjs(params.row.cancellationDate).format('MM/DD/YYYY') : "");
                }
            });
            //tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'subscribeStatus',
                headerName: getTranslation('products_search_page', 'search_grid', 'Status'),
                width: 120,
                renderCell: function (params) {
                    switch (params.row.subscribeStatus) {
                        case "Trial":
                        case "Active":
                        case "Internal Acct":
                            return <Chip label={params.row.subscribeStatus} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                            break;
                        case "Renewing":
                            return <Chip label="Active" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white", marginBottom: "3px" }} />
                            break;
                        case "Inactive":
                        case "Cancelled":
                            return <Chip label={params.row.subscribeStatus} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />
                            break;
                    }
                }
            });
        }


        setColumns(tmpColumns);
    }

    function buildPostColumnSettings(params) {
        return {
            field: 'posted',
            headerName: getTranslation('products_search_page', 'search_grid', 'Posted'),
            width: 120,
            renderCell: function (params) {
                return <div>
                    {params.row.posted == true ?
                        <>
                            <Chip label="Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not-Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.emailSent == true ?
                        <>
                            <Chip label="Email Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Email Not Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.fulfilled == true ?
                        <>
                            <Chip label="Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                </div>
            }
        };
    }


    function getPageCaption() {
        var title = "";
        switch (transType) {
            case 'order':
                title = "Orders";
                break;
            case 'quote':
                title = "Quotes";
                break;
            case 'invoice':
                title = "Invoices";
                break;
            case 'payment':
                title = "Payments";
                break;
            case 'subscription':
                title = "Subscriptions";
                break;
        }
        return title;
    }

    function newRecord() {
        switch (transactionType) {
            case "order":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Order dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={0} contactId={contactId}></Order>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "quote":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Quote dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={0} contactId={contactId}></Quote>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "invoice":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Invoice dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={0} contactId={contactId}></Invoice>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('InvoiceEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "payment":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Payment dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={0} contactId={contactId}></Payment>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "subscription":
                //navigate('/subscription/' + row.headerId);
                break;
        }
    }

    function onGridOpenBtnClick(row) {
        switch (transactionType) {
            case "order":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Order dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={row.headerId}></Order>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "quote":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Quote dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={row.headerId} ></Quote>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "invoice":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Invoice dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={row.headerId} ></Invoice>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('InvoiceEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "payment":
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <Payment dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} headerId={row.headerId} ></Payment>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
            case "subscription":
                navigate('/subscription/' + row.headerId);
                break;
        }

    }

    function onPopupOK(returnCategory, returnValues) {
        resetResults();
    }



    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {
        var filtering = {};
        var conditionalGroups = [];
        var conditions = [];

        conditions.push({ "Name": "customerId", "ConditionOperator": "EQUAL", "Value": contactId, "DataType": "Number" });
        conditions.push({ "Name": "transactionType", "ConditionOperator": "EQUAL", "Value": transactionType, "DataType": "String" });

        conditionalGroups.push({ "LogicalOperator": "and", "Conditions": conditions })

        if (criteria != null && criteria != '') {
            conditions = [];
            if (helpers.isNumeric(criteria)) {
                conditions.push({ "Name": "headerId", "ConditionOperator": "EQUAL", "Value": criteria, "DataType": "String" });
            } else {
                conditions.push({ "Name": "customerName", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
                conditions.push({ "Name": "customerEmail", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
            }
            conditionalGroups.push({ "LogicalOperator": "or", "Conditions": conditions })
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": conditionalGroups
        };

        if (transactionType == "invoice") {
            BAFetchData({
                callName: "GetData",
                method: "GET",
                url: "accounting/transactionheader/getinvoicelist?filtering=" + JSON.stringify(filtering),
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "GetData",
                method: "GET",
                url: "accounting/transactionheader/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }




    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' transaction' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function deleteRecords() {
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "accounting/transactionheader/BulkDelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setRowIds([]);
        beginSearch();
        //setSearchRefetch(true);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Transaction record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Transaction(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        resetGridColumns();
        //setTransactionType(params.transactionType);
        beginSearch();
    }, [transactionType]);

    function handleTransactionTypeChange(transactionType) {
        transType = transactionType;
        setTransactionType(transactionType);
    }
    return (
        <>
            <div style={{textAlign:"center",backgroundColor:"whitesmoke"} }>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={transactionType}
                        onChange={(e) => { handleTransactionTypeChange(e.target.value); }}
                    >
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Orders') == true ?
                            <FormControlLabel value="order" control={<Radio />} label="Orders" />
                            : null}
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Invoices') == true ?
                            <FormControlLabel value="invoice" control={<Radio />} label="Invoices" />
                            : null}
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Payments') == true ?
                            <FormControlLabel value="payment" control={<Radio />} label="Payments" />
                            : null}
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Subscriptions') == true ?
                            <FormControlLabel value="subscription" control={<Radio />} label="Subscriptions" />
                            : null}
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Quotes') == true ?
                            <FormControlLabel value="quote" control={<Radio />} label="Quotes" />
                            : null}
                        {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-AbandonedCarts') == true ?
                                <FormControlLabel value="carts" control={<Radio />} label="Abandoned Carts" />
                            : null}
                    </RadioGroup>
                </FormControl>
            </div>
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
                <div>
                    <Button variant="outlined" class="btn btn-sm btn-success" size="small" fullWidth onClick={() => { newRecord(); }} style={{ width: "100%" }}>New</Button>
                </div>
            </div>
            <div>
                <Box sx={{ height: 630, width: '100%' }}>
                    <DataGrid
                        sx={{
                            border: 0,
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                outline: "none !important",
                            }, '& .MuiDataGrid-cell': {
                                alignItems: 'start', paddingTop: '10px', paddingBottom: '10px'
                            },
                        }}
                        rowHeight={80}
                        rows={searchResultData}
                        getRowId={(row) => row.headerId}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(ids) => {
                            setRowIds(ids);
                            //if (handlerReturnValue) {
                            //    handlerReturnValue(ids)
                            //}
                        }}
                    />
                </Box>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ContactTransactionsCtrl;

import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


import { DataBrokerContext } from "./PageBuilderCtrl";
import helpers from '../../helpers/Helpers';


function ElementNavCtrl({ elementData, activeId}) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);

    const [elementList, setElementList] = useState(elementData);
    const [selectedId, setSelectedId] = useState(null);
    //const mouseEnterEvent = new Event('mouseEnter');
    //var el = tmpElements["fpgwidget_root"];

    function handleMouseEnter(e, id) {
        //e.preventDefault();
        e.stopPropagation();


        console.log(id);
        console.log(elementList[id].elementName)

        onWidgetMouseEnter(e, id);

        //var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        //var el = iFrameDocument.getElementById(id);
        //el.dispatchEvent(new MouseEvent('mouseover', { 'bubbles': true, cancelable: true }));
    }


    function handleMouseOver(e, id) {

    }



    function handleMouseLeave(e, id) {
        //e.preventDefault();
        //e.stopPropagation();

        //var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        //var el = iFrameDocument.getElementById(id);
        //el.dispatchEvent(new MouseEvent('mouseout', { 'bubbles': true, cancelable: true }));
    }

    function updateActiveHighlight(id) {
        if (selectedId != null) {
            if (document.getElementById(selectedId) != null) {
                document.getElementById(selectedId).classList.remove('fg-element-nav-item-active');
            }
        }
        if (id != null) {
            if (document.getElementById(id) != null) {
                document.getElementById(id).classList.add("fg-element-nav-item-active");
                setSelectedId(id);
            }
        }
    }

    function handleNavClick(e, id) {
        updateActiveHighlight(id);

        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        var el = iFrameDocument.getElementById(id);
        el.click();
        //var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        //onWidgetClick(e, id, widgetData, (updatedWidgetData) => handleOnPropertyChange(updatedWidgetData), () => handleOnHideHighlight(), () => handleOnSave());

    }

    function renderRootElement(id) {
        var tmpElementList = { ...elementList };
        return renderElement(tmpElementList, id);
    }



    function renderElement(tmpElementList, id) {
        var elementObj = tmpElementList[id];
        if (elementObj != undefined) {
            return <li>
                <div id={elementObj.id} className="fg-element-nav-item" style={{ marginLeft: "-10px", paddingLeft: "3px", marginBottom: "3px", display: "grid", gridTemplateColumns: "15px 1fr", gridGap: "0px" }} onMouseOver={(e) => { handleMouseEnter(e, elementObj.id); }} onClick={(e) => { handleNavClick(e, elementObj.id); }}>
                    <div>{elementObj.children.length > 0 ? <ExpandMoreIcon style={{ fontSize: "14px" }} /> : null}</div>
                    {elementObj.hasOwnProperty('customId') ?
                        <div>{(elementObj.customId != '' && elementObj.customId != null) ? elementObj.customId : elementObj.elementName}</div>
                        : <div>{elementObj.elementName}</div>}
                </div>
                {elementObj.children.length > 0 ?
                    <>
                        <ul id={elementObj.id + '_box'} style={{ listStyleType: "none", paddingLeft: "10px", borderLeft: "1px dotted grey" }}>
                            {elementObj.children.map((childId, index) => (
                                renderElement(tmpElementList, childId)
                            ))}
                        </ul>
                    </>
                    : null}
            </li>
        } else {
            <li></li>
        }
    }

    useEffect(() => {
        setElementList(elementData);
    }, [elementData])

    useEffect(() => {
        if (activeId != selectedId) {
            updateActiveHighlight(activeId);
        }
    }, [activeId])

    return (
        <>
            {JSON.stringify(elements) != "{}" ?
                <div style={{ paddingTop: "5px" }}>
                    <ul style={{ listStyleType: "none", paddingLeft: "10px" }}>                    
                        {renderRootElement(helpers.getRootElementId(elements))}
                    </ul>
                </div>
                : null}
        </>
    );

}

export default ElementNavCtrl;
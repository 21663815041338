import React, { useState }  from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import './css/dropdownmenuctrl.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';


export default function DropdownMenuCtrl({ buttonType, buttonText, imageSrc, toolTip, menuItems, avatarText, iconStyle}) {
    //const [anchorEl, setAnchorEl] = React.useState < null | HTMLElement > (null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        //event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: React.MouseEvent<HTMLElement>, menuItem) => {
        //event.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
        if (menuItem != null) {
            menuItem.callback();
        }
    };
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {buttonType != "Button" &&
                    <Tooltip title={toolTip}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 0 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            style={{padding:"0px"}}
                        >
                            {buttonType == "Image" && <img src={imageSrc} style={{ width: "32px", height: "32px", borderRadius: "32px" }}></img>}
                            {buttonType == "Avatar" && <Avatar sx={{ width: 32, height: 32, marginBottom: "5px", fontSize: "19px" }}>{avatarText}</Avatar>}
                            {buttonType == "Add" && <AddIcon sx={iconStyle} />}
                            {buttonType == "More" && <MoreHorizIcon sx={iconStyle} />}
                            
                        </IconButton>
                    </Tooltip>
                }
                {buttonType == "Button" && <Button
                    onClick={handleClick}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined" color="primary">{buttonText}<KeyboardArrowDown/></Button>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={(e) => handleClose(e, null)}
                onClick={(e) => handleClose(e, null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },

                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItems.map(menuItem => { 
                    var tmpKey = menuItem.name.replaceAll(" ", "_");
                    switch (menuItem.menuType) {
                        case "MenuItem":
                            return <MenuItem key={tmpKey} onClick={(e) => handleClose(e, menuItem)}>
                                {menuItem.caption}
                            </MenuItem>
                            break;
                        case "Divider":
                            return <Divider key={tmpKey} />
                            break;
                        case "UserProfile":
                            return <div key={tmpKey}><MenuItem >
                            <div style={{ display: "grid", gridTemplateColumns: "40px 1fr" }}>
                                    <div><Avatar>{menuItem.icon}</Avatar></div>
                                    <div style={{ paddingTop: "7px" }}>{menuItem.caption}</div>                                
                                </div>
                                
                            </MenuItem>
                                <Divider /></div>
                            break;
                    }

                })}
            </Menu>
        </React.Fragment>
    );
}
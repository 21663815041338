import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FGApp from './FGApp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import FGAppWithLayout from './FGAppWithLayout';
import { BAFetchData } from './customhooks/useBAFetch';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const baseUrl = window.__WL__.BASEURL;
//console.log(baseUrl);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

//Get white label info and store in local storage.

async function getWLInfo() {
    console.log('base', baseUrl);
    var path = document.location.href;
    var domain = window.location.protocol + '//' + window.location.host;
    if (path.indexOf('localhost:3000') > -1) {
        domain = 'whitelabel1';
    } else if (path.indexOf('localhost') > -1) {
        domain = baseUrl.replaceAll('\/', '');
    }
    console.log('domain', domain);

    var wlInfo = await BAFetchData({
        callName: "GetData",
        method: "GET",
        url: "security/businessaccount/getwhitelabelinfo?wlappdomain=" + domain,
        token: "fg1234",
        body: null, onSuccess: null, onError: null
    });
    document.title = wlInfo.data.wlName;
    localStorage.setItem('wl-info', JSON.stringify(wlInfo.data));
}

getWLInfo();

//let baseUrl = '/';


//function AppTest() {
//    const { t } = useTranslation();
//    return <h1>{t("overview")}</h1>
//}

root.render(    
    <BrowserRouter basename={baseUrl}>
        <FGAppWithLayout />
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';

function Inactive() {
    const [currentUser] = useGlobalState("userInfo");

    return (
        <>
                <div className="fg-app-page-box">
                    <div style={{display:"grid",gridTemplateColumns:"1fr 300px",gridWidth:"100%",gridGap:"10px"}}>
                        <div>
                        <h2 style={{ color: "red" }}>Account Inactive</h2>
                        <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Business Account is inactive for one of the following reasons:</h1>
                        <p>
                            <ul>
                                <li>Trial period is over.</li>
                                <li>Payment not received.</li>
                                <li>Account cancelled.</li>
                            </ul>
                        </p>
                        <h1 style={{ fontSize: "16px", fontWeight: "500", color:"green" }}>***You can still access other active business accounts. Click on Settings in the main menu to the left and then click on Business Accounts.***</h1>
                        <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>When do accounts become inactive?</h1>
                        <p>
                            Accounts become inactive when payment is late for 3 days after due date. Customer service cannot add extensions.
                        </p>
                        <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>What does inactive account mean?</h1>
                        <p>
                            When an account is inactive the following is true:
                            <ul>
                                <li>You and others users will not be able to access business account.</li>
                                <li>Any websites or funnels are not accessible to the public. Visitors to your websites and funnels will see a message that reads... "Temporarily Unavailable."</li>
                                <li>All automations are paused.</li>
                                <li>Any automated payment processing of customer subscriptions/invoices will be paused.</li>
                                <li>All syncing of 3rd party data through integrations will be paused.</li>
                            </ul>
                        </p>
                        <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Inactive Account Data Retention:</h1>
                        <p>
                            We save all data for "Inactive Accounts" for 30 days after the due date. On the 31st day of inactive status data is deleted. <span style={{fontWeight:"500"}}>Deleted data cannot be restored.</span>
                        </p>
                        </div>
                    </div>                
                </div>
        </>
    );
}

export default Inactive;


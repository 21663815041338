import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

function NoLayout() {
    return (
        /*<div id="app-container" className="fgd-app-container" >            */
            <Outlet />
        /*</div>*/
    );
}

export default NoLayout


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import ContactSearch from './ContactSearch';
import BoardColumnCtrl from '../components/formcontrols/BoardColumnCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import { useGlobalState } from '../globalstates/GState';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function BoardSettings({dialogType,handlerReturnValue}) {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [refreshId, setRefreshId] = useState(0);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.boardId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = {
        "LogicalOperator": "and", "ConditionGroups": [{
            "LogicalOperator": "and", "Conditions": [
                { "Name": "object", "ConditionOperator": "EQUAL", "Value": "automation_settings_page", "DataType": "String" },
                { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }]
        }]
    };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('automation_settings_page', 'header_section', 'Workflow Board Settings');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.boardId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }

    if ((helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectBoards', ['allowDelete']) == true && primaryKeyValue != '0')) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
    }
    if ((helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectBoards', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectBoards', ['allowAdd']) == true && primaryKeyValue == '0')) {
        headerOptions.elements.push({ type: "button", text: getTranslation('automation_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == '0') {
            data.active = true;
            data.boardMembers = [];
        }        
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case 'SaveNewForm':
            case "SaveForm":                
                setPrimaryKeyValue(data.boardId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/boardsettings/' + data.boardId); refreshPage(); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/boards');} })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                tmpFormData.boardOwnerId = data.contactId;
                tmpFormData.boardOwner = data;
                setFormData((...formData) => { return tmpFormData });
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function onColumnsChange(e, tmpColumnList) {
        var tmpFormData = { ...formData };
        tmpFormData.columns = tmpColumnList;
        setFormData(tmpFormData);
    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());
    }

    function openContactSearch(returnCategory) {
        var msg = '';
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK(returnCategory, returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "GetBoardOwner":
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "pm/board/getrecord?boardId=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "pm_boards", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Board Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.pipeline} onChange={(e) => setFormData(formData => ({ ...formData, pipeline: e.target.checked }))} name="gilad" />
                                                }
                                                label="Is Sales Pipeline"
                                            />
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.incidentDefault} onChange={(e) => setFormData(formData => ({ ...formData, incidentDefault: e.target.checked }))} name="gilad" />
                                                }
                                                label="Incident Default"
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="boardName" autoFocus required fullWidth inputProps={{ maxLength: 55 }} label="Board Name"  variant="standard" helperText={errorList['boardName'] ? <span style={{ color: "red" }}>{errorList['boardName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.boardName || ''} InputLabelProps={{ shrink: formData.boardName }} onChange={(e) => setFormData(formData => ({ ...formData, boardName: e.target.value }))} />
                                                    <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />
                                                    <LookupFieldCtrl dataPackage={formData.boardOwner || ''} textFieldName="contactName" valueFieldName="contactId" label="Board Owner" helperText={errorList['boardOwnerId'] ? <span style={{ color: "red" }}>{errorList['boardOwnerId']}</span> : ''} onClick={() => { openContactSearch("GetBoardOwner"); }} onRemove={() => { setFormData(formData => ({ ...formData, boardOwnerId: null })); setFormData(formData => ({ ...formData, boardOwner: null })); }}></LookupFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment" style={{marginTop:"20px"}}>
                                <div className="segment-title">Status Columns</div>
                                <formHelperText className="fg-helper-text">{errorList['columns'] ? <span style={{ color: "red" }}>{errorList['columns']}</span> : ''} </formHelperText>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <BoardColumnCtrl boardId={formData.boardId} columns={formData.columns} onChange={(e, tmpColumnList) => { onColumnsChange(e, tmpColumnList); } } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete workflow board. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "pm/board/delete?boardid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    async function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (await isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        if (primaryKeyValue == '0') {
            var tmpFormData = { ...formData };
            //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "pm/board/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            var tmpFormData = { ...formData };
            //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "pm/board/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    async function boardNameExists() {
        const data = await BAFetchData({
            callName: "BoardNameExists",
            method: "GET",
            url: "pm/board/boardnameexists?boardname=" + formData.boardName + "&boardid=" + formData.boardId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    function duplicateColumnNameExists() {
        let isDuplicate = false;
        for (var mci = 0; mci < formData.columns.length; mci++) {
            let masterColumnName = formData.columns[mci].columnName;
            for (var ci = 0; ci < formData.columns.length; ci++) {
                if (formData.columns[ci].columnName == masterColumnName && mci != ci) {
                    isDuplicate = true;
                    break;
                }
            }            
            if (isDuplicate == true) {
                break;
            }
        }
        return isDuplicate;
    }

    function isColumnCountValid() {
        var count = 0;
        var valid = true;
        for (var i = 0; i < formData.columns.length; i++) {
            if (formData.columns.internalState != 'deleted') {
                count++;
            }
        }
        if (count < 2) {
            valid = false;
        }
        return valid;
    }

    async function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.boardName == null || formData.boardName == '') {
            isValid = false;
            tempErrorList['boardName'] = "Is required.";
        } else {
            if (formData.boardName.length <= 3) {
                isValid = false;
                tempErrorList['boardName'] = "Should be three(3) or more characters.";
            }

            var total = await boardNameExists();
            if (total.data > 0) {
                isValid = false;
                tempErrorList['boardName'] = "Board name already exist. Enter a different board name.";
            }
        }
        if (helpers.isListEmpty(formData.columns) == true) {
            isValid = false;
            tempErrorList['columns'] = "At least two status column is required.";
        } else {
            if (isColumnCountValid() == false) {
                isValid = false;
                tempErrorList['columns'] = "At least two status column is required.";
            }
        }
        if (duplicateColumnNameExists() == true) {
            isValid = false;
            tempErrorList['columns'] = "Duplicate column names is not allowed.";
        }
        

        setErrorList(tempErrorList);
        return isValid;
    }
}

export default BoardSettings;
import React, { useContext, useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import { useParams, useSearchParams } from 'react-router-dom';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';

function AuthSetup() {
    const [currentUser] = useGlobalState("userInfo");
    const [searchParams] = useSearchParams();
    const [integrationSchema, setIntegrationSchema] = useState({});

    function processReturn(data) {
        if (searchParams.get('state') != null) {
            var clientId = data.settings.clientId;
            var appSecret = data.settings.appSecret;
            var code = searchParams.get('code');
            var stateJson = JSON.parse(searchParams.get('state'));
            var platform = stateJson.platform;
            var apiName = stateJson.apiName;
            var bamId = stateJson.bamId;
            var integrationId = stateJson.integrationId;
            var iSchemaId = stateJson.iSchemaId;
            var loginOnly = stateJson.loginOnly;

            var queryStrings = '&display=page&scope=public_profile,email';
            var state = '&state={"platform":"' + integrationSchema.platform + '","apiName":"' + integrationSchema.apiName + '","bamId":' + currentUser.currentBusinessAccountDTO.bamId + ',"integrationId":' + integrationId + ',"iSchemaId":' + iSchemaId + ',"loginOnly":true}';
            var redirectUrl = 'https://dev.funnelgoal.net/authreturn';

            var url = getHostUrlItemByName('AccessToken') + '?client_id=' + clientId + '&client_secret=' + appSecret + '&code=' + code + '&redirect_uri=' + redirectUrl + state;
            window.location.href = url;
        }       
    }
    function getHostUrlItemByName(name) {
        var hostUrlItem = null;
        var settings = integrationSchema.settings;
        for (var i = 0; i < settings.hostUrls.length; i++) {
            if (settings.hostUrls[i].name == name) {
                hostUrlItem = settings.hostUrls[i];
                break;
            }
        }
        return hostUrlItem
    }

    useEffect(() => {
        var stateJson = JSON.parse(searchParams.get('state'));
        var integrationId = stateJson.integrationId;
        var iSchemaId = stateJson.iSchemaId;

        BAFetchData({
            callName: "GetMainRecord",
            method: "GET",
            url: "integration/schema/getrecord?ischemaid=" + iSchemaId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });

    }, []);

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                if (data.settings != null & data.settings != '') {
                    data.settings = JSON.parse(data.settings);
                }
                setIntegrationSchema(data);
                processReturn(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }

    //const endpoint =

    //useEffect(() => {
    //    BAFetchData({
    //        callName: "GetMainRecord",
    //        method: "GET",
    //        url: "security/businessaccount/getrecord?baid=" + primaryKeyValue,
    //        token: "fg1234",
    //        toggleRefetch: searchRefetch,
    //        onSuccess: onAPISuccess, onError: onAPIError
    //    });
    //}, []);

    useEffect(() => {
        //loadValues();
    }, []);

    return (
        <>
            <div className="fg-app-page-box">
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gridWidth: "100%", gridGap: "10px" }}>
                    <div>
                        <h1>Auth Setup</h1>

                    </div>
                </div>
            </div>
        </>
    );
}

export default AuthSetup;


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAChangeDefaultHeaders, BAFetchData} from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Tags from "./Tags";
import SegmentedLists from "./SegmentedLists";
import EmailBroadcastDetail from "./EmailBroadcastDetail";
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import useFetch, { fetchData } from '../customhooks/useFetch';
import TextField from '@mui/material/TextField';
import ConfirmPasswordCtrl from '../components/dialogctrl/popupforms/ConfirmPasswordCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';

//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function BusinessAccounts() {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);

    let params = useParams();
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );



    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [toggleRefetch, setToggleRefetch] = useState(false)

    const [rowIds, setRowIds] = useState([]);



    const pageTitle = getTranslation('contact_search_page', 'header_section', 'Business Accounts');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }
   

    if (currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') {
        headerOptions.elements.push({ type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Delete Account(s)'), name: "Delete Account(s)", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
    }


    const columns = [
        {
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('contact_search_page', 'search_grid', 'Open')}</Button>,
        },
        //{
        //    field: 'avatar',
        //    headerName: '',
        //    width: 100,
        //    width: 60, sortable: false, filterable: false, hideable: false,
        //    renderCell: function (params) {
        //        if (params.row.isCompany == false) {
        //            return <Avatar>{params.row.firstName.charAt(0) + params.row.lastName.charAt(0)}</Avatar>;
        //        } else {
        //            return <Avatar>{params.row.businessName.charAt(0)}</Avatar>;
        //        }
        //    }
        //},
        //{
        //    field: 'contactName',
        //    headerName: getTranslation('contact_search_page', 'search_grid', 'Name'),
        //    description: 'Shows the person name or business name of the record',
        //    width: 300,
        //    //valueGetter: function(params) {
        //    //    if (params.row.isCompany == false) {
        //    //        return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
        //    //    } else {
        //    //        return `${params.row.businessName || ''}`;
        //    //    }
        //    //}                             
        //},
        {
            field: 'businessName',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Business Name'),
            width: 300
        },
        {
            field: 'title',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Title'),
            width: 300
        },
        {
            field: 'accountHolder',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Acct. Holder'),
            width: 150,
            renderCell: function (params) {
                if (params.row.accountHolder == true) {
                    return <Chip label="Account Holder" color="secondary" />
                } else {
                    return null
                }
            }
        },
        {
            field: 'memberType',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Member Type'),
            width: 150,
            renderCell: function (params) {
                return <Chip label={params.row.memberType} />
            }
        },
        {
            field: 'status',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Status'),
            width: 150,
            renderCell: function (params) {
                if (params.row.internalAccount == true) {
                    return <Chip label="Internal Account" color="success" />
                } else {
                    if (params.row.status == "Active") {
                        return <Chip label="Active" color="success" />
                    } else if (params.row.status == "Inactive") {
                        return <Chip label="Active" color="error" />
                    } else if (params.row.status == "Pending Cancellation") {
                        return <Chip label="Active" color="error" />
                    }
                }
            }
        },
    ];



    function onGridOpenBtnClick(row) {
        fetchData({
            callName: "SwitchAccount",
            method: "POST",
            url: "security/businessaccount/switchaccount?destbaid=" + row.baid + '&userToken=' + currentUser.token,
            token: "fg1234",
            body: JSON.parse('[' + rowIds + ']'), onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    useEffect(() => {
        beginSearch();
        setShowLoader(false);            
    },[]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
            </div>
            <div className="fg-app-page-box">
                {/*<Button variant="outlined" onClick={showDialog}>*/}
                {/*    Open alert dialog*/}
                {/*</Button>*/}
                {/*<Button variant="contained" color="success" onClick={importContacts}>*/}
                {/*    Import Contacts*/}
                {/*</Button>*/}
                <div>
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                border: 0,
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                },
                            }}
                            rows={searchResultData}
                            getRowId={(row) => row.baid}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkCancelConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to cancel ' + rowIds.length + ' Business Account' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Cancel Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); cancelRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }



    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var msg = '<p>In order to delete this business account you have to enter your login information. Deleted accounts cannot be reverse. <b>ALL DATA WILL BE GONE FOREVER.</b><p>';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: (dialogType, setReturnValue) => <ConfirmPasswordCtrl handlerReturnValue={dialogType, setReturnValue} msg={msg}></ConfirmPasswordCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); deleteRecords(returnValues); } })
            setOpenDialog(true);

            //var plural = helpers.getPlural(rowIds.length);
            //var msg = 'You are about to delete ' + rowIds.length + ' Business Account' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            //setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            //setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function cancelRecords() {
        var test = '';
        test = '1234';
        //BAFetchData({
        //    callName: "BulkDelete",
        //    method: "POST",
        //    url: "security/businessaccount/bulkdelete",
        //    token: "fg1234",
        //    body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        //});
    }

    function deleteRecords(returnValues) {
        if (returnValues == false) {
            var msg = 'In order to delete this account you have to provide login credentials.';
            setGenericDialogSchema({ title: 'Invalid Login', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        } else {
            BAFetchData({
                callName: "BulkDelete",
                method: "POST",
                url: "security/businessaccount/bulkdelete",
                token: "fg1234",
                body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function openTags(actionType) {    
        if (rowIds.length > 0) {
            var msg = 'This is a test';
            setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue) => <Tags handlerReturnValue={dialogType, setReturnValue}></Tags>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleAttachTags(returnValues, actionType); } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function handleAttachTags(tagIds, actionType) {
        var tagPlural = helpers.getPlural(tagIds.length);
        var selectedPlural = helpers.getPlural(rowIds.length);
        var msg = '';
        if (actionType == 'Add') {
            msg = 'You are about to add ' + tagIds.length + ' tag' + tagPlural + ' to ' + rowIds.length + ' contact' + selectedPlural + '. <span style="font-weight:bold;">Are you sure you want to continue</span>?';
        } else {
            msg = 'You are about to remove ' + tagIds.length + ' tag' + tagPlural + ' from ' + rowIds.length + ' contact' + selectedPlural + '. <span style="font-weight:bold;">Are you sure you want to continue</span>?';
        }
        setGenericDialogSchema({ title: actionType + ' Tag Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); updateTagLinks(tagIds, actionType) } })
        setOpenDialog(true);
    }

    function updateTagLinks(tagIds, actionType) {
        var tagList = JSON.parse('[' + tagIds + ']');

        var qs = '';
        var comma = '';
        for (var i = 0; i < tagList.length; i++) {
            qs += comma + 'tagIds=' + tagList[i]
            comma = '&';
        }

        var callName = 'AttachTag';
        var url = "crm/tagmapping/bulkadd"; 
        if (actionType == 'Remove') {
            callName = 'RemoveTag';
            url = "crm/tagmapping/BulkDeleteByRef"; 
        }

        BAFetchData({
            callName: callName,
            method: "POST",
            url: url + "?reftype=contact&" + qs,
            token: "fg1234",
            body: JSON.parse('[' + rowIds + ']'), onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function openNewEmailBroadcast() {
        if (rowIds.length > 0) {
            var msg = 'This is a test';
            setGenericDialogSchema({ title: 'List Search', component: (dialogType, setReturnValue) => <EmailBroadcastDetail handlerReturnValue={dialogType, setReturnValue}></EmailBroadcastDetail>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleAttachLists(returnValues); } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }



    function openLists(actionType) {
        if (rowIds.length > 0) {
            var msg = 'This is a test';
            setGenericDialogSchema({ title: 'List Search', component: (dialogType, setReturnValue) => <SegmentedLists handlerReturnValue={dialogType, setReturnValue}></SegmentedLists>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleAttachLists(returnValues, actionType); } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function handleAttachLists(listIds, actionType) {
        var listPlural = helpers.getPlural(listIds.length);
        var selectedPlural = helpers.getPlural(rowIds.length);
        var msg = '';
        if (actionType == 'Add') {
            msg = 'You are about to add ' + listIds.length + ' list' + listPlural + ' to ' + rowIds.length + ' contact' + selectedPlural + '. <span style="font-weight:bold;">Are you sure you want to continue</span>?';
        } else {
            msg = 'You are about to remove ' + listIds.length + ' list' + listPlural + ' from ' + rowIds.length + ' contact' + selectedPlural + '. <span style="font-weight:bold;">Are you sure you want to continue</span>?';
        }
        setGenericDialogSchema({ title: actionType + ' List Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); updateListLinks(listIds, actionType) } })
        setOpenDialog(true);
    }

    function updateListLinks(listIds, actionType) {
        var list = JSON.parse('[' + listIds + ']');

        var qs = '';
        var comma = '';
        for (var i = 0; i < list.length; i++) {
            qs += comma + 'listIds=' + list[i]
            comma = '&';
        }

        var callName = 'AttachList';
        var url = "crm/segmentedlistmapping/bulkadd";
        if (actionType == 'Remove') {
            callName = 'RemoveLIst';
            url = "crm/segmentedlistmapping/BulkDeleteByRef";
        }

        BAFetchData({
            callName: callName,
            method: "POST",
            url: url + "?" + qs,
            token: "fg1234",
            body: JSON.parse('[' + rowIds + ']'), onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setRowIds([]);
        setSearchRefetch(true);
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {
        var filtering = {};
        var conditionalGroups = [];
        var conditions = [];

        conditions.push({ "Name": "userid", "ConditionOperator": "EQUAL", "Value": currentUser.userId, "DataType": "Number" });
        conditionalGroups.push({ "LogicalOperator": "and", "Conditions": conditions })

        if (criteria != null && criteria != '') {
            conditions = [];
            conditions.push({ "Name": "businessName", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });

            conditionalGroups.push({ "LogicalOperator": "or", "Conditions": conditions })
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": conditionalGroups
        };

        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "security/businessaccountmember/getlistbyuser?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "SwitchAccount":
                //set global state
                //update local storage
                localStorage.setItem('fg-user', JSON.stringify(data))
                if (data.permissions != '') {
                    data.permissions = JSON.parse(data.permissions);
                }
                setGlobalState("userInfo", data);
                BAChangeDefaultHeaders(data.currentBusinessAccountDTO.token)
                //window.location.href('/overview','_self');
                if (data.currentBusinessAccountDTO.active == true) {
                    navigate('/welcome');
                } else {
                    navigate('/inactive');
                }
                break;
            case "BulkDelete":

                const loggedInUser = localStorage.getItem("fg-user");
                const foundUser = JSON.parse(loggedInUser);
                foundUser.currentBusinessAccountDTO = null;

                localStorage.setItem('fg-user', JSON.stringify(foundUser));
                setGlobalState("userInfo", foundUser);

                //resetResults(); //Reset search results and clear records selected.               
                var msg = "Business Account record(s) has been deleted. If you have other Business Accounts they will still be availble for you to open.";
                setGenericDialogSchema({ title: 'Business Account(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); window.location.reload(true); } })
                setOpenDialog(true);                
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

}

export default BusinessAccounts;
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import './css/multiselectctrl.css';
import Button from '@mui/material/Button';
import helpers from '../../helpers/Helpers.js';

function MultiSelectCtrl({ id, label, placeholder, defaultKeyValuePair, remoteSource = false, dataSourceList, dataSourceIdField, dataSourceTextField, maxResult = 10, onSearchCallback, onTagAddedCallback, onTagRemovedCallback, onDatabaseInsert, hideLabel = false, errMsg }) {
    let internalTimeout = null;
    const [controlId, setControlId] = useState(helpers.getUUID());
    const [showList, setShowList] = useState(false);
    const [showDBMsg, setShowDBMsg] = useState(false);
    const [resultList, setResultList] = useState(dataSourceList);

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

   
    const [tags, setTags] = React.useState([]);

    const handleDelete = (chipToDelete) => () => {
        setTags((chips) => chips.filter((chip) => chip[dataSourceIdField] !== chipToDelete[dataSourceIdField]));
        onTagRemovedCallback(chipToDelete[dataSourceIdField]);
    };

    const handleOnTextChange = e => {
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch(e.target.value.toLowerCase())
        }, 250);
    }

    function handleOnSearchCallbackComplete(data, header, callName) {
        //update search result list
        setResultList(data);
    }

    function beginSearch(criteria) {
        
        if (remoteSource == true) {
            if (criteria != "") {
                setShowList(true);
                onSearchCallback(criteria, handleOnSearchCallbackComplete);
            } else if (criteria == ""){
                setShowList(false);
            }
        } else {
            setShowList(true);
            performInternalFilter(criteria);
        }
    }

    function performInternalFilter(criteria) {
        var list = [];
        if (criteria != '') {
            for (let i = 0; i < dataSourceList.length; i++) {
                if (dataSourceList[i][dataSourceTextField].toLowerCase().includes(criteria)) {
                    list.push(JSON.parse(JSON.stringify(dataSourceList[i])));
                }
            }
        } else {
            list = JSON.parse(JSON.stringify(dataSourceList));
        }
        setResultList(list);
    }

    const handleOptionClick = (e) => {
        var el = e.target;
        var index = el.getAttribute('data-index');
        

        if (tagExist(resultList[index][dataSourceTextField]) == false) {
            setTags([...tags, { [dataSourceIdField]: resultList[index][dataSourceIdField], [dataSourceTextField]: resultList[index][dataSourceTextField] }])
            onTagAddedCallback(resultList[index][dataSourceIdField], resultList[index][dataSourceTextField]);
        }
        e.target.value = "";
        handleListClose();
    }




    const handleKeyUp = (e) => {
        if (e.key == "Enter" && e.target.value != "") {
            if (remoteSource == true) {
                if (tagExist(resultList[0][dataSourceTextField]) == false) {
                    setTags([...tags, { [dataSourceIdField]: resultList[0][dataSourceIdField], [dataSourceTextField]: resultList[0][dataSourceTextField] }])
                    onTagAddedCallback(resultList[0][dataSourceIdField], resultList[0][dataSourceTextField]);
                }
                e.target.value = "";
                handleListClose();
            } else if (remoteSource == false) {
                if (tagExist(resultList[0][dataSourceTextField]) == false) {
                    setTags([...tags, { [dataSourceIdField]: resultList[0][dataSourceIdField], [dataSourceTextField]: resultList[0][dataSourceTextField] }])
                }
                e.target.value = "";
                handleListClose();
            } else {
                if (tagExist(resultList[0][dataSourceTextField]) == false) {
                    setTags([...tags, { [dataSourceIdField]: resultList[0][dataSourceIdField], [dataSourceTextField]: e.target.value }])
                }
                e.target.value = "";
                handleListClose();
            }

        } else if (e.key == "Escape") {
            e.target.value = "";
            handleListClose();
        }
    };

    function tagExist(textValue) {
        let found = false;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].text == textValue) {
                found = true;
                break;
            }
        }
        return found;
    }

    const handleDatabaseInsert = () => {
        var criteria = document.getElementById(controlId).value;
        var keyValuePair = onDatabaseInsert(criteria, handleOnDBInsertCallbackComplete);

    }

    function handleOnDBInsertCallbackComplete(data, header, callName) {
        //update search result list
        setTags([...tags, { [dataSourceIdField]: data[dataSourceIdField], [dataSourceTextField]: data[dataSourceTextField] }]);
        onTagAddedCallback(data[dataSourceIdField], data[dataSourceTextField]);
        handleListClose();
        //setResultList(data);
    }

    function handleListClose() {
        document.getElementById(controlId).value = "";
        setShowList(false);
    }


    useEffect(() => {       
        setResultList(dataSourceList);
    }, [dataSourceList]);

    //const [tags, setTags] = React.useState(defaultKeyValuePair);

    useEffect(() => {
        setTags(defaultKeyValuePair);

    }, [defaultKeyValuePair]);

    return (
        <>
            <div style={{border:"1px solid #C4C4C4",borderRadius:"4px",padding:"5px"}}>
                <label style={{ fontFamily: '"Roboto","Helvetica","Arial","sans-serif"', fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", position: "relative", zIndex: "5", backgroundColor: "white", left: "4px", top: "-18px", padding: "0px 6px 0px 6px" }}>{label}</label>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}
                    component="ul"
                    style={{border:"none",boxShadow:"none"}}
                >                   
                    {tags.map((data) => {
                        let icon;

                        //if (data.text === 'React') {
                        //    icon = <TagFacesIcon />;
                        //}

                        return (
                            <ListItem key={data[dataSourceIdField]}>
                                <Chip
                                    icon={icon}
                                    label={data[dataSourceTextField]}
                                    onDelete={data[dataSourceTextField] === 'Reacts' ? undefined : handleDelete(data)}
                                />
                            </ListItem>
                        );
                    })}
                    <TextField id={controlId} fullWidth variant="standard" placeholder={placeholder} onFocus={handleOnTextChange} onChange={handleOnTextChange} onKeyUp={handleKeyUp}  style={{ marginTop: "5px" }} />
                    {(showList == true || showDBMsg == true) &&
                        <div style={{ position: "relative", width: "100%" }}>
                            <div className="multiselectctr-option-box">
                                {(showList == true && resultList.length > 0 ) &&                                   
                                    <div style={{ width: "100%",height:"100%",maxHeight:"200px",overflow:"auto" }}>
                                        <ul style={{ listStyle: "none", padding: "0px", margin: "0px", width: "100%" }}>
                                            {resultList.map((data, index) => {
                                                return <li className="multiselectctr-option-item" data-index={index} onClick={handleOptionClick} >{data[dataSourceTextField]}</li>
                                            })}
                                        </ul>
                                    </div>                                    
                                }
                                {resultList.length == 0 &&
                                    <div>
                                        <div style={{ marginBottom: "10px" }}>No matches found. Do you want to add this value to the database so you can select it now?</div>
                                        <Button variant="contained" onClick={handleDatabaseInsert}>Yes... Add to database</Button>&nbsp;&nbsp;<Button variant="outlined" onClick={handleListClose}>NO</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Paper>               
            </div>
        </>
    );
}

export default MultiSelectCtrl;

import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';

function Overview() {
    const [datePreset, setDatePreset] = useState('last-7-days');
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [currentUser] = useGlobalState("userInfo");

    const [trafficRptSettings, setTrafficRptSettings] = useState({
        groupByOptions: [
            { value: "reportdate", text: "Report Date" },
            { value: "referrerhost", text: "Referrer Host" },
            { value: "source/medium", text: "Source/Medium" },
            { value: "source/medium/content", text: "Source/Medium/Content" },
            { value: "source/medium/campaign/content/term", text: "All UTMs" },
            { value: "adid", text: "ADID - for paid ads" },
            { value: "campaign", text: "Campaign" },
            { value: "device", text: "Device" }
        ],
        dataColumns: [],
        datePreset: 'last-7-days',
        dateRange: { startDate: null, endDate: null },
        trafficRptFields: {
            groupByFields: ["referrerHost"],
            selectFields: ["visitors", "sessions", "pageViews", "viewContent", "lead", "addToCart", "initiateCheckout", "addPaymentInfo", "purchase", "purchaseAmt"]
        },
        masterColumns: 
            [
                { name: "reportDate", caption: "Date", placement: "left", width: 200, displayOrder: 1, icon: "", dataType: "date", showSettings: false, showSort:false, showIcon:false},
                { name: "referrerHost", caption: "Referrer Host", placement: "left", width: 350, displayOrder: 1, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "utmSource", caption: "Source", placement: "left", width: 200, displayOrder: 1, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "utmMedium", caption: "Medium", placement: "left", width: 200, displayOrder: 2, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "utmCampaign", caption: "Campaign", placement: "left", width: 200, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "utmTerm", caption: "Term", placement: "left", width: 200, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false, showIcon: false },
                { name: "utmContent", caption: "Content", placement: "left", width: 200, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "adId", caption: "AdId", placement: "left", width: 200, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "device", caption: "Device", placement: "left", width: 140, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "path", caption: "Path", placement: "left", width: 200, displayOrder: 3, icon: "", dataType: "string", showSettings: false, showSort: false, showIcon: false },
                { name: "visitors", caption: "Visitors", placement: "main", width: 105, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "sessions", caption: "Sessions", placement: "main", width: 105, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "pageViews", caption: "Page Views", placement: "main", width: 125, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "viewContent", caption: "View Content", placement: "main", width: 140, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "lead", caption: "Leads", placement: "main", width: 90, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "addToCart", caption: "Add to Cart", placement: "main", width: 130, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "initiateCheckout", caption: "Initiate Checkout", placement: "main", width: 165, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "addPaymentInfo", caption: "Add Pay Info", placement: "main", width: 130, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "purchase", caption: "Purchase", placement: "main", width: 110, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "number", showSettings: false, showSort: false, showIcon: true },
                { name: "purchaseAmt", caption: "Purchase Amt", placement: "main", width: 140, displayOrder: 1, icon: "icon_funnelgoal_14x14.png", dataType: "money", showSettings: false, showSort: false, showIcon: true },
            ],
    });
    const [resizeId, setResizeId] = useState(null);
    const [trafficRptData, setTrafficRptData] = useState({ rows: [], totals: [] });
    

    function handleOnGroupByChange(value) {
        var tmpTrafficRptSettings = { ...trafficRptSettings };
        switch (value) {
            case "path":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["path"];
                break;
            case "channel":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["channel"];
                break;
            case "reportdate":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["reportDate"];
                break;
            case "referrerhost":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["referrerHost"];
                break;
            case "source/medium":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["utmSource","utmMedium"];
                break;
            case "source/medium/content":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["utmSource", "utmMedium","utmContent"];
                break;
            case "source/medium/campaign/content/term":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["utmSource", "utmMedium", "utmCampaign", "utmContent", "utmTerm"];
                break;
            case "campaign":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["utmCampaign"];
                break;
            case "adid":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["adId"];
                break;
            case "device":
                tmpTrafficRptSettings.trafficRptFields.groupByFields = ["device"];
                break;
        }
        setTrafficRptSettings(tmpTrafficRptSettings);
    }

    function updateColumns(selectedColumns) {
        var tmpTrafficRptSettings = { ...trafficRptSettings };
        var list = [];
        for (var i = 0; i < trafficRptSettings.masterColumns.length; i++) {
            if (selectedColumns.includes(trafficRptSettings.masterColumns[i].name, 0)) {
                list.push({ ...trafficRptSettings.masterColumns[i]});
            }
        }
        tmpTrafficRptSettings.dataColumns = list;
        setTrafficRptSettings(tmpTrafficRptSettings);
    }

    function onDatePresetChanged(datePreset) {
        var dateFormat = "MM-DD-YYYY";
        var tmpDateRange = { ...dateRange };
        switch (datePreset) {
            case "today":
                tmpDateRange.startDate = dayjs().format(dateFormat);
                tmpDateRange.endDate = dayjs().add(1, 'day').format(dateFormat); 
                break;
            case "last-3-days":
                tmpDateRange.startDate = dayjs().subtract(3, 'day').format(dateFormat); 
                tmpDateRange.endDate = dayjs().add(1, 'day').format(dateFormat); 
                break;
            case "last-7-days":
                tmpDateRange.startDate = dayjs().subtract(7, 'day').format(dateFormat); 
                tmpDateRange.endDate = dayjs().add(1, 'day').format(dateFormat); 
                break;
            case "last-14-days":
                tmpDateRange.startDate = dayjs().subtract(14, 'day').format(dateFormat); 
                tmpDateRange.endDate = dayjs().add(1, 'day').format(dateFormat); 
                break;
            case "last-30-days":
                tmpDateRange.startDate = dayjs().subtract(30, 'day').format(dateFormat); 
                tmpDateRange.endDate = dayjs().add(1, 'day').format(dateFormat); 
                break;
        }
        setDateRange(tmpDateRange);
    }

    function beginSearch() {
        var filtering = {};

        //if (criteria != null && criteria != '') {
        //    var conditions = [];
        //    conditions.push({ "Name": "pixelName", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });

        //    filtering = {
        //        "LogicalOperator": "and", "ConditionGroups": [
        //            {
        //                "LogicalOperator": "or", "Conditions": conditions
        //            }]
        //    };

        //}
        var groupByFieldsLCase = trafficRptSettings.trafficRptFields.groupByFields.map(name => name.toLowerCase())
        var selectFieldsLCase = trafficRptSettings.trafficRptFields.selectFields.map(name => name.toLowerCase())
        var pixelId = "618543b9ce484c4e93e48114197c4488";
        BAFetchData({
            callName: "GetTrafficData",
            method: "GET",
            url: "rpt/marketing/gettrafficdata?pixelid=" + pixelId + "&startdate=" + dateRange.startDate + "&endDate=" + dateRange.endDate + "&groupby=" + JSON.stringify(groupByFieldsLCase) + "&selectfields=" + JSON.stringify(selectFieldsLCase) + "&filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });

    }


    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTrafficData":
                setTrafficRptData({ rows: data.rows, totals: data.totals });
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        alert(msg);
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    } 

    

    useEffect(() => {
        onDatePresetChanged(datePreset);
    }, []);

    useEffect(() => {
        var tmpColumns = trafficRptSettings.trafficRptFields.groupByFields.concat(trafficRptSettings.trafficRptFields.selectFields);
        updateColumns(tmpColumns);
        //setShowLoader(false);
    }, [trafficRptSettings.trafficRptFields.groupByFields]);

    useEffect(() => {
        if (trafficRptSettings.dataColumns.length > 0) {
            if (dateRange.startDate != null) {
                beginSearch();
            }
        }
        //setShowLoader(false);
    }, [trafficRptSettings, dateRange, trafficRptSettings.dataColumns]);

    return (
        <>
            <ResizeObserver onResize={() => {
                setResizeId(helpers.getUUID());
                console.log('page resize triggered');
            }}>
                <div className="fg-app-page-box">
                    <div style={{display:"grid",gridTemplateColumns:"1fr 300px",gridWidth:"100%",gridGap:"10px"}}>
                        <div>
                            <h1>Overview</h1>
                        </div>
                        <div style={{textAlign:"right"}}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                size="small"
                                onChange={(e) => { setDatePreset(e.target.value); onDatePresetChanged(e.target.value); }}
                                value={datePreset}
                            >
                                <MenuItem value="today">Today</MenuItem>
                                <MenuItem value="last-3-days">Last 3 days</MenuItem>
                                <MenuItem value="last-7-days">Last 7 days</MenuItem>
                                <MenuItem value="last-14-days">Last 14 days</MenuItem>
                                <MenuItem value="last-30-days">Last 30 days</MenuItem>
                            </Select>
                        </div>
                    </div>                
                <p>{process.env.REACT_APP_KEY}</p>
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "20px" }}>
                        <div>
                                <GridReportCtrl props={{ id: "traffic_rpt_data_metrics", reportName: "Conversion Metrics", height: 400, columns: trafficRptSettings.dataColumns, data: trafficRptData, showCheckbox: false, primaryKey: "reportDate", resizeId: resizeId, groupByOptions: trafficRptSettings.groupByOptions, onGroupByChange: (value) => handleOnGroupByChange(value)}} />
                        </div>
                    </div>
                </div>
                </div>
            </ResizeObserver>
        </>
    );
}

export default Overview;


import { useCallback, useEffect, useState } from "react";
//import axios from "axios";
import axiosConfig from '../components/BAAxiosConfig';



const methodNameMapping = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
}

export const BAChangeDefaultHeaders = (BAToken) => {
    axiosConfig.defaults.headers.common['Authorization'] = 'Bearer ' + BAToken;
};

export const BAFetchData = async ({
    callName,
    method,
    body,
    url,
    token,
    config = {},
    onSuccess,
    onError,
    packageObj
}) => {      

    const enrichedConfig = {
        headers: {
            'Content-Type': 'application/json', 
            //'Content-Type': 'multipart/form-data',            
            //'Authorization': 'Bearer ' + token,
        },
        //...config,
    };

    const timeout = { timeout: 120000 };
    try {
        let response;
        const axiosMethodName = methodNameMapping[method] || method;
        if (body) {      
            if (body.constructor == FormData) {
                //response = (await axiosConfig[axiosMethodName](url, body, { headers: { 'Content-Type': 'multipart/form-data' } }, timeout));
                response = (await axiosConfig[axiosMethodName](url, body, { transformRequest: () => body}, timeout));
            } else {
                if (url.indexOf('openai') > -1) {
                    enrichedConfig.headers['Authorization'] = 'Bearer ' + token;
                }
                response = (await axiosConfig[axiosMethodName](url, body, enrichedConfig, timeout));                
            }            
        } else {
            response = (await axiosConfig[axiosMethodName](url, enrichedConfig, timeout));
        }
        const { data, headers } = response;
        if (onSuccess) {
            onSuccess(data, headers, callName, packageObj);
        } else {
            return { data, headers, callName, packageObj };
        }
        
    } catch (error) {
        
     console.log(error, callName);
        var errMsg = null;
        try {
            if (error.response.data.hasOwnProperty('message')) {
                errMsg = error.response.data.message;
                onError(errMsg);
            } else {
                errMsg = error.response.data.split('\r\n')[0];
                onError(errMsg);
            }
        }
        catch (err) {
            errMsg = error.response.data;
            onError(errMsg);
        }                
    }
};

const useBAFetch = ({ callName, method, url, token, body, toggleRefetch }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSuccess = useCallback((dt) => {
        setData(dt);
        setLoading(false);
    }, [])

    const handleError =useCallback((error) => {
        setError(error.message);
        setLoading(false);
    }, [])

    useEffect(() => {

        setLoading(true);
        BAFetchData({
            callName,
            url,
            body,
            method,
            token,
            onSuccess: handleSuccess,
            onError: handleError
        });
    }, [url, body, method, token, handleSuccess, handleError , toggleRefetch]);

    return { data, loading, error };
};

export default useBAFetch;

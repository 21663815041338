import useBAFetch, { BAFetchData } from '../../customhooks/useBAFetch';
import helpers from '../../helpers/Helpers';



const PageGenerator = {

    GenerateSitePages: async function (siteId, siteInfo, orderedBreakpoints, mobileFirst, handleOnPublishCompleted) {
        const refreshResult = await BAFetchData({
            callName: "RefreshSite", method: "POST", url: "cms/sites/refreshsite?siteId=" + siteId,
            token: "fg1234", body: null, onSuccess: null, onError: null
        });

        var pageFiltering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "siteid", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" }
                ]
            }]
        };

        const pageListData = await BAFetchData({
            callName: "GetSitePages",
            method: "GET",
            url: "cms/sitepages/getlist?filtering=" + JSON.stringify(pageFiltering),
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        await this.GenerateSiteCss(siteInfo, orderedBreakpoints, mobileFirst);

        for (var i = 0; i < pageListData.data.length; i++) {
            var pageInfo = pageListData.data[i];
            if (pageInfo.pageType == 'p') {

                var variantFiltering = {
                    "LogicalOperator": "and", "ConditionGroups": [{
                        "LogicalOperator": "and", "Conditions": [
                            { "Name": "sitepageid", "ConditionOperator": "EQUAL", "Value": pageInfo.sitePageId, "DataType": "String" }
                        ]
                    }]
                };


                const pageVariantList = await BAFetchData({
                    callName: "GetPageVariants",
                    method: "GET",
                    url: "cms/pagevariants/getlist?filtering=" + JSON.stringify(variantFiltering),
                    token: "fg1234",
                    body: null, onSuccess: null, onError: null
                });


                for (var pvli = 0; pvli < pageVariantList.data.length; pvli++) {


                    var variantInfo = pageVariantList.data[pvli];
                    var components = JSON.parse(variantInfo.components);

                    const mpList = await BAFetchData({
                        callName: "GetMasterPageInfo",
                        method: "GET",
                        url: "cms/sitepages/getlinkedmasterpages?sitepageid=" + pageInfo.sitePageId,
                        token: "fg1234",
                        body: null, onSuccess: null, onError: null
                    });

                    var masterPageList = mpList.data;

                    for (var mpi = 0; mpi < masterPageList.length; mpi++) {
                        masterPageList[mpi].components = JSON.parse(masterPageList[mpi].components);
                    }

                    var finalResult = {};
                    for (var mii = 0; mii < masterPageList.length; mii++) {
                        if (mii > 0) {
                            var childRootKeyId = helpers.getRootElementId(masterPageList[mii].components);
                            var containerKeyId = helpers.getPageContainerElementId(masterPageList[mii - 1].components);
                            masterPageList[mii - 1].components[containerKeyId].children.push(childRootKeyId);
                        }
                        Object.keys(masterPageList[mii].components)
                            .forEach(key => finalResult[key] = masterPageList[mii].components[key]);
                    }
                    if (masterPageList.length > 0) {
                        var childRootKeyId = helpers.getRootElementId(components);
                        var containerKeyId = helpers.getPageContainerElementId(masterPageList[masterPageList.length - 1].components);
                        masterPageList[masterPageList.length - 1].components[containerKeyId].children.push(childRootKeyId);
                    }

                    Object.keys(components)
                        .forEach(key => finalResult[key] = components[key]);

                    if (pageInfo.dataSources != '') {
                        pageInfo.dataSources = JSON.parse(pageInfo.dataSources);
                    }
                    var htmlCss = await this.GeneratePage(finalResult, siteInfo, pageInfo, orderedBreakpoints, mobileFirst);

                    var formData = new FormData();
                    formData.append('rootPageContent', htmlCss.rootPageContent);
                    formData.append('html', htmlCss.html);
                    formData.append('css', htmlCss.css);
                    formData.append('pageName', pageInfo.name);
                    formData.append('siteId', siteId);

                    
                    if (pageInfo.dataSources != null) {
                        var slugList = '';
                        var delimeter = '';
                        var dataSource = null;
                        for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                            dataSource = pageInfo.dataSources[dsi];
                            if (dataSource.runtimeParameters != null && dataSource.runtimeParameters != '') {
                                var regexp = /(%%params.)([a-zA-Z._(0-9)-]*)[~]/gm;
                                var matches = dataSource.runtimeParameters.matchAll(regexp);

                                for (const match of matches) {

                                    var slugName = match[0].replaceAll("%%", "").replaceAll("~", "").replaceAll("params.","");
                                    slugList += delimeter + slugName;
                                    delimeter = ",";
                                }
                            }                           
                        }
                        if (slugList != '') {
                            formData.append('slugListStr', slugList);
                        }
                    }

                    

                    const result = await BAFetchData({
                        callName: "WriteSitePage", method: "POST", url: "cms/sites/writepage",
                        token: "fg1234", body: formData, onSuccess: null, onError: null
                    });
                }
            }
        }


        const rebuildResult = await BAFetchData({
            callName: "RebuildSite", method: "POST", url: "cms/sites/rebuild?siteId=" + siteId,
            token: "fg1234", body: null, onSuccess: handleOnPublishCompleted, onError: null
        });
    },

    GenerateSiteCss: async function (siteInfo, orderedBreakpoints, mobileFirst) {
        var styleList = siteInfo.styleList;
        const reactToCss = require('react-style-object-to-css');



        const capitilizeWord = (word) => {
            const firstLetter = word.charAt(0)
            const firstLetterCap = firstLetter.toUpperCase()
            const remainingLetters = word.slice(1)
            return firstLetterCap + remainingLetters
        };      

        const renderStyleSheet = () => {
            var css = '';
            for (var i = 0; i < orderedBreakpoints.length; i++) {
                css += renderCssByBP(i, orderedBreakpoints[i]);
            }
            return css;
        };

        const getRefChar = (id) => {
            var refChar = "";
            var firstCharacter = id.charAt(0);
            if (firstCharacter != "#") {
                refChar = ".";
            }
            return refChar;
        };

        const renderCssByBP = (index, breakpoint) => {
            var css = '';
            var id = null;
            if (mobileFirst == true) {
                if (index == 0) {
                    css += '/* When the browser is at least 0px and above */\n';
                } else if (index > 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and above */\n';
                    css += '@media screen and (min-width: ' + breakpoint + 'px) {\n';
                }

                if (index == 0) {
                }

               
                for (const key in styleList) {
                    var element = styleList[key];
                    id = helpers.useCustomIdCheck(element);
                    if (element.breakpointStyles.hasOwnProperty(breakpoint)) {
                        css += getRefChar(id) + id.replace("#","") + " {" + reactToCss(element.breakpointStyles[breakpoint]) + "}\n";
                    }
                }
                //add closing curly brackets if this is not the first breakpoint
                if (index > 0) {
                    css += '}\n';
                }
            } else {
                if (index == 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and below */\n';
                } else if (index > 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and below */\n';
                    css += '@media screen and (max-width: ' + breakpoint + 'px) {\n';
                }

                //if (index == 0) {
                //    for (const key in styleList) {
                //        var element = styleList[key];
                //        if (JSON.stringify(element.events) != "{}") {
                //            for (const eventId in element.events) {
                //                var eventObj = element.events[eventId];
                //                if (eventObj.action.hasOwnProperty('config')) {
                //                    if (eventObj.action.config.hasOwnProperty('title')) {
                //                        var title = eventObj.action.config.title.replaceAll(" ", "");
                //                        for (var aigi = 0; aigi < eventObj.action.config.actionItemGroups.length; aigi++) {
                //                            var actionItemGroup = eventObj.action.config.actionItemGroups[aigi];
                //                            for (var aii = 0; aii < actionItemGroup.actionItems.length; aii++) {
                //                                var actionItem = actionItemGroup.actionItems[aii];
                //                                var targetId = actionItem.config.target.id;
                //                                var value = actionItem.config.value;
                //                                var cssProperty = getCssProperty(actionItem.actionTypeId);
                //                                var delay = actionItem.config.delay;
                //                                var easing = actionItem.config.easing;
                //                                var duration = actionItem.config.duration;
                //                                css += "\n#" + targetId + "." + title + " {" + cssProperty + ":" + value + ";";
                //                                css += "transition: all " + duration + "s " + delay + "s;}\n";
                //                            }
                //                        }
                //                    }
                //                }
                //            }
                //        }
                //    }
                //}

                for (const key in styleList) {
                    var element = styleList[key];
                    if (element.breakpointStyles.hasOwnProperty(breakpoint)) {
                        //id = helpers.useCustomIdCheck(element);
                        id = element.id;
                        css += getRefChar(id) + id.replace("#", "") + " {" + reactToCss(element.breakpointStyles[breakpoint]) + "}\n";
                    }
                }
                //add closing curly brackets if this is not the first breakpoint
                if (index > 0) {
                    css += '}\n';
                }
            }
            return css;
        };

        const getCssProperty = (actionTypeId) => {
            var cssProperty = '';
            switch (actionTypeId) {
                case 'GENERAL_DISPLAY':
                    cssProperty = 'display';
                    break;
            }
            return cssProperty;
        };

        var siteCss = renderStyleSheet();
        var formData = new FormData();
        formData.append('css', siteCss);
        formData.append('siteId', siteInfo.siteId);

        const result = await BAFetchData({
            callName: "WriteSiteCss", method: "POST", url: "cms/sites/createglobalsitestylesheet",
            token: "fg1234", body: formData, onSuccess: null, onError: null
        });

        return renderStyleSheet();
    },

    GeneratePage: function (elements, siteInfo, pageInfo, orderedBreakpoints, mobileFirst) {

        const reactToCss = require('react-style-object-to-css');

        const capitilizeWord = (word) => {
            const firstLetter = word.charAt(0)
            const firstLetterCap = firstLetter.toUpperCase()
            const remainingLetters = word.slice(1)
            return firstLetterCap + remainingLetters
        };

        const renderStyleSheet = () => {
            var css = '';
            for (var i = 0; i < orderedBreakpoints.length; i++) {
                css += renderCssByBP(i, orderedBreakpoints[i]);
            }

            if (pageInfo.css != '' && pageInfo.css != null) {
                css += "\n";
                css += pageInfo.css;
                css += "\n";
            }

            return css;
        };

        const getRefChar = (elementName) => {
            var refChar = "#";
            switch (elementName) {
                case "field":
                    refChar = ".";
                    break;
            }
            return refChar;
        };

        const renderCssByBP = (index, breakpoint) => {
            var css = '';
            var id = null;
            if (mobileFirst == true) {
                if (index == 0) {
                    css += '/* When the browser is at least 0px and above */\n';
                } else if (index > 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and above */\n';
                    css += '@media screen and (min-width: ' + breakpoint + 'px) {\n';
                }

                if (index == 0) {
                }

                for (const key in elements) {
                    var element = elements[key];
                    if (element.breakpointStyles.hasOwnProperty(breakpoint)) {
                        id = helpers.useCustomIdCheck(element);
                        css += getRefChar(element.elementName) + id + " {" + reactToCss(element.breakpointStyles[breakpoint]) + "}\n";
                    }
                }
                //add closing curly brackets if this is not the first breakpoint
                if (index > 0) {
                    css += '}\n';
                }
            } else {
                if (index == 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and below */\n';
                } else if (index > 0) {
                    css += '/* When the browser is at least ' + breakpoint + 'px and below */\n';
                    css += '@media screen and (max-width: ' + breakpoint + 'px) {\n';
                }

                if (index == 0) {
                    for (const key in elements) {
                        var element = elements[key];
                        if (JSON.stringify(element.events) != "{}") {
                            for (const eventId in element.events) {
                                var eventObj = element.events[eventId];
                                if (eventObj.action.hasOwnProperty('config')) {
                                    if (eventObj.action.config.hasOwnProperty('title')) {
                                        var title = eventObj.action.config.title.replaceAll(" ", "");
                                        for (var aigi = 0; aigi < eventObj.action.config.actionItemGroups.length; aigi++) {
                                            var actionItemGroup = eventObj.action.config.actionItemGroups[aigi];
                                            for (var aii = 0; aii < actionItemGroup.actionItems.length; aii++) {
                                                var actionItem = actionItemGroup.actionItems[aii];
                                                var targetId = helpers.useCustomIdCheck(elements[actionItem.config.target.id]);
                                                if (targetId != null) {
                                                    var value = actionItem.config.value;
                                                    var cssProperty = getCssProperty(actionItem.actionTypeId);
                                                    var delay = actionItem.config.delay;
                                                    var easing = actionItem.config.easing;
                                                    var duration = actionItem.config.duration;
                                                    css += "\n#" + targetId + "." + title + " {" + cssProperty + ":" + value + ";";
                                                    css += "transition: all " + duration + "s " + delay + "s;}\n";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                for (const key in elements) {
                    var element = elements[key];
                    if (element.breakpointStyles.hasOwnProperty(breakpoint)) {
                        id = helpers.useCustomIdCheck(element);
                        css += getRefChar(element.elementName) + id + " {" + reactToCss(element.breakpointStyles[breakpoint]) + "}\n";
                    }
                }
                //add closing curly brackets if this is not the first breakpoint
                if (index > 0) {
                    css += '}\n';
                }
            }


            return css;
        };

        const getCssProperty = (actionTypeId) => {
            var cssProperty = '';
            switch (actionTypeId) {
                case 'GENERAL_DISPLAY':
                    cssProperty = 'display';
                    break;
            }
            return cssProperty;
        };

        const renderPage = () => {
            var html = '';
            html += renderImports();
            html += renderPageWrapper();
            return html;
        };

        const renderRootPage = () => {
            var capitalizePageName = capitilizeWord(pageInfo.name);
            var html = '';
            html += "import { BAFetchData } from '@/helpers/useBAFetch';\n";
            html += "import " + capitalizePageName + " from '@/pagecomponents/" + capitalizePageName.toLowerCase() + "';\n";
            html += "import Image from 'next/image';\n";
            html += "\n";
            html += "\n";
            if (pageInfo.dynamicSeo == true) {
                html += "export async function generateMetadata({ params }) {\n";

                html += " let dataPackage = await BAFetchData({\n";
                html += "   callName:'GetRecord',\n";
                html += "   method:'GET',\n";
                if (pageInfo.name.toLowerCase().indexOf('blog') > -1 || pageInfo.name.toLowerCase().indexOf('article') > -1) {
                    html += "   url:process.env.FG_API_URL + 'v1/marketing/blog/getrecordbyslug?slug=' + params.slug,\n";
                } else if (pageInfo.name.toLowerCase().indexOf('product') > -1) {
                    html += "   url:process.env.FG_API_URL + 'v1/ecommerce/product/getrecordbyslug?slug=' + params.slug,\n";
                }
                html += "   token: process.env.BATOKEN,\n";
                html += "   body:null,\n";
                html += "   onSuccess: null,\n";
                html += "   onError: null\n";
                html += " })\n";
                html += "\n";
                html += " let title = (dataPackage.data.seoTitle || '');\n";
                html += " let description = (dataPackage.data.seoDescription || '');\n";
                html += " let seoImageUrl = (dataPackage.data.seoImage || '');\n";
                html += "\n";
                html += " if ((title || '') == '') {\n";
                html += "   title = '" + (siteInfo.seoTitle || '').replaceAll('\'', '\\\'') + "';\n";
                html += "   description = '" + (siteInfo.seoDescription || '').replaceAll('\'', '\\\'') + "';\n";
                html += "   seoImageUrl = '" + (siteInfo.seoImage || '').replaceAll('\'', '\\\'') + "';\n";
                html += " }\n";

                html += "\n";
                html += "   let category = '';\n";
                html += "   let openGraph = {\n";
                html += "     title, \n";
                html += "     description,\n";
                html += "     images:[{url:seoImageUrl}]\n";
                html += "   };\n";
                html += "   let twitter = {\n";
                html += "     title, \n";
                html += "     description,\n";
                html += "     images:[{url:seoImageUrl}]\n";
                html += "   };\n";
                html += "\n";
                html += "   return {\n";
                html += "     title,\n";
                html += "     description,\n";
                html += "     category,\n";
                html += "     openGraph,\n";
                html += "     twitter\n";
                html += "   }\n";
                html += " }\n";
            } else {
                var seoTitle = (pageInfo.seoTitle || '').replaceAll('\'', '\\\'');
                var seoDescription = (pageInfo.seoDescription || '').replaceAll('\'', '\\\'');
                var seoImage = (pageInfo.seoImage || '').replaceAll('\'', '\\\'');
                var seoKeywords = pageInfo.seoKeywords;

                if ((seoTitle || '') == '') {
                    seoTitle = (siteInfo.seoTitle || '').replaceAll('\'', '\\\'');
                    seoDescription = (siteInfo.seoDescription || '').replaceAll('\'', '\\\'');
                    seoImage = (siteInfo.seoImage || '').replaceAll('\'', '\\\'');
                    seoKeywords = siteInfo.seoKeywords;
                }

                html += "export const metadata = {\n";
                if (seoTitle != null && seoTitle != '' && seoDescription != undefined) {
                    html += "  title: '" + seoTitle + "',\n";
                }
                if (seoDescription != null && seoDescription != '' && seoDescription != undefined) {
                    html += "  description: '" + seoDescription + "',\n";
                }
                html += "  openGraph: {\n";
                if (seoTitle != null && seoTitle != '' && seoTitle != undefined) {
                    html += "    title: '" + seoTitle + "',\n";
                }
                if (seoDescription != null && seoDescription != '' && seoDescription != undefined) {
                    html += "    description: '" + seoDescription + "',\n";
                }
                if (seoImage != null && seoImage != '' && seoImage != undefined) {
                    html += "    images: [{ url: '" + seoImage + "' }]\n";
                }
                html += "  },\n";
                html += "  twitter: {\n";
                if (seoTitle != null && seoTitle != '' && seoTitle != undefined) {
                    html += "    title: '" + seoTitle + "',\n";
                }
                if (seoDescription != null && seoDescription != '' && seoDescription != undefined) {
                    html += "    description: '" + seoDescription + "',\n";
                }
                if (seoImage != null && seoImage != '' && seoImage != undefined) {
                    html += "    images: [{ url: '" + seoImage + "' }]\n";
                }
                html += "  }\n";
                html += "};\n";
            }
            html += "\n";
            html += "export default function " + capitalizePageName + "Shell() {\n";
            html += "\n";
            html += "  return (\n";
            html += "    <>\n";
            //html += "      <main>\n";
            //html += "        <div style={{ padding: "40px 20px 40px 20px" }}>\n";
            //html += "          <h1 style={{ fontSize: "24px", marginBottom: "20px", fontWeight: "bold", textAlign: "center" }}>New Site</h1>\n";
            //html += "          <p style={{ textAlign: "center" }}>Coming Soon...</p>\n";
            //html += "          <p style={{ textAlign: "center", fontSize: "14px", marginTop: "180px", color: "#808080", fontStyle: "italic" }}>powered by: Funnel Goal</p>\n";
            //html += "        </div>\n";
            //html += "      </main>\n";
            html += "      <" + capitalizePageName + " />\n";
            html += "    </>\n";
            html += "  );\n";
            html += "}\n";

            return html;
        };

        const renderImports = () => {
            var list = [];
            var html = '';
            html += "'use client';\n";
            html += "import React, { useContext, useState, useEffect, useRef } from 'react';\n";
            //html += "import { Helmet } from 'react-helmet';\n";
            //html += "import {useParams, Link, useNavigate } from 'react-router-dom';\n";
            html += "import { useRouter, useParams } from 'next/navigation';\n";
            html += "import './css/" + pageInfo.name + ".css';\n";
            html += "import { BAFetchData } from '@/helpers/useBAFetch';\n";
            html += "import helpers from '@/helpers/Helpers';\n";

            for (const key in elements) {
                var element = elements[key];
                const capitalizedElementName = capitilizeWord(element.elementName);
                if (list.includes(capitalizedElementName) == false) {
                    list.push(capitalizedElementName);
                    html += "import " + capitalizedElementName + "Widget from '@/app/components/standard/" + capitalizedElementName + "Widget.js';\n";
                }
            }
            html += '\n';
            return html;
        };

        const renderPageTitle = (pageInfo, site) => {
            var title = site.name;
            if (pageInfo.seoTitle != null) {
                title = pageInfo.seoTitle;
            } else if (site.seoTitle != null) {
                title = site.seoTitle;
            }
            return title;
        };


        const renderFormSupport = (buttonElement, formCodeList) => {
            var html = "";
            if (buttonElement.formCode != '') {
                var delimeter = "";
                var formCode = buttonElement.formCode;
                formCodeList.push(formCode);

                html += "     const [form_" + formCode + ", setform_" + formCode + "] = useState({";

                for (const key in elements) {
                    var element = elements[key];
                    if (element.elementName == 'field' && element.formCode == formCode) {
                        html += delimeter + element.fieldName + ":''";
                        delimeter = ",";
                    }
                }

                html += "});\n";
                html += "\n";
            }
            return html;
        };

        const getDataSourceInfo = (pageInfo, dataSourceName) => {
            for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                var dataSource = pageInfo.dataSources[dsi]
                if (dataSource.sourceName == dataSourceName) {
                    return dataSource;
                    break;
                }
            }
        };

        const getLType = (element) => {
            var lType = 'internal';
            if (element.linkTarget == '_blank' || element.linkUrl.indexOf('http') > -1) {
                lType = 'external';
            }
            return lType;
        }

        const renderJavascript = () => {
            var id = null;
            var jsStr = '\n';

            jsStr += 'const navigate = useRouter();\n';
            jsStr += 'let params = useParams();\n';

            var dataSource = null;
            if (pageInfo.dataSources != null) {
                for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                    dataSource = pageInfo.dataSources[dsi];
                    jsStr += '    const [' + dataSource.sourceName + ',set' + dataSource.sourceName + '] = useState({data:null,paginationInfo:{currentPage:null,totalPages:0}})\n';
                }
            }



            //for (const key in elements) {
            //    var element = elements[key];
            //    if (element.elementName == 'collectionlistwrapper') {
            //        jsStr += '    const [' + element.dataSourceName + ',set' + element.dataSourceName + '] = useState({data:[],paginationInfo:{currentPage:null,totalPages:0}})\n';
            //        
            //    }
            //}

            jsStr += '\n';

            var funcList = [];
            var dataSourceInfo = null;
            if (pageInfo.dataSources != null) {
                for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                    dataSourceInfo = pageInfo.dataSources[dsi];
                    //var dataSourceInfo = getDataSourceInfo(pageInfo, element.dataSourceName);


                    var apiUrl = dataSourceInfo.apiEndpoint + '?'
                    if (dataSourceInfo.runtimeParameters != '') {
                        apiUrl += mergeCollectionVariables(dataSourceInfo.runtimeParameters, true);
                    }
                    if (dataSourceInfo.pageSize != "") {
                        if (helpers.getLastChar(apiUrl) == "?") {
                            apiUrl += "paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSourceInfo.pageSize }) + " + \'";
                        } else {
                            apiUrl += "&paginationParams=' + JSON.stringify({\"page\": page, \"itemsPerPage\": " + dataSourceInfo.pageSize + " }) + \'";
                        }
                    }

                    //setDataSource({ id: helpers.getUUID(), sourceName: '', apiEndpoint: '', designParameters: '', runtimeParameters: '', method: "GET", properties: {} })
                    jsStr += '    function getData_' + dataSourceInfo.sourceName + '(page){\n';
                    jsStr += '        BAFetchData({\n';
                    jsStr += '            callName: \'GetData_' + dataSourceInfo.sourceName + '\',\n';
                    jsStr += '            method: \'' + dataSourceInfo.method + '\',\n';
                    jsStr += '            url: process.env.NEXT_PUBLIC_FG_API_URL + \'' + apiUrl + '\',\n';
                    jsStr += '            token: process.env.NEXT_PUBLIC_BATOKEN,\n';
                    //jsStr += '            toggleRefetch: null,\n';
                    jsStr += '            onSuccess: onAPISuccess, onError: onAPIError\n';
                    jsStr += '        });\n';
                    jsStr += '    }\n';
                    jsStr += '\n';

                    funcList.push('getData_' + dataSourceInfo.sourceName);
                }
            }
            jsStr += '\n';


            //var funcList = [];
            //for (const key in elements) {
            //    var element = elements[key];
            //    if (element.elementName == 'collectionlistwrapper') {
            //        var dataSourceInfo = getDataSourceInfo(pageInfo, element.dataSourceName);


            //        var apiUrl = dataSourceInfo.apiEndpoint + '?'
            //        if (dataSourceInfo.runtimeParameters != '') {
            //            apiUrl += dataSourceInfo.runtimeParameters;
            //        }
            //        if (dataSourceInfo.pageSize != "") {
            //            if (helpers.getLastChar(apiUrl) == "?") {
            //                apiUrl += "paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSourceInfo.pageSize });
            //            } else {
            //                apiUrl += "&paginationParams=' + JSON.stringify({\"page\": page, \"itemsPerPage\": " + dataSourceInfo.pageSize + " })";
            //            }
            //        }

            //        //setDataSource({ id: helpers.getUUID(), sourceName: '', apiEndpoint: '', designParameters: '', runtimeParameters: '', method: "GET", properties: {} })
            //        jsStr += '    function getData_' + element.dataSourceName + '(page){\n';
            //        jsStr += '        BAFetchData({\n';
            //        jsStr += '            callName: \'GetData_' + element.dataSourceName + '\',\n';
            //        jsStr += '            method: \'' + dataSourceInfo.method + '\',\n';
            //        jsStr += '            url: \'' + apiUrl + ',\n';
            //        jsStr += '            token: \'fg1234\',\n';
            //        jsStr += '            toggleRefetch: null,\n';
            //        jsStr += '            onSuccess: onAPISuccess, onError: onAPIError\n';
            //        jsStr += '        });\n';
            //        jsStr += '    }\n';
            //        jsStr += '\n';

            //        funcList.push('getData_' + element.dataSourceName);
            //    }
            //}
            //jsStr += '\n';

            jsStr += '    const funcs = {';
            var funcListComma = '';
            for (var fi = 0; fi < funcList.length; fi++) {
                jsStr += funcListComma + funcList[fi];
                funcListComma = ',';
            }
            jsStr += '};\n';
            jsStr += '\n';
            jsStr += '    function navigateToPage(direction, dataSourceName, dataSourceObj) {\n';
            jsStr += '        var page = null;\n';
            jsStr += '        if (direction == \'next\') {\n';
            jsStr += '            page = dataSourceObj.paginationInfo.currentPage + 1;\n';
            jsStr += '            if (page <= dataSourceObj.paginationInfo.totalPages) {\n';
            jsStr += '                funcs[\'getData_\' + dataSourceName](page);\n';
            jsStr += '            }\n';
            jsStr += '        } else if (direction == \'prev\') {\n';
            jsStr += '            page = dataSourceObj.paginationInfo.currentPage - 1;\n';
            jsStr += '            if (page >= 1) {\n';
            jsStr += '                funcs[\'getData_\' + dataSourceName](page);\n';
            jsStr += '            }\n';
            jsStr += '        }\n';
            jsStr += '    }\n';
            jsStr += '\n';
            jsStr += '\n';

            jsStr += '    function onAPISuccess(data, header, callName, packageObj) {\n';
            jsStr += '        switch (callName) {\n';
            if (pageInfo.dataSources != null) {
                for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                    var dataSourceInfo = pageInfo.dataSources[dsi];
                    jsStr += '            case "GetData_' + dataSourceInfo.sourceName + '":\n';
                    jsStr += '                var dataPackage = { data: data, paginationInfo: (header.get(\'x-pagination\') != undefined ? JSON.parse(header.get(\'x-pagination\')) : null) };\n'
                    jsStr += '                set' + dataSourceInfo.sourceName + '(dataPackage);\n';
                    jsStr += '                break;\n';
                }
            }
            jsStr += '        }\n';
            jsStr += '    }\n';
            jsStr += '\n';

            //jsStr += '    function onAPISuccess(data, header, callName, packageObj) {\n';
            //jsStr += '        switch (callName) {\n';
            //for (const key in elements) {
            //    var element = elements[key];
            //    if (element.elementName == 'collectionlistwrapper') {
            //        jsStr += '            case "GetData_' + element.dataSourceName + '":\n';
            //        jsStr += '                var dataPackage = { data: data, paginationInfo: JSON.parse(header[\'x-pagination\']) };\n'
            //        jsStr += '                set' + element.dataSourceName + '(dataPackage);\n';
            //        jsStr += '                break;\n';
            //    }
            //}
            //jsStr += '        }\n';
            //jsStr += '    }\n';
            //jsStr += '\n';

            jsStr += '    function onAPIError(error, callName) {\n';
            jsStr += '        var msg = error;\n';
            jsStr += '        alert(msg);\n';
            jsStr += '    }\n';
            jsStr += '\n';

            for (const key in elements) {
                var element = elements[key];
                if (element.elementName == 'button' && element.linkType == 'detail_add_to_cart') {
                    jsStr += '    async function addToCart(e, type, payOption, price, productId, variantId, cogs, skuNum) {\n';
                    jsStr += '        var headerId = await helpers.eComm_AddToCart(e, type, payOption, price, productId, variantId, cogs, skuNum, qty);\n';
                    jsStr += '        window["bcpre"]("event", "AddToCart");\n';
                    if (element.linkUrl != '') {
                        var url = element.linkUrl
                        switch (getLType(element)) {
                            case "internal":
                                jsStr += '        navigate.push("' + url + '");\n';
                                break;
                            case "external":
                                jsStr += '        window.open("' + url + '", "' + element.linkTarget + '");\n';
                                break;
                        }

                    }
                    jsStr += '    };\n';
                    jsStr += '\n';
                } else if (element.elementName == 'button' && element.linkType == 'remove_from_cart') {
                    jsStr += '    async function removeFromCart(e, detailId) {\n';
                    jsStr += '        var headerId = await helpers.eComm_RemoveFromCart(e, detailId);\n';
                    jsStr += '        window["bcpre"]("event", "RemoveFromCart");\n';
                    if (element.linkUrl != '') {
                        var url = element.linkUrl
                        switch (getLType(element)) {
                            case "internal":
                                jsStr += '        navigate.push("' + url + '");\n';
                                break;
                            case "external":
                                jsStr += '        window.open("' + url + '", "' + element.linkTarget + '");\n';
                                break;
                        }

                    } else {
                        jsStr += '        refreshPage();\n';
                    }
                    jsStr += '    };\n';
                    jsStr += '\n';
                }
            }

            jsStr += '\n';
            for (const key in elements) {
                var element = elements[key];
                if (element.macroPreset != null && element.macroPreset != '') {
                    switch (element.macroPreset) {
                        case 'detail-update-qty':
                            jsStr += '\n';
                            jsStr += "    const [qty, setQty] = useState(1);\n";
                            jsStr += "    function changeQty(value) {\n";
                            jsStr += "        setQty(value);\n";
                            jsStr += "    }\n";
                            jsStr += '\n';
                            break;
                        case 'select-variant-option':
                            jsStr += "    const [productId, setProductId] = useState(null);\n";
                            jsStr += "    function getProductId() {\n";
                            jsStr += "        BAFetchData({\n";
                            jsStr += "            callName: 'GetProductId',\n";
                            jsStr += "            method: 'GET',\n";
                            jsStr += "            url: process.env.NEXT_PUBLIC_FG_API_URL + 'v1/ecommerce/product/getproductdetailviewbyslug?slug=' + params.slug + '&selectedattributes={}',\n";
                            jsStr += "            token: process.env.NEXT_PUBLIC_BATOKEN,\n";
                            jsStr += "            onSuccess: onAPISuccess_Custom, onError: onAPIError\n";
                            jsStr += "        });\n";
                            jsStr += "    }\n";
                            jsStr += '\n';
                            jsStr += '\n';
                            jsStr += "    const [selectedVariants, setSelectedVariants] = useState({});\n";
                            jsStr += "    function selectVariant(e, variantName, variantValue) {\n";
                            jsStr += "        var tmpSelectedVariants = { ...selectedVariants };\n";
                            jsStr += "        var exists = false;\n";
                            jsStr += "        if (tmpSelectedVariants[variantName] != undefined) {\n";
                            jsStr += "            if (tmpSelectedVariants[variantName] == variantValue) {\n";
                            jsStr += "                exists = true;\n";
                            jsStr += "            }\n";
                            jsStr += "        }\n";
                            jsStr += "        if (exists == false) {\n";
                            jsStr += "            tmpSelectedVariants[variantName] = variantValue;\n";
                            jsStr += "        } else if (exists == true) {\n";
                            jsStr += "            delete tmpSelectedVariants[variantName];\n";
                            jsStr += "        }\n";
                            jsStr += "        setSelectedVariants(tmpSelectedVariants);\n";
                            jsStr += "        getData_fg_available_attr_list(tmpSelectedVariants);\n";
                            jsStr += "    }\n";
                            jsStr += "\n";
                            jsStr += "    const [fg_available_attr_list, setfg_available_attr_list] = useState({ data: [], paginationInfo: { currentPage: null, totalPages: 0 } })\n";
                            jsStr += "    function getData_fg_available_attr_list(selectedVarList) {\n";
                            jsStr += "        BAFetchData({\n";
                            jsStr += "            callName: 'GetData_fg_available_attr_list',\n";
                            jsStr += "            method: 'GET',\n";
                            jsStr += "            url: process.env.NEXT_PUBLIC_FG_API_URL + 'v1/ecommerce/productvariantattribute/getavailableattributes?productid=' + productId + '&selectedattributes=' + JSON.stringify(selectedVarList) + '',\n";
                            jsStr += "            token: process.env.NEXT_PUBLIC_BATOKEN,\n";
                            jsStr += "            onSuccess: onAPISuccess_Custom, onError: onAPIError\n";
                            jsStr += "        });\n";
                            jsStr += "    }\n";
                            jsStr += "\n";
                            jsStr += "\n";
                            jsStr += "    function getUpdatedPrice(page) {\n";
                            jsStr += "        BAFetchData({\n";
                            jsStr += "            callName: 'GetUpdatedPrice',\n";
                            jsStr += "            method: 'GET',\n";
                            jsStr += "            url: process.env.NEXT_PUBLIC_FG_API_URL + 'v1/ecommerce/product/getproductdetailview?productid=' + productId + '&selectedattributes=' + JSON.stringify({ ...selectedVariants }),\n";
                            jsStr += "            token: process.env.NEXT_PUBLIC_BATOKEN,\n";
                            jsStr += "            onSuccess: onAPISuccess_Custom, onError: onAPIError\n";
                            jsStr += "        });\n";
                            jsStr += "    }\n";
                            jsStr += "\n";
                            jsStr += "    function toggleUnavailable(attrName, attrValue) {\n";
                            jsStr += "        var className = 'unavailable ';\n";
                            jsStr += "        var tmpList = fg_available_attr_list.data;\n";
                            jsStr += "        for (var i = 0; i < tmpList.length; i++) {\n";
                            jsStr += "            if (tmpList[i].attrName == attrName && tmpList[i].attrValue == attrValue) {\n";
                            jsStr += "                className = '';\n";
                            jsStr += "            }\n";
                            jsStr += "        }\n";
                            jsStr += "        return className;\n";
                            jsStr += "    }\n";
                            jsStr += "\n";
                            jsStr += "    function toggleSelected(attrName, attrValue) {\n";
                            jsStr += "        var className = '';\n";
                            jsStr += "        var tmpList = selectedVariants;\n";
                            jsStr += "\n";
                            jsStr += "        if (selectedVariants[attrName] != undefined) {\n";
                            jsStr += "            if (selectedVariants[attrName] == attrValue) {\n";
                            jsStr += "                className = 'selected ';\n";
                            jsStr += "            }\n";
                            jsStr += "        }\n";
                            jsStr += "        return className;\n";
                            jsStr += "    }\n";
                            jsStr += "\n";
                            jsStr += "    function onAPISuccess_Custom(data, header, callName, packageObj) {\n";
                            jsStr += "        switch (callName) {\n";
                            jsStr += "            case 'GetData_fg_available_attr_list':\n";
                            jsStr += "                var dataPackage = { data: data, paginationInfo: (header.get('x-pagination') != undefined ? JSON.parse(header.get('x-pagination')) : null) };\n";
                            jsStr += "                setfg_available_attr_list(dataPackage);\n";
                            jsStr += "                break;\n";
                            jsStr += "            case 'GetUpdatedPrice':\n";
                            jsStr += "                var dataPackage = { data: data, paginationInfo: (header.get('x-pagination') != undefined ? JSON.parse(header.get('x-pagination')) : null) };\n";
                            jsStr += "                setfg_product_detail_view(dataPackage);\n";
                            jsStr += "                break;\n";
                            jsStr += "            case 'GetProductId':\n";
                            jsStr += "                setProductId(data.productId);\n";
                            jsStr += "                break;\n";
                            jsStr += "        }\n";
                            jsStr += "    }\n";
                            jsStr += "\n";

                            jsStr += "    useEffect(() =>{\n";
                            jsStr += "        getProductId();\n";
                            jsStr += "    },[])\n";
                            jsStr += "\n";
                            jsStr += "    useEffect(() => {\n";
                            jsStr += "        if (productId != null){\n";
                            jsStr += "            getData_fg_available_attr_list({}, []);\n";
                            jsStr += "        }\n";
                            jsStr += "    }, [productId]);\n";
                            jsStr += "\n";
                            jsStr += "    useEffect(() => {\n";
                            jsStr += "        if (productId != null){\n";
                            jsStr += "            getUpdatedPrice(1);\n";
                            jsStr += "        }\n";
                            jsStr += "    }, [selectedVariants]);\n";
                            break;
                        case 'update-cart-item-qty':
                            jsStr += '    async function updateCartItemQty(e, detailId) {\n';
                            jsStr += '        var headerId = await helpers.eComm_UpdateItemQtyInCart(e, detailId, e.target.value);\n';
                            jsStr += '        refreshPage();\n';
                            jsStr += '    };\n';
                            break;
                        case 'initiate-checkout':
                            jsStr += '\n';
                            jsStr += '    async function initiateCheckout(e) {\n';
                            jsStr += '        var headerId = await helpers.eComm_InitiateCheckout(e);\n';
                            jsStr += '        window["bcpre"]("event", "InitiateCheckout");\n';
                            if (element.linkUrl != '') {
                                var url = element.linkUrl
                                switch (getLType(element)) {
                                    case "internal":
                                        jsStr += '        navigate.push("' + url + '/" + headerId);\n';
                                        break;
                                    case "external":
                                        jsStr += '        window.open("' + url + '", "' + element.linkTarget + '/" + headerId);\n';
                                        break;
                                }

                            }
                            jsStr += '    };\n';
                            jsStr += '\n';
                            break;
                        case 'process-payment':
                            jsStr += '\n';
                            jsStr += '    async function processPayment(e) {\n';
                            jsStr += '        var paid = await helpers.eComm_ProcessPayment(e,fg_shopping_cart);\n';
                            jsStr += '        if(paid == true){\n';
                            jsStr += '            window["bcpre"]("event", "Purchase",{"layerName":"PurchaseData","platform":"Funnel Goal","data":{"value":fg_shopping_cart.data.headerAmt,"cogs":"0","taxAmt":"0","shippingAmt":"0","discountAmt":"0","currency":"USD"}});\n';
                            if (element.linkUrl != '') {
                                var url = element.linkUrl
                                switch (getLType(element)) {
                                    case "internal":
                                        jsStr += '        navigate.push("' + url + '/" + fg_shopping_cart.data.headerId);\n';
                                        break;
                                    case "external":
                                        jsStr += '        window.open("' + url + '/" + fg_shopping_cart.data.headerId, "' + element.linkTarget + '");\n';
                                        break;
                                }

                            }
                            jsStr += '        } else {\n';
                            jsStr += '            alert(\'Payment did not go through\')\n';
                            jsStr += '        }\n';
                            jsStr += '    };\n';
                            jsStr += '\n';
                            break;
                    }
                }
            }
            jsStr += '\n';


            for (const key in elements) {
                var element = elements[key];

                if (element.hasOwnProperty('events')) {
                    if (JSON.stringify(element.events) != "{}") {
                        var elementId = helpers.useCustomIdCheck(element);
                        jsStr += '    function onClick_' + elementId + '(e) {\n';
                        for (const eventId in element.events) {
                            if (eventId.indexOf('-') == -1) {
                                var eventObj = element.events[eventId];
                                if (eventObj.action.config.title != undefined) {
                                    var title = eventObj.action.config.title.replaceAll(" ", "");
                                    for (var aigi = 0; aigi < eventObj.action.config.actionItemGroups.length; aigi++) {
                                        var actionItemGroup = eventObj.action.config.actionItemGroups[aigi];
                                        for (var aii = 0; aii < actionItemGroup.actionItems.length; aii++) {
                                            var actionItem = actionItemGroup.actionItems[aii];
                                            var targetId = helpers.useCustomIdCheck(elements[actionItem.config.target.id]);
                                            if (targetId != null) {
                                                var secondClickInfo = existIn2ndClick(targetId, element.events[eventId + '-2']);

                                                jsStr += '        var el_' + targetId + ' = document.getElementById(\'' + targetId + '\');\n';
                                                jsStr += '        if (el_' + targetId + '.classList.contains(\'' + title + '\')) {\n';
                                                jsStr += '            el_' + targetId + '.classList.remove(\'' + title + '\');\n';
                                                if (secondClickInfo.found == true) {
                                                    jsStr += '            el_' + targetId + '.classList.add(\'' + secondClickInfo.title + '\');\n';
                                                }
                                                jsStr += '        } else {\n';
                                                jsStr += '            el_' + targetId + '.classList.add(\'' + title + '\');\n';
                                                if (secondClickInfo.found == true) {
                                                    jsStr += '            el_' + targetId + '.classList.remove(\'' + secondClickInfo.title + '\');\n';
                                                }
                                                jsStr += '        }\n';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        jsStr += '    }\n\n';
                    }
                }

            }
            return jsStr;
        };

        const existIn2ndClick = (targetId, eventObj) => {
            var found = false;
            var title = ''
            //var eventObj = element.events[eventId];
            if (eventObj.action.config.hasOwnProperty('title')) {
                title = eventObj.action.config.title.replaceAll(" ", "");
                for (var aigi = 0; aigi < eventObj.action.config.actionItemGroups.length; aigi++) {
                    var actionItemGroup = eventObj.action.config.actionItemGroups[aigi];
                    for (var aii = 0; aii < actionItemGroup.actionItems.length; aii++) {
                        var actionItem = actionItemGroup.actionItems[aii];
                        if (helpers.useCustomIdCheck(elements[actionItem.config.target.id]) == targetId) {
                            found = true;
                            break;
                        }
                    }
                }
            }
            return { found: found, title: title };
        };

        const renderPageWrapper = () => {
            var capitalizePageName = capitilizeWord(pageInfo.name);
            var formCodeList = [];
            var html = '';
            html += "export default function " + capitalizePageName + "() {\n";
            html += "\n";
            for (const key in elements) {
                var element = elements[key];
                if (element.elementName == 'button') {
                    html += renderFormSupport(element, formCodeList);
                }
            }

            html += "\n";
            html += renderJavascript();
            html += "\n";

            html += "\n";
            html += "    function onFormValueChange(e, formObj, setFormObj, propertyName, value) {\n";
            html += "        var tmpFormObj = { ...formObj };\n";
            html += "        tmpFormObj[propertyName] = value;\n";
            html += "        setFormObj((...formObj) => { return tmpFormObj });\n";
            html += "    }\n";
            html += "\n";
            html += "    function refreshPage() {\n";
            //html += "        document.title = '" + renderPageTitle(pageInfo, siteInfo) + "';\n";
            if (pageInfo.dataSources != null) {
                for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                    var dataSourceInfo = pageInfo.dataSources[dsi];
                    html += "        getData_" + dataSourceInfo.sourceName + "(1);\n";
                }
            }
            html += "    }\n";
            html += "\n";
            html += "    function handleMainComponentUnmount() {\n";
            html += "        //window['bcpre']('event', 'PageClose')\n";
            html += "    }\n";
            html += "\n";
            html += "        function pageLoadEvents(){\n";
            html += "            //this method gets called by the bcrumb when init is completed and ready\n";
            html += "            if (window['bcp_ready'] == true) {\n";
            html += "                //default page load events\n";
            html += "                window['bcpre']('event', 'PageView');\n";
            html += "                " + siteInfo.globalPageLoadJsScripts + "\n";
            if (pageInfo.pageLoadedTrackingEvents != null && pageInfo.pageLoadedTrackingEvents != '') {
                html += "                " + pageInfo.pageLoadedTrackingEvents + "\n";
            }
            html += "                //custom page load events\n";
            html += "            }\n";
            html += "        }\n";
            html += "\n";
            html += "    const bcpreReadyInterval = useRef();\n";
            html += "    useEffect(() => {\n";
            html += "        bcpreReadyInterval.current = setInterval(() => {\n";
            html += "            if (window['bcp_ready'] == true) {\n";
            html += "                clearInterval(bcpreReadyInterval.current); // Stop polling\n";
            html += "                pageLoadEvents();\n";
            html += "            }\n";
            html += "        }, 200);\n";
            html += "    }, []);\n";
            html += "\n";
            html += "\n";
            html += "    useEffect(() => {\n";
            html += "        refreshPage();\n";
            html += '        return handleMainComponentUnmount();\n';
            html += "    }, []);\n";
            html += "\n";
            html += "//begin custom code \n";
            if (pageInfo.javascript != null && pageInfo.javascript != '') {
                html += pageInfo.javascript + '\n';
            }
            html += "//end custom code \n";
            html += "\n";
            html += "    return (\n";
            html += "        <>\n";
            if (pageInfo.dataSources != null) {
                if (pageInfo.dataSources.length > 0) {
                    html += "        {" + pageInfo.dataSources[0].sourceName + ".data != null ? \n";
                }
            }
            html += "            <div className=\"fg-app-page-box\">\n";

            //html += "                <Helmet>\n";
            //if (pageInfo.dynamicSeo == false) {
            //    if (pageInfo.seoTitle != null && pageInfo.seoTitle != '') {
            //        html += "                    <title>" + pageInfo.seoTitle + "</title>\n";
            //        html += "                    <meta name=\"og:title\" content=\"" + pageInfo.seoTitle + "\" />\n";
            //    }
            //    if (pageInfo.seoDescription != null && pageInfo.seoDescription != '') {
            //        html += "                    <meta name=\"description\" content=\"" + pageInfo.seoDescription + "\" />\n";
            //        html += "                    <meta name=\"og:description\" content=\"" + pageInfo.seoDescription + "\" />\n";
            //    }
            //    if (pageInfo.seoImage != null && pageInfo.seoImage != '') {
            //        html += "                    <meta name=\"og:image\" content=\"" + pageInfo.seoImage + "\" />\n";
            //    }
            //    if (pageInfo.seoKeywords != null && pageInfo.seoKeywords != '') {
            //        html += "                    <meta name=\"keywords\" content=\"" + pageInfo.seoKeywords + "\" />\n";
            //    }
            //} else {
            //    //if (pageInfo.name.indexOf('blog') > -1) {

            //    //} else if (pageInfo.name.indexOf('product') > -1) {

            //    //}
            //}

            //html += "                    <meta name=\"og:site_name\" content=\"" + siteInfo.prodUrl + "\" />\n";
            //html += "                    <link name=\"canonical\" href=\"/" + pageInfo.name + "\" />\n";
            //html += "                </Helmet>\n";


            html += renderElement(helpers.getRootElementId(elements), (12 + 4), formCodeList);

            html += "            </div>\n";
            if (pageInfo.dataSources != null) {
                if (pageInfo.dataSources.length > 0) {
                    html += "        : null } \n";
                }
            }
            html += "        </>\n";
            html += "    );\n";
            html += "}\n";
            //html += "export default " + capitalizePageName + ";\n";
            return html;
        };

        const cssToReact = (css) => {
            var newCss = '';
            var cssList = css.split(";");
            for (var i = 0; i < cssList.length; i++) {
                if (cssList[i] != '') {
                    var cssPropertyInfo = cssList[i].split(':');
                    var cssProperty = cssPropertyInfo[0];
                    var value = cssPropertyInfo[1];
                    var newProperty = '';
                    var cssPropertyNameList = cssProperty.split('-')
                    for (var pi = 0; pi < cssPropertyNameList.length; pi++) {
                        if (pi == 0) {
                            newProperty = cssPropertyNameList[pi];
                        } else {
                            var tmpName = cssPropertyNameList[pi];
                            newProperty += tmpName.charAt(0).toUpperCase() + tmpName.slice(1);
                        }
                    }
                    newCss += newProperty.trim() + ":\"" + value.trim() + "\"";
                }
            }
            return newCss;
        };

        const convertCssStyletoReactStyle = (value) => {
            // a pattern having 'JavaScript' followed by a digit
            const regex = /(style=")(.*?)(")/gm;
            // finding matches in the string for the given regular expression
            let results = value.matchAll(regex);
            // looping through the iterator
            for (var result of results) {
                console.log(result);
                var tempCss = result[0].replaceAll('style="', '').replaceAll('"', '');
                value = value.replace(result[0], 'style={{' + cssToReact(tempCss) + '}} ');
            }
            return value;
        };

        const mergeCollectionVariablesTextPropertyOnly = (value, nonHtml) => {
            if (value != undefined) {

                var regexp = /(%%params.)([a-zA-Z._(0-9)-]*)[~]/gm;
                var matches = value.matchAll(regexp);

                for (const match of matches) {
                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');

                    var dataValue = "${" + keys[0] + "." + keys[1] + "}";
                    if (nonHtml == true) {
                        value = value.replaceAll(match[0], "\' + " + dataValue.replace("${", "").replace("}", "") + " + \'");
                    } else {
                        value = value.replaceAll(match[0], dataValue);
                    }
                }


                regexp = /(%%)([a-zA-Z._(0-9)-]*)[~]/gm;
                matches = value.matchAll(regexp);

                var parentDataSource = null;
                for (const match of matches) {
                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');
                    if (keys.length == 2) {
                        //var dataValue = "${" + keys[0] + ".data." + keys[1] + " || ''}";
                        var dataValue = "${" + keys[0] + ".data." + keys[1] + "}";
                        dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                        if (nonHtml == true) {
                            value = value.replaceAll(match[0], "\' + " + dataValue.replace("${", "").replace("}", "") + " + \'");
                        } else {
                            value = value.replaceAll(match[0], dataValue);
                        }

                    }
                    if (keys.length == 3) {
                        if (keys[1] == "row") {
                            //var dataValue = "${" + keys[0] + "_row." + keys[2] + " || ''}";
                            var dataValue = "${" + keys[0] + "_row." + keys[2] + "}";
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            if (nonHtml == true) {
                                value = value.replaceAll(match[0], "\' + " + dataValue.replace("${", "").replace("}", "") + " + \'");
                            } else {
                                value = value.replaceAll(match[0], dataValue);
                            }

                        } else if (keys[1] == "paginationInfo") {
                            var dataValue = match[0].replaceAll("%%", "${").replaceAll("~", "}");
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            value = value.replaceAll(match[0], dataValue);
                        } else {
                            var dataValue = "${" + keys[0] + ".data";
                            var variableDelimeter = ".";
                            for (var i = 1; i < keys.length; i++) {
                                dataValue += variableDelimeter + keys[i];
                            }
                            dataValue += "}";
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            value = value.replaceAll(match[0], dataValue);
                        }
                    }
                }
            } else {
                value = '';
            }
            return value;
        }

        const mergeCollectionVariables = (value, nonHtml) => {
            if (value != undefined) {

                var regexp = /(%%cookie.)([a-zA-Z._(0-9)-]*)[~]/gm;
                var matches = value.matchAll(regexp);

                for (const match of matches) {
                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');

                    var dataValue = "helpers.getCookie('" + keys[1] + "')";
                    //var dataValue = "{" + keys[0] + "." + keys[1] + "}";
                    //if (nonHtml == true) {
                        value = value.replaceAll(match[0], "\' + " + dataValue.replace("{", "").replace("}", "") + " + \'");
                    //} else {
                    //    value = value.replaceAll(match[0], dataValue);
                    //}
                }

                var regexp = /(%%params.)([a-zA-Z._(0-9)-]*)[~]/gm;
                var matches = value.matchAll(regexp);

                for (const match of matches) {
                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');

                    var dataValue = "{" + keys[0] + "." + keys[1] + "}";
                    if (nonHtml == true) {
                        value = value.replaceAll(match[0], "\' + " + dataValue.replace("{", "").replace("}", "") + " + \'");
                    } else {
                        value = value.replaceAll(match[0], dataValue);
                    }
                }


                regexp = /(%%)([a-zA-Z._(0-9)-]*)[~]/gm;
                matches = value.matchAll(regexp);

                var parentDataSource = null;
                for (const match of matches) {
                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');
                    if (keys.length == 2) {
                        //var dataValue = "{" + keys[0] + ".data." + keys[1] + " || ''}";
                        var dataValue = "{" + keys[0] + ".data." + keys[1] + "}";
                        dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                        if (nonHtml == true) {
                            value = value.replaceAll(match[0], "\' + " + dataValue.replace("{", "").replace("}", "") + " + \'");
                        } else {
                            value = value.replaceAll(match[0], dataValue);
                        }

                    }
                    if (keys.length == 3) {
                        if (keys[1] == "row") {
                            //var dataValue = "{" + keys[0] + "_row." + keys[2] + " || ''}";
                            var dataValue = "{" + keys[0] + "_row." + keys[2] + "}";
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            if (nonHtml == true) {
                                value = value.replaceAll(match[0], "\' + " + dataValue.replace("{", "").replace("}", "") + " + \'");
                            } else {
                                value = value.replaceAll(match[0], dataValue);
                            }

                        } else if (keys[1] == "paginationInfo") {
                            var dataValue = match[0].replaceAll("%%", "{").replaceAll("~", "}");
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            value = value.replaceAll(match[0], dataValue);
                        } else {
                            var dataValue = "{" + keys[0] + ".data";
                            var variableDelimeter = ".";
                            for (var i = 1; i < keys.length; i++) {
                                dataValue += variableDelimeter + keys[i];
                            }
                            dataValue += "}";
                            dataValue = dataValue.replace('.csvvalue', '_csvvalue');
                            value = value.replaceAll(match[0], dataValue);
                        }
                    }
                }
            } else {
                value = '';
            }
            return value;
        }

        const getParentCollectionElement = (element) => {
            var parentCollectionElement = null;
            for (var i = 0; i < element.parents.length; i++) {
                if (elements[element.parents[i]].elementName == 'collectionlistwrapper') {
                    parentCollectionElement = elements[element.parents[i]];
                    break;
                }
            }
            return parentCollectionElement;
        }

        const renderFieldInnerContents = (element) => {
            var html = '';
            switch (element.fieldType) {
                case 'select':
                    var options = [];
                    if (element.fieldOptions != '') {
                        options = JSON.parse(element.fieldOptions);
                    }
                    for (var i = 0; i < options.length; i++) {
                        html += '<option value="' + options[i].value + '">' + options[i].text + '</option>\n';
                    }
                    break;
            }
            return html;
        }

        const attachParentCollectionRowItem = (collectionListEement, collectionName) => {
            var useRowItem = false;
            var parentCollectionElement = getParentCollectionElement(collectionListEement);
            if (parentCollectionElement != null) {
                var collectionDataSourceNameKeys = parentCollectionElement.dataSourceName.split('.');
                if (collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] == collectionName) {
                    useRowItem = true;
                }
            }
            return useRowItem;
        }

        const addFieldEventTracking = (element) => {
            var js = "";
            if (element.hasOwnProperty('eventTracker')) {
                if (element.eventTracker.onValueChanged != undefined) {
                    if (element.eventTracker.onValueChanged != null && element.eventTracker.onValueChanged != "") {
                        js += "if (e.target.value != '') " + element.eventTracker.onValueChanged;
                    }
                }
            }
            return js;            
        }

        const renderElement = (key, repeatN, formCodeList) => {
            var html = '';
            var element = elements[key];
            const capitalizedElementName = capitilizeWord(element.elementName);
            const capitalizedWidgetName = capitalizedElementName + 'Widget';
            html += " ".repeat(repeatN) + "<" + capitalizedWidgetName + " ";
            html += "params = {{ " + renderElementParams(element, capitalizedWidgetName, formCodeList) + " }}"


            var customEventDelimeter = "";
            var customEventStr = "";
            customEventStr = renderElementAdditionalEventProps(element, capitalizedWidgetName, formCodeList)
            if (customEventStr != '') {
                customEventDelimeter = ", ";
            }

            //add interaction events
            if (element.hasOwnProperty('events')) {
                if (JSON.stringify(element.events) != "{}") {
                    //customEventStr += customEventDelimeter + "handleOnClick: (e) => onClick_" + helpers.useCustomIdCheck(element) + "()";
                    customEventStr += customEventDelimeter + "onClick: (e) => onClick_" + helpers.useCustomIdCheck(element) + "()";
                }
            }
            //add universal custom javascript events          
            if (element.hasOwnProperty('customEvents')) {                                           
                if (customEventStr != '') {
                    customEventDelimeter = ", ";
                }
                var customEvent = null;
                for (var cei = 0; cei < element.customEvents.length; cei++) {
                    var customEvent = element.customEvents[cei];
                    //customEventStr += customEventDelimeter + customEvent.eventName + ":(e) => {" + mergeCollectionVariables(customEvent.value).replaceAll("{", "").replaceAll("}", "") + ";}";
                    if (customEvent.value.indexOf('helpers.eComm_BuyNow(') > -1) {
                        var eventValue = customEvent.value.replace(")", ",'" + element.linkUrl + "','" + element.linkTarget + "', navigate)")
                        customEventStr += customEventDelimeter + customEvent.eventName + ":(e) => {" + mergeCollectionVariables(eventValue).replaceAll("{", "").replaceAll("}", "") + ";}";
                    } else {
                        customEventStr += customEventDelimeter + customEvent.eventName + ":(e) => {" + mergeCollectionVariables(customEvent.value).replaceAll("{", "").replaceAll("}", "") + ";" + ((element.elementName == 'field' && customEvent.eventName == 'onBlur') ? addFieldEventTracking(element) : "") + "}";
                    }                   
                    customEventDelimeter = ", ";
                }
            }
            if (customEventStr != "") {
                html += " eventProps={{";
                html += customEventStr;
                html += "}} ";
            }

            ////add custom javascript events
            //if (element.hasOwnProperty('customEvents')) {
            //    for (var cei = 0; cei < element.customEvents.length; cei++) {
            //        var customEvent = element.customEvents[cei];
            //        switch (customEvent.eventName) {
            //            case 'onClick':
            //                params += ",handleOnClick: (e) => {" + mergeCollectionVariables(customEvent.value).replaceAll("{", "").replaceAll("}", "") + "}";
            //                break;
            //        }
            //    }
            //}





            html += renderElementAdditionalProp(element, capitalizedWidgetName, formCodeList);
            html += ">\n";
            var showPagination = false;
            if (element.elementName == 'collectionlistwrapper') {
                showPagination = (getPropertyValue(element, 'showPagination').toString() === "true");
            }
            if (element.elementName == 'collectionlistcontent') {                
                var collectionDataSourceName = elements[element.parents[0]].dataSourceName;
                var collectionDataSourceType = elements[element.parents[0]].dataSourceType;
                switch (collectionDataSourceType) {
                    case "collection":

                        html += '    {' + collectionDataSourceName + '.data.map((' + collectionDataSourceName.replaceAll('.', '_') + '_row, index) => (\n';
                        break;
                    case "nested-collection":
                        var collectionDataSourceNameKeys = collectionDataSourceName.split('.');
                        if (collectionDataSourceNameKeys.length == 1) {
                            html += '    {' + collectionDataSourceName + '.data.map((' + collectionDataSourceName.replaceAll('.', '_') + '_row, index) => (\n';
                        } else {
                            if (helpers.getDataSource(collectionDataSourceNameKeys[0], pageInfo.dataSources) != null) {
                                html += '    {' + collectionDataSourceNameKeys[0] + '.data.' + collectionDataSourceNameKeys[1] + ' != undefined ? \n';
                                html += '    <>\n';

                                html += '    {' + collectionDataSourceNameKeys[0] + '.data.' + collectionDataSourceNameKeys[1] + '.map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row, index) => (\n';
                            } else {
                                if (collectionDataSourceNameKeys.length == 2) {
                                    html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[1] + ' != undefined ? \n';
                                    html += '    <>\n';

                                    html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[1] + '.map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row, index) => (\n';
                                } else if (collectionDataSourceNameKeys.length == 3) {
                                    html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[2] + ' != undefined ? \n';
                                    html += '    <>\n';

                                    html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[2] + '.map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row, index) => (\n';
                                }                                
                            }
                        }
                            break;
                    case "nested-csv":
                        var collectionDataSourceNameKeys = collectionDataSourceName.split('.');                       
                        if (collectionDataSourceNameKeys.length == 1) {
                            html += '    {' + collectionDataSourceName + ' != null ? \n';
                            html += '    <> \n';
                            html += '    {' + collectionDataSourceName + '.data.split(\',\').map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row_csvvalue, index) => (\n';                           
                        } else {
                            if (helpers.getDataSource(collectionDataSourceNameKeys[0], pageInfo.dataSources) != null) {
                                html += '    {' + collectionDataSourceNameKeys[0] + '.data.' + collectionDataSourceNameKeys[1] + ' != null ? \n';
                                html += '    <> \n';
                                html += '    {' + collectionDataSourceNameKeys[0] + '.data.' + collectionDataSourceNameKeys[1] + '.split(\',\').map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row_csvvalue, index) => (\n';
                            } else {
                                if (collectionDataSourceNameKeys.length == 2) {
                                    html += '    {' + collectionDataSourceName + ' != null ? \n';
                                    html += '    <> \n';
                                    html += '    {' + collectionDataSourceName + '.split(\',\').map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row_csvvalue, index) => (\n';
                                } else if (collectionDataSourceNameKeys.length == 3) {
                                    if (attachParentCollectionRowItem(elements[element.parents[0]], collectionDataSourceNameKeys[0]) == true) {
                                        html += '    {' + collectionDataSourceNameKeys[0] + '_row.' + collectionDataSourceNameKeys[2] + ' != null ? \n';
                                        html += '    <> \n';
                                        html += '    {' + collectionDataSourceNameKeys[0] + '_row.' + collectionDataSourceNameKeys[2] + '.split(\',\').map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row_csvvalue, index) => (\n';
                                    } else {
                                        html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[2] + ' != null ? \n'; 
                                        html += '    <> \n';
                                        html += '    {' + collectionDataSourceNameKeys[0] + '.' + collectionDataSourceNameKeys[2] + '.split(\',\').map((' + collectionDataSourceNameKeys[collectionDataSourceNameKeys.length - 1] + '_row_csvvalue, index) => (\n';
                                    }                                    
                                }
                            }
                        }                        
                        break;
                }                
                html += '    <>\n';
            }
            if (element.hasOwnProperty('text')) {
                if (element.text != '') {
                    //html += "{`" + (" ".repeat(repeatN + 4)) + convertCssStyletoReactStyle(mergeCollectionVariablesTextPropertyOnly(element.text).replaceAll('<br>', '<br/>')) + "`}\n";
                    html += "{`" + convertCssStyletoReactStyle(mergeCollectionVariablesTextPropertyOnly(element.text).replaceAll('<br>', '<br/>')) + "`}\n";

                }
            }            
            for (var i = 0; i < element.children.length; i++) {
                var skip = false;
                if (element.elementName == 'collectionlistwrapper' && elements[element.children[i]].elementName == 'collectionlistpagination' && showPagination == false) {
                    skip = true;
                }
                if (skip == false) {
                    html += renderElement(element.children[i], (repeatN + 4), formCodeList);
                }

            }
            if (element.elementName == 'collectionlistcontent') {
                html += '    </>\n';
                html += '    ))}\n';
                var collectionDataSourceType = elements[element.parents[0]].dataSourceType;
                switch (collectionDataSourceType) {
                    case "nested-collection":
                        html += '   </>\n';
                        html += ': null }\n';
                        break;
                    case "nested-csv":
                        html += '    </>\n';
                        html += ': null } \n';
                        break;
                }
            }
            html += " ".repeat(repeatN) + "</" + capitalizedWidgetName + ">\n";
            return html;
        }

        const getPropertyValue = (element, propertyName) => {
            if (element.hasOwnProperty(propertyName)) {
                return element[propertyName];
            } else {
                return '';
            }
        };

        const getPropertyValue2Level = (element, propertyName1, propertyName2) => {
            if (element.hasOwnProperty(propertyName1)) {
                if (element[propertyName1].hasOwnProperty(propertyName2)) {
                    return element[propertyName1][propertyName2];
                } else {
                    return '';
                }
            } else {
                return '';
            }
        };

        const renderElementAdditionalEventProps = (element, capitalizedWidgetName, formCodeList) => {
            var final = "";
            switch (element.elementName) {
                case 'field':
                    if (formCodeList.includes(element.formCode)) {
                        final += "onChange:(e) => onFormValueChange(e, form_" + element.formCode + ", setform_" + element.formCode + ", '" + element.fieldName + "', e.target.value) ";
                    }
                    break;
            }
            return final;
        }

        const renderElementAdditionalProp = (element, capitalizedWidgetName, formCodeList) => {
            var final = "";   
            switch (element.elementName) {
                case 'field':                    
                    if (formCodeList.includes(element.formCode)) {
                        final += " value={form_" + element.formCode + "." + element.fieldName + "} ";
                    } else {
                        switch (element.fieldDataType) {
                            case "string":
                                var valueDelimeter = "";
                                final += " valueProps={{";
                                if (element.fieldValue != null && element.fieldValue != '') {
                                    final += "value:" + mergeCollectionVariables(element.fieldValue).replaceAll("{", "").replaceAll("}", "") + "";
                                    valueDelimeter = ",";
                                }
                                if (element.fieldDefaultValue != null && element.fieldDefaultValue != '') {
                                    final += valueDelimeter + "defaultValue:" + mergeCollectionVariables(element.fieldDefaultValue).replaceAll("{", "").replaceAll("}", "") + "";
                                }
                                final += "}} ";


                                break;
                            case "number":
                            case "decimal":
                                var valueDelimeter = "";
                                final += " valueProps={{";
                                if (element.fieldValue != null && element.fieldValue != '') {
                                    final += "value:" + mergeCollectionVariables(element.fieldValue).replaceAll("{", "").replaceAll("}", "") + "";
                                    valueDelimeter = ", ";
                                }
                                if (element.fieldDefaultValue != null && element.fieldDefaultValue != '') {
                                    final += valueDelimeter + "defaultValue:" + mergeCollectionVariables(element.fieldDefaultValue).replaceAll("{", "").replaceAll("}", "") + "";
                                }
                                final += "}} ";
                                break;
                            default:
                                var valueDelimeter = "";
                                final += " valueProps={{";
                                if (element.fieldValue != null && element.fieldValue != '') {
                                    final += "value:'" + mergeCollectionVariables(element.fieldValue).replaceAll("{", "").replaceAll("}", "") + "'";
                                    valueDelimeter = ", ";
                                }
                                if (element.fieldDefaultValue != null && element.fieldDefaultValue != '') {
                                    final += valueDelimeter + "defaultValue:'" + mergeCollectionVariables(element.fieldDefaultValue).replaceAll("{", "").replaceAll("}", "") + "'";
                                }
                                final += "}} ";
                                break;
                        }
                    }
                    break;
                case 'button':
                    if (element.formCode != '') {
                        final += " formData={form_" + element.formCode + "} ";
                        final += " setFormData={setform_" + element.formCode + "} ";
                        final += " formSchema={" + buildFormSchema(element.formCode) + "}";
                        final += " eventTracker={" + JSON.stringify(element.eventTracker) + "}";
                    }
                    break;
            }
            return final; 
        };

        const buildFormSchema = (formCode) => {
            var formSchema = {};
            for (const key in elements) {
                var element = elements[key];
                if (element.elementName == 'field' && element.formCode == formCode) {
                    formSchema[helpers.useCustomIdCheck(element)] = {"fieldName":element.fieldName,"dataType":element.fieldDataType, "required":element.fieldRequired, "maxLength":element.fieldMaxLength};
                }
            }
            return JSON.stringify(formSchema);
        };

        const getClosestParentCollectionList = (element) => {
            var collectionListWrapperElement = null;
            for (var i = 0; i < element.parents.length; i++) {
                if (elements[element.parents[i]].elementName == 'collectionlistwrapper') {
                    collectionListWrapperElement = elements[element.parents[i]];
                    break;
                }
            }
            return collectionListWrapperElement;
        }

        const renderElementParams = (element, capitalizedWidgetName, formCodeList) => {
            var value = "";
            var onChange = "";
            var params = "id:'" + helpers.useCustomIdCheck(element) + "', className:'" + element.className + "'";
            if (element.hasOwnProperty('customClassName')) {
                if (element.customClassName != '' && element.customClassName != null) {
                    params += mergeCollectionVariables(element.customClassName).replaceAll("{", "").replaceAll("}", "");
                }                
            }
            params += ",elementName:'" + capitalizedWidgetName + "'";
            switch (element.elementName) {
                case 'image':
                    if (element.src.indexOf('%%') > -1) {
                        var src = mergeCollectionVariables(element.src).replaceAll('{', '').replaceAll('}', '');
                        params += ",src:" + src + ", alt:'" + element.alt + "'";
                    } else {
                        params += ",src:'" + element.src + "', alt:'" + element.alt + "'";
                    }                    
                    break;
                case 'fieldlabel':
                    if (element.labelFor != null && element.labelFor != '') {
                        params += ",for:'" + element.labelFor + "'";
                    }
                    break;
                case 'field':
                    params += ",formCode:'" + element.formCode + "', controlType:'" + element.fieldType + "', type:'" + element.fieldDataType + "', fieldName:'" + element.fieldName + "', fieldLabel:'" + element.fieldLabel + "'";
                    if (element.fieldPlaceholder != null && element.fieldPlaceholder != '') {
                        params += ",placeholder:'" + element.fieldPlaceholder + "'";
                    }
                    if (element.fieldType == 'select') {
                        params += ",fieldOptions:" + element.fieldOptions;
                    }
                    value += "{form_" + element.formCode + "." + element.fieldName + "}";
                    if (formCodeList.includes(element.formCode)) {
                        onChange = "{(e, value) => onFormValueChange(e, form_" + element.formCode + ", setform_" + element.formCode + ", '" + element.fieldName + "', value)}";
                    }
                    break;
                case 'button':
                    params += ",formId:'" + getPropertyValue(element, 'formId') + "'";
                    params += ",formCode:'" + getPropertyValue(element, 'formCode') + "'";
                    params += ",linkType:'" + getPropertyValue(element,'linkType') + "'";
                    params += ",linkUrl:'" + mergeCollectionVariables(getPropertyValue(element, 'linkUrl'),true) + "'";
                    params += ",linkUrlParams:'" + getPropertyValue(element, 'linkUrlParams') + "'";
                    params += ",linkTarget:'" + getPropertyValue(element, 'linkTarget') + "'";
                    params += ",linkDiscountCode:'" + getPropertyValue(element, 'linkDiscountCode') + "'";
                    break;
                case 'linkblock':
                    params += ",linkType:'" + getPropertyValue(element, 'linkType') + "'";
                    params += ",linkUrl:'" + mergeCollectionVariables(getPropertyValue(element, 'linkUrl'),true) + "'";
                    params += ",linkUrlParams:'" + getPropertyValue(element, 'linkUrlParams') + "'";
                    params += ",linkTarget:'" + getPropertyValue(element, 'linkTarget') + "'";
                    params += ",linkDiscountCode:'" + getPropertyValue(element, 'linkDiscountCode') + "'";
                    if (elements[element.parents[0]].elementName == 'collectionlistpagination') {
                        var collectionListWrapperElement = getClosestParentCollectionList(element);
                        if (collectionListWrapperElement != null) {
                            var collectionDataSourceName = collectionListWrapperElement.dataSourceName;
                            if (getPropertyValue(element, 'linkType') == 'collection_prev') {
                                params += ",handleOnClick: () => navigateToPage('prev', '" + collectionDataSourceName + "'," + collectionDataSourceName + ")";
                            }
                            if (getPropertyValue(element, 'linkType') == 'collection_next') {
                                params += ",handleOnClick: () => navigateToPage('next', '" + collectionDataSourceName + "'," + collectionDataSourceName + ")";
                            }
                        }
                    }
                    break;
                case 'video':
                    params += ",thumbnailUrl:'" + getPropertyValue2Level(element, 'videoSettings', 'thumbnailUrl') + "'";
                    params += ",videoUrl:'" + getPropertyValue2Level(element, 'videoSettings', 'url') + "'";
                    break;
            }
            return params;
        };

        return { rootPageContent: renderRootPage(), html: renderPage(), css: renderStyleSheet()};       
    },

}

export default PageGenerator
import React, { useContext, useState, useEffect } from 'react';
import ColorPicker from 'react-best-gradient-color-picker'

function ColorSelectorCtrl({ defaultColor, hideControls, dialogType, handlerReturnValue }) {
    const [color, setColor] = useState(defaultColor);


    const handleChange = (newColor) => {
        setColor(newColor);
        if (handlerReturnValue) {
            handlerReturnValue(newColor)
        }
    }




    return (
        <>
            <ColorPicker value={color} onChange={handleChange} hidePresets={true} hideControls={hideControls} width={260} height={260} />
            <div style={{background:color,height:"20px",width:"100%",marginTop:"5px"}}></div>
        </>
    );
}

export default ColorSelectorCtrl;
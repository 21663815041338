import React, { useState, useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import helpers from '../../helpers/Helpers';

export default function DialogProCtrl({ schema }) {
    const [open, setOpen] = useState(true);
    const [returnValue, setReturnValue] = useState([]);
    const initialRender = useRef(true);
    const [leftPos, setLeftPos] = useState(updatePosition());
    const handleClickOpen = () => {
        //setOpen(true);
    };


    function updatePosition() {
        if (schema.hasOwnProperty("paperProps")) {
            if (JSON.stringify(schema.paperProps) != '{}') {
                return (window.innerWidth - 300);
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    const handleCancelClick = (event, reason) => {
        
        if (reason != 'escapeKeyDown' && reason != 'backdropClick') {
            schema.closeModal();
        } else if (reason == 'escapeKeyDown') {
            if (schema.ignoreESCKey) {
                if (schema.ignoreESCKey != true) {
                    schema.closeModal();
                }
            } else {
                schema.closeModal();
            }
        } else if (reason == 'backdropClick') {
            if (schema.ignoreBGClick) {
                if (schema.ignoreBGClick != true) {
                    schema.closeModal();
                }
            } else {
                schema.closeModal();
            }
        }
    };

    const handleOKClick = () => {
        schema.okModal(returnValue);
    };

    //schema.onReturnValueChanged(returnValue)


    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (schema.hasOwnProperty('onReturnValueChanged')) {
                if (schema.onReturnValueChanged != null) {
                    schema.onReturnValueChanged(returnValue);
                }
            }
        }
    }, [returnValue]);


    return (
        <div>
            <Dialog
                open={true}
                onClose={handleCancelClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={schema.maxWidth}
                PaperProps={schema.paperProps}
                //PaperProps={{ sx: { position: "fixed", left: leftPos, margin: 0, padding: 0, width: 300 } }}
                hideBackdrop={schema.hideBackdrop}                
            >
                {(schema.dialogType == "normal" || schema.dialogType == undefined) &&
                    <DialogTitle id="alert-dialog-title">
                        {schema.title}
                    </DialogTitle>
                }                

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {
                            schema.component ?
                                schema.component(setReturnValue) :
                                <span dangerouslySetInnerHTML={schema.body.innerHtml}></span>
                        }


                     </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {schema.showCancelBtn && <Button onClick={handleCancelClick}>{schema.cancelBtnText}</Button>}
                    <Button onClick={handleOKClick} autoFocus>
                        {schema.okBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import MasterVariantsCtrl from '../components/formcontrols/MasterVariantsCtrl';
import ContactSearch from './ContactSearch';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TransactionDetailCtrl from '../components/formcontrols/TransactionDetailCtrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import PaymentDetailCtrl from '../components/formcontrols/PaymentDetailCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import PaymentInfoCtrl from '../components/formcontrols/payment/PaymentInfoCtrl';
import { useGlobalState } from '../globalstates/GState';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function Payment({ dialogType, handlerReturnValue, headerId, setAcceptAfterUpdate,contactId }) {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [refreshId, setRefreshId] = useState(0);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(dialogType == undefined ? params.headerId : headerId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = {
        "LogicalOperator": "and", "ConditionGroups": [{
            "LogicalOperator": "and", "Conditions": [
                { "Name": "object", "ConditionOperator": "EQUAL", "Value": "product_type_settings_page", "DataType": "String" },
                { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }]
        }]
    };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('product_type_settings_page', 'header_section', 'Payment Number: ' + (primaryKeyValue > 0 ? primaryKeyValue : ''));
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.headerId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Customer View'), name: "CustomerView", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleCustomerViewTransaction(); } });
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Re-send Email'), name: "ResendEmail", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleResendEmail(); } });
        if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Payments', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }
    }
    if (formData.posted == false) {
        if ((helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Payments', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Payments', ['allowAdd']) == true && primaryKeyValue == 0)) {
            headerOptions.elements.push(
                {
                    type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Save'), name: "Save", icon: null, url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: [
                        { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save as Draft'), name: "SaveAsDraft", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(false, false) } },
                        { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save and Post Only'), name: "SaveAndPost", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(true, false) } },
                        { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save, Post & Send'), name: "SaveAndEmail", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(true, true) } }
                    ]
                }
            )
        }
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == '0') {
            data.transactionType = 'payment';
            data.status = "Draft";
            data.headerAmt = parseFloat(0);
            data.transactionDate = dayjs();
            data.shippingAmt = parseFloat(0);
            data.discountAmt = parseFloat(0);
            data.taxPercent = parseFloat(0);
        }        
    }

    function handleResendEmail() {
        if (formData.posted == true) {
            emailTransaction(formData.headerId);
        }
    }

    function handleCustomerViewTransaction() {
        var url = '/transactionviewer/payment/' + currentUser.currentBusinessAccountDTO.token + '/' + formData.token;
        window.open(url, '_blank');
    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());        
    }

    function handleProcessPayment() {

    }

    function openContactSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetCustomerInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "GetCustomerInfo":
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    function handleDetailsChanged(details) {
        var tmpFormData = { ...formData };
        tmpFormData.details = details;
        updateTotals(tmpFormData);
        setFormData(tmpFormData);
    }

    function onFieldBlur(e, propertyName, value) {
        var tmpFormData = { ...formData };

        switch (propertyName) {
            case 'billingAddress1':
            case 'billingAddress2':
            case 'billingCity':
            case 'billingState':
            case 'billingZip':
            case 'billingCountryCode':
                if (tmpFormData.copyBillingAddress == true) {
                    copyBillingAddress(tmpFormData);
                }
                break;
        }

        updateTotals(tmpFormData);
        setFormData(tmpFormData);
    }

    function onFieldValueChange(e, propertyName, value) {
        var tmpFormData = { ...formData };
        tmpFormData[propertyName] = value;
        setFormData(tmpFormData);
    }

    function onCopyBillingAddressChange(e, value) {
        var tmpFormData = { ...formData };        
        tmpFormData.copyBillingAddress = value;
        if (value == true) {
            copyBillingAddress(tmpFormData);
        }
        setFormData(tmpFormData);

    }

    function updateTotals(tmpFormData) {
        var detail = null;
        var amtPaid = parseFloat(tmpFormData.headerAmt);
        var remainingAmt = parseFloat(tmpFormData.headerAmt);
        var openBalance = parseFloat(0);
        //var netAmt = parseFloat(0);
        //var amtToTax = parseFloat(0);
        //var taxAmt = parseFloat(0);
        //var totalAmtDue = parseFloat(0);


        for (var i = 0; i < tmpFormData.details.length; i++) {
            detail = tmpFormData.details[i];
            if (detail.internalState != 'deleted') {
                openBalance = parseFloat(detail.openBalance);
                if (remainingAmt == 0) {
                    detail.detailAmount = 0;
                } else if (remainingAmt <= openBalance) {
                    detail.detailAmount = remainingAmt;
                    remainingAmt -= remainingAmt;
                } else if (remainingAmt > openBalance) {
                    detail.detailAmount = openBalance;
                    remainingAmt -= openBalance;
                }
            }
        }
    }

    function copyBillingAddress(tmpFormData) {
        tmpFormData.mailingAddress1 = tmpFormData.billingAddress1;
        tmpFormData.mailingAddress2 = tmpFormData.billingAddress2;
        tmpFormData.mailingCity = tmpFormData.billingCity;
        tmpFormData.mailingState = tmpFormData.billingState;
        tmpFormData.mailingZip = tmpFormData.billingZip;
        tmpFormData.mailingCountryCode = tmpFormData.billingCountryCode;
    }

    function postTransaction(tmpFormData, sendEmail, headerId) {
        BAFetchData({
            callName: "PostTransaction", method: "PUT", url: "accounting/transactionheader/posttransaction",
            token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: { "sendEmail": sendEmail, "headerId": headerId }
        });
    }

    function emailTransaction(headerId) {
        BAFetchData({
            callName: "EmailTransaction", method: "GET", url: "accounting/transactionheader/emailtransaction?headerid=" + headerId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: { "headerId": headerId }
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":                
            case 'SaveNewForm':
                setPrimaryKeyValue(data.headerId);
                data.creditCardNumber = formData.creditCardNumber;
                data.creditCardCvv = formData.creditCardCvv;
                data.creditCardExpireMonth = formData.creditCardExpireMonth;
                data.creditCardExpireYear = formData.creditCardExpireYear;
                setFormData(data);
                if (packageObj.postTransaction == true) {
                    postTransaction(data, packageObj.sendEmail, data.headerId);
                }
                if (packageObj.postTransaction == false && packageObj.sendEmail == true) {
                    emailTransaction(data.headerId);
                }
                if (packageObj.postTransaction == false && packageObj.sendEmail == false) {
                    var msg = 'Form has been saved.';                   
                    if (dialogType == undefined) {
                        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/payment/' + data.headerId); refreshPage(); } })
                    } else {
                        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(data.headerId); } })
                    }
                    setOpenDialog(true);
                }
                break;
            case "PostTransaction":
                if (packageObj.sendEmail == true) {
                    emailTransaction(packageObj.headerId);
                } else {
                    var msg = 'Payment has been processed and posted.';
                    setGenericDialogSchema({ title: 'Record Posted', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/payment/' + packageObj.headerId); refreshPage(); } })
                    setOpenDialog(true);
                }
                break;
            case "EmailTransaction":
                var msg = 'Email has been sent.';
                setGenericDialogSchema({ title: 'Email sent', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/payment/' + packageObj.headerId); refreshPage(); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/transactions/payment'); } })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                tmpFormData.customerId = data.contactId;
                tmpFormData.customerEmail = data.email;
                tmpFormData.customerName = data.contactName;
                tmpFormData.customer = data;

                tmpFormData.billingAddress1 = data.physicalAddress1;
                tmpFormData.billingAddress2 = data.physicalAddress2
                tmpFormData.billingCity = data.physicalCity;
                tmpFormData.billingState = data.physicalState;
                tmpFormData.billingZip = data.physicalZip;
                tmpFormData.billingCountryCode = data.physicalCountryCode;

                if (tmpFormData.copyBillingAddress == true) {
                    copyBillingAddress(tmpFormData);
                }
                setFormData((...formData) => { return tmpFormData });
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "accounting/transactionheader/getpaymentrecord?headerid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            } else {
                if (primaryKeyValue == '0') {
                    if (contactId != undefined) {
                        onPopupOK("GetCustomerInfo", contactId);
                    }
                }
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "accounting_transaction_headers", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {JSON.stringify(formData) == "{}" && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {JSON.stringify(formData) != "{}" &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "1200px" }}>
                    {formData.posted == true ?
                        <div style={{ backgroundColor: "grey", color: "white", padding: "2px 5px 2px 5px", borderRadius: "4px", marginBottom: "10px", textAlign: "center" }}>Record has been posted... You will not be able to edit.</div>
                        : null}
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="segment">
                                        <div className="segment-title">Customer Info</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{marginBottom:"10px"} }>
                                                                <LookupFieldCtrl dataPackage={formData.customer || ''} textFieldName="contactName" valueFieldName="contactId" label="Customer" helperText={errorList['customerId'] ? <span style={{ color: "red" }}>{errorList['customerId']}</span> : ''} onClick={() => { openContactSearch('lookup'); }} onRemove={() => { setFormData(formData => ({ ...formData, customerId: null })); setFormData(formData => ({ ...formData, customer: null })); setFormData(formData => ({ ...formData, customerEmail: null })); setFormData(formData => ({ ...formData, customerName: null })); }}></LookupFieldCtrl>
                                                            </div>
                                                            <TextField id="customerEmail" fullWidth label="Customer Email" variant="standard" helperText={errorList['customerEmail'] ? <span style={{ color: "red" }}>{errorList['customerEmail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.customerEmail || ''} InputLabelProps={{ shrink: formData.customerEmail }} onChange={(e) => setFormData(formData => ({ ...formData, customerEmail: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="segment">
                                        <div className="segment-title">Date & Amount Paying</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{marginBottom:"10px"}}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                                        <DesktopDatePicker
                                                                            label="Payment Date"
                                                                            variant="Standard"
                                                                            slotProps={{ textField: { fullWidth: true, helperText: errorList['transactionDate'] ? <span style={{ color: "red" }} > {errorList['transactionDate']}</span> : '' } }}
                                                                            value={formData.transactionDate != null ? dayjs(formData.transactionDate) : null}
                                                                            onChange={(newValue) => setFormData(formData => ({ ...formData, transactionDate: newValue }))} />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>
                                                            </div>
                                                            <FormControl fullWidth variant="standard" helperText={<span style={{ color: "red" }}>Amount customer is paying</span>} style={{ marginBottom: "16px" }}>
                                                                <InputLabel htmlFor="headerAmt">Amount Paid</InputLabel>
                                                                <Input
                                                                    id="headerAmt"
                                                                    type="number"
                                                                    step="any"
                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                    inputProps={{ min: 0, style: { textAlign: 'right' }, className: "fg-number-no-arrows" }}
                                                                    value={formData.headerAmt || '0'}
                                                                    onChange={(e) => onFieldValueChange(e, 'headerAmt', e.target.value)}
                                                                    onBlur={(e) => onFieldBlur(e, 'headerAmt', e.target.value)}
                                                                />
                                                                <formHelperText className="fg-helper-text">{errorList['headerAmt'] ? <span style={{ color: "red" }}>{errorList['headerAmt']}</span> : ''} </formHelperText>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="segment">
                                        <div className="segment-title">Line Items</div>
                                        <formHelperText className="fg-helper-text">{errorList['details'] ? <span style={{ color: "red" }}>{errorList['details']}</span> : ''} </formHelperText>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <PaymentDetailCtrl customerId={formData.customerId} paymentId={primaryKeyValue} details={formData.details} onChange={(e, details) => { handleDetailsChanged(e, details) }}></PaymentDetailCtrl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="segment">
                                        <div className="segment-title">Payment Info</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <PaymentInfoCtrl formData={formData} setFormData={setFormData} errorList={errorList} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="segment">
                                <div className="segment-title">Total Summary</div>
                                <div style={{fontSize:"14px",fontStyle:"italic"}}>***This section is read only</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div style={{ backgroundColor: "whitesmoke", padding: "15px", border: "1px solid rgba(0,0,0,.1)" }}>                                                   
                                                    <FormControl fullWidth variant="standard" helperText={errorList['headerAmt'] ? <span style={{ color: "red" }}>{errorList['headerAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                        <InputLabel htmlFor="headerAmt" style={{color:"black",fontWeight:"bold"}}>Amount Paid</InputLabel>
                                                            <Input
                                                                readOnly={true}
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            inputProps={{ min: 0, style: { textAlign: 'right', fontSize: "28px", fontWeight: "bold" } }}
                                                            value={helpers.formatNumber(formData.headerAmt,2) || '0'}
                                                        />
                                                    </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete order. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "accounting/transactionheader/delete?headerid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm(postTransaction, sendEmail) {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm(postTransaction, sendEmail);
            }
        }                
    }

    function saveForm(postTransaction, sendEmail) {
        var additionalActions = { "postTransaction": postTransaction, "sendEmail": sendEmail };
        var tmpFormData = { ...formData };
        //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
        //tmpFormData.autoSurvey = JSON.stringify(tmpFormData.autoSurvey);
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "accounting/transactionheader/addpayment",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
                packageObj: additionalActions
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "accounting/transactionheader/updatepayment",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
                packageObj: additionalActions
            });
        }
    }

    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.customerId == null || formData.customerId == '') {
            isValid = false;
            tempErrorList['customerId'] = "Is required.";
        }
        if (formData.headerAmt == '0' || formData.headerAmt == null) {
            isValid = false;
            tempErrorList['headerAmt'] = "Should be greater than zero.";
        }

        if (helpers.isListEmpty(formData.details) == true) {
            isValid = false;
            tempErrorList['details'] = "At least one invoice is required.";
        }
        if (formData.paymentMethod == null || formData.paymentMethod == '') {
            isValid = false;
            tempErrorList['paymentMethod'] = "Is required.";
        }
        if (formData.paymentMethod != null && formData.paymentMethod != '') {
            if (formData.paymentMethod == 'Check') {
                if (formData.paymentRef == null || formData.paymentRef == '') {
                    isValid = false;
                    tempErrorList['paymentRef'] = "Is required.";
                }
            } else if (formData.paymentMethod == 'Card') {
                if (formData.cardholderName == null || formData.cardholderName == '') {
                    isValid = false;
                    tempErrorList['cardholderName'] = "Is required.";
                }
                if (formData.creditCardNumber == null || formData.creditCardNumber == '') {
                    isValid = false;
                    tempErrorList['creditCardNumber'] = "Is required.";
                }
                if (formData.creditCardExpireMonth == null || formData.creditCardExpireMonth == '') {
                    isValid = false;
                    tempErrorList['creditCardExpireMonth'] = "Is required.";
                }
                if (formData.creditCardExpireYear == null || formData.creditCardExpireYear == '') {
                    isValid = false;
                    tempErrorList['creditCardExpireYear'] = "Is required.";
                }
                if (formData.creditCardExpireMonth != null && formData.creditCardExpireMonth != "" && formData.creditCardExpireYear != null && formData.creditCardExpireYear != "") {
                    var month = formData.creditCardExpireMonth;
                    var year = formData.creditCardExpireYear;
                    var expireFullDate = dayjs(month + '/01/' + year).add(1, 'month');
                    if (expireFullDate < dayjs()) {
                        isValid = false;
                        tempErrorList['creditCardExpireDate'] = "Invalid expiration date.";
                    }
                }
                if (formData.creditCardCvv == null || formData.creditCardCvv == '') {
                    isValid = false;
                    tempErrorList['creditCardCvv'] = "Is required.";
                } else if(helpers.isNumeric(formData.creditCardCvv) == false){
                    isValid = false;
                    tempErrorList['creditCardCvv'] = "Must be numeric only.";
                }
                if (formData.billingZip == null || formData.billingZip == '') {
                    isValid = false;
                    tempErrorList['billingZip'] = "Is required.";
                }
            }
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default Payment;
import React, { useContext, useState, useEffect } from 'react';
import BlankAppLayout from "./components/BlankAppLayout";
import DefaultAppLayout from "./components/DefaultAppLayout";
import { Route, Routes,Router, useNavigate } from 'react-router-dom';
import AppRoutes from "./AppRoutes";
import AuthLayout from "./components/AuthLayout";

const FGAppWithLayout = () => {

    const navigate = useNavigate();  

    return (
        <div>
            <Routes navigate={navigate}>
                {AppRoutes.map((i, index) => {
                    return <Route key={index} element={<i.layout />}>
                        <Route exact={i.exact} path={i.path} element={i.element}></Route>
                    </Route>
                }) }
            </Routes>
        </div>
    )
}

export default FGAppWithLayout;
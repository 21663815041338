import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGlobalState } from '../globalstates/GState';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function WhiteLabelSettings({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.baid);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'White Label Settings');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.baid != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-AccountCancellation', ['allow']) == true) {
        //<Button variant="outlined" style={{ backgroundColor: "#EB5454", color: "white", border:"1px solid #EB5454"}} onClick={() => onCancelBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Yes, Cancel Business Account')}</Button>
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Activate White Label'), name: "Activate", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleActivateWhiteLabelApp(); } });
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Cancel White Label App'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-BusinessAccountInfo', ['allowEdit']) == true) {
        headerOptions.elements.push({ type: "button", text: getTranslation('site_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (process.env.ENV == 'Local') {
            data.wlAppDomain = "whitelabel" + primaryKeyValue
        }
    }

 
    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.baid);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/whitelabelsettings/' + data.baid); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case "CreateWhiteLabelSite":
                var msg = 'White label app has been activated.';
                setGenericDialogSchema({ title: 'White Label App Activated', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();} })
                setOpenDialog(true);
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/businessaccounts'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function createWhiteLabelSite() {
        BAFetchData({
            callName: "CreateWhiteLabelSite",
            method: "POST",
            url: "security/businessaccount/createwhitelabeliisentry?baid=" + primaryKeyValue,
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "security/businessaccount/getrecord?baid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "security_business_accounts", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function isReadonly() {
        var readOnly = false;
        if (window.location.href.indexOf('localhost') > -1) {
            readOnly = true;
        }
        return readOnly;
    }


    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">White Label Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="wlName" fullWidth label="White Label Brand Name" variant="standard" helperText={errorList['wlName'] ? <span style={{ color: "red" }}>{errorList['wlName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlName || ''} InputLabelProps={{ shrink: formData.wlName }} onChange={(e) => setFormData(formData => ({ ...formData, wlName: e.target.value }))} />
                                                    <TextField id="wlAppDomain" fullWidth label="App Domain" variant="standard" inputProps={{ readOnly: isReadonly() }} helperText={errorList['wlAppDomain'] ? <span style={{ color: "red" }}>{errorList['wlAppDomain']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlAppDomain || ''} InputLabelProps={{ shrink: formData.wlAppDomain }} onChange={(e) => setFormData(formData => ({ ...formData, wlAppDomain: e.target.value }))} />
                                                    <TextField id="wlBusinessDomain" fullWidth label="Business Domain" variant="standard" helperText={errorList['wlBusinessDomain'] ? <span style={{ color: "red" }}>{errorList['wlBusinessDomain']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlBusinessDomain || ''} InputLabelProps={{ shrink: formData.wlBusinessDomain }} onChange={(e) => setFormData(formData => ({ ...formData, wlBusinessDomain: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">White Label Images</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ImageFieldCtrl imgSrc={formData.wlLoginLogo || ''} label="Login Logo (width: 200px height: 30px)" imgType="large" imgWidth="200px" imgHeight="30px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlLoginLogo: imgValue }))}></ImageFieldCtrl>
                                                    <ImageFieldCtrl imgSrc={formData.wlEmailLogo || ''} label="Email Logo (width: 200px height: 30px)" imgType="large" imgWidth="200px" imgHeight="30px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlEmailLogo: imgValue }))}></ImageFieldCtrl>
                                                    <div style={{display:"grid",gridTemplateColumns:"150px 1fr",gridGap:"10px",width:"100%"}}>
                                                        <div>
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Icon Logo Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.wlIconLogoType || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, wlIconLogoType: e.target.value }))}
                                                                label="Icon Logo Type"
                                                            >
                                                                <MenuItem value="icon">Icon</MenuItem>
                                                                <MenuItem value="image">Image</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{errorList['wlIconLogoType'] ? <span style={{ color: "red" }}>{errorList['wlIconLogoType']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                        </div>
                                                        <div>
                                                            {formData.wlIconLogoType == 'icon' ?
                                                                <ImageFieldCtrl imgSrc={formData.wlIconLogo || ''} label="Icon Logo (width: 36px height: 36px)" imgType="icon" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlIconLogo: imgValue }))}></ImageFieldCtrl>
                                                                :
                                                                <ImageFieldCtrl imgSrc={formData.wlIconLogo || ''} label="Icon Logo (width: 36px height: 36px)" imgType="inline"  returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlIconLogo: imgValue }))}></ImageFieldCtrl>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Contact Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="wlSupportEmail" fullWidth label="Support Email" variant="standard" helperText={errorList['wlSupportEmail'] ? <span style={{ color: "red" }}>{errorList['wlSupportEmail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlSupportEmail || ''} InputLabelProps={{ shrink: formData.wlSupportEmail }} onChange={(e) => setFormData(formData => ({ ...formData, wlSupportEmail: e.target.value }))} />
                                                    <TextField id="wlSupportEmailName" fullWidth label="Support Email Name" variant="standard" helperText={errorList['wlSupportEmailName'] ? <span style={{ color: "red" }}>{errorList['wlSupportEmailName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlSupportEmailName || ''} InputLabelProps={{ shrink: formData.wlSupportEmailName }} onChange={(e) => setFormData(formData => ({ ...formData, wlSupportEmailName: e.target.value }))} />
                                                    <TextField id="wlNotificationEmail" fullWidth label="Notification Email" variant="standard" helperText={errorList['wlNotificationEmail'] ? <span style={{ color: "red" }}>{errorList['wlNotificationEmail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlNotificationEmail || ''} InputLabelProps={{ shrink: formData.wlNotificationEmail }} onChange={(e) => setFormData(formData => ({ ...formData, wlNotificationEmail: e.target.value }))} />
                                                    <TextField id="wlNotificationEmailName" fullWidth label="Notification Email Name" variant="standard" helperText={errorList['wlNotificationEmailName'] ? <span style={{ color: "red" }}>{errorList['wlNotificationEmailName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlNotificationEmailName || ''} InputLabelProps={{ shrink: formData.wlNotificationEmailName }} onChange={(e) => setFormData(formData => ({ ...formData, wlNotificationEmailName: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>        
    );

    function handleActivateWhiteLabelApp() {
        createWhiteLabelSite();
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to remove White Label App. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        //BAFetchData({
        //    callName: "DeleteRecord", method: "DELETE", url: "security/businessaccount/delete?baid=" + primaryKeyValue,
        //    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        //});
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        //if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        //}                
    }

    function saveForm() {
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "security/businessaccount/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "security/businessaccount/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.wlName == null || formData.wlName == '') {
            isValid = false;
            tempErrorList['wlName'] = "Is required.";
        }
        if (formData.wlIconLogo == null || formData.wlIconLogo == '') {
            isValid = false;
            tempErrorList['wlIconLogo'] = "Is required.";
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default WhiteLabelSettings;
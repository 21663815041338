import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';

function SegmentedListDetailCtrl({listId }) {
    const [currentUser] = useGlobalState("userInfo");

    const [refreshId, setRefreshId] = useState('0');
    const navigate = useNavigate();
    //let params = useParams();
    const [tempFileRecId, setFileRecId] = useState(helpers.getUUID());
    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(listId);
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('form_detail_page', 'header_section', 'Segmented List Detail');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs: [] };
    if (listId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('form_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('form_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } }
                ]
            }
        )
    }
    headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == "0") {
            //    //set default data
            data.active = true;
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":
                setPrimaryKeyValue(data.listId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); reloadComponent(); } });  //navigate('/contactdetail/' + data.listId);
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/forms'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function reloadComponent() {
        setFormData({});
        setRefreshId(helpers.getUUID);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "crm/segmentedlist/getrecord?listid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "crm_segmented_lists", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);


    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete form. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "crm/segmentedlist/delete?listId=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        } else {
            var msg = 'There are form validation errors.';
            setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "crm/segmentedlist/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "crm/segmentedlist/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        setErrorList(tempErrorList);
        return isValid;
    }

    return (
        <>
            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "900px",borderTop:"none" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">List Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="listName" autoFocus required fullWidth label="List Name" variant="standard" helperText={errorList['listName'] ? <span style={{ color: "red" }}>{errorList['listName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.listName || ''} InputLabelProps={{ shrink: formData.listName }} onChange={(e) => setFormData(formData => ({ ...formData, listName: e.target.value }))} />
                                                    <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                                <div className="segment">
                                    <div className="segment-title">Dynamic Settings</div>
                                    <div>Use Dynamic Settings when you want to determine at run-time which contacts will be included that meet a certain filter condition.</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <FormControlLabel style={{ marginBottom: "10px" }}
                                                    control={
                                                        <Switch checked={formData.dynamic} onChange={(e) => setFormData(formData => ({ ...formData, dynamic: e.target.checked }))} name="gilad" />
                                                    }
                                                    label="Dynamic"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <TextField id="conditions" multiline rows={4} fullWidth label="Conditions" variant="standard" helperText={errorList['conditions'] ? <span style={{ color: "red" }}>{errorList['conditions']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.conditions || ''} InputLabelProps={{ shrink: formData.conditions }} onChange={(e) => setFormData(formData => ({ ...formData, conditions: e.target.value }))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <button type="button" className="btn btn-primary" onClick={handleSaveForm}>Save</button>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default SegmentedListDetailCtrl;

import React, { useContext, useState, useEffect, createContext, useRef, useCallback} from 'react';
import './css/pagebuilderctrl.css';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import LaptopIcon from '@mui/icons-material/Laptop';
import TabletIcon from '@mui/icons-material/Tablet';
import TvIcon from '@mui/icons-material/Tv';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';


import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import helpers from '../../helpers/Helpers';
import WidgetRouter, { MergeStylesToCurrBP } from './WidgetRouter';
import WidgetListCtrl from './WidgetListCtrl';
import CssEditorCtrl from './CssEditorCtrl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SitePagesListCtrl from './SitePagesListCtrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import NewSitePage from '../../pages/NewSitePage';
import PageGenerator from './PageGenerator';
import StyleSheet from '../../pages/StyleSheet';
import DataSourceListCtrl from './DataSourceListCtrl';
import { defaultAiTraining, sendAiPrompt } from '../ai/AiUtil';
import { useGlobalState } from '../../globalstates/GState';
import SectionTemplateSettings from './SectionTemplateSettings';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { toPng, toBlob } from "html-to-image";
import SectionListCtrl from './SectionListCtrl';
import { IFrame } from './IFrame';
import ElementNavCtrl from './ElementNavCtrl';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { mergeStylesToCurrBP } from './StyleUtil';
import PageEngineUtil from './PageEngineUtil';
import Chip from '@mui/material/Chip';
import JavascriptEditor from '../../pages/JavascriptEditor';
import PageCssEditor from '../../pages/PageCssEditor';
import PageSettings from '../../pages/PageSettings';



export const PageBuilderVariablesContext = createContext();

export const DataBrokerContext = createContext();

function PageBuilderCtrl({ siteId, buildMode, buildModeId, setMode, popupOpenRef, autoItemSummary, onChangeCompleted }) {
    const [currentUser] = useGlobalState("userInfo");
    let cdnFolder = process.env.REACT_APP_CDN_BASE_URL;
    const masterPageChangedRef = useRef();
    let internalTimeout = null;
    const hlRef = useRef();
    const hlLabelRef = useRef();

    const iFrameRef = useRef();
    const [emailSectionBtnText, setEmailSectionBtnText] = useState("Collapse");
    const [siteInfo, setSiteInfo] = useState(null);
    const [resizeId, setResizeId] = useState(null);
    const [positionTarget, setPositionTarget] = useState(null);
    const navigate = useNavigate();
    //const [mobileFirst, setMobileFirst] = useState(false);
    const [breakpoints, setBreakpoints] = useState(setupBreakpoints());
    const [currentBreakpoint, setCurrentBreakpoint] = useState('2500');
    const [winDimensions, setWinDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });
    const [orderedBreakpoints, setOrderedBreakpoints] = useState(setupOrderedBreakpoints);
    const [elements, setElements] = useState({});
    const [masterInfo, setMasterInfo] = useState([]);
    const elementsRef = useRef({});
    const updateAiSectionsRef = useRef(false);
    const [elementHistory, setElementHistory] = useState([]);
    const [hoverSettings, setHoverSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition:"top",labels:[], show: false });
    const [selectedSettings, setSelectedSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [selectedWidgetId, setSelectedWidgetId] = useState(null);

    const editorElementIdRef = useRef(null);
    const actionElementIdRef = useRef(null);
    const fontListRef = useRef([]);
    const pageRootElementIdRef = useRef(null);

    const [targetPlaceholder, setTargetPlaceholder] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: "300px", height: 0, border: "2px solid red" }, labelPosition: "top", show: true });

    const [dragItem, setDragItem] = useState(null);
    const [showContextMenu, setShowContextMenu] = useState(false);

    const [destElements, setDestElements] = useState({});
    const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
    const [pageSaveSnackbarOpen, setPageSaveSnackbarOpen] = useState(false);
    const [publishSnackbarOpen, setPublishSnackbarOpen] = useState(false);
    const [redoUndo, setRedoUndo] = useState({ undoActive: false, undoColor: "#c0c0c0", redoActive: false, redoColor: "#c0c0c0" });

    const [widgetCallbacks, setWidgetCallbacks] = useState({ widgetPropertyCallback: null, widgetHideHighlightCallback: null, widgetSaveCallback: null });
    const [pageLoaded, setPageLoaded] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [panelTab, setPanelTab] = useState(buildMode == undefined ? 'Pages' : 'Elements');
    const [pageInfo, setPageInfo] = useState(null);
    const [variantId, setVariantId] = useState('');
    const [formData, setFormData] = useState(null);
    const [renderNum, setRenderNum] = useState(null);
    const [reloadNum, setReloadNum] = useState(null);
    
    const [styleInfo, setStyleInfo] = useState(null);
    const [stylePath, setStylePath] = useState(null);

    const [updatePage, setUpdatePage] = useState(false);

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    //const [overview, setOverview] = useState('');
    const [prompts, setPrompts] = useState([]);
    const [promptPkg, setPromptPkg] = useState({});
    const [useAiText, setUseAiText] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [imagePrompt, setImagePrompt] = useState([]);
    const [componentType, setComponentType] = useState('Sections');
    const [contentType, setContentType] = useState('Ai');
    const [hideObjPanel, setHideObjPanel] = useState(false);
    const [purposeList, setPurposeList] = useState([]);

    const totalSectionPromptsRef = useRef(0);
    const totalSectionPromptsProcessedRef = useRef(0);

    const totalToProcessRef = useRef(0);
    const totalProcessedRef = useRef(0);

    const sectionOrderRef = useRef([]);
    const [locked, setLocked] = useState(false);
    const [styleList, setStyleList] = useState({});
    const [selectedReference, setSelectedReference] = useState(null);
    const [siteStyleSheet, setSiteStyleSheet] = useState('');
    const styleRef = useRef('');
    const [masterPageList, setMasterPageList] = useState([]);
    const [emailInfo, setEmailInfo] = useState({});

    function setupBreakpoints() {
        if (buildMode != 'Automation-Email') {
            return { "2500": { icon: "Tv" }, "1920": { icon: "Laptop" }, "1024": { icon: "Laptop" }, "768": { icon: "Tablet" }, "479": { icon: "PhoneAndroid" } };
        } else {
            return { "600": { icon: "Laptop" }, "479": { icon: "PhoneAndroid" } };
        }
    }

    function setupOrderedBreakpoints() {
        if (buildMode != 'Automation-Email') {
            return [479, 768, 1024, 1920, 2500];
        } else {
            return [479, 600];
        }
    }

    function handleOnBreakpointChange(e, selectedDevice) {
        //setCurrentBreakpoint(selectedDevice);
        if (selectedDevice != null) {
            setCurrentBreakpoint((...currentBreakpoint) => { return selectedDevice });
        }
    }

    function closeBuilder() {
        if (buildMode == 'Automation' || buildMode == 'Automation-Email') {
            popupOpenRef.current = false;
            onChangeCompleted();
        }
    }

    function buildDefaultElements() {        
        setPageLoaded(true);
    }

    function onEmailInfoChange(propertyName, value) {
        var tmpEmailInfo = { ...emailInfo };
        tmpEmailInfo[propertyName] = value;
        setEmailInfo(tmpEmailInfo);
    }

    function resizeControls(winH, winW) {
        var appHeaderH = document.getElementById('app-header').clientHeight;
        var fgPBHeaderH = document.getElementById('fg-pb-header').clientHeight;   
        var newHeight = (winH - (appHeaderH + fgPBHeaderH)) + 'px';
        document.getElementById('fg-pb-container').style.height = newHeight;
        document.getElementById('fg-pb-left-panel').style.height = newHeight;
        document.getElementById('fg-pb-canvas').style.height = newHeight;
        document.getElementById('fg-pb-right-panel').style.height = newHeight;
        setResizeId(helpers.getUUID());
    }


    useEffect(() => {
        function handlePageResize() {
            setWinDimensions({ height: window.innerHeight, width: window.innerWidth });
            resizeControls(window.innerHeight, window.innerWidth);            
        }
        window.addEventListener('resize', handlePageResize);
        return _ => {
            window.removeEventListener('resize', handlePageResize);
        }
    })

    function handleUpdateChanges(widgetData) {
        setElements({
            ...elements, // copy the old fields
            [widgetData.id]: widgetData //override with updated target widget
        });
    }



    function buildRootParams() {
        const tmpElements = { ...elements };
        const tmpCurrBP = currentBreakpoint;
        var rootId = helpers.getRootElementId(tmpElements);
        var el = tmpElements[rootId];
        if (el != null && el != undefined) {
            return {
                "id": rootId,
                "elementName": el.elementName,
                "tag": el.tag,
                "widgetType": el.widgetType,
                "draggable": el.draggable,
                "src": (el.hasOwnProperty('src') ? el.src : null),
                "text": el.text,
                "aiText": (el.hasOwnProperty('aiText') ? el.aiText : null),
                "className": el.className,
                "customClassName": (el.hasOwnProperty('customClassName') ? el.customClassName : null),
                "customEvents": (el.hasOwnProperty('customEvents') ? el.customEvents : []),
                "customAttributes": (el.hasOwnProperty('customAttributes') ? el.customAttributes : []),
                "inlineStyle": el.inlineStyle,
                "breakpointStyles": el.breakpointStyles,
                "eventTracker": (el.hasOwnProperty('eventTracker') ? el.eventTracker : {}),
                "children": el.children,
                "parents": el.parents,
                "placeholderPosition": el.placeholderPosition,
                "macroPreset": (el.hasOwnProperty('macroPreset') ? el.macroPreset : ''),
                "labelFor": (el.hasOwnProperty('labelFor') ? el.labelFor : null),
                "formId": (el.hasOwnProperty('formId') ? el.formId : null),
                "formCode": (el.hasOwnProperty('formCode') ? el.formCode : null),
                "formName": (el.hasOwnProperty('formName') ? el.formName : null),
                "fieldType": (el.hasOwnProperty('fieldType') ? el.fieldType : null),
                "fieldName": (el.hasOwnProperty('fieldName') ? el.fieldName : null),
                "fieldValue": (el.hasOwnProperty('fieldValue') ? el.fieldValue : ''),
                "fieldDefaultValue": (el.hasOwnProperty('fieldDefaultValue') ? el.fieldDefaultValue : ''),
                "fieldRequired": (el.hasOwnProperty('fieldRequired') ? el.fieldRequired : true),
                "fieldMaxLength": (el.hasOwnProperty('fieldMaxLength') ? el.fieldMaxLength : null),
                "fieldLabel": (el.hasOwnProperty('fieldLabel') ? el.fieldLabel : null),
                "linkType": (el.hasOwnProperty('linkType') ? el.linkType : null),
                "linkUrl": (el.hasOwnProperty('linkUrl') ? el.linkUrl : null),
                "linkUrlParams": (el.hasOwnProperty('linkUrlParams') ? el.linkUrlParams : null),
                "linkTarget": (el.hasOwnProperty('linkTarget') ? el.linkTarget : null),
                "linkDiscountCode": (el.hasOwnProperty('linkDiscountCode') ? el.linkDiscountCode : null),
                "listId": (el.hasOwnProperty('listId') ? el.listId : null),
                "listName": (el.hasOwnProperty('listName') ? el.listName : null),
                "tagId": (el.hasOwnProperty('tagId') ? el.tagId : null),
                "tagName": (el.hasOwnProperty('tagName') ? el.tagName : null),
                "aiBGImages": (el.hasOwnProperty('aiBGImages') ? el.aiBGImages : null),
                "aiImage": (el.hasOwnProperty('aiImage') ? el.aiImage : null),
                "backgroundVideo": (el.hasOwnProperty('backgroundVideo') ? el.backgroundVideo : null),
                "customId": el.customId,
                "purpose": (el.hasOwnProperty('purpose') ? el.purpose : null),
                "aiPrompt": (el.hasOwnProperty('aiPrompt') ? el.aiPrompt : null),
                "sectionCategory": (el.hasOwnProperty('sectionCategory') ? el.sectionCategory : null),
                "videoSettings": (el.hasOwnProperty('videoSettings') ? el.videoSettings : null),
                "events": (el.hasOwnProperty('events') ? el.events : null),
                "anchorName": (el.hasOwnProperty('anchorName') ? el.anchorName : null),
                "fieldDataType": (el.hasOwnProperty('fieldDataType') ? el.fieldDataType : ""),
                "fieldPlaceholder": (el.hasOwnProperty('fieldPlaceholder') ? el.fieldPlaceholder : ""),
                "fieldOptions": (el.hasOwnProperty('fieldOptions') ? el.fieldOptions : ""),
                "dataSourceType": (el.hasOwnProperty('dataSourceType') ? el.dataSourceType : null),
                "dataSourceListText": (el.hasOwnProperty('dataSourceListText') ? el.dataSourceListText : null),
                "dataSourceName": (el.hasOwnProperty('dataSourceName') ? el.dataSourceName : null),
                "dataSourceParams": (el.hasOwnProperty('dataSourceParams') ? el.dataSourceParams : null),
                "addToCartInfo": (el.hasOwnProperty('addToCartInfo') ? el.addToCartInfo : null),
                "showPagination": (el.hasOwnProperty('showPagination') ? el.showPagination : false)
            }                
        }
    }

    function buildInternalPageStyle() {
        const reactToCss = require('react-style-object-to-css');

        var reference = '';
        var css = '';
        var tmpCss = '';
        var cssJson = null;
        for (var key in siteInfo.styleList) {
            reference = key;
            cssJson = mergeStylesToCurrBP(siteInfo.styleList[key], currentBreakpoint, orderedBreakpoints);
            if (JSON.stringify(cssJson) != '{}') {
                var x = reactToCss(cssJson);
                if (reference.indexOf('#') > -1) {
                    css += reference.replace('#','') + "{" + x.trim() + "}";
                } else {
                    css += "." + reference + "{" + x.trim() + "}";
                }
                
            }
            
        }
        setSiteStyleSheet(css)
        //styleRef.current = css;
    }

    function orderBreakpoints() {
        var tempOrderedBP = [];

        for (const property in breakpoints) {
            tempOrderedBP.push(Number(property));
        }
        if (siteInfo.mobileFirst == true) {
            tempOrderedBP.sort(function (a, b) { return a - b });
        } else {
            tempOrderedBP.sort(function (a, b) { return b - a });
        }
        
        setCurrentBreakpoint(tempOrderedBP[0].toString());
        setOrderedBreakpoints(tempOrderedBP);
    }

    function removeMasterInfo(tmpElements) {
        for (var mii = 0; mii < masterInfo.length; mii++) {
            for (const key in masterInfo[mii].components) {
                delete tmpElements[key];
            }
        }
        return tmpElements;
    }

    useEffect(() => {
        if (siteInfo != null) {
            if (selectedReference == null) {
                orderBreakpoints();
            }
            resizeControls(window.innerHeight, window.innerWidth);
            buildDefaultElements();
            buildPurposeList();
            buildInternalPageStyle();
            if (buildMode == 'Automation') {
                BAFetchData({
                    callName: "GetRemotePageInfo", method: "GET", url: "cms/sitepages/getrecord?sitepageid=" + autoItemSummary.sitePageId,
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: buildModeId
                });
            } else if (buildMode == 'Automation-Email') {
                BAFetchData({
                    callName: "GetRemoteEmailPageInfo", method: "GET", url: "automation/automationobject/getrecord?autoobjectid=" + autoItemSummary.aoId,
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: buildModeId
                });
            }
        }
    },[siteInfo])


    useEffect(() => {
        //Use this
        //let obj = { 'b': 2, 'c': 3 };
        //let newObj = { 'a': 1, ...obj };
        if (JSON.stringify(elements) != "{}") {
            pageRootElementIdRef.current = helpers.getRootElementId(elements);
        }        
        var result = {};
        for (var mii = 0; mii < masterInfo.length; mii++) {
            if (mii > 0) {
                var childRootKeyId = helpers.getRootElementId(masterInfo[mii].components);
                var containerKeyId = helpers.getPageContainerElementId(masterInfo[mii - 1].components);
                masterInfo[mii - 1].components[containerKeyId].children.push(childRootKeyId);
            }
            Object.keys(masterInfo[mii].components)
                .forEach(key => result[key] = masterInfo[mii].components[key]);
        }
        if (masterInfo.length > 0) {
            var childRootKeyId = helpers.getRootElementId(elements);
            var containerKeyId = helpers.getPageContainerElementId(masterInfo[masterInfo.length - 1].components);
            masterInfo[masterInfo.length - 1].components[containerKeyId].children.push(childRootKeyId);
        }

        Object.keys(elements)
            .forEach(key => result[key] = elements[key]);

        var test = "test";
        test = "hello";

        setElements(result);

        //console.log('count: ' + masterInfo.length)
        //console.log('remove master contact')
        //console.log('re-Merge Master Content');
    }, [masterInfo]);

    useEffect(() => {
        getSiteInfo(siteId)
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (pageInfo != null) {
            if (buildMode == undefined || buildMode == 'Automation') {
                if (pageInfo.sitePageId != '') {
                    if (pageLoaded == true) {
                        if (variantId != '') {
                            //handleSavePage();
                            //renderNow();
                        }
                    }
                }
            } else if (buildMode == 'Automation-Email') {
                if (pageLoaded == true) {
                    if (variantId != '') {
                        //handleSavePage();
                        //renderNow();
                    }
                }
            }
        }
    }, [pageInfo]);

    useEffect(() => {
        if (updatePage == true) {
            handleSavePage();
            setUpdatePage(false);
            //renderNow();
        } else {
            if (formData != null) {
                getMasterPageInfo(formData.sitePageId);
            }
        }
    }, [updatePage]);

    useEffect(() => {
        var head = document.head;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = stylePath;

        head.appendChild(link);

        return () => { head.removeChild(link); }

    }, [stylePath]);

    function getMasterPageList() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "or", "Conditions": [
                    { "Name": "pagetype", "ConditionOperator": "EQUAL", "Value": "m", "DataType": "String" },
                    { "Name": "pagetype", "ConditionOperator": "EQUAL", "Value": "s", "DataType": "String" }
                ]
            },
            {
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "siteid", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetMasterPageList",
            method: "GET",
            url: "cms/sitepages/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function buildPurposeList() {
        BAFetchData({
            callName: "GetPurposeList",
            method: "GET",
            url: "cms/purpose/getlist?filtering={}",
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getSiteInfo(siteId) {
        var tet = "test";
        tet = "hello";
        BAFetchData({
            callName: "GetSiteInfo",
            method: "GET",
            url: "cms/sites/getrecord?siteId=" + siteId,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    const updateHighlightPosition = useCallback((e, id) => {
        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        //let el = e.target;
        let el = iFrameDocument.getElementById(id);
        let canvasEl = document.getElementById('fg-pb-canvas');
        var elW = el.clientWidth;
        var elH = el.clientHeight;
        var rect = el.getBoundingClientRect();
        hlRef.current.style.width = elW + "px";
        hlRef.current.style.height = elH + "px";
        //hlRef.current.style.top = el.offsetTop + "px";
        //hlRef.current.style.left = el.offsetLeft + "px";

        var fgAppHeaderH = document.getElementById('app-header').offsetHeight;
        var pbHeaderH = document.getElementById('fg-pb-header').offsetHeight;
        var fgAppSidebarW = document.getElementById('app-sidebar').offsetWidth;
        var pbLeftPanelW = document.getElementById('fg-pb-left-panel').offsetWidth;

        var fgViewerLeft = document.getElementById('fg-pb-viewer').offsetLeft;
        var fgCanvasLeft = document.getElementById('fg-pb-canvas').offsetLeft;

        //temporary fix might need to keep
        fgAppHeaderH = 0;
        pbHeaderH = 0;
        fgCanvasLeft = 0;
        //end temporary fix


        //hlRef.current.style.top = rect.top - (fgAppHeaderH + pbHeaderH) + (canvasEl.scrollTop) + "px";
        hlRef.current.style.top = (rect.top + iFrameDocument.scrollingElement.scrollTop) + "px";
        hlRef.current.style.left = rect.left - (fgAppSidebarW + pbLeftPanelW) - (fgCanvasLeft - fgViewerLeft)  + "px";
        

        hlLabelRef.current.innerText = elements[id].elementName;
        hlLabelRef.current.style.top = "-19px";

        hlRef.current.style.display = "block";
    });

    const updateWidgetSettings = useCallback((e, id) => {

    });

    function onWidgetMouseOver(e, id) {
        e.preventDefault();
        e.stopPropagation();

        
        updateHighlightPosition(e, id);
        
        //var elObj = elements[id];
        //e.stopPropagation();
        //var el = document.getElementById(id);
        //var elW = el.clientWidth;
        //var elH = el.clientHeight;
        //var labelPosition = "bottom";
        //setHoverSettings({ style: { pointerEvents: "none", position: "absolute", top: el.offsetTop, left: el.offsetLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, "labelPosition": labelPosition, labels: [{ id: id, elementName: elObj.elementName, widgetType: elObj.widgetType }],show: true })
    }

    function onWidgetMouseEnter(e, id) {
        e.preventDefault();
        e.stopPropagation();

        //console.log(elements[id].elementName);

        updateHighlightPosition(e, id);

        //var elObj = elements[id];
        //e.stopPropagation();
        //var el = document.getElementById(id);
        //var elW = el.clientWidth; 
        //var elH = el.clientHeight; 
        //var labelPosition = "bottom";
        //setHoverSettings({ style: { pointerEvents: "none", position: "absolute", top: el.offsetTop, left: el.offsetLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, "labelPosition": labelPosition, labels: [{ id: id, elementName: elObj.elementName, widgetType: elObj.widgetType }],show: true })
    }

    function onWidgetMouseLeave(e, id) {        
        e.stopPropagation();

        hlRef.current.style.display = "none";

        //var el = document.getElementById(id);
        //var elW = el.clientWidth;
        //var elH = el.clientHeight;
        //setHoverSettings({ style: { pointerEvents: "none", position: "absolute", top: el.offsetTop, left: el.offsetLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false })
    }

    function endAnimationSelection(value) {
        if (value == false) {
            var originalId = (editorElementIdRef.current != null ? editorElementIdRef.current : selectedWidgetId);            
            editorElementIdRef.current = null;
            actionElementIdRef.current = null;
            setLocked(false);
            setSelectedWidgetId(originalId);
        } else {
            setLocked(true);
        }
    }

    function onWidgetClick(e, id, widgetData, propertyChangeCallback, widgetHideHighlightCallback, widgetSaveCallback) {
        hideExtendedLabels();
        if (selectedWidgetId != null) {
            if (selectedWidgetId != id) {
                //widgetCallbacks.widgetSaveCallback();
                if (widgetCallbacks.widgetHideHighlightCallback != null) {
                    widgetCallbacks.widgetHideHighlightCallback();
                }
            }
        }

        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        var tet = "test";
        tet = "testing";
        setWidgetCallbacks((...widgetCallbacks) => { return { widgetPropertyCallback: (updatedWidgetData) => { propertyChangeCallback(updatedWidgetData); }, widgetHideHighlightCallback: () => { widgetHideHighlightCallback(); }, widgetSaveCallback: () => { widgetSaveCallback(); } } });

        setShowContextMenu(false);
        var widgetObj = elements[id];
        var elementName = widgetObj.elementName;

        var labels = [{ id: id, elementName: elementName, widgetType: widgetObj.widgetType }];
        for (var i = 0; i < widgetObj.parents.length; i++) {
            labels.push({ id: widgetObj.parents[i], elementName: elements[widgetObj.parents[i]].elementName, widgetType: elements[widgetObj.parents[i]].widgetType });
        }
        var prevSelectedWidgetId = selectedWidgetId;


        if (e != undefined) {
            e.stopPropagation();
        }

        if (locked == true) {
            editorElementIdRef.current = (editorElementIdRef.current == null ? selectedWidgetId : editorElementIdRef.current);
            actionElementIdRef.current = id;
        }
        setSelectedWidgetId(id);


        //var el = document.getElementById(id);
        //var el = iFrameRef.contentWindow.document.getElementById(id);
        var el = document.getElementById('canvas_iframe').contentWindow.document.getElementById(id);
        let canvasEl = document.getElementById('fg-pb-canvas');
        var rect = el.getBoundingClientRect();


        var fgAppHeaderH = document.getElementById('app-header').offsetHeight;
        var pbHeaderH = document.getElementById('fg-pb-header').offsetHeight;
        var fgAppSidebarW = document.getElementById('app-sidebar').offsetWidth;
        var pbLeftPanelW = document.getElementById('fg-pb-left-panel').offsetWidth;


        var fgViewerLeft = document.getElementById('fg-pb-viewer').offsetLeft;
        var fgCanvasLeft = document.getElementById('fg-pb-canvas').offsetLeft;


        //hlRef.current.style.top = rect.top - (fgAppHeaderH + pbHeaderH) + (canvasEl.scrollTop) + "px";
        //hlRef.current.style.left = rect.left - (fgAppSidebarW + pbLeftPanelW) - (fgCanvasLeft - fgViewerLeft) + "px";

        //begin temporary fix here
        fgAppHeaderH = 0;
        pbHeaderH = 0;
        fgCanvasLeft = 0;
        //end temporary fix here

        //var tmpTop = rect.top - (fgAppHeaderH + pbHeaderH) + (canvasEl.scrollTop);
        var tmpTop = rect.top + (iFrameDocument.scrollingElement.scrollTop);

       
        var tmpLeft = rect.left - (fgAppSidebarW + pbLeftPanelW) - (fgCanvasLeft - fgViewerLeft);



        //console.log('top: ' + tmpTop + '  left: ' + tmpLeft + ' widgth: ' + elW + ' height: ' + elH);


        var elW = el.clientWidth;
        var elH = el.clientHeight;
        var labelPosition = "bottom";

        //setSelectedSettings({ style: { pointerEvents: "none", position: "absolute", top: el.offsetTop, left: el.offsetLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, "labelPosition": labelPosition, labels: labels, show: true })
        setSelectedSettings({ style: { pointerEvents: "none", position: "absolute", top: tmpTop, left: tmpLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, "labelPosition": labelPosition, labels: labels, show: true })

        if (e != undefined) {
            e.stopPropagation();
        }
    }

    function onWidgetClick_Old(e, id) {
        //setShowContextMenu(false);
        //var widgetObj = elements[id];
        //var elementName = widgetObj.elementName;

        //var labels = [{ id: id, elementName: elementName, widgetType: widgetObj.widgetType }];
        //for (var i = 0; i < widgetObj.parents.length; i++) {
        //    labels.push({ id: widgetObj.parents[i], elementName: elements[widgetObj.parents[i]].elementName, widgetType: elements[widgetObj.parents[i]].widgetType });
        //}
        //var prevSelectedWidgetId = selectedWidgetId;

        
        //if (e != undefined) {
        //    e.stopPropagation();
        //}
        //setSelectedWidgetId(id);

        //var el = document.getElementById(id);

        //var elW = el.clientWidth;
        //var elH = el.clientHeight;
        //var labelPosition = "bottom";

        //setSelectedSettings({ style: { pointerEvents: "none", position: "absolute", top: el.offsetTop, left: el.offsetLeft, width: elW, height: elH, border: "1px solid #5E95DE" }, "labelPosition": labelPosition, labels: labels, show: true })
        
    }

    function showExtendedLabels() {

        setShowContextMenu(false);

        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        var collection = iFrameDocument.getElementsByClassName("fg-pb-hl-ext-label-item");
        //var collection = document.getElementsByClassName("fg-pb-hl-ext-label-item");
        for (let i = 0; i < collection.length; i++) {
            if (!collection[i].classList.contains("fg-pb-hl-ext-label-item-show")) {
                collection[i].classList.add("fg-pb-hl-ext-label-item-show");
            }            
        }
    }

    function hideExtendedLabels() {
        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        var collection = iFrameDocument.getElementsByClassName("fg-pb-hl-ext-label-item");
        //var collection = document.getElementsByClassName("fg-pb-hl-ext-label-item");
        for (let i = 0; i < collection.length; i++) {
            if (collection[i].classList.contains("fg-pb-hl-ext-label-item-show")) {
                collection[i].classList.remove("fg-pb-hl-ext-label-item-show");
            }
        }
    }

    function openAnscestor(e, id) {
        hideExtendedLabels();
        document.getElementById('canvas_iframe').contentWindow.document.getElementById(id).click();
    }

    function renderWidgetLabel(selectedSettings) {
        var elObj;

        var iFrameEl = document.getElementById('canvas_iframe');
        var iFrameHeight = iFrameEl.clientHeight;
        var iFrameWidth = iFrameEl.clientWidth;

        var top = selectedSettings.style.top - 20;
        var left = selectedSettings.style.left;
        var containerWidth = parseInt(120);
        if ((left + containerWidth) > iFrameWidth) {
            left = left + (iFrameWidth - (left + containerWidth));
        }

        if (top < iFrameEl.contentWindow.document.scrollingElement.scrollTop) {
            top = 3;
        }

        if (selectedSettings.style.top < 50) {
            return <div style={{ position: "absolute", zIndex: 2, left: left, top: top, display: "inline-flex", flexDirection: "row", alignItems: "start" }}>
            <div style={{display:"inline-block"}} className="fg-pb-hl-labels-box">
            {selectedSettings.labels.map((widget, index) => (
                index == 0 ?
                    <div style={{ cursor: "pointer", backgroundColor: "red", color: "white", padding: "3px", fontSize: "10px" }} onClick={(e) => { showExtendedLabels(e, widget.id); }}>{widget.elementName}</div>
                    : <div key={"label_" + index} class="fg-pb-hl-ext-label-item" style={{ cursor: "pointer", backgroundColor: "#5E95DE", color: "white", padding: "3px", fontSize: "10px" }} onClick={(e) => { openAnscestor(e, widget.id); }}>{widget.elementName}</div>
            ))}
            </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "50px", marginLeft: "5px",backgroundColor:"white" }}>
                <div style={{ display: "inline-block", color: "red", cursor:"move" }} draggable onDragStart={(e) => handleOnDragFromCanvas(e, selectedSettings.labels[0].id, selectedSettings.labels[0].elementName, selectedSettings.labels[0].widgetType)}><DragIndicatorIcon style={{ fontSize: "18px", margin: "0px 5px 0px 0px", padding: "0px" }} /></div>
                    <div style={{ display: "inline-block", color: "red" }} onClick={(e) => { setShowContextMenu(true); hideExtendedLabels(); }}><SettingsIcon style={{ fontSize: "18px", margin: "0px 5px 0px 0px", padding: "0px"}} /></div>
            </div>
        </div> 

        } else {
            return <div style={{ position: "absolute", zIndex: 2, left: left, top: top, display: "inline-flex", flexDirection:"row",alignItems:"start" }}>
                <div style={{ display: "inline-block" }} className="fg-pb-hl-labels-box" style={{marginTop:"2px"}}>
                    {selectedSettings.labels.map((widget, index) => (
                        index == 0 ?
                            <div style={{ cursor: "pointer", backgroundColor: "red", color: "white", padding: "3px", fontSize: "10px" }} onClick={(e) => { showExtendedLabels(e, widget.id); }}>{widget.elementName}</div>
                            : <div key={"label_" + index} class="fg-pb-hl-ext-label-item" style={{ cursor: "pointer", backgroundColor: "#5E95DE", color: "white", padding: "3px", fontSize: "10px" }} onClick={(e) => { openAnscestor(e, widget.id); }}>{widget.elementName}</div>
                    ))}
                </div>
                <div style={{ display: "grid",gridTemplateColumns:"1fr 1fr",width:"50px", marginLeft: "5px",backgroundColor:"white" }}>
                    <div style={{ display: "inline-block", color: "red", cursor: "move" }} draggable onDragStart={(e) => handleOnDragFromCanvas(e, selectedSettings.labels[0].id, selectedSettings.labels[0].elementName, selectedSettings.labels[0].widgetType)}><DragIndicatorIcon style={{ fontSize: "18px", margin: "0px 5px 0px 0px", padding: "0px" }} /></div>
                    <div style={{ display: "inline-block", color: "red" }} onClick={(e) => { setShowContextMenu(true); hideExtendedLabels(); }}><SettingsIcon style={{ fontSize: "18px", margin: "0px 5px 0px 0px", padding: "0px" }} /></div>
                </div>
            </div> 
        }
    }

    function renderWidgetContextMenu(selectedSettings) {
        

        if (selectedWidgetId != undefined) {
            var iFrameEl = document.getElementById('canvas_iframe');
            var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

            var elObj = elements[selectedWidgetId];

            var objectType = '';
            if (elObj.elementName == "section") {
                objectType = "Grid";
            } else if (elObj.elementName == "grid") {
                objectType = "Column";
            }

            var itemWidth = parseFloat(151.48);
            var itemHeight = parseFloat(29.5);
            var totalItems = parseInt(9);
            if (elObj.elementName == 'section') {
                totalItems += 3;
            } if (elObj.elementName == 'grid') {
                totalItems += 2;
            }
             

            var totalHeight = itemHeight * totalItems;
            var top = selectedSettings.style.top - iFrameDocument.scrollingElement.scrollTop;
            var left = selectedSettings.style.left;
            var iFrameHeight = iFrameEl.clientHeight;
            var iFrameWidth = iFrameEl.clientWidth;
            if ((top + totalHeight) > iFrameHeight) {
                top = top - (totalHeight + 29);
            } else if (top < (iFrameDocument.scrollingElement.scrollTop + 22)) {
                top = 20;
            }


            if ((left + itemWidth) > iFrameWidth) {
                left = left + (iFrameWidth - (left + itemWidth)) ;
            }

            return <div style={{ position: "absolute", left: left, top: top, display: "block", backgroundColor: "white", border: "1px solid #c0c0c0", fontSize: "14px", padding: "0px 0px 0px 0px" }}>
                <div className="fg-pb-context-menu-item" onClick={(e) => { openProperties(elObj.id); }}>
                    <div><SettingsIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Properties</div>
                </div>
                {objectType != '' ?
                    <div>
                        <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuInsertBefore(elObj.id, objectType); }}>
                            <div><UndoIcon style={{ fontSize: "18px" }} /></div>
                            <div className="fg-pb-context-text">Add {objectType} Before</div>
                        </div>
                        <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuInsertAfter(elObj.id, objectType); }}>
                            <div><RedoIcon style={{ fontSize: "18px" }} /></div>
                            <div className="fg-pb-context-text">Add {objectType} After</div>
                        </div>
                    </div>
                    : null}
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuCut(elObj.id); }}>
                    <div><ContentCutIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Cut</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuDuplicate(elObj.id); }}>
                    <div><CopyAllIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Duplicate</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuCopy(elObj.id); }}>
                    <div><ContentCopyIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Copy</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuPaste(elObj.id); }}>
                    <div><ContentPasteIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Paste</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuPasteBefore(elObj.id); }}>
                    <div><ContentPasteIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Paste Before</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuCopyStyle(elObj.id); }}>
                    <div><ContentCopyIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Copy Style</div>
                </div>
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuPasteStyle(elObj.id); }}>
                    <div><ContentPasteIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Paste Style</div>
                </div>
                {elObj.elementName == 'section' ?
                    <div className="fg-pb-context-menu-item" onClick={(e) => { saveSectionAsTemplate(elObj.id); }}>
                        <div><WidgetsIcon style={{ fontSize: "18px" }} /></div>
                        <div className="fg-pb-context-text">Save as Template</div>
                    </div>
                    : null}
                <div className="fg-pb-context-menu-item" onClick={(e) => { contextMenuDeleteWidget(elObj.id); }}>
                    <div><DeleteIcon style={{ fontSize: "18px" }} /></div>
                    <div className="fg-pb-context-text">Delete</div>
                </div>
            </div>
        }
    }

    function onContextMenuItemClick(e, menuItemName, id, objectType) {
        alert('menu item click: ' + menuItemName + ' ' + id + ' ' + objectType);
    }

    function renderBreakpointIcon(icon) {
        switch (icon) {
            case "Tv":
                return <TvIcon/>;
                break;
            case "Laptop":
                return <LaptopIcon />;
                break;
            case "Tablet":
                return <TabletIcon />;
                break;
            case "PhoneAndroid":
                return <PhoneAndroidIcon />;
                break;
        }
    }

    function testFunction() {
        console.log("testing");
    }

    async function handleSwapSection(e, widgetName, widgetType, componentType, templateId) {
        //e, "section", "container", "template", section.templateId
        var isValid = false;
        if (selectedWidgetId != null) {
            if (elements[selectedWidgetId].elementName == 'section') {
                isValid = true;
            }
        }
        if (isValid == true) {
            var rootId = null;
            if (masterInfo.length > 0) {
                rootId = pageRootElementIdRef.current;
            } else {
                rootId = helpers.getRootElementId(elements);
            }                        
            
            var sectionElements = await PageEngineUtil.GetClonedSectionTemplate(templateId);
            var sectionRootId = helpers.getRootElementId(sectionElements);

            var tmpElements = Object.assign(elements, sectionElements);

            for (var i = 0; i < tmpElements[rootId].children.length; i++) {                
                if (tmpElements[rootId].children[i] == selectedWidgetId) {
                    tmpElements[rootId].children.splice(i, 0, sectionRootId);
                    break;
                }
            }
            tmpElements[sectionRootId].parents.push(rootId);   




            var widgetObj = tmpElements[selectedWidgetId];
            for (var i = 0; i < widgetObj.children.length; i++) {
                removeDescendents(tmpElements, widgetObj.children[i]);
            }

          
            //remove widget from parent
            var childIndexToRemove = tmpElements[rootId].children.indexOf(selectedWidgetId);
            tmpElements[rootId].children.splice(childIndexToRemove, 1);

            //remove widget object
            delete tmpElements[selectedWidgetId];


            setElements(tmpElements);

            selectWidget(sectionRootId);
            //hideHighlighters();
            //alert(JSON.stringify(sectionElements));
        }else {
            alert('section must be selected on canvas.')
        }
    }

    function handleOnDragFromList(e: React.DragEvent, widgetName, widgetType, componentType, templateId) {
        //console.log('drag from list')
        e.dataTransfer.setData("widgetInfo", JSON.stringify({ id: null, widgetName: widgetName, widgetType: widgetType, componentType: componentType, templateId: templateId }));
        setDragItem({ id: null, widgetName: widgetName, widgetType: widgetType, componentType: componentType, templateId: templateId });
        //console.log('dragging ' + widgetName + ' from list.');
    }

    function handleOnDragFromCanvas(e: React.DragEvent, id, widgetName, widgetType) {
        //console.log('dragging ' + widgetName + ' from canvas.');
        e.stopPropagation(); 
        e.dataTransfer.setData("widgetInfo", JSON.stringify({ id: id, widgetName: widgetName, widgetType: widgetType }));
        setDragItem({ id: id, widgetName: widgetName, widgetType: widgetType });        
    }

    function determineTargets(widgetInfo, targetObj) {
        var targetId = null;
        var siblingId = null;
        switch (widgetInfo.widgetType) {
            case "widget":
                if (targetObj.widgetType == "widget") {
                    siblingId = targetObj.id;
                    targetId = targetObj.parents[0];
                } else if (targetObj.elementName == "divblock" || targetObj.elementName == "linkblock" || targetObj.elementName == "repeater" || targetObj.elementName == "collectionlistcontent" || targetObj.elementName == "collectionlistitem" || targetObj.elementName == "collectionlistheader" || targetObj.elementName == "collectionlistfooter" || targetObj.elementName == "collectionlistpagination") {
                    siblingId = null;
                    targetId = targetObj.id;
                } else if (targetObj.elementName == "column") {
                    siblingId = null;
                    targetId = targetObj.id;
                } else if (targetObj.elementName == "listitem") {
                    siblingId = null;
                    targetId = targetObj.id;
                } else if (targetObj.elementName == "list") {
                    siblingId = targetObj.id;
                    targetId = targetObj.parents[0];
                } else if (targetObj.elementName == "pagecontainer") {
                    siblingId = targetObj.id;
                    targetId = targetObj.parents[0];
                }
                break;
            case "container":
                switch (widgetInfo.widgetName) {
                    case "listitem":
                        if (targetObj.elementName == "list") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "listitem") {
                            siblingId = targetObj.id
                            targetId = targetObj.parents[0];
                        }
                        break;
                    case "list":
                        if (targetObj.widgetType == "widget" || targetObj.elementName == 'list') {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else  if (targetObj.elementName == "column") {
                            siblingId = null
                            //siblingId = targetObj.parents[0];
                            targetId = targetObj.id;
                        }
                        break;
                    case "column":
                        if (targetObj.widgetType == "widget" || targetObj.elementName == 'list') {
                            siblingId = targetObj.parents[0];
                            targetId = targetObj.parents[1];
                        } else if (targetObj.elementName == "column" || targetObj.elementName == "pagecontainer") {
                            siblingId = targetObj.id
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "grid") {
                            siblingId = null
                            targetId = targetObj.id;
                        }
                        break;
                    case "linkblock":
                        if (targetObj.widgetType == "widget" || targetObj.widgetType == "linkblock") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "column") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "divblock") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        }
                        break;
                    case "collectionlistwrapper":
                        if (targetObj.widgetType == "widget") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "column") {
                            siblingId = null;
                            targetId = targetObj.id;
                        }
                        break;
                    case "repeater":
                        if (targetObj.widgetType == "widget") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "column") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "divblock") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "repeater") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "collectionlistwrapper") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "grid") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "section") {
                            siblingId = null;
                            targetId = targetObj.id
                        }
                        break;
                    case "grid":
                    case "divblock":
                    case "pagecontainer":
                        if (targetObj.widgetType == "widget") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "column") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "divblock") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "repeater") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "collectionlistwrapper") {
                            siblingId = null;
                            targetId = targetObj.id;
                        } else if (targetObj.elementName == "grid") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        //} else if (targetObj.elementName == "divblock") {
                        //    siblingId = targetObj.id;
                        //    targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "section") {
                            siblingId = null;
                            targetId = targetObj.id
                        }
                        break;
                    case "section":
                        if (targetObj.widgetType == "widget") {
                            siblingId = targetObj.parents[targetObj.parents.length - 2];
                            targetId = targetObj.parents[targetObj.parents.length - 1];
                        } else if (targetObj.elementName == "column") {
                            siblingId = targetObj.parents[targetObj.parents.length - 2];
                            targetId = targetObj.parents[targetObj.parents.length - 1];
                        } else if (targetObj.elementName == "grid") {
                            siblingId = targetObj.parents[targetObj.parents.length - 2];
                            targetId = targetObj.parents[targetObj.parents.length - 1];
                        } else if (targetObj.elementName == "pagecontainer") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "section") {
                            siblingId = targetObj.id;
                            targetId = targetObj.parents[0];
                        } else if (targetObj.elementName == "body") {
                            siblingId = null;
                            targetId = targetObj.id;
                        }
                        break;
                }
                break;
        }
        return { targetId: targetId, siblingId: siblingId };
    }

    function handleOnDrop(e: React.DragEvent, id) {
        e.preventDefault();
        e.stopPropagation(); 

        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        var placeholder = iFrameDocument.getElementById('fg-widgetplace-holder');        
        if (placeholder != null) {
            placeholder.remove();
        } else {
            placeholder = document.getElementById('fg-widgetplace-holder');
            if (placeholder != null) {
                placeholder.remove();
            }
        }

        const widgetInfoStr = e.dataTransfer.getData("widgetInfo");
        const widgetInfo = JSON.parse(widgetInfoStr);
        var targetObj = elements[id];
        //determine 
        const { targetId, siblingId } = determineTargets(widgetInfo, targetObj)


        hideHighlighters();

        var elementName = elements[targetId].elementName;
        if (widgetInfo.id == null) {
            if (widgetInfo.componentType == null) {
                var newId = addNewWidget(widgetInfo.widgetName, targetId, siblingId, positionTarget);
                //console.log('drop ' + widgetInfo.widgetName + ' into ' + elementName + ' from list.');
                selectWidget(newId);
            } else if (widgetInfo.componentType == 'template') {
                var packageObj = { widgetName: widgetInfo.widgetName, targetId: targetId, siblingId: siblingId, positionTarget: positionTarget }
                getTemplateData(widgetInfo.templateId, packageObj);
            }
        } else {
            relocateWidget(widgetInfo.id, targetId, siblingId, positionTarget, { ...elements });
            //console.log('moved ' + widgetInfo.widgetName + ' into ' + elementName + ' from another location on canvas.');
            selectWidget(widgetInfo.id);
        }              
        
    }

    function insertSectionTemplate(data, packageObj, aiSectionData) {
        //var packageObj = { widgetName: widgetInfo.widgetName, targetId: targetId, siblingId: siblingId, positionTarget: positionTarget }
        //var newElements = JSON.parse(cloneCopyFromMemory(JSON.parse(data.components)));
        var targetId = packageObj.targetId
        if (packageObj.positionTarget != 'lastchild') {
            targetId = packageObj.siblingId;
        }
        pasteWidget(targetId, JSON.parse(data.components), packageObj.positionTarget, aiSectionData);
        hideHighlighters();
    }

    function getTemplateData(templateId, packageObj) {
        BAFetchData({
            callName: "GetSectionTemplate", method: "GET", url: "template/getrecord?templateid=" + templateId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj:packageObj
        });
    }


    function handleOnDragOver(e, id) {
        e.preventDefault();
        e.stopPropagation();


        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        var targetObj = elements[id];

        const { targetId, siblingId } = determineTargets(dragItem, targetObj)
        if (targetId != null) {
            //console.log('id: ' + id + ' targetId: ' + targetId);
            var elementName = null;
            var el = null;

            if (siblingId != null) {
                targetObj = elements[siblingId];
                elementName = targetObj.elementName;
                el = iFrameDocument.getElementById(siblingId);
            } else {
                targetObj = elements[targetId];
                elementName = targetObj.elementName;
                el = iFrameDocument.getElementById(targetId);
            }

            var elW = el.clientWidth;
            var elH = el.clientHeight;
            var elTop = el.offsetTop;
            var elLeft = el.offsetLeft;

            //if (id != hoverWidgetInfo.id) {            
            //    setHoverWidgetInfo({ id: id, top: elTop, right: null, bottom: null, left: elLeft, width: elW, height: elH });
            //}

            //console.log('id: ' + id + ' top: ' + elTop + ' height: ' + elH + ' mouseY: ' + mousePos.y);


            var position = "";
            var aliasPosition = ""

            if (siblingId != null) {
                if (dragItem.widgetName != "column") {
                    //if (((e.pageY + document.getElementById('fg-pb-canvas').scrollTop) - 98) < (elTop + (elH / 2))) {
                    if ((e.pageY + document.getElementById('fg-pb-canvas').scrollTop) < (elTop + (elH / 2))) {
                        position = "beforebegin";
                        aliasPosition = "before";
                    } else {
                        position = "afterend";
                        aliasPosition = "after";
                    }
                } else {
                    var appLeftPanelW = document.getElementById('fg-pb-left-panel').offsetWidth;
                    var appSidebarW = document.getElementById('app-sidebar').offsetWidth;

                    appLeftPanelW = 0
                    appSidebarW = 0

                    if ((e.pageX - (appLeftPanelW + appSidebarW)) < (elLeft + (elW / 2))) {
                        position = "beforebegin";
                        aliasPosition = "before";
                    } else {
                        position = "afterend";
                        aliasPosition = "after";
                    }
                }

            } else {
                position = "beforeend";
                aliasPosition = "lastchild";
            }

            setPositionTarget(aliasPosition);

            var placeholder = iFrameDocument.getElementById('fg-widgetplace-holder');
            if (placeholder != null) {
                placeholder.remove();
            } else {
                placeholder = document.getElementById('fg-widgetplace-holder');
                if (placeholder != null) {
                    placeholder.remove();
                }
            }

            //var html = <div style={{ pointerEvents: "none" }} className="fg-pb-target-placeholder" onDragOver={(e) => { handleOnDragOver(e, params.id); }} onDragLeave={(e) => { handleOnDragLeave(e, params.id); }} onDrop={(e) => { handleOnDrop(e, params.id); }}></div>
            var html = "";
            if (dragItem.widgetName != "column") {
                html = '<div id="fg-widgetplace-holder" style="pointer-events: none;" class="fg-pb-target-placeholder"></div>';
                el.insertAdjacentHTML(position, html)
            } else {
                if (aliasPosition == "after") {
                    html = '<div id="fg-widgetplace-holder" style="pointer-events: none;width:2px;position:absolute;top:' + elTop + 'px;left:' + (elLeft + elW) + 'px;height:' + elH + 'px;" class="fg-pb-target-placeholder"></div>';
                } else if (aliasPosition == "before") {
                    html = '<div id="fg-widgetplace-holder" style="pointer-events: none;width:2px;position:absolute;top:' + elTop + 'px;left:' + (elLeft) + 'px;height:' + elH + 'px;" class="fg-pb-target-placeholder"></div>';
                } else if (aliasPosition == "lastchild") {
                    html = '<div id="fg-widgetplace-holder" style="pointer-events: none;width:2px;position:absolute;top:' + elTop + 'px;left:' + (elLeft + elW) + 'px;height:' + elH + 'px;" class="fg-pb-target-placeholder"></div>';
                }
                document.getElementById('fg-pb-canvas').insertAdjacentHTML('beforeend', html);
            }
        }
    }

    function handleOnDragLeave(e, id) {
        e.preventDefault();
        e.stopPropagation(); 

        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;

        //setElements({
        //    ...elements,
        //    [id]: {
        //        ...elements[id],
        //        placeholderPosition: "",
        //    },
        //})

        var placeholder = iFrameDocument.getElementById('fg-widgetplace-holder');
        if (placeholder != null) {
            placeholder.remove();
            //placeholder.style.display = "none";
        }

        var elementName = elements[id].elementName;
        
        //console.log('dragging  over leave ' + id + ' ' + elementName);
    }

    function createNewSection(tmpElements) {
        var newSectionId = 'fpgwidget_' + helpers.getUUID();
        var newSectionObj = createNewWidget("section");
        newSectionObj.id = newSectionId;
        tmpElements[newSectionId] = newSectionObj;

        var newGridId = 'fpgwidget_' + helpers.getUUID();
        var newGridObj = createNewWidget("grid");
        newGridObj.id = newGridId;
        newGridObj.parents.push(newSectionId);
        tmpElements[newGridId] = newGridObj;

        var newColumnId = 'fpgwidget_' + helpers.getUUID();
        var newColumnObj = createNewWidget("column");
        newColumnObj.id = newColumnId;
        newColumnObj.parents.push(newGridId);
        newColumnObj.parents.push(newSectionId);
        tmpElements[newColumnId] = newColumnObj;

        newSectionObj.children.push(newGridId);
        newGridObj.children.push(newColumnId);


        return newSectionId;
    }

    function createNewCollectionList(tmpElements) {
        var newCollectionListId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListObj = createNewWidget("collectionlistwrapper");
        newCollectionListObj.id = newCollectionListId;
        tmpElements[newCollectionListId] = newCollectionListObj;

        var newCollectionListContentId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListContentObj = createNewWidget("collectionlistcontent");
        newCollectionListContentObj.id = newCollectionListContentId;
        newCollectionListContentObj.breakpointStyles['2500'] = { "margin-bottom": "20px"};
        newCollectionListContentObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListContentId] = newCollectionListContentObj;

        var newCollectionListItemId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListItemObj = createNewWidget("collectionlistitem");
        newCollectionListItemObj.id = newCollectionListItemId;
        newCollectionListItemObj.parents.push(newCollectionListContentId);
        newCollectionListItemObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListItemId] = newCollectionListItemObj;

        var newCollectionListPaginationId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListPaginationObj = createNewWidget("collectionlistpagination");
        newCollectionListPaginationObj.id = newCollectionListPaginationId;
        newCollectionListPaginationObj.breakpointStyles['2500'] = { "text-align": "center" };
        newCollectionListPaginationObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListPaginationId] = newCollectionListPaginationObj;

        var newCollectionListPrevBtnId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListPrevBtnObj = createNewWidget("linkblock");
        newCollectionListPrevBtnObj.id = newCollectionListPrevBtnId;
        newCollectionListPrevBtnObj.breakpointStyles['2500'] = { "display": "inline-block", "margin-left": "10px", "margin-right": "10px","color":"grey","border":"1px solid grey","padding":"10px 15px 10px 15px","border-radius":"7px" };
        newCollectionListPrevBtnObj.parents.push(newCollectionListPaginationId);
        newCollectionListPrevBtnObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListPrevBtnId] = newCollectionListPrevBtnObj;

        var newCollectionListPrevBtnIconId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListPrevBtnIconObj = createNewWidget("icon");
        newCollectionListPrevBtnIconObj.id = newCollectionListPrevBtnIconId;
        newCollectionListPrevBtnIconObj.className = 'fa-solid fa-angle-left fa-fw';
        newCollectionListPrevBtnIconObj.breakpointStyles['2500'] = { "display": "inline-block","margin-right":"7px" };
        newCollectionListPrevBtnIconObj.parents.push(newCollectionListPrevBtnId);
        newCollectionListPrevBtnIconObj.parents.push(newCollectionListPaginationId);
        newCollectionListPrevBtnIconObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListPrevBtnIconId] = newCollectionListPrevBtnIconObj;

        var newCollectionListPrevBtnTextId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListPrevBtnTextObj = createNewWidget("text");
        newCollectionListPrevBtnTextObj.id = newCollectionListPrevBtnTextId;
        newCollectionListPrevBtnTextObj.breakpointStyles['2500'] = { "display": "inline-block", "margin": "0px" };
        newCollectionListPrevBtnTextObj.text = "Prev";
        newCollectionListPrevBtnTextObj.parents.push(newCollectionListPrevBtnId);
        newCollectionListPrevBtnTextObj.parents.push(newCollectionListPaginationId);
        newCollectionListPrevBtnTextObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListPrevBtnTextId] = newCollectionListPrevBtnTextObj;


        var newCollectionListPageInfoId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListPageInfoObj = createNewWidget("text");
        newCollectionListPageInfoObj.id = newCollectionListPageInfoId;
        newCollectionListPageInfoObj.breakpointStyles['2500'] = { "display": "inline-block", "margin-left": "5px", "margin-right": "5px","color":"grey" };
        newCollectionListPageInfoObj.text = "1 of 5";
        newCollectionListPageInfoObj.parents.push(newCollectionListPaginationId);
        newCollectionListPageInfoObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListPageInfoId] = newCollectionListPageInfoObj;

        var newCollectionListNextBtnId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListNextBtnObj = createNewWidget("linkblock");
        newCollectionListNextBtnObj.id = newCollectionListNextBtnId;
        newCollectionListNextBtnObj.breakpointStyles['2500'] = { "display": "inline-block", "margin-left": "10px", "margin-right": "10px", "color": "grey", "border": "1px solid grey", "padding": "10px 15px 10px 15px", "border-radius": "7px" };
        newCollectionListNextBtnObj.parents.push(newCollectionListPaginationId);
        newCollectionListNextBtnObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListNextBtnId] = newCollectionListNextBtnObj;

        var newCollectionListNextBtnIconId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListNextBtnIconObj = createNewWidget("icon");
        newCollectionListNextBtnIconObj.id = newCollectionListNextBtnIconId;
        newCollectionListNextBtnIconObj.className = 'fa-solid fa-angle-right fa-fw';
        newCollectionListNextBtnIconObj.breakpointStyles['2500'] = { "display": "inline-block", "margin-left": "7px" };
        newCollectionListNextBtnIconObj.parents.push(newCollectionListNextBtnId);
        newCollectionListNextBtnIconObj.parents.push(newCollectionListPaginationId);
        newCollectionListNextBtnIconObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListNextBtnIconId] = newCollectionListNextBtnIconObj;

        var newCollectionListNextBtnTextId = 'fpgwidget_' + helpers.getUUID();
        var newCollectionListNextBtnTextObj = createNewWidget("text");
        newCollectionListNextBtnTextObj.id = newCollectionListNextBtnTextId;
        newCollectionListNextBtnTextObj.breakpointStyles['2500'] = { "display": "inline-block","margin":"0px" };
        newCollectionListNextBtnTextObj.text = "Next";
        newCollectionListNextBtnTextObj.parents.push(newCollectionListNextBtnId);
        newCollectionListNextBtnTextObj.parents.push(newCollectionListPaginationId);
        newCollectionListNextBtnTextObj.parents.push(newCollectionListId);
        tmpElements[newCollectionListNextBtnTextId] = newCollectionListNextBtnTextObj;

        newCollectionListObj.children.push(newCollectionListContentId);
        newCollectionListObj.children.push(newCollectionListPaginationId);
        newCollectionListContentObj.children.push(newCollectionListItemId);
        newCollectionListPaginationObj.children.push(newCollectionListPrevBtnId);
        newCollectionListPrevBtnObj.children.push(newCollectionListPrevBtnIconId);
        newCollectionListPrevBtnObj.children.push(newCollectionListPrevBtnTextId);

        newCollectionListPaginationObj.children.push(newCollectionListPageInfoId);
        

        newCollectionListPaginationObj.children.push(newCollectionListNextBtnId);
        newCollectionListNextBtnObj.children.push(newCollectionListNextBtnTextId);
        newCollectionListNextBtnObj.children.push(newCollectionListNextBtnIconId);

        return newCollectionListId;
    }

    function addSection(sectionName, widgetName, targetWidgetId, siblingWidgetId, positionTarget) {
        var tempTargetWidget = elements[targetWidgetId];

        var tmpElements = { ...elements };
        var newWidgetId = createNewSection(tmpElements);
        var newWidgetDetails = tmpElements[newWidgetId]

        //add parent which is initial targetWidgetId
        newWidgetDetails.parents.push(targetWidgetId);
        //add all parents from the targetWidget
        newWidgetDetails.parents = newWidgetDetails.parents.concat(tempTargetWidget.parents);


        //add newWidgetId to children property of targetWidget;
        if (siblingWidgetId == null) {
            tempTargetWidget.children.push(newWidgetId);
        } else {
            switch (positionTarget) {
                case "lastchild":
                    tempTargetWidget.children.push(newWidgetId);
                    break;
                case "before":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex, 0, newWidgetId);
                    break;
                case "after":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex + 1, 0, newWidgetId);
                    break;
            }
        }

        if (tempTargetWidget.elementName == "grid") {
            if (!tempTargetWidget.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                tempTargetWidget.breakpointStyles[currentBreakpoint] = {};
            }
            tempTargetWidget.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(tempTargetWidget.children.length);
        }

        //update parents property of all descendents
        for (var i = 0; i < newWidgetDetails.children.length; i++) {
            updateDescendentsParents(tmpElements, newWidgetDetails.children[i], newWidgetId, newWidgetDetails.parents);
        }


        setElements((...elements) => { return tmpElements });

        hideHighlighters();

        return newWidgetId;
    }

    function addCollectionList(sectionName, widgetName, targetWidgetId, siblingWidgetId, positionTarget) {
        var tempTargetWidget = elements[targetWidgetId];

        var tmpElements = { ...elements };
        var newWidgetId = createNewCollectionList(tmpElements);
        var newWidgetDetails = tmpElements[newWidgetId]

        //add parent which is initial targetWidgetId
        newWidgetDetails.parents.push(targetWidgetId);
        //add all parents from the targetWidget
        newWidgetDetails.parents = newWidgetDetails.parents.concat(tempTargetWidget.parents);


        //add newWidgetId to children property of targetWidget;
        if (siblingWidgetId == null) {
            tempTargetWidget.children.push(newWidgetId);
        } else {
            switch (positionTarget) {
                case "lastchild":
                    tempTargetWidget.children.push(newWidgetId);
                    break;
                case "before":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex, 0, newWidgetId);
                    break;
                case "after":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex + 1, 0, newWidgetId);
                    break;
            }
        }

        if (tempTargetWidget.elementName == "grid") {
            if (!tempTargetWidget.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                tempTargetWidget.breakpointStyles[currentBreakpoint] = {};
            }
            tempTargetWidget.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(tempTargetWidget.children.length);
        }

        //update parents property of all descendents
        for (var i = 0; i < newWidgetDetails.children.length; i++) {
            updateDescendentsParents(tmpElements, newWidgetDetails.children[i], newWidgetId, newWidgetDetails.parents);
        }


        setElements((...elements) => { return tmpElements });

        hideHighlighters();

        return newWidgetId;
    }


    function addNewWidget(widgetName, targetWidgetId, siblingWidgetId, positionTarget) {
        var newWidgetId = null;
        if (widgetName == 'section') {
            newWidgetId = addSection('default', widgetName, targetWidgetId, siblingWidgetId, positionTarget);
        } else if (widgetName == 'collectionlistwrapper') {
            newWidgetId = addCollectionList('default', widgetName, targetWidgetId, siblingWidgetId, positionTarget);
        } else {
            var tempTargetWidget = elements[targetWidgetId];

            newWidgetId = 'fpgwidget_' + helpers.getUUID();
            var newWidgetDetails = createNewWidget(widgetName);
            newWidgetDetails.id = newWidgetId;
            //add parent which is initial targetWidgetId
            newWidgetDetails.parents.push(targetWidgetId);
            //add all parents from the targetWidget
            newWidgetDetails.parents = newWidgetDetails.parents.concat(tempTargetWidget.parents);


            //add newWidgetId to children property of targetWidget;
            if (siblingWidgetId == null) {
                tempTargetWidget.children.push(newWidgetId);
            } else {
                switch (positionTarget) {
                    case "lastchild":
                        tempTargetWidget.children.push(newWidgetId);
                        break;
                    case "before":
                        var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                        tempTargetWidget.children.splice(siblingIndex, 0, newWidgetId);
                        break;
                    case "after":
                        var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                        tempTargetWidget.children.splice(siblingIndex + 1, 0, newWidgetId);
                        break;
                }
            }

            if (tempTargetWidget.elementName == "grid") {
                if (!tempTargetWidget.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                    tempTargetWidget.breakpointStyles[currentBreakpoint] = {};
                }
                tempTargetWidget.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(tempTargetWidget.children.length);
            }


            tempTargetWidget.placeholderPosition = ""; //remove placeholder

            //update the elements state

            //it's important here to use the newWidgetId 
            setElements({
                ...elements, // copy the old fields
                [targetWidgetId]: tempTargetWidget //override with updated target widget
            });

            setElements({ ...elements, [newWidgetId]: newWidgetDetails }); // this create a new property within the elements for new widget
        }
        return newWidgetId;
    }

    function relocateWidget(dragWidgetId, targetWidgetId, siblingWidgetId, positionTarget, tmpElements) {
        //getTargetWidget
        var tempTargetWidget = tmpElements[targetWidgetId];
        //get dragged widget object
        var tmpDragedWidgetObj = tmpElements[dragWidgetId];
        var parentWidgetObj = tmpElements[tmpDragedWidgetObj.parents[0]];

        //remove child from previous parent
        var childIndexToRemove = parentWidgetObj.children.indexOf(dragWidgetId);
        parentWidgetObj.children.splice(childIndexToRemove, 1);

        //remove all parents from dragged widgetd;
        tmpDragedWidgetObj.parents = [];
        //update parents with initial targetWidgetId
        tmpDragedWidgetObj.parents.push(targetWidgetId);
        //add all parents from the targetWidget
        tmpDragedWidgetObj.parents = tmpDragedWidgetObj.parents.concat(tempTargetWidget.parents);


        //update children of targetWidget;
        if (siblingWidgetId == null) {
            tempTargetWidget.children.push(dragWidgetId);
        } else {
            switch (positionTarget) {
                case "lastchild":
                    tempTargetWidget.children.push(dragWidgetId);
                    break;
                case "before":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex, 0, dragWidgetId);
                    break;
                case "after":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex + 1, 0, dragWidgetId);
                    break;
            }
        }

        //check if drag item is a column and if it is then update the gridTemplateColumns for both the old and new parent
        if (tmpDragedWidgetObj.elementName == "column") {
            if (!parentWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                parentWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            parentWidgetObj.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(parentWidgetObj.children.length);

            if (!tempTargetWidget.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                tempTargetWidget.breakpointStyles[currentBreakpoint] = {};
            }
            tempTargetWidget.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(tempTargetWidget.children.length);
        }

        //update parents property of all descendents
        for (var i = 0; i < tmpDragedWidgetObj.children.length; i++) {
            updateDescendentsParents(tmpElements, tmpDragedWidgetObj.children[i], dragWidgetId, tmpDragedWidgetObj.parents);
        }


        //update the elements state

        setElements((...elements) => { return tmpElements });

        hideHighlighters();

        ////Update parent item it's important here to use the newWidgetId 
        //setElements({
        //    ...elements, // copy the old fields
        //    [targetWidgetId]: tempTargetWidget 
        //});

        ////Update old parent 
        //setElements({
        //    ...elements, // copy the old fields
        //    [parentWidgetObj.id]: parentWidgetObj 
        //});

        ////update dragged item

        //setElements({
        //    ...elements, // copy the old fields
        //    [tmpDragedWidgetObj.id]: tmpDragedWidgetObj 
        //});

    }

    function updateDescendentsParents(tmpElements, childWidgetId, mainParentWidgetId, mainParents) {
        var childWidgetObj = tmpElements[childWidgetId];

        //remove all anscestors after mainParentId
        childWidgetObj.parents.length = (childWidgetObj.parents.indexOf(mainParentWidgetId) + 1); //remove all parents above main parent
        childWidgetObj.parents = childWidgetObj.parents.concat(mainParents); //append mainParents to existing parents 

        for (var i = 0; i < childWidgetObj.children.length; i++) {
            updateDescendentsParents(tmpElements, childWidgetObj.children[i], mainParentWidgetId, mainParents);
        }
    }

    function relocateWidget_Master(dragWidgetId, targetWidgetId, siblingWidgetId, positionTarget) {
        //getTargetWidget
        var tempTargetWidget = elements[targetWidgetId];
        //get dragged widget object
        var tmpDragedWidgetObj = elements[dragWidgetId];
        var parentWidgetObj = elements[tmpDragedWidgetObj.parents[0]];

        //remove child from previous parent
        var childIndexToRemove = parentWidgetObj.children.indexOf(dragWidgetId);
        parentWidgetObj.children.splice(childIndexToRemove, 1);

        //remove all parents from dragged widgetd;
        tmpDragedWidgetObj.parents = [];
        //update parents with initial targetWidgetId
        tmpDragedWidgetObj.parents.push(targetWidgetId);
        //add all parents from the targetWidget
        tmpDragedWidgetObj.parents = tmpDragedWidgetObj.parents.concat(tempTargetWidget.parents);


        //update children of targetWidget;
        if (siblingWidgetId == null) {
            tempTargetWidget.children.push(dragWidgetId);
        } else {
            switch (positionTarget) {
                case "lastchild":
                    tempTargetWidget.children.push(dragWidgetId);
                    break;
                case "before":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex, 0, dragWidgetId);
                    break;
                case "after":
                    var siblingIndex = tempTargetWidget.children.indexOf(siblingWidgetId);
                    tempTargetWidget.children.splice(siblingIndex + 1, 0, dragWidgetId);
                    break;
            }
        }

        //check if drag item is a column and if it is then update the gridTemplateColumns for both the old and new parent
        if (tmpDragedWidgetObj.elementName == "column") {
            if (!parentWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                parentWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            parentWidgetObj.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(parentWidgetObj.children.length);

            if (!tempTargetWidget.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                tempTargetWidget.breakpointStyles[currentBreakpoint] = {};
            }
            tempTargetWidget.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(tempTargetWidget.children.length);
        }



        //update the elements state

        //Update parent item it's important here to use the newWidgetId 
        setElements({
            ...elements, // copy the old fields
            [targetWidgetId]: tempTargetWidget
        });

        //Update old parent 
        setElements({
            ...elements, // copy the old fields
            [parentWidgetObj.id]: parentWidgetObj
        });

        //update dragged item

        setElements({
            ...elements, // copy the old fields
            [tmpDragedWidgetObj.id]: tmpDragedWidgetObj
        });

    }
    function createNewWidget(widgetName) {
        var bp = "2500";
        if (siteInfo.mobileFirst == true) {
            bp = "479";
        }
        //var newWidgetId = 'fpgwidget_' + helpers.getUUID();

        var newItem = {};
        switch (widgetName) {
            case "body":
                newItem =  {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false, 
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "section":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "",
                    aiBGImages: [], backgroundVideo: { url: null, breakpointStyles: {} }, purpose: "", aiPrompt: "", sectionCategory: null, events: {}
                }
                break;
            case "grid":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false, 
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "column":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false, 
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "divblock":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "collectionlistwrapper":
            case "repeater":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    dataSourceType: "", dataSourceListText: "", dataSourceName: "", dataSourceParams: "", showPagination: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "collectionlistcontent":
            case "collectionlistitem":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "collectionlistpagination":
            case "collectionlistheader":
            case "collectionlistfooter":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "linkblock":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    linkType: "basic", linkUrl: "", linkUrlParams: "", linkTarget: "_self", linkDiscountCode: "", 
                    formId: "", formCode: "", formName: "", 
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "heading":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "h1", widgetType: "widget", draggable: false, 
                    text: "Heading",
                    aiText: { variable: '', prompt: '', answer: '', accept: false },
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "text":
                newItem = {
                    id: null, name: "", elementName: widgetName, tag: "p", widgetType: "widget", draggable: false, 
                    text: "This is the paragraph widget. Click edit to change this text. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
                    aiText: { variable: '', prompt: '', answer: '', accept: false },
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "image":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "image", widgetType: "widget", draggable: false, 
                    src:"", alt:"",
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: { [bp]: { display: "block", width: "100%", marginBottom: "10px" } }, children: [], parents: [], placeholderPosition: "",
                    aiImage: { variable: '', prompt: '', answer: '', accept: false }, events: {}
                }
                break;
            case "button":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "button", widgetType: "widget", draggable: false,
                    linkType: "basic", linkUrl: "", linkUrlParams: "", linkTarget: "_self", linkDiscountCode: "", text: "BUTTON",
                    aiText: { variable: '', prompt: '', answer: '', accept: false },
                    formId: "", formCode: "", formName: "", addToCartInfo: {},
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "divider":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "hr", widgetType: "widget", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: { [bp]: { width: "100%", height: "1px", backgroundColor: "black" } }, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "field":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "image", widgetType: "widget", draggable: false,
                    formCode: "", fieldType: "textbox", fieldRequired: true, fieldMaxLength: "", fieldName: "", fieldValue: "", fieldDefaultValue: "", fieldLabel: "Textbox", fieldDataType: "string", fieldPlaceholder: "", fieldOptions: "",
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "fieldlabel":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "label", widgetType: "widget", draggable: false,
                    text: "Field Label",
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "list":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "ul", widgetType: "container", draggable: false,
                    aiText: { variable: '', prompt: '', answer: '', accept: false },
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "listitem":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "li", widgetType: "container", draggable: false,
                    aiText: { variable: '', prompt: '', answer: '', accept: false },
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "icon":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "i", widgetType: "widget", draggable: false,
                    className: "fa fa-star",customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break;
            case "video":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "widget", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: { [bp]: { position: "relative", maxWidth:"600px",marginTop: "10px", marginRight:"auto",marginBottom: "10px",marginLeft:"auto" } }, children: [], parents: [], placeholderPosition: "",
                    videoSettings: { videoType: "", url: "", startAt: "", autoPlay: "", showControls: "", hideRelated: "", thumbnailUrl: '' }, events: {}
                }
                break;
            case "pagecontainer":
                newItem = {
                    id: null, customId: "", elementName: widgetName, tag: "div", widgetType: "container", draggable: false,
                    className: "", customClassName: "", customEvents: [], customAttributes: [], inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
                }
                break; 
        }
        return JSON.parse(JSON.stringify(newItem));
    }

    function hideHighlighters(e) {
        if (widgetCallbacks.widgetHideHighlightCallback != null) {
            widgetCallbacks.widgetHideHighlightCallback();
            //setHoverSettings({
            //    ...hoverSettings, // copy the old fields
            //    show: false
            //});
        }
        setSelectedSettings({
            ...selectedSettings, // copy the old fields
            show: false 
        });

        setShowContextMenu(false);
            setSelectedWidgetId(null);

    }

    function selectWidget(id) {
        setSelectedWidgetId(id);
        //onWidgetClick(null, id);
    }

    useEffect(() => {
        function handlePageKeyDown(e) {
            if (['27', 'Escape'].includes(String(e.key))) {
                hideHighlighters();
            }
        }
        window.addEventListener('keydown', handlePageKeyDown);

        var iFrame = document.getElementById('canvas_iframe');
        var iFrameDocument = null;
        if (iFrame != null) {
            iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
            iFrameDocument.addEventListener('keydown', handlePageKeyDown);
        }
     
        return _ => {

            window.removeEventListener('keydown', handlePageKeyDown);
            if (iFrameDocument != null) {
                iFrameDocument.removeEventListener('keydown', handlePageKeyDown);
            }
        }
    })


    function openProperties(id) {

    }

    function contextMenuInsertBefore(id, objectType) {

    }

    function contextMenuInsertAfter(id, objectType) {

    }

    function contextMenuCut(id) {
        copyWidget(id, false);
        deleteWidget(id);
        hideHighlighters();
    }

    function contextMenuDuplicate(id) {
       var tmpDestElements =  copyWidget(id, true);
       pasteWidget(id, tmpDestElements, 'after');
       hideHighlighters();
    }

    function contextMenuCopy(id) {
        copyWidget(id, false);
    }

    function isTransferAllowed(dropWidgetName, targetWidgetName) {
        var allowed = false;
        if (targetWidgetName != "body" && targetWidgetName != "section" && targetWidgetName != "grid" && targetWidgetName != "column" && targetWidgetName != "list") {
            if (dropWidgetName != "body" && dropWidgetName != "section" && dropWidgetName != "column" && dropWidgetName != "collectionlistwidget") {
                allowed = true;
            }
        }
        switch (targetWidgetName) {
            case "body":
                if (dropWidgetName == "section") {
                    allowed = true;
                }
                break;
            case "pagecontainer":
                if (dropWidgetName == "section") {
                    allowed = true;
                }
                break;
            case "section":
                if (dropWidgetName == "section" || dropWidgetName == "grid" || dropWidgetName == "divblock") {
                    allowed = true;
                }
                break;
            case "repeater":
                if (dropWidgetName == "grid" || dropWidgetName == "divblock" || dropWidgetName == "linkblock" || dropWidgetName == "column") {
                    allowed = true;
                }
                break;
            case "collectionlistwrapper":
                if (dropWidgetName == "collectionlistheader" || dropWidgetName == "collectionlistcontent" || dropWidgetName == "collectionlistitem" || dropWidgetName == "collectionlistfooter") {
                    allowed = true;
                }
                break;
            case "linkblock":
            case "grid":
            case "divblock":
                if (dropWidgetName == "grid" || dropWidgetName == "divblock" || dropWidgetName == "linkblock" || dropWidgetName == "column") {
                    allowed = true;
                }
                break;
            case "column":
                if (dropWidgetName != "body" || dropWidgetName != "section") {
                    allowed = true;
                }
                break;
            case "list":
                if (dropWidgetName == "listitem") {
                    allowed = true;
                }
                break;
            case "listitem":
                if (dropWidgetName == "list" || dropWidgetName == "listitem") {
                    allowed = true;
                }
                break;
        }
        return allowed;
    }

    function contextMenuPaste(id) {
        pasteWidget(id, null, 'after');
    }

    function contextMenuPasteBefore(id) {
        pasteWidget(id, null, 'before');
    }

    function contextMenuCopyStyle(id) {
        var tmpElements = { ...elements };
        var tmpDestElements = {};
        var widgetObj = tmpElements[id];


        tmpDestElements[id] = JSON.parse(JSON.stringify(widgetObj)); //copy widget
      
        setStyleInfo({ id: id, breakpointStyles: tmpDestElements[id].breakpointStyles });

        //setDestElements(tmpDestElements);
        //hideHighlighters();
        setCopySnackbarOpen(true);

    }

    function contextMenuPasteStyle(id) {
        if (styleInfo != null) {
            var tmpElements = { ...elements };
            
            var targetObj = tmpElements[id];
            var sourceObj = tmpElements[styleInfo.id];


            if (targetObj.elementName == sourceObj.elementName) {
                targetObj.breakpointStyles = JSON.parse(JSON.stringify(sourceObj.breakpointStyles));
                setElements((...elements) => { return tmpElements });
            } else {
                var msg = "You can only paste style on the same widget type.";
                setGenericDialogSchema({ title: 'Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
                setOpenDialog(true);
            }
        }
    }

    function contextMenuDeleteWidget(id) {
        deleteWidget(id);
    }

    function deleteWidget(id) {
        var tmpElements = { ...elements };
        var widgetObj = tmpElements[id];

        //remove all descendents
        for (var i = 0; i < widgetObj.children.length; i++) {
            removeDescendents(tmpElements, widgetObj.children[i]);
        }
        //remove from parent
        var parentObj = tmpElements[tmpElements[id].parents[0]];
        //remove widget from parent
        var childIndexToRemove = parentObj.children.indexOf(id);
        parentObj.children.splice(childIndexToRemove, 1);


        if (widgetObj.elementName == "column") {
            if (!parentObj.breakpointStyles.hasOwnProperty(currentBreakpoint)) {
                parentObj.breakpointStyles[currentBreakpoint] = {};
            }
            parentObj.breakpointStyles[currentBreakpoint]['gridTemplateColumns'] = "1fr ".repeat(parentObj.children.length);
        }

        //remove widget object
        delete tmpElements[id];
        //update elements state



        hideHighlighters();
        setElements((...elements) => { return tmpElements });
        
    }

    function removeDescendents(tmpElements, childWidgetId) {
        var childWidgetObj = tmpElements[childWidgetId];
        for (var i = 0; i < childWidgetObj.children.length; i++) {
            removeDescendents(tmpElements, childWidgetObj.children[i]);
        }
        delete tmpElements[childWidgetId];
    }

    function pasteWidget(id, tmpDestElements, defaultPositionTarget, aiSectionData) {
        var tmpElements = null;
        if (updateAiSectionsRef.current == false) {
            tmpElements = { ...elements };
        } else {
            tmpElements = elementsRef.current;
        }
        
        var newElements = JSON.parse(cloneCopyFromMemory(tmpDestElements));
        var firstId = null;
        for (const key in newElements) {
            firstId = key;
            if (updateAiSectionsRef.current == true) {
                newElements[firstId].purpose = aiSectionData.purpose;
                sectionOrderRef.current.push({ "sectionId": firstId, "displayOrder": aiSectionData.displayOrder });
            }
            break;
        }
        var firstElementObj = newElements[firstId];
        var targetObj = tmpElements[id];

        if (isTransferAllowed(firstElementObj.elementName, targetObj.elementName) == true) {
            //merge new elements to existing elements
            tmpElements = Object.assign(tmpElements, newElements);
            var widgetObj = tmpElements[firstId];
            var widgetInfo = { id: widgetObj.id, widgetName: widgetObj.elementName, widgetType: widgetObj.widgetType };

            const { targetId, siblingId } = determineTargets(widgetInfo, targetObj)

            var parentObj = tmpElements[targetId];
            parentObj.children.push(firstId);
            widgetObj.parents.push(targetId);

            var positionTarget = ""
            if (siblingId != null) {
                positionTarget = defaultPositionTarget;
            } else {
                positionTarget = "lastchild";
            }

            //setElements((...elements) => { return tmpElements });
            relocateWidget(firstId, targetId, siblingId, positionTarget, tmpElements);

            if (updateAiSectionsRef.current == true) {
                var counter = totalProcessedRef.current;
                counter++;
                totalProcessedRef.current = counter;
                if (totalToProcessRef.current == totalProcessedRef.current) {
                    updateAiSectionsRef.current = false;
                    //alert('done')
                    //selectWidget(firstId);
                    totalToProcessRef.current = 0;
                    totalProcessedRef.current = 0;
                    reorderAiSections();
                }                
            }else {
                selectWidget(firstId);
            }

            //add as child to 
        } else {
            alert("Cannot paste " + firstElementObj.elementName + " into " + targetObj.elementName);
        }
    }

    function copyWidget(id, returnValue) {
        var tmpElements = { ...elements };
        var tmpDestElements = {};
        var widgetObj = tmpElements[id];


        tmpDestElements[id] = JSON.parse(JSON.stringify(widgetObj)); //copy widget
        tmpDestElements[id].parents = []; //remove parents

        //copy all descendents to tmpDestElements
        for (var i = 0; i < widgetObj.children.length; i++) {
            copyDescendents(tmpElements, tmpDestElements, widgetObj.children[i], id);
        }

        if (returnValue == false) {
            var total = 0;
            for (const key in tmpDestElements) {
                total++;
            }

            setDestElements(tmpDestElements);
            hideHighlighters();
            setCopySnackbarOpen(true);
        } else {
            return tmpDestElements;
        }
    }

    function copyDescendents(tmpElements, tmpDestElements, childWidgetId, mainParentWidgetId) {
        var childWidgetObj = tmpElements[childWidgetId];

        tmpDestElements[childWidgetId] = JSON.parse(JSON.stringify(childWidgetObj)); //copy widget
        tmpDestElements[childWidgetId].parents.length = (tmpDestElements[childWidgetId].parents.indexOf(mainParentWidgetId) + 1); //remove all parents above main parent

        for (var i = 0; i < childWidgetObj.children.length; i++) {
            copyDescendents(tmpElements, tmpDestElements, childWidgetObj.children[i], mainParentWidgetId);
        }
    }


    function cloneCopyFromMemory(tmpDestElementsCopy) {
        var tmpDestElements = null;

        if (tmpDestElementsCopy == null) {
            tmpDestElements = { ...destElements };
        } else {
            tmpDestElements = { ...tmpDestElementsCopy };           
        }
        var tmpFinalElementsStr = JSON.stringify(tmpDestElements);
        var newId = null;
        for (const key in tmpDestElements) {
            newId = 'fpgwidget_' + helpers.getUUID();
            tmpFinalElementsStr = tmpFinalElementsStr.replaceAll(key, newId);
        }
        return tmpFinalElementsStr;
    }

    const handleCopySnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopySnackbarOpen(false);
    };

    const handlePageSaveSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setPageSaveSnackbarOpen(false);
    }

    const handleSiteSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setPublishSnackbarOpen(false);
    }


    const getMergedStyleToBP = (widgetData, propertyName, custom) => {
        var value = "";
        var bp = null;
        if (custom == undefined) {
            for (var i = 0; i < orderedBreakpoints.length; i++) {
                bp = orderedBreakpoints[i];
                if (widgetData.breakpointStyles.hasOwnProperty(bp)) {
                    if (widgetData.breakpointStyles[bp].hasOwnProperty(propertyName)) {
                        if (widgetData.breakpointStyles[bp][propertyName] != '') {
                            value = widgetData.breakpointStyles[bp][propertyName];
                        }
                    }
                }
                if (bp == Number(currentBreakpoint)) {
                    break;
                }
            }
        } else {
            for (var i = 0; i < orderedBreakpoints.length; i++) {
                bp = orderedBreakpoints[i];
                if (widgetData.backgroundVideo.breakpointStyles.hasOwnProperty(bp)) {
                    if (widgetData.backgroundVideo.breakpointStyles[bp].hasOwnProperty(propertyName)) {
                        if (widgetData.backgroundVideo.breakpointStyles[bp][propertyName] != '') {
                            value = widgetData.backgroundVideo.breakpointStyles[bp][propertyName];
                        }
                    }
                }
                if (bp == Number(currentBreakpoint)) {
                    break;
                }
            }
        }
        return value;
    }

    useEffect(() => {
        if (siteInfo != null) {
            buildInternalPageStyle();
        }
    }, [currentBreakpoint]);

    useEffect(() => {
        if (pageLoaded == true) {
            setElementHistory(elementHistory => [...elementHistory, elements]);
        }
    }, [elements]);



    useEffect(() => {
        var tmpUndoActive = false;
        var tmpUndoColor = "#c0c0c0";
        if (elementHistory.length > 1) {
            tmpUndoActive = true;
            tmpUndoColor = "#757575";
        }
        setRedoUndo({
            ...redoUndo,
            undoActive: tmpUndoActive,
            undoColor: tmpUndoColor,
        });
    }, [elementHistory]);

    function clearPage() {
        setVariantId('');
        setPageInfo(null);
        //setPageInfo((...pageInfo) => { return { sitePageId: '', name: '', slug: '', variantId: '' } });
        setElements((...elements) => { return {} });
        setElementHistory(elementHistory => [...elementHistory, []]);
        setSelectedWidgetId(null);     
        hideHighlighters();
        renderNow();
    }

    function handleOnPageDelete(e) {
        //e.preventDefault();
        //e.stopPropagation(); 
        clearPage();
    }

    function handleOnPageItemClick(sitePageId, tmpPageInfo, pageMode) {
        if (buildMode == undefined || buildMode == "Automation") {
            tmpPageInfo.dataSources = JSON.parse(tmpPageInfo.dataSources);
            clearPage();
            setPageInfo((...pageInfo) => { return tmpPageInfo });

            var test = "test";
            test = "hello";
            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "sitepageid", "ConditionOperator": "EQUAL", "Value": sitePageId, "DataType": "String" },
                        { "Name": "primaryvariant", "ConditionOperator": "EQUAL", "Value": "True", "DataType": "Boolean" },
                    ]
                }]
            };

            BAFetchData({
                callName: "GetPageVariant", method: "GET", url: "cms/pagevariants/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        } else if (buildMode == "Automation-Email") {
            tmpPageInfo.dataSources = [];
            clearPage();
            setPageInfo((...pageInfo) => { return tmpPageInfo });


            setFormData((...formData) => { return tmpPageInfo });
            setElements((...elements) => { return JSON.parse(tmpPageInfo.components) });
            setEmailInfo(JSON.parse(tmpPageInfo.emailInfo));

            setVariantId(tmpPageInfo.autoObjectId);
            renderNow();
            

        }
    }

    function handleSavePage() {
        saveForm();
    }

    function saveForm() {
        if (buildMode == undefined || buildMode == 'Automation') {
            var tmpFormData = { ...formData };
            //tmpFormData.components = JSON.stringify({ ...elements });

            var tmpElements = removeMasterInfo({ ...elements });
            tmpFormData.components = JSON.stringify(tmpElements);
            //console.log(tmpFormData.components);
            //if (tmpFormData.components.includes('Heading', 0) == false) {
            //    console.log('not found: ' + tmpFormData.components);
            //}
            
            tmpFormData.createdBy = '0';
            tmpFormData.modifiedBy = '0';
            tmpFormData.dateModified = new Date().toISOString();
            //var tmpSiteInfo = { ...siteInfo };
            //tmpSiteInfo.fontList = JSON.stringify(fontListRef.current);
            //tmpSiteInfo.styleList = JSON.stringify(siteInfo.styleList);
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "cms/pagevariants/update",
                token: "fg1234", body: tmpFormData, onSuccess: onAPISuccess, onError: onAPIError
            });

            //BAFetchData({
            //    callName: "SaveSite", method: "PUT", url: "cms/sites/update",
            //    token: "fg1234", body: tmpSiteInfo, onSuccess: onAPISuccess, onError: onAPIError
            //});

            //var tmpPageInfo = { ...pageInfo };
            //tmpPageInfo.dataSources = JSON.stringify(tmpPageInfo.dataSources);
            //BAFetchData({
            //    callName: "SavePageInfo", method: "PUT", url: "cms/sitepages/update",
            //    token: "fg1234", body: tmpPageInfo, onSuccess: onAPISuccess, onError: onAPIError
            //});
        } else if (buildMode == 'Automation-Email') {
            var tmpFormData = { ...formData };
            var tmpElements = removeMasterInfo({ ...elements });
            tmpFormData.components = JSON.stringify(tmpElements);
            tmpFormData.emailInfo = JSON.stringify(emailInfo);
            BAFetchData({
                callName: "SavePageInfo", method: "PUT", url: "automation/automationobject/update",
                token: "fg1234", body: tmpFormData, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function getMasterPageInfo(sitePageId) {
        var test = "test";
        test = "hello";
         BAFetchData({
            callName: "GetMasterPageInfo", method: "GET", url: "cms/sitepages/getlinkedmasterpages?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }
    

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "SavePageInfo":
                if (masterPageChangedRef.current == true) {
                    masterPageChangedRef.current = false;
                    setReloadNum(helpers.getUUID());
                }
                break;
            case "GetSiteInfo":
                
                if (data.fontList != null) {
                    fontListRef.current = JSON.parse(data.fontList);
                } else {
                    fontListRef.current = [];
                }
                data.styleList = JSON.parse(data.styleList);
                setSiteInfo(data);                 
                setStylePath(cdnFolder + "/accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/stylesheets/" + data.siteCode + ".css");                
                break;
            case "GetPageVariant":
                //var headerXPagination = JSON.parse(header['x-pagination']);
                //setPaginationInfo((...paginationInfo) => { return { itemsPerPage: headerXPagination.itemsPerPage, totalPages: headerXPagination.totalPages, totalRecords: headerXPagination.totalCount, hasNext: headerXPagination.hasNext, hasPrevious: headerXPagination.hasPrevious } });
                //setPageLoaded
                //setPageInfo((...pageInfo) => { return { data: data, loading: false, error: null } });

                setFormData((...formData) => { return data[0]});
                setElements((...elements) => { return JSON.parse(data[0].components) });
                setVariantId(data[0].variantId);
                getMasterPageList();
                getMasterPageInfo(data[0].sitePageId);
                renderNow();
                //setElements(data);
                break;
            case "GetMasterPageInfo":
                for (var i = 0; i < data.length; i++) {
                    data[i].components = JSON.parse(data[i].components);
                }
                setMasterInfo(data);
                break;
            case "SaveForm":
                var tmpSiteInfo = { ...siteInfo };
                tmpSiteInfo.fontList = JSON.stringify(fontListRef.current);
                tmpSiteInfo.styleList = JSON.stringify(siteInfo.styleList);


                BAFetchData({
                    callName: "SaveSite", method: "PUT", url: "cms/sites/update",
                    token: "fg1234", body: tmpSiteInfo, onSuccess: onAPISuccess, onError: onAPIError
                });

                var tmpPageInfo = { ...pageInfo };
                tmpPageInfo.dataSources = JSON.stringify(tmpPageInfo.dataSources);
                BAFetchData({
                    callName: "SavePageInfo", method: "PUT", url: "cms/sitepages/update",
                    token: "fg1234", body: tmpPageInfo, onSuccess: onAPISuccess, onError: onAPIError
                });

                setPageSaveSnackbarOpen(true);
                break;
            case "AddPage":                               
                setPageSaveSnackbarOpen(true);
                handleOnPageItemClick(data.sitePageId, data,'Page');
                break;
            case "GetMasterPageList":
                setMasterPageList(data);
                break;
            case "DeleteRecord":
                //resetResults(); //Reset search results and clear records selected.               
                //var msg = "File has been deleted.";
                //setGenericDialogSchema({ title: 'File Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                //setOpenDialog(true);
                break;
            case "DuplicatePage":
                alert("Page has been duplicated")

                renderNow();
                break;
            case "CreateSectionTemplate":
                hideHighlighters();
                var msg = "Section template has been saved. You can now access the template under sections in the left panel.";
                setGenericDialogSchema({ title: 'Section Template Saved', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
            case "GetSectionTemplate":
                insertSectionTemplate(data, packageObj, null);
                break;
            case "GetTemplateSectionForAi":
                if (data.length > 0) {
                    //var templateRecord = data[0];
                    //"section", "container", "template", section.templateId

                    var rootId = '';
                    if (updateAiSectionsRef.current == false) {
                        if (masterInfo.length > 0) {
                            rootId = pageRootElementIdRef.current;
                        } else {
                            rootId = helpers.getRootElementId(elements);
                        }                        
                    } else {
                        if (masterInfo.length > 0) {
                            rootId = pageRootElementIdRef.current;
                        } else {
                            rootId = helpers.getRootElementId(elementsRef.current);
                        }                        
                    }
                    var tmpPackageObj = { widgetName: "section", targetId: rootId, siblingId: null, positionTarget: 'lastchild' }
                    //var tmpPackageObj = { widgetName: "section", targetId: "fpgwidget_root", siblingId: null, positionTarget: 'lastchild' }
                    //getTemplateData(templateRecord.templateId, tmpPackageObj);
                    var aiSectionData = packageObj;
                    insertSectionTemplate(data[0], tmpPackageObj, aiSectionData);
                }
                break;
            case "GetBARecord":
                //setOverview(newValue);       
                var tmpMessageList = defaultAiTraining(currentUser);
                tmpMessageList.push({ "role": "system", "content": data.aiTraining });
                if (pageInfo.purpose != null) {

                    tmpMessageList.push({ "role": "system", "content": "Page Purpose: " + pageInfo.purpose + ". But relevant to our offer and summary."});
                }                
                tmpMessageList.push({ "role": "system", "content": pageInfo.aiTraining });
                setMessageList(tmpMessageList);
                buildPrompts(tmpMessageList);
                break;
            case "GetPurposeList":
                setPurposeList(data);
                break;
            case "GetImagesForVariable":
                //console.log("images", data);
                var img = data.photos[0].source.landscape;

                var elementObj = null;
                var tmpElements = { ...elements };
                for (const key in tmpElements) {
                    elementObj = tmpElements[key];
                    switch (elementObj.elementName) {
                        case 'section':
                            for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                if (elementObj.aiBGImages[i].variable == packageObj) {
                                    elementObj.aiBGImages[i].answer = img;
                                    break;
                                }
                            }
                            break;
                        case 'image':
                            if (elementObj.aiImage.variable == packageObj) {
                                elementObj.aiImage.answer = img;
                                break;
                            }
                            break;
                    }
                }
                setElements(tmpElements);
                setShowLoader(false);
                break;
            case "GetImages":
                //console.log("images", data);
                var img = data.photos[0].source.landscape;

                var elementObj = null;
                var tmpElements = { ...elements };
                for (const key in tmpElements) {
                    elementObj = tmpElements[key];
                    switch (elementObj.elementName) {                       
                        case 'image':
                            elementObj.src = img;
                            break;
                        case 'section':
                            for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                if (elementObj.aiBGImages[i].variable != '' && elementObj.aiBGImages[i].prompt != '') {
                                    elementObj.aiBGImages[i].answer = img;
                                }                                
                            }
                            //elementObj.aiBGImages
                            //var test = "";
                            break;
                    }
                }
                setElements(tmpElements);
                break;
            case "GetRemotePageInfo":
                if (data.sitePageId == undefined) {
                    var newPageInfo = { "sitePageId": autoItemSummary.aoId, "name": autoItemSummary.pageName, "slug": autoItemSummary.slug, "pageType": 'p', "masterPage": '', "automationId":buildModeId }
                    handlePageAdded(newPageInfo);
                } else {
                    handleOnPageItemClick(data.sitePageId, data, 'Page');
                }                
                break;
            case "GetRemoteEmailPageInfo":
                if (data == '') {
                    var newPageInfo = {};
                    handlePageAdded(newPageInfo);
                } else {
                    setPageSaveSnackbarOpen(true);
                    handleOnPageItemClick(data.autoObjectId, data, 'Email');
                }
                break;
            case "AddEmailLayout":
                setPageSaveSnackbarOpen(true);
                handleOnPageItemClick(data.autoObjectId, data, 'Email');
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleAddPage() {
        var msg = '';
        setGenericDialogSchema({ title: 'New Page', component: (dialogType, setReturnValue) => <NewSitePage handlerReturnValue={dialogType, setReturnValue}></NewSitePage>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handlePageAdded(returnValues); }, ignoreESCKey: true, ignoreBGClick: true })
        setOpenDialog(true);
    }

    function handlePageAdded(returnValues) {       
        if (buildMode == undefined || buildMode == 'Automation') {
            var formData = { sitePageId: returnValues.sitePageId, baid: 0, siteId: siteId, name: returnValues.name, slug: returnValues.slug, pageType: returnValues.pageType, masterPage: returnValues.masterPage, dateCreated: new Date().toISOString(), automationId:returnValues.automationId };
            BAFetchData({
                callName: "AddPage", method: "POST", url: "cms/sitepages/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else if (buildMode == 'Automation-Email') {
            var formData = {autoObjectId: autoItemSummary.aoId, automationId: buildModeId, baid: 0, components: JSON.stringify({}), emailInfo: JSON.stringify({})};
            BAFetchData({
                callName: "AddEmailLayout", method: "POST", url: "automation/automationobject/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }


    function renderNow() {
        setRenderNum(helpers.getUUID());
    }

    function changeTab(tabName) {
        setPanelTab(tabName);
    }

    function preview() {
        window.open(siteInfo.prodUrl, 'preview');
    }

    function publish() {
        setShowLoader(true);
        setTimeout(() => {
            var results = PageGenerator.GenerateSitePages(siteId, siteInfo, orderedBreakpoints, siteInfo.mobileFirst, () => { onPublishCompleted(); });        
        }, "50");
    }

    function onPublishCompleted() {
        setShowLoader(false);
        setPublishSnackbarOpen(true);
        preview();
    }

    function onPageNameChangeNoUpdate(e, pageName) {
        pageName = helpers.pageNameUrlFormatter(pageName);
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.name = pageName;
        //tmpPageInfo.name = pageName.toLowerCase();
        setPageInfo((...pageInfo) => { return tmpPageInfo });
        //setUpdatePage(true);
    }


    function onPageNameOnBlur(e, pageName) {
        pageName = helpers.pageNameUrlFormatter(pageName);
        var tmpPageInfo = { ...pageInfo };
        //tmpPageInfo.name = pageName.toLowerCase();
        tmpPageInfo.name = pageName;
        setPageInfo((...pageInfo) => { return tmpPageInfo });
        setUpdatePage(true);
    }

    function onMasterPageChange(e, name) {
        masterPageChangedRef.current = true;
        var tmpElements = {};
        tmpElements = removeMasterInfo({ ...elements });
        setElements(tmpElements);
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.masterPage = name.toLowerCase();
        setPageInfo((...pageInfo) => { return tmpPageInfo });
        setUpdatePage(true);
        
    }

    function duplicatePage() {
        if (pageInfo == null) {
            var msg = 'You must select a page to duplicate';
            setGenericDialogSchema({ title: 'Duplicate Page Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        } else {

            var pageElements = removeMasterInfo({ ...elements });

            var clonedElements = JSON.stringify({ ...pageElements });

            

            for (const key in elements) {
                if (key.indexOf('root') == -1) {
                    var newId = 'fpgwidget_' + helpers.getUUID();
                    clonedElements = clonedElements.replaceAll(key, newId);
                }
            }
            var newSitePageId = helpers.getUUID();
            var newVariantId = helpers.getUUID();


            var tmpFormVariantData = { ...formData };
            tmpFormVariantData.siteId = siteId;
            tmpFormVariantData.sitePageId = newSitePageId;
            tmpFormVariantData.variantId = newVariantId;
            tmpFormVariantData.components = clonedElements;
            tmpFormVariantData.dateCreated = new Date().toISOString();
            tmpFormVariantData.createdBy = 0;
            tmpFormVariantData.modifiedBy = '0';
            tmpFormVariantData.dateModified = null;

            var tmpFormPageData = { ...pageInfo };
            tmpFormPageData.siteId = siteId;
            tmpFormPageData.sitePageId = newSitePageId;
            tmpFormPageData.nextVariantId = newVariantId;
            tmpFormPageData.name = pageInfo.name + '_' + helpers.getUUID();
            tmpFormPageData.slug = tmpFormPageData.name;
            tmpFormPageData.dateCreated = new Date().toISOString();            
            tmpFormPageData.createdBy = 0;
            tmpFormPageData.modifiedBy = '0';
            tmpFormPageData.dateModified = null;
            tmpFormPageData.pageVariants.push(tmpFormVariantData);
            tmpFormPageData.dataSources = JSON.stringify(tmpFormPageData.dataSources);

            BAFetchData({
                callName: "AddPage", method: "POST", url: "cms/sitepages/add",
                token: "fg1234", body: JSON.stringify(tmpFormPageData), onSuccess: onAPISuccess, onError: onAPIError
            });


            //BAFetchData({
            //    callName: "DuplicateVariant", method: "POST", url: "cms/pagevariants/add",
            //    token: "fg1234", body: tmpFormVariantData, onSuccess: onAPISuccess, onError: onAPIError
            //});

            //BAFetchData({
            //    callName: "SaveSite", method: "PUT", url: "cms/sites/update",
            //    token: "fg1234", body: siteInfo, onSuccess: onAPISuccess, onError: onAPIError
            //});
        }
    }

    function openPageSettings() {
        var msg = '';
        setGenericDialogSchema({
            title: 'Page Settings', component: (dialogType, setReturnValue) => <PageSettings pageInfo={pageInfo} setPageInfo={setPageInfo} handlerReturnValue={dialogType, setReturnValue}></PageSettings>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 1200 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handlePageInfoChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true
        })
        setOpenDialog(true);
    }

    function openPageCssEditor() {
        var msg = '';
        setGenericDialogSchema({
            title: 'Page CSS Editor', component: (dialogType, setReturnValue) => <PageCssEditor cssText={pageInfo.css || ''} handlerReturnValue={dialogType, setReturnValue}></PageCssEditor>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 1200 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handlePageCssChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true
        })
        setOpenDialog(true);
    }

    function handlePageInfoChanged(returnValues) {
        setPageInfo(returnValues);
    }

    function handlePageCssChanged(returnValues) {
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.css = returnValues;
        setPageInfo(tmpPageInfo);
    }

    function openJavascriptEditor() {
        var msg = '';
        setGenericDialogSchema({
            title: 'Javascript Editor', component: (dialogType, setReturnValue) => <JavascriptEditor javascriptText={pageInfo.javascript || ''} handlerReturnValue={dialogType, setReturnValue}></JavascriptEditor>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 1200 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleJavascriptChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true })
        setOpenDialog(true);
    }

    function handleJavascriptChanged(returnValues) {
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.javascript = returnValues;
        setPageInfo(tmpPageInfo);
    }

    function openStyleSheet() {
        var msg = '';
        setGenericDialogSchema({ title: 'Stylesheet', component: (dialogType, setReturnValue) => <StyleSheet handlerReturnValue={dialogType, setReturnValue}></StyleSheet>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 1200 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleStylesheetChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true })
        setOpenDialog(true);
    }    

    function handleStylesheetChanged(returnValues) {
        var tmpSiteInfo = { ...siteInfo };
        tmpSiteInfo.styleSheet = returnValues;
        setSiteInfo(tmpSiteInfo);
        setStylePath(process.env.REACT_APP_CDN_BASE_URL + "/accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/stylesheets/" + tmpSiteInfo.siteCode + ".css?t=" + helpers.getUUID());
        //setStylePath('http://localhost/cdn/accountfiles/1/stylesheets/site0001.css?t=' + helpers.getUUID());
        //renderNow();
    }

    function showActiveTab(tabName) {
        var className = '';
        if (panelTab == tabName) {
            className = 'fg-pb-panel-tab-active';
        }
        return className;
    }

    function handleMergeVariables(value, isJson, collectionItemContext, rowIndex, parentCollectionListRowIndex) {
        if (isJson == true) {
            value = JSON.stringify(value);
        }
        if (value != '%%~') {
            if (pageInfo.dataSources != null) {
                if (pageInfo.dataSources.length > 0) {

                    var regexp = /({eval\()(.*)[}]/gm;
                    var evalMatches = value.matchAll(regexp);

                    var parentDataSource = null;

                    for (const evalMatch of evalMatches) {
                        var evalMatchValue = evalMatch[0];

                        //check match for variables
                        regexp = /(%%)([a-zA-Z._(0-9)-]*)[~]/gm;
                        var varMatches = evalMatchValue.matchAll(regexp);


                        var parentDataSource = null;
                        for (const varMatch of varMatches) {
                            if (collectionItemContext != null) {
                                parentDataSource = helpers.getParentDataSource(elements, pageInfo.dataSources, collectionItemContext, null, parentCollectionListRowIndex);
                            }

                            var keys = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.');
                            var sourceName = null;
                            var propertyName = null;
                            var variableType = null;
                            if (keys.length == 2) {
                                sourceName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                                propertyName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                            } else if (keys.length == 3) {
                                variableType = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                                sourceName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                                propertyName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                            } else if (keys.length == 5) {
                                variableType = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                                sourceName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                                propertyName = varMatch[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                            }
                            if (keys.length == 5) {
                                var test = "test";
                                test = "water";
                            }

                            if (variableType == 'paginationInfo') {
                                var test = "test";
                                test = "water";
                            }
                            var varDataValue = '';
                            switch (keys.length) {
                                case 2:
                                    if (parentDataSource != null) {
                                        var valObj = parentDataSource.properties;
                                        if (valObj.hasOwnProperty(propertyName) == true) {
                                            varDataValue = valObj[propertyName];
                                        }
                                    } else {
                                        parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                        var valObj = parentDataSource.properties;
                                        if (valObj.hasOwnProperty(propertyName) == true) {
                                            varDataValue = valObj[propertyName];
                                        }
                                    }

                                    if (varDataValue == null) {
                                        varDataValue = '';
                                    }
                                    break;
                                case 3:
                                    switch (variableType) {
                                        case "row":
                                            if (parentDataSource != null) {
                                                if (propertyName != 'csvvalue') {
                                                    if (parentDataSource.hasOwnProperty('rows') == true) {
                                                        if (parentDataSource.rows[rowIndex].hasOwnProperty(propertyName) == true) {
                                                            varDataValue = parentDataSource.rows[rowIndex][propertyName];
                                                        }
                                                    } else {
                                                        if (parentDataSource[rowIndex].hasOwnProperty(propertyName) == true) {
                                                            varDataValue = parentDataSource[rowIndex][propertyName];
                                                        }
                                                    }
                                                } else if (propertyName == 'csvvalue') {
                                                    varDataValue = parentDataSource[rowIndex];
                                                }
                                            }
                                            //if (dataSource.relationInfo != "" && dataSource.relationInfo != undefined) {
                                            //    const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSource.relationInfo);
                                            //    var parentDataSource = helpers.getDataSource(toDataSourceName, pageInfo.dataSources);
                                            //    var filterValue = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
                                            //    var dataView = helpers.filterDataSource(dataSource, filterValue, fromFieldName);
                                            //    dataValue = dataView[rowIndex][propertyName];

                                            //} else {
                                            //    dataValue = parentDataSource.rows[rowIndex][propertyName];
                                            //}
                                            break;
                                        case 'paginationInfo':
                                            if (parentDataSource == null) {
                                                parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                            }
                                            varDataValue = parentDataSource.paginationInfo[propertyName];
                                            break;
                                    }
                                    break;
                                case 5:
                                    break;
                            }
                            evalMatchValue = evalMatchValue.replaceAll(varMatch[0], '\'' + varDataValue + '\'');
                            //value = value.replaceAll(varMatch[0], varDataValue);

                        }

                        //var myDate = dayjs('2023-01-15').format('MM/DD/YYYY');
                        var js = evalMatchValue.replaceAll('{eval(', '').replaceAll(')}', '');
                        var evalDataValue = eval(js);
                        value = value.replaceAll(evalMatch[0], evalDataValue);
                    }

                    regexp = /(%%)([a-zA-Z._(0-9)-]*)[~]/gm;
                    var matches = value.matchAll(regexp);

                    var parentDataSource = null;
                    for (const match of matches) {
                        if (collectionItemContext != null) {
                            parentDataSource = helpers.getParentDataSource(elements, pageInfo.dataSources, collectionItemContext, null, parentCollectionListRowIndex);
                        }

                        var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');
                        var sourceName = null;
                        var propertyName = null;
                        var variableType = null;
                        if (keys.length == 2) {
                            sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                            propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                        } else if (keys.length == 3) {
                            variableType = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                            sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                            propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                        } else if (keys.length == 5) {
                            variableType = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                            sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                            propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                        }
                        if (keys.length == 5) {
                            var test = "test";
                            test = "water";
                        }

                        if (variableType == 'paginationInfo') {
                            var test = "test";
                            test = "water";
                        }
                        var dataValue = '';
                        switch (keys.length) {
                            case 2:
                                if (parentDataSource != null) {
                                    var valObj = parentDataSource.properties;
                                    if (valObj.hasOwnProperty(propertyName) == true) {
                                        dataValue = valObj[propertyName];
                                    }
                                } else {
                                    parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                    if (parentDataSource != null) {
                                        var valObj = parentDataSource.properties;
                                        if (valObj.hasOwnProperty(propertyName) == true) {
                                            dataValue = valObj[propertyName];
                                        }
                                    }
                                }

                                if (dataValue == null) {
                                    dataValue = '';
                                }
                                break;
                            case 3:
                                switch (variableType) {
                                    case "row":
                                        if (parentDataSource != null) {
                                            if (propertyName != 'csvvalue') {
                                                if (parentDataSource.hasOwnProperty('rows') == true) {
                                                    if (parentDataSource.rows[rowIndex].hasOwnProperty(propertyName) == true) {
                                                        dataValue = parentDataSource.rows[rowIndex][propertyName];
                                                    }
                                                } else {
                                                    if (parentDataSource[rowIndex].hasOwnProperty(propertyName) == true) {
                                                        dataValue = parentDataSource[rowIndex][propertyName];
                                                    }
                                                }
                                            } else if (propertyName == 'csvvalue') {
                                                dataValue = parentDataSource[rowIndex];
                                            }
                                        }
                                        //if (dataSource.relationInfo != "" && dataSource.relationInfo != undefined) {
                                        //    const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSource.relationInfo);
                                        //    var parentDataSource = helpers.getDataSource(toDataSourceName, pageInfo.dataSources);
                                        //    var filterValue = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
                                        //    var dataView = helpers.filterDataSource(dataSource, filterValue, fromFieldName);
                                        //    dataValue = dataView[rowIndex][propertyName];

                                        //} else {
                                        //    dataValue = parentDataSource.rows[rowIndex][propertyName];
                                        //}
                                        break;
                                    case 'paginationInfo':
                                        if (parentDataSource == null) {
                                            parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                        }
                                        dataValue = parentDataSource.paginationInfo[propertyName];
                                        break;
                                }
                                break;
                            case 5:
                                break;
                        }
                        value = value.replaceAll(match[0], dataValue);
                    }
                }
            }
        }

        if (isJson == false) {
            return value;
        } else {
            return JSON.parse(value);
        }
    }

    function handleMergeVariables_Old(value, isJson, collectionItemContext, rowIndex, parentCollectionListRowIndex) {
        if (isJson == true) {
            value = JSON.stringify(value);
        }
        if (value != '%%~') {
            if (pageInfo.dataSources != null) {
                const regexp = /(%%)([a-zA-Z._(0-9)-]*)[~]/gm;
                const matches = value.matchAll(regexp);

                var parentDataSource = null;
                for (const match of matches) {
                    if (collectionItemContext != null) {
                        parentDataSource = helpers.getParentDataSource(elements, pageInfo.dataSources, collectionItemContext, null, parentCollectionListRowIndex);
                    }

                    var keys = match[0].replaceAll("%%", "").replaceAll("~", "").split('.');
                    var sourceName = null;
                    var propertyName = null;
                    var variableType = null;
                    if (keys.length == 2) {
                        sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                        propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                    } else if (keys.length == 3) {
                        variableType = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                        sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                        propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                    } else if (keys.length == 5) {
                        variableType = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[1];
                        sourceName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[0];
                        propertyName = match[0].replaceAll("%%", "").replaceAll("~", "").split('.')[2];
                    }
                    if (keys.length == 5) {
                        var test = "test";
                        test = "water";
                    }

                    if (variableType == 'paginationInfo') {
                        var test = "test";
                        test = "water";
                    }
                    var dataValue = '';
                    switch (keys.length) {
                        case 2:
                            if (parentDataSource != null) {
                                var valObj = parentDataSource.properties;
                                dataValue = valObj[propertyName];
                            } else {
                                parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                var valObj = parentDataSource.properties;
                                dataValue = valObj[propertyName];
                            }

                            if (dataValue == null) {
                                dataValue = '';
                            }
                            break;
                        case 3:
                            switch (variableType) {
                                case "row":
                                    if (parentDataSource != null) {
                                        if (propertyName != 'csvvalue') {
                                            if (parentDataSource.hasOwnProperty('rows') == true) {
                                                dataValue = parentDataSource.rows[rowIndex][propertyName];
                                            } else {
                                                dataValue = parentDataSource[rowIndex][propertyName];
                                            }
                                        } else if (propertyName == 'csvvalue') {
                                            dataValue = parentDataSource[rowIndex];
                                        }
                                    }
                                    //if (dataSource.relationInfo != "" && dataSource.relationInfo != undefined) {
                                    //    const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSource.relationInfo);
                                    //    var parentDataSource = helpers.getDataSource(toDataSourceName, pageInfo.dataSources);
                                    //    var filterValue = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
                                    //    var dataView = helpers.filterDataSource(dataSource, filterValue, fromFieldName);
                                    //    dataValue = dataView[rowIndex][propertyName];

                                    //} else {
                                    //    dataValue = parentDataSource.rows[rowIndex][propertyName];
                                    //}
                                    break;
                                case 'paginationInfo':
                                    if (parentDataSource == null) {
                                        parentDataSource = helpers.getDataSource(sourceName, pageInfo.dataSources);
                                    }
                                    dataValue = parentDataSource.paginationInfo[propertyName];
                                    break;
                            }
                            break;
                        case 5:
                            break;
                    }
                    value = value.replaceAll(match[0], dataValue);
                    //check for eval

                    const regexp = /({eval\()(.*)[}]/gm;
                    const matches = value.matchAll(regexp);

                    var parentDataSource = null;
                    for (const match of matches) {
                        //var myDate = dayjs('2023-01-15').format('MM/DD/YYYY');
                        var js = match[0].replaceAll('{eval(', '').replaceAll(')}', '').replace(dataValue, '\'' + dataValue + '\'');
                        dataValue = eval(js);
                        value = value.replaceAll(match[0], dataValue);
                    }

                    //for (var dsi = 0; dsi < pageInfo.dataSources.length; dsi++) {
                    //    var dataSource = pageInfo.dataSources[dsi]
                    //    if (dataSource.sourceName == sourceName) {
                    //        var valObj = dataSource.properties;
                    //        var dataValue = '';
                    //        if (keys.length == 2) {
                    //            dataValue = valObj[propertyName];
                    //            if (dataValue == null) {
                    //                dataValue = '';
                    //            }
                    //        } if (keys.length == 3) {
                    //            if (dataSource.rows.length > rowIndex) {
                    //                switch (variableType) {
                    //                    case 'row':
                    //                        if (dataSource.relationInfo != "" && dataSource.relationInfo != undefined) {
                    //                            const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSource.relationInfo);
                    //                            var parentDataSource = helpers.getDataSource(toDataSourceName, pageInfo.dataSources);
                    //                            var filterValue = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
                    //                            var dataView = helpers.filterDataSource(dataSource, filterValue, fromFieldName);
                    //                            dataValue = dataView[rowIndex][propertyName];

                    //                        } else {
                    //                            dataValue = dataSource.rows[rowIndex][propertyName];
                    //                        }


                    //                        break;
                    //                    case 'page':
                    //                        dataValue = dataSource.paginationInfo[propertyName];
                    //                        break;
                    //                }
                    //            }
                    //            if (dataValue == null) {
                    //                dataValue = '';
                    //            }
                    //        } if (keys.length == 5) {
                    //            if (dataSource.rows.length > parentCollectionListRowIndex) {
                    //                switch (variableType) {
                    //                    case 'row':
                    //                        //if (dataSource.relationInfo != "" && dataSource.relationInfo != undefined) {
                    //                        //    const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSource.relationInfo);
                    //                        //    var parentDataSource = helpers.getDataSource(toDataSourceName, pageInfo.dataSources);
                    //                        //    var filterValue = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
                    //                        //    var dataView = helpers.filterDataSource(dataSource, filterValue, fromFieldName);
                    //                        //    dataValue = dataView[rowIndex][propertyName];

                    //                        //} else {
                    //                        var list = dataSource.rows[parentCollectionListRowIndex][propertyName].split(',');
                    //                            var arrayVariableType = match[0].replaceAll("{", "").replaceAll("}", "").split('.')[3];
                    //                            var arraySourceName = match[0].replaceAll("{", "").replaceAll("}", "").split('.')[2];
                    //                            var arrayPropertyName = match[0].replaceAll("{", "").replaceAll("}", "").split('.')[4];

                    //                            dataValue = list[rowIndex];
                    //                        //}


                    //                        break;
                    //                    case 'page':
                    //                        dataValue = dataSource.paginationInfo[propertyName];
                    //                        break;
                    //                }
                    //            }
                    //            if (dataValue == null) {
                    //                dataValue = '';
                    //            }
                    //        }

                    //        //for (var ki = 0; ki < keys.length; ki++) {
                    //        //    valObj = valObj[keys[ki]];
                    //        //}
                    //        //value = value.replaceAll(match[0], dataSource.properties[propertyName]);
                    //        value = value.replaceAll(match[0], dataValue);
                    //        break;
                    //    }
                    //}
                }
            }
        }

        if (isJson == false) {
            return value;
        } else {
            return JSON.parse(value);
        }
    }

    function intFormatDate(date, dateFormat) {
        var formattedDate = dayjs(date).format(dateFormat);
        return formattedDate;
    }

    function loadImages() {
        BAFetchData({
            callName: "GetImages",
            method: "GET",
            url: "cms/sitepages/searchimages?query=" + imagePrompt + "&orientation=&color=",
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getPurposeRecord(purposeName) {
        for (var i = 0; i < purposeList.length; i++) {
            if (purposeList[i].purposeName == purposeName) {
                return purposeList[i];
                break;
            }
        }
    }

    function reorderAiSections() {
        var children = []
        sectionOrderRef.current.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0))
        for (var i = 0; i < sectionOrderRef.current.length; i++) {
            children.push(sectionOrderRef.current[i].sectionId)
        }
        var tmpElements = { ...elementsRef.current };
        var rootId = null;
        if (masterInfo.length > 0) {
            rootId = pageRootElementIdRef.current;
        } else {
            rootId = helpers.getRootElementId(tmpElements);
        }
        
        tmpElements[rootId].children = children;
        setElements(tmpElements);
        sectionOrderRef.current = [];
    }

    function delay(milliseconds) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    async function rebuildSections() {       

        elementsRef.current = { ...elements };
        var counter = parseInt(0);
        

        updateAiSectionsRef.current = true;
        var rootId = null;
        if (masterInfo.length > 0) {
           rootId = pageRootElementIdRef.current;
        } else {
           rootId = helpers.getRootElementId(elements);
        }

        

        var root = elements[rootId];
        root.children = [];
        root.parents = [];

        var currentRootId = null;
        if (masterInfo.length > 0) {
            currentRootId = pageRootElementIdRef.current;
        } else {
            currentRootId = helpers.getRootElementId(elementsRef.current);
        }
        
        if (currentRootId != null) {
            elementsRef.current[currentRootId] = root;
        } else {
            elementsRef.current[rootId] = root;
        }

        var purposeInfo = getPurposeRecord(pageInfo.purpose);
        var settingsJson = JSON.parse(purposeInfo.settings);

        for (var i = 0; i < settingsJson.sections.length; i++) {


            counter++; 
            totalToProcessRef.current = counter;

            var section = settingsJson.sections[i];
            var conditions = [];
            conditions.push({ "Name": "category", "ConditionOperator": "EQUAL", "Value": section.sectionCategory.replaceAll('_', ' '), "DataType": "String" });
            conditions.push({ "Name": "templateName", "ConditionOperator": "EQUAL", "Value": section.templateName, "DataType": "String" });
            if (section.keyword != '') {
                conditions.push({ "Name": "keywords", "ConditionOperator": "CONTAINS", "Value": section.keyword, "DataType": "String" });
            }
            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": conditions
                }]
            };

            BAFetchData({
                callName: "GetTemplateSectionForAi",
                method: "GET",
                url: "template/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234",
                body: formData, onSuccess: onAPISuccess, onError: onAPIError, packageObj: settingsJson.sections[i]
            });

            
        }
    }

    function generateAiContent() {
        if (pageInfo.aiTraining != '') {
            BAFetchData({
                callName: "GetBARecord",
                method: "GET",
                url: "security/businessaccount/getrecord?baid=" + currentUser.currentBusinessAccountDTO.baid,
                token: "fg1234",
                body: formData, onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            var msg = "";
            msg += "<p>Please provide the following:";
            msg += "    <ul>";
            msg += "        <li>Company overview. If you provided an overview on the site record then this is not necessary.</li>";
            msg += "        <li>Offer overview. (optional depending on the intent of this page)</li>";
            msg += "        <li>5 to 10 benefits for your offer (if offer overview is provided).</li>";
            msg += "        <li>5 to 10 pain points that your prospects are experiencing because they don't have your offer (if offer overview is provided).</li>";
            msg += "        <li>Offer price (if offer overview is provided).</li>";
            msg += "        <li>Main objective for this page.</li>";
            msg += "    </ul>";
            msg += "</p>"
            setGenericDialogSchema({ title: 'Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function addElementPromptToSection(section, childId, tmpElements, imagesNeeded) {
        var childElementObj = tmpElements[childId];

        switch (childElementObj.elementName) {
            case 'heading':
            case 'text':
            case 'button':
                if (childElementObj.aiText.variable != '') {
                    section.prompts.push({ promptType: "element", elementId: childId, name: childElementObj.aiText.variable, prompt: childElementObj.aiText.prompt, answer: "", accept: true });
                }
                break;
            case 'image':
                if (childElementObj.hasOwnProperty('aiImage') == true) {
                    if (childElementObj.aiImage != null) {
                        if (childElementObj.aiImage.variable != '') {
                            imagesNeeded++;
                        }
                    }
                }
                break;
        }
        for (var ci = 0; ci < childElementObj.children.length; ci++) {
            childId = childElementObj.children[ci];
            addElementPromptToSection(section, childId, tmpElements, imagesNeeded);
        }
    }

    function buildPrompts(tmpMessageList) {
        var tmpPromptPkg = { ...promptPkg };
        var prompts = [];
        var tmpElements = { ...elements };
        var elementObj = null;
        var imagesNeeded = 0;

        var sections = [];
        var section = null;
        var childId = null;
        var counter = 0;
        for (const key in tmpElements) {
            if (tmpElements[key].elementName == 'section') {
                if (tmpElements[key].purpose != undefined) {


                    elementObj = tmpElements[key];
                    section = { sectionName: elementObj.name, elementId: elementObj.id, purpose: elementObj.purpose, sectionOverview: elementObj.aiPrompt, prompts: [] }
                    if (elementObj.hasOwnProperty('aiBGImages') == true) {
                        if (elementObj.aiBGImages != null) {
                            for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                if (elementObj.aiBGImages[i].variable != '') {
                                    imagesNeeded++;
                                }
                            }
                        }
                    }
                    for (var ci = 0; ci < elementObj.children.length; ci++) {
                        childId = elementObj.children[ci];
                        addElementPromptToSection(section, childId, tmpElements, imagesNeeded);
                    }
                    //Only send if there are prompts within section.
                    if (section.prompts.length > 0) {
                        counter++;
                        sections.push(section);

                        totalSectionPromptsRef.current = counter;

                        setShowLoader(true);
                        tmpPromptPkg = { sections: sections, promptType: "Page" };
                        setPromptPkg(tmpPromptPkg);
                        var test = "test";
                        test = "hello";
                        sendAiPrompt(JSON.stringify(tmpPromptPkg), tmpMessageList, setMessageList, (returnValue) => { loadAiAnswers(returnValue); });
                    }
                    sections = [];
                }
            };
        }


        //if (imagesNeeded > 0) {
        //    imagesNeeded = imagesNeeded * 2;
        //    var pluralGrammer = "";
        //    if (imagesNeeded > 1) {
        //        pluralGrammer = "s";
        //    }
        //    prompts.push({ name: "imageKeywords", prompt: "Give me " + imagesNeeded + " keyword" + pluralGrammer + " that I can use to search for images in a database. The keywords should be 1 to 3 words long and should be relevant to the product offer. Provide answer as a JSON array.", answer: "", accept: true });
        //}
        
        //setShowLoader(true);
        //tmpPromptPkg = { sections: sections, promptType: "Page" };
        //setPromptPkg(tmpPromptPkg);
        //sendAiPrompt(JSON.stringify(tmpPromptPkg), tmpMessageList, setMessageList, (returnValue) => { loadAiAnswers(returnValue); });
    }

    function buildPrompts_Old(tmpMessageList) {
        var prompts = [];
        var tmpElements = { ...elements };
        var elementObj = null;
        var imagesNeeded = 0;

        for (const key in tmpElements) {
            elementObj = tmpElements[key];
            switch (elementObj.elementName) {
                case 'heading':
                case 'text':
                case 'button':
                    if (elementObj.aiText.variable != '') {
                        prompts.push({ name: elementObj.aiText.variable, prompt: elementObj.aiText.prompt, answer: "", accept: true });
                    }
                    break;
                case 'section':
                    if (elementObj.hasOwnProperty('aiBGImages') == true) {
                        if (elementObj.aiBGImages != null) {
                            for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                if (elementObj.aiBGImages[i].variable != '') {
                                    imagesNeeded++;
                                }
                            }
                        }
                    }
                    break;
                case 'image':
                    if (elementObj.hasOwnProperty('aiImage') == true) {
                        if (elementObj.aiImage != null) {
                            if (elementObj.aiImage.variable != '') {
                                imagesNeeded++;
                            }
                        }
                    }
                    break;
            }
        }
        if (imagesNeeded > 0) {
            imagesNeeded = imagesNeeded * 2;
            var pluralGrammer = "";
            if (imagesNeeded > 1) {
                pluralGrammer = "s";
            }
            prompts.push({ name: "imageKeywords", prompt: "Give me " + imagesNeeded + " keyword" + pluralGrammer + " that I can use to search for images in a database. The keywords should be 1 to 3 words long and should be relevant to the product offer. Provide answer as a JSON array.", answer: "", accept: true });
        }
        setShowLoader(true);
        setPrompts(prompts);
        sendAiPrompt(JSON.stringify(prompts), tmpMessageList, setMessageList, (returnValue) => { loadAiAnswers(returnValue); });
    }

    function toggleAiText(useAiText) {
        var elementObj = null;
        var tmpElements = { ...elements };
        for (const key in tmpElements) {
            elementObj = tmpElements[key];
            switch (elementObj.elementName) {
                case 'heading':
                case 'text':
                case 'button':
                    if (elementObj.aiText.variable != '' && elementObj.aiText.prompt != '' && elementObj.aiText.answer != '') {
                        elementObj.aiText.accept = useAiText.toString();
                    }
                    break;
                case 'section':
                    for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                        if (elementObj.aiBGImages[i].variable != '' && elementObj.aiBGImages[i].prompt != '' && elementObj.aiBGImages[i].answer != '') {
                            elementObj.aiBGImages[i].accept = useAiText.toString();
                        }
                    }
                    break;
                case 'image':
                    if (elementObj.aiImage.variable != '' && elementObj.aiImage.prompt != '' && elementObj.aiImage.answer != '') {
                        elementObj.aiImage.accept = useAiText.toString();
                    }
                    break;
            }
        }
        setElements(tmpElements);
    }

    function loadAiAnswers(returnValues) {
        var counter = totalSectionPromptsProcessedRef.current;
        counter++;
        totalSectionPromptsProcessedRef.current = counter;

        var imageIndex = -1;
        var tmpPromptPkg = { ...returnValues };
        var prompt = null;
        var elementObj = null;
        var tmpElements = { ...elements };
        var sectionObj = null;
        //var found = false;
        if (tmpPromptPkg.promptType == 'Page') {
            for (var si = 0; si < tmpPromptPkg.sections.length; si++) {
                sectionObj = tmpPromptPkg.sections[si];
                for (var pvi = 0; pvi < sectionObj.prompts.length; pvi++) {
                    prompt = sectionObj.prompts[pvi];
                    if (prompt.name != 'imageKeywords') {
                        //found = false;
                        //for (const key in tmpElements) {
                            elementObj = tmpElements[prompt.elementId];
                            //switch (elementObj.elementName) {
                            //    case 'heading':
                            //    case 'text':
                            //    case 'button':
                                    if (elementObj.aiText.variable == prompt.name) {
                                        elementObj.aiText.answer = prompt.answer;
                                        //found = true;
                                    }
                             //       break;
                            //}
                         //   if (found == true) {
                         //       break;
                         //   }
                        //}
                    } else if (prompt.name == 'imageKeywords') {
                        if (typeof prompt.answer == 'string') {
                            prompt.answer = JSON.parse(prompt.answer.replaceAll('\'', '\"'));
                        }

                        for (const key in tmpElements) {
                            elementObj = tmpElements[key];
                            switch (elementObj.elementName) {
                                case 'image':
                                    if (elementObj.hasOwnProperty('aiImage') == true) {
                                        if (elementObj.aiImage != null) {
                                            if (elementObj.aiImage.variable != '') {
                                                imageIndex++;
                                                elementObj.aiImage.prompt = prompt.answer[imageIndex];

                                                BAFetchData({
                                                    callName: "GetImagesForVariable",
                                                    method: "GET",
                                                    url: "cms/sitepages/searchimages?query=" + prompt.answer[imageIndex] + "&orientation=landscape&color=",
                                                    token: "fg1234",
                                                    body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: elementObj.aiImage.variable
                                                });

                                            }
                                        }
                                    }
                                    break;
                                case 'section':
                                    if (elementObj.hasOwnProperty('aiBGImages') == true) {
                                        if (elementObj.aiBGImages != null) {
                                            for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                                if (elementObj.aiBGImages[i].variable != '') {
                                                    imageIndex++;
                                                    elementObj.aiBGImages[i].prompt = prompt.answer[imageIndex];

                                                    BAFetchData({
                                                        callName: "GetImagesForVariable",
                                                        method: "GET",
                                                        url: "cms/sitepages/searchimages?query=" + prompt.answer[imageIndex] + "&orientation=landscape&color=",
                                                        token: "fg1234",
                                                        body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: elementObj.aiBGImages[i].variable
                                                    });

                                                }
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
            setElements(tmpElements);
            if (imageIndex == -1) {
                if (totalSectionPromptsProcessedRef.current == totalSectionPromptsRef.current) {
                    totalSectionPromptsProcessedRef.current = 0;
                    totalSectionPromptsRef.current = 0;
                    setShowLoader(false);
                }
            }
        }
    }

    function loadAiAnswers_Old(returnValues) {
        var imageIndex = -1;
        var tmpPrompts = [...returnValues ];
        var prompt = null;
        var elementObj = null;
        var tmpElements = { ...elements };
        for (var pvi = 0; pvi < tmpPrompts.length; pvi++) {
            prompt = tmpPrompts[pvi];
            if (prompt.name != 'imageKeywords') {
                for (const key in tmpElements) {
                    elementObj = tmpElements[key];
                    switch (elementObj.elementName) {
                        case 'heading':
                        case 'text':
                        case 'button':
                            if (elementObj.aiText.variable == prompt.name) {
                                elementObj.aiText.answer = prompt.answer;
                            }
                            break;
                    }
                }
            } else if (prompt.name == 'imageKeywords') {
                if (typeof prompt.answer == 'string') {
                    prompt.answer = JSON.parse(prompt.answer.replaceAll('\'', '\"'));
                }

                for (const key in tmpElements) {
                    elementObj = tmpElements[key];
                    switch (elementObj.elementName) {
                        case 'image':
                            if (elementObj.hasOwnProperty('aiImage') == true) {
                                if (elementObj.aiImage != null) {
                                    if (elementObj.aiImage.variable != '') {
                                        imageIndex++;
                                        elementObj.aiImage.prompt = prompt.answer[imageIndex];

                                        BAFetchData({
                                            callName: "GetImagesForVariable",
                                            method: "GET",
                                            url: "cms/sitepages/searchimages?query=" + prompt.answer[imageIndex] + "&orientation=landscape&color=",
                                            token: "fg1234",
                                            body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: elementObj.aiImage.variable
                                        });

                                    }
                                }
                            }
                            break;
                        case 'section':
                            if (elementObj.hasOwnProperty('aiBGImages') == true) {
                                if (elementObj.aiBGImages != null) {
                                    for (var i = 0; i < elementObj.aiBGImages.length; i++) {
                                        if (elementObj.aiBGImages[i].variable != '') {
                                            imageIndex++;
                                            elementObj.aiBGImages[i].prompt = prompt.answer[imageIndex];

                                            BAFetchData({
                                                callName: "GetImagesForVariable",
                                                method: "GET",
                                                url: "cms/sitepages/searchimages?query=" + prompt.answer[imageIndex] + "&orientation=landscape&color=",
                                                token: "fg1234",
                                                body: null, onSuccess: onAPISuccess, onError: onAPIError, packageObj: elementObj.aiBGImages[i].variable
                                            });

                                        }
                                    }
                                }
                            }
                            break;
                    }
                }
            }
        }
        setElements(tmpElements);
        if (imageIndex == -1) {
            setShowLoader(false);
        }
    }

    function loadPromptAnswers() {
        var tmpPrompts = [...prompts];

        var prompt = null;
        var elementObj = null;
        var tmpElements = { ...elements };
        for (var pvi = 0; pvi < tmpPrompts.length; pvi++) {
            prompt = tmpPrompts[pvi];
            for (const key in tmpElements) {
                elementObj = tmpElements[key];
                switch (elementObj.elementName) {
                    case 'heading':
                    case 'text':
                    case 'button':
                        if (elementObj.aiText.variable == prompt.name) {
                            elementObj.aiText.answer = prompt.answer;                        
                        }                        
                        break;
                }
            }
        }
        setElements(tmpElements);
    }

    async function onPurposeChange(newValue) {
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.purpose = newValue;
        setPageInfo((...pageInfo) => { return tmpPageInfo });
    }

    async function onOverviewChange(newValue) {
        var tmpPageInfo = { ...pageInfo };
        tmpPageInfo.aiTraining = newValue;
        setPageInfo((...pageInfo) => { return tmpPageInfo });
    }

    function saveSectionAsTemplate(targetId) {
        //createSectionTemplate(targetId);
        var msg = '';
        setGenericDialogSchema({
            title: 'Section Template', component: (dialogType, setReturnValue) => <SectionTemplateSettings buildMode={buildMode} handlerReturnValue={dialogType, setReturnValue}></SectionTemplateSettings>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('CreateSectionTemplate', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'CreateSectionTemplate':
                createSectionTemplate(selectedWidgetId, returnValues);
        }
    }

    function toggleEmailInfo() {
        if (emailSectionBtnText == "Expand") {
            setEmailSectionBtnText("Collapse");
        } else {
            setEmailSectionBtnText("Expand");
        }
    }

    const createSectionTemplate = (targetId, templateInfo) => {
        var top = 0;
        var left = 0;
        //var maxSizes = getCanvasMaxWidthHeight();
        var iFrameDocument = document.getElementById('canvas_iframe').contentWindow.document;
        var canvas = iFrameDocument.getElementById(targetId);
        //canvas.style.width = maxSizes.width + "px";
        //canvas.style.height = maxSizes.height + "px";
        
        toBlob(canvas, { cacheBust: false, useCorsEverywhereProxy:true,skipFonts:true })
            .then((dataUrl) => {
                //const link = document.createElement("a");
                //link.download = "my-image-name.png";
                //link.href = dataUrl;
                //link.click();

                var templateType = 'Section-Page';
                if (buildMode == "Automation-Email") {
                    templateType = 'Section-Email';
                }

                var tmpDestElements = copyWidget(targetId, true);
                var newElements = JSON.parse(cloneCopyFromMemory(tmpDestElements));
                var fileName = templateInfo.templateName + "_section_thumbnail.png";
                var templateDTO = { "templateId": 0, "baid": 0, override: templateInfo.override, systemRecord: templateInfo.systemRecord, "templateName": templateInfo.templateName, "templateType": templateType, "category": templateInfo.templateCategory, "keywords": templateInfo.keywords, "components": JSON.stringify(newElements), "active": true, "thumbnail": fileName }

                var formData = new FormData();
                formData.append('templateDTOStr', JSON.stringify(templateDTO));
                formData.append('file', dataUrl,fileName);

                BAFetchData({
                    callName: "CreateSectionTemplate",
                    method: "POST",
                    url: "template/addwiththumbnail",
                    token: "fg1234",
                    body: formData, onSuccess: onAPISuccess, onError: onAPIError
                });



                //canvas.style.width = "10000px";
                //canvas.style.height = "10000px";
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function toggleLeftPanel() {
        if (hideObjPanel == true) {
            setHideObjPanel(false);
        } else {
            setHideObjPanel(true);
        }        
    }


    return (
        <>
            {siteInfo != null ?
                <DataBrokerContext.Provider value={{
                    elements: elements, setElements: setElements, currentBreakpoint: currentBreakpoint, orderedBreakpoints: orderedBreakpoints, mobileFirst: siteInfo.mobileFirst,
                    getMergedStyleToBP: (widgetData, propertyName, custom) => { return getMergedStyleToBP(widgetData, propertyName, custom); },
                    onWidgetClick: (e, id, widgetData, onPropertyChangeCallback, widgetHideHighlightCallback, widgetSaveCallback) => { onWidgetClick(e, id, widgetData, onPropertyChangeCallback, widgetHideHighlightCallback, widgetSaveCallback); },
                    onContextMenuItemClick: (e, menuItemName, id, objectType) => { onContextMenuItemClick(e, menuItemName, id, objectType); },
                    onWidgetMouseEnter: (e, id) => { onWidgetMouseEnter(e, id); },
                    onWidgetMouseOver: (e, id) => { onWidgetMouseOver(e, id); },
                    onWidgetMouseLeave: (e, id) => { onWidgetMouseLeave(e, id); },
                    handleOnDragFromList: (e, widgetName, widgetType, componentType, sectionId) => { handleOnDragFromList(e, widgetName, widgetType, componentType, sectionId); },
                    handleOnDragFromCanvas: (e, id, widgetName, widgetType) => { handleOnDragFromCanvas(e, id, widgetName, widgetType); },
                    handleOnDragOver: (e, id) => { handleOnDragOver(e, id); }, handleOnDragLeave: (e, id) => { handleOnDragLeave(e, id); },
                    handleOnDrop: (e, id) => { handleOnDrop(e, id); },
                    handleUpdateChanges: (e, widgetData) => { handleUpdateChanges(e, widgetData); },
                    handleMergeVariables: (value, isJson, collectionItemContext, rowIndex, parentCollectionListRowIndex) => { return handleMergeVariables(value, isJson, collectionItemContext, rowIndex, parentCollectionListRowIndex); },
                    dataSources: (pageInfo == null ? [] : pageInfo.dataSources),
                    fontList: fontListRef.current,
                    siteInfo: siteInfo, setSiteInfo: setSiteInfo, selectedReference: selectedReference, setSelectedReference: setSelectedReference, 
                    handleSwapSection: (e, widgetName, widgetType, componentType, sectionId) => { handleSwapSection(e, widgetName, widgetType, componentType, sectionId); },
                }}>
                    <div>
                        <link rel="stylesheet" type="text/css" href={stylePath} />
                        {buildMode == undefined ?
                            <div id="fg-pb-header" className="fg-pb-header" style={{ display: "grid", gridTemplateColumns: "500px 1fr 500px", backgroundColor: "white", padding: "5px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
                                <div style={{ paddingTop: "7px" }}>Page: <span><input type="textbox" value={pageInfo ? pageInfo.name : ''} style={{ width: "150px", border: "1px solid #c0c0c0", borderRadius: "4px" }} onChange={(e) => { onPageNameChangeNoUpdate(e, e.target.value); }}  onBlur={(e) => { onPageNameOnBlur(e, e.target.value); }} /> </span>&nbsp;
                                    Master Page: &nbsp;
                                    <select className="form-control form-control-sm fg-pb-property-field" value={pageInfo ? pageInfo.masterPage : ''} style={{ width: "100px", border: "1px solid #c0c0c0", borderRadius: "4px", display: "inline-block" }} onChange={(e) => { onMasterPageChange(e, e.target.value); }} >
                                        <option value=""></option>
                                        {masterPageList.map((masterPage, index) => (
                                            <option key={"mp_" + masterPage.sitePageId} value={masterPage.name} >{masterPage.name}</option>
                                        ))}
                                    </select>&nbsp;
                                    <IconButton aria-label="delete" size="small">
                                        <PreviewIcon />
                                    </IconButton>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ display: "inline-block" }}>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={currentBreakpoint.toString()}
                                            onChange={handleOnBreakpointChange}
                                            aria-label="breakpoint">
                                            {orderedBreakpoints.map(bp => (
                                                <ToggleButton key={bp} value={bp.toString()} title={bp} aria-label="tv" size="small">
                                                    {renderBreakpointIcon(breakpoints[bp].icon)}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </div>
                                    <FormControlLabel style={{ marginBottom: "0px", marginTop: "-10px", marginLeft: "10px" }}
                                        control={
                                            <Switch checked={siteInfo.mobileFirst} onChange={(e) => setSiteInfo(siteInfo => ({ ...siteInfo, mobileFirst: e.target.checked }))} name="gilad" />
                                        }
                                        label="Mobile First"
                                    />
                                </div>
                                <div style={{ textAlign: "right" }}>
                                    {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ?
                                        <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px" }}>
                                            <AddIcon onClick={() => { handleAddPage(); }} />
                                        </IconButton>
                                        : null}
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('undo'); }}>
                                        <UndoIcon style={{ color: redoUndo.undoColor }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('redo'); }}>
                                        <RedoIcon style={{ color: redoUndo.redoColor }} />
                                    </IconButton>
                                    {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ?
                                        <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }}>
                                            <SaveIcon onClick={() => { handleSavePage(); }} />
                                        </IconButton>
                                        : null}
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px" }}>
                                        <VisibilityIcon onClick={() => { preview(); }} />
                                    </IconButton>
                                    <Dropdown style={{ display: "inline-block", marginLeft: "5px" }} size="small">
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "5px" }} size="small">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ?
                                                <Dropdown.Item onClick={() => { duplicatePage(); }}>Duplicate Page</Dropdown.Item>
                                                : null}
                                            {pageInfo != null ?
                                                <>
                                                {pageInfo.pageType == 'p' ?
                                                        <>
                                                            <Dropdown.Item onClick={() => { openPageSettings(); }}>Page Settings</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => { openPageCssEditor(); }}>Edit Page CSS</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => { openJavascriptEditor(); }}>Edit Page Javascript</Dropdown.Item>
                                                        </>
                                                        : null}
                                                </>
                                                : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ? 
                                        <Button variant="outlined" color="primary" size="medium" style={{ marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { publish(); }} >Publish</Button>
                                    : null}
                                    {(buildMode == 'Automation' || buildMode == 'Automation-Email' || buildMode == 'Broadcast' || buildMode == 'Form') ?
                                        <Button variant="outlined" color="primary" size="medium" style={{ marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { closeBuilder(); }} >Close</Button>
                                        : null}
                                </div>
                            </div>
                            : null}
                        {buildMode == 'Automation' ?
                            <div id="fg-pb-header" className="fg-pb-header" style={{ display: "grid", gridTemplateColumns: "500px 1fr 500px", backgroundColor: "white", padding: "5px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
                                <div style={{ paddingTop: "7px" }}>Page: <span><input type="textbox" value={pageInfo ? pageInfo.name : ''} style={{ width: "150px", border: "1px solid #c0c0c0", borderRadius: "4px" }} onChange={(e) => { onPageNameChangeNoUpdate(e, e.target.value); }}  onBlur={(e) => { onPageNameOnBlur(e, e.target.value); }} /> </span>&nbsp;
                                    Master Page: &nbsp;
                                    <select className="form-control form-control-sm fg-pb-property-field" value={pageInfo ? pageInfo.masterPage : ''} style={{ width: "100px", border: "1px solid #c0c0c0", borderRadius: "4px", display: "inline-block" }} onChange={(e) => { onMasterPageChange(e, e.target.value); }} >
                                        <option value=""></option>
                                        {masterPageList.map((masterPage, index) => (
                                            <option key={"mp_" + masterPage.sitePageId} value={masterPage.name} >{masterPage.name}</option>
                                        ))}
                                    </select>&nbsp;
                                    <IconButton aria-label="delete" size="small">
                                        <PreviewIcon />
                                    </IconButton>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ display: "inline-block" }}>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={currentBreakpoint.toString()}
                                            onChange={handleOnBreakpointChange}
                                            aria-label="breakpoint">
                                            {orderedBreakpoints.map(bp => (
                                                <ToggleButton key={bp} value={bp.toString()} title={bp} aria-label="tv" size="small">
                                                    {renderBreakpointIcon(breakpoints[bp].icon)}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </div>
                                    <FormControlLabel style={{ marginBottom: "0px", marginTop: "-10px", marginLeft: "10px" }}
                                        control={
                                            <Switch checked={siteInfo.mobileFirst} onChange={(e) => setSiteInfo(siteInfo => ({ ...siteInfo, mobileFirst: e.target.checked }))} name="gilad" />
                                        }
                                        label="Mobile First"
                                    />
                                </div>
                                <div style={{ textAlign: "right" }}>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px" }}>
                                        <AddIcon onClick={() => { handleAddPage(); }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('undo'); }}>
                                        <UndoIcon style={{ color: redoUndo.undoColor }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('redo'); }}>
                                        <RedoIcon style={{ color: redoUndo.redoColor }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }}>
                                        <SaveIcon onClick={() => { handleSavePage(); }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px" }}>
                                        <VisibilityIcon onClick={() => { preview(); }} />
                                    </IconButton>
                                    <Dropdown style={{ display: "inline-block", marginLeft: "5px" }} size="small">
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "5px" }} size="small">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => { duplicatePage(); }}>Duplicate Page</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openStyleSheet(); }}>Stylesheet</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openPageCssEditor(); }}>Edit Page CSS</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openJavascriptEditor(); }}>Edit Page Javascript</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button variant="outlined" color="primary" size="medium" style={{ marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { publish(); }} >Publish</Button>
                                    {(buildMode == 'Automation' || buildMode == 'Automation-Email' || buildMode == 'Broadcast' || buildMode == 'Form') ?
                                        <Button variant="outlined" color="primary" size="medium" style={{ marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { closeBuilder(); }} >Close</Button>
                                        : null}
                                </div>
                            </div>
                            : null}
                        {buildMode == 'Automation-Email' ?
                            <div id="fg-pb-header" className="fg-pb-header" style={{ display: "grid", gridTemplateColumns: "500px 1fr 500px", backgroundColor: "white", padding: "5px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
                                <div style={{ paddingTop: "7px" }}>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ display: "inline-block" }}>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={currentBreakpoint.toString()}
                                            onChange={handleOnBreakpointChange}
                                            aria-label="breakpoint">
                                            {orderedBreakpoints.map(bp => (
                                                <ToggleButton key={bp} value={bp.toString()} title={bp} aria-label="tv" size="small">
                                                    {renderBreakpointIcon(breakpoints[bp].icon)}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </div>
                                    <FormControlLabel style={{ marginBottom: "0px", marginTop: "-10px", marginLeft: "10px" }}
                                        control={
                                            <Switch checked={siteInfo.mobileFirst} onChange={(e) => setSiteInfo(siteInfo => ({ ...siteInfo, mobileFirst: e.target.checked }))} name="gilad" />
                                        }
                                        label="Mobile First"
                                    />
                                </div>
                                <div style={{ textAlign: "right" }}>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('undo'); }}>
                                        <UndoIcon style={{ color: redoUndo.undoColor }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }} onClick={() => { alert('redo'); }}>
                                        <RedoIcon style={{ color: redoUndo.redoColor }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px", borderRight: "none" }}>
                                        <SaveIcon onClick={() => { handleSavePage(); }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" style={{ border: "1px solid rgba(0,0,0,.12)", borderRadius: "0px" }}>
                                        <VisibilityIcon onClick={() => { preview(); }} />
                                    </IconButton>
                                    <Dropdown style={{ display: "inline-block", marginLeft: "5px" }} size="small">
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "5px" }} size="small">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => { duplicatePage(); }}>Duplicate Page</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openStyleSheet(); }}>Stylesheet</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {(buildMode == 'Automation' || buildMode == 'Automation-Email' || buildMode == 'Broadcast' || buildMode == 'Form') ?
                                        <Button variant="outlined" color="primary" size="medium" style={{ marginLeft: "5px", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { closeBuilder(); }} >Close</Button>
                                        : null}
                                </div>
                            </div>
                            : null}
                        <div id="fg-pb-container" className="fg-pb-container" style={{ borderBottom: "1px solid red", display: "grid", gridTemplateColumns: (hideObjPanel == false ? "300px 1fr 300px" : "25px 1fr 300px") }}>
                            <div id="fg-pb-left-panel" className="fg-pb-left-panel" style={{ display: "grid", gridTemplateColumns: (hideObjPanel == false ? "1fr" : "25px"), backgroundColor: "#F5F5F5", overflowY: "auto", padding: "0px" }}>
                                {hideObjPanel == false ?
                                    <div style={{padding:"5px"}}>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", gridGap: "5px", width: "100%", marginBottom: "5px" }}>
                                            <div style={{ fontWeight: "bold" }}>Object Panel</div>
                                            <div style={{ textAlign: "right" }} onClick={(e) => toggleLeftPanel()}><HighlightOffIcon /></div>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: (buildMode == undefined) ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr", gridGap: "5px", width: "100%", borderBottom: "1px solid #C0C0C0" }}>
                                            {buildMode == undefined ?
                                                <div className={"fg-pb-panel-tab " + showActiveTab('Pages')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Pages'); }}>Pages</div>
                                                : null}
                                            <div className={"fg-pb-panel-tab " + showActiveTab('Elements')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Elements'); }}>Elements</div>
                                            <div className={"fg-pb-panel-tab " + showActiveTab('Content')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Content'); }}>Content</div>
                                            <div className={"fg-pb-panel-tab " + showActiveTab('Nav')} style={{ cursor: "pointer" }} onClick={() => { changeTab('Nav'); }}>Nav</div>
                                        </div>


                                        {panelTab == "Pages" ?
                                            <SitePagesListCtrl siteId={siteId} handleOnPageClick={(sitePageId, pageInfo) => { handleOnPageItemClick(sitePageId, pageInfo, 'Page'); }} handleOnPageDelete={(e) => { handleOnPageDelete(e); }} renderNum={renderNum} reloadNum={reloadNum} modifedPageName={pageInfo != null ? pageInfo.name : ''} />
                                            : null}
                                        {panelTab == "Elements" ?
                                            <div style={{ marginTop: "5px" }}>
                                                <div>
                                                    <ToggleButtonGroup exclusive value={componentType} aria-label="align" fullWidth onChange={(e, selectedValue) => { setComponentType(selectedValue); }}>
                                                        <ToggleButton value="Sections" title="Sections" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                            <span style={{ fontSize: "12px", marginTop: "3px" }}>Sections</span>
                                                        </ToggleButton>
                                                        <ToggleButton value="Widgets" title="Widgets" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                            <span style={{ fontSize: "12px", marginTop: "3px" }}>Widgets</span>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                                {componentType == 'Widgets' ?
                                                    <WidgetListCtrl buildMode={buildMode} />
                                                    :
                                                    <SectionListCtrl buildMode={buildMode} />}
                                            </div>
                                            : null}
                                        {panelTab == "Content" ?
                                            <>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>
                                                        <ToggleButtonGroup exclusive value={contentType} aria-label="align" fullWidth onChange={(e, selectedValue) => { setContentType(selectedValue); }}>
                                                            <ToggleButton value="Ai" title="Ai" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                <span style={{ fontSize: "12px", marginTop: "3px" }}>Ai Content</span>
                                                            </ToggleButton>
                                                            <ToggleButton value="Data" title="Data" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                <span style={{ fontSize: "12px", marginTop: "3px" }}>API Content</span>
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>

                                                    </div>
                                                    {contentType == 'Ai' ?
                                                        <>
                                                            <div>
                                                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "40px" }}>
                                                                    <h2 style={{ marginBottom: "5px", fontSize: "20px", fontWeight: "500" }}>Ai Content Generator</h2>
                                                                    <p style={{marginBottom: "20px", fontSize:"12px"}}>Generate content for the entire page using Ai.</p>
                                                                    {pageInfo != null ?
                                                                        <>
                                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, maxWidth:270,marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">Page Purpose</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    value={pageInfo.purpose || ''}
                                                                                    onChange={(e) => { onPurposeChange(e.target.value); }}
                                                                                    label="Page Purpose"
                                                                                >
                                                                                    <MenuItem value="">
                                                                                        <em>None</em>
                                                                                    </MenuItem>
                                                                                    {purposeList.map((purpose, index) => (
                                                                                        <MenuItem key={"purpose_" + index} value={purpose.purposeName}>{purpose.label}</MenuItem>
                                                                                    ))}

                                                                                </Select>
                                                                            </FormControl>
                                                                            {pageInfo.purpose != '' ?
                                                                                <div style={{marginBottom:"20px"} }>
                                                                                
                                                                                    <Button fullWidth variant="outlined" color="primary" size="medium" style={{ backgroundColor: "#5E95DE", color: "white", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { rebuildSections(); }} >Rebuild Relevant Sections</Button>
                                                                                    
                                                                            </div>
                                                                                : null}
                                                                    <TextField fullWidth multiline minRows={8} maxRows={8} label="Page Overview" value={pageInfo.aiTraining || ''} variant="standard" style={{ marginBottom: "16px" }} onChange={(e) => { onOverviewChange(e.target.value); }} />
                                                                    <div>
                                                                        {pageInfo.aiTraining != '' ?
                                                                            <Button fullWidth variant="outlined" color="primary" size="medium" style={{ backgroundColor: "#5E95DE", color: "white", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={() => { generateAiContent(); }} >Generate Content</Button>
                                                                            : null}
                                                                    </div>
                                                                    </>
                                                                    : null}
                                                                    <div>
                                                                        <FormControlLabel style={{ marginBottom: "10px" }}
                                                                            control={
                                                                                <Switch checked={useAiText} onChange={(e) => { setUseAiText(e.target.checked); toggleAiText(e.target.checked); }} name="gilad" />
                                                                            }
                                                                            label="Use Ai Content"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                        : null}
                                                    {contentType == 'Data' ?
                                                        pageInfo != null ?
                                                            <>
                                                                <DataSourceListCtrl pageInfo={pageInfo} setPageInfo={setPageInfo} setCopySnackbarOpen={setCopySnackbarOpen} />
                                                            </>
                                                            : null
                                                        : null}
                                                </div>
                                            </>
                                            : null}
                                        {panelTab == "Nav" ?
                                            <div>
                                                {elements[helpers.getRootElementId(elements)] != undefined ?
                                                    <ElementNavCtrl elementData={elements} activeId={selectedWidgetId} />
                                                    : null}
                                            </div>
                                            : null}

                                    </div>
                                    :
                                    <div style={{ width: "25px", height: "100%", backgroundColor: "rgb(128,128,128)", }} onClick={(e) => toggleLeftPanel()}>
                                        <div style={{ cursor: "pointer", height: "250px" }}>
                                            <div className="fgao-slideout-tab-text">Object Panel</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div id="fg-pb-viewer" className="fg-pb-viewer" style={{ backgroundColor: "#c5c5c5" }}>
                                        {buildMode == 'Automation-Email' ?
                                            <>                                 
                                                <div style={{ backgroundColor: "#F5F5F5", padding: "10px" }}>                                            
                                            <div style={{ fontSize: "20px", marginBottom: "10px", cursor: "pointer" }} onClick={(e) => { (toggleEmailInfo()); }}><span style={{ fontWeight: "bold"}}>Email Recipient and Subject</span>
                                                <Chip label={emailSectionBtnText == "Expand" ? "Collapse" : "Expand"} sx={{ marginLeft:"10px",height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#5E95DE", color: "white", marginBottom: "3px" }} />
                                            </div>
                                            {emailSectionBtnText == "Expand" ?
                                                <>
                                                    <div style={{backgroundColor:"white",padding:"10px",borderRadius:"12px",marginBottom:"10px"}}>
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                        <div>
                                                            <TextField id="senderName" fullWidth label="Sender Name" variant="standard" value={emailInfo.senderName || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('senderName', e.target.value); }} />
                                                        </div>
                                                        <div>
                                                            <TextField id="senderEmail" fullWidth label="Sender Email" variant="standard" value={emailInfo.senderEmail || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('senderEmail', e.target.value); }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                        <div>
                                                            <TextField id="replyToName" fullWidth label="Reply to Name" variant="standard" value={emailInfo.replyToName || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('replyToName', e.target.value); }} />
                                                        </div>
                                                        <div>
                                                            <TextField id="replyToEmail" fullWidth label="Reply to Email" variant="standard" value={emailInfo.replyToEmail || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('replyToEmail', e.target.value); }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                        <div>
                                                            <TextField id="sendToName" fullWidth label="Send to Name" variant="standard" value={emailInfo.sendToName || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('sendToName', e.target.value); }} />
                                                        </div>
                                                        <div>
                                                            <TextField id="sendToEmail" fullWidth label="Send to Email" variant="standard" value={emailInfo.sendToEmail || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('sendToEmail', e.target.value); }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                        <div>
                                                            <TextField id="subject" fullWidth label="Subject" variant="standard" value={emailInfo.subject || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('subject', e.target.value); }} />
                                                        </div>
                                                        <div>
                                                            <TextField id="preheader" fullWidth label="Preheader" variant="standard" value={emailInfo.preheader || ''} style={{ marginBottom: "16px" }} onChange={(e) => { onEmailInfoChange('preheader', e.target.value); }} />
                                                        </div>
                                                    </div>
                                                    </div>
                                                </>
                                                : null}
                                                </div>
                                            </>
                                        : null}
                                <div id="fg-pb-canvas" className="fg-pb-canvas" style={{ position: "relative", marginLeft: "auto", marginRight: "auto", backgroundColor: "white", maxWidth: currentBreakpoint + "px", height: "100%", overflowY: "auto" }}>                                    
                                    {elements[helpers.getRootElementId(elements)] != undefined ?
                                        <IFrame id="canvas_iframe" ref={iFrameRef}>
                                            <link rel="stylesheet" href="css/pbiframe2.css" />
                                            <link rel="stylesheet" href={stylePath} />
                                            
                                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                                            {/*<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous" />*/}
                                            {fontListRef.current.map((font, index) => (
                                                <link href={"https://fonts.googleapis.com/css?family=" + font + ":100,200,300,400,500,600,700,800,900"} rel="stylesheet" crossorigin="anonymous" referrerpolicy="no-referrer" />
                                            ))}
                                            <style>{siteStyleSheet}</style>
                                            <div id="fg-pg-highliter" ref={hlRef} style={{ zIndex: "2", pointerEvents: "none", display: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }}>
                                                <div ref={hlLabelRef} style={{ position: "relative", left: "-1px", top: "-5px", display: "inline-block", color: "white", padding: "3px", fontSize: "10px", backgroundColor: "red" }}></div>
                                            </div>
                                            {selectedSettings.show ?
                                                renderWidgetLabel(selectedSettings)
                                                : null}
                                            <WidgetRouter params={buildRootParams()} tmpElements={elements} currBP={currentBreakpoint} resizeId={resizeId}></WidgetRouter>
                                        </IFrame>
                                        : null}
                                    {showContextMenu ?
                                        renderWidgetContextMenu(selectedSettings)
                                        : null}
                                    {locked == true ?
                                        <div style={{ display: "grid",gridTemplateColumns:"1fr 70px", position: "absolute", top: "700px", left: "0px", right: "0px", backgroundColor: "black", color: "white", width: "450px", borderRadius: "4px", padding: "5px" }}>
                                            <div style={{paddingTop:"4px"}}>Select elements and click + to add Actions</div>
                                            <div style={{ textAlign: "right" }}><button type="button" className="btn btn-sm btn-primary" onClick={() => { setLocked(false); }}>Done</button></div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div id="fg-pb-right-panel" className="fg-pb-right-panel" style={{ backgroundColor: "#F5F5F5", overflowY: "auto", padding: "10px" }}>
                                {selectedWidgetId ?
                                    elements[selectedWidgetId] ?
                                        <CssEditorCtrl
                                            widgetData={((locked == false || editorElementIdRef.current == null) ? elements[selectedWidgetId] : elements[editorElementIdRef.current])} actionElementId={(locked == true ? actionElementIdRef.current : null)}
                                            propertyChangeCallback={(propertyName, value) => { widgetCallbacks.widgetPropertyCallback(propertyName, value); }} handleEndAnimationSelection={(value) => { endAnimationSelection(value) }} referenceData={siteInfo.styleList[selectedReference]} setSelectedReference={setSelectedReference}></CssEditorCtrl>
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </DataBrokerContext.Provider>
                : null}
            

                <Snackbar
                open={copySnackbarOpen}
                autoHideDuration={6000}
                onClose={handleCopySnackClose}
                message="Copy completed" />
            <Snackbar
                open={pageSaveSnackbarOpen}
                autoHideDuration={6000}
                onClose={handlePageSaveSnackClose}
                message="Page Saved" />
            <Snackbar
                open={publishSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSiteSnackClose}
                message="Site Published" />
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default PageBuilderCtrl;
import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import '../css/pagebuilderctrl.css';

function CssCustomEventsCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);

    function handleOnValueChange(index, propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj['customEvents'][index][propertyName] = value
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });    
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnBlur(index, propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj['customEvents'][index][propertyName] = value
        propertyChangeCallback(tmpWidgetObj);
    }

    function addCustomEvent() {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj['customEvents'].push({ "eventName": "", "value": "" });
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function removeCustomEvent(index) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj['customEvents'].splice(index, 1);
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        if (widgetData.hasOwnProperty('customEvents') == false) {
            widgetData.customEvents = [];
        }
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>

            <div>
                <div className="fg-pg-panel-sub-section">Custom Javascript Events</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                        <div className="fg-pb-subsection-instruction" style={{paddingTop:"3px"}}>Add Custom Event</div>
                        <div onClick={(e) => { addCustomEvent(); }}><AddIcon></AddIcon></div>
                    </div>
                    {widgetObj.customEvents != undefined ?
                        (widgetObj.customEvents.map((customEvent, index) => (
                            <div style={{borderBottom:"1px dotted grey",marginBottom:"10px",paddingBottom:"10px"}}>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                                    <div className="fg-pb-subsection-instruction">Custom event</div>
                                    <div onClick={(e) => { removeCustomEvent(index); }}><DeleteForeverIcon></DeleteForeverIcon></div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                    <div className="fg-pb-property-sub-label">Name:</div>
                                    <div>
                                        <input className="form-control form-control-sm fg-pb-property-field" value={customEvent.eventName != undefined ? customEvent.eventName : ""} onChange={(e) => { handleOnValueChange(index, "eventName", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "eventName", e.target.value); }} />
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                    <div className="fg-pb-property-sub-label">Value</div>
                                    <div>
                                        <input className="form-control form-control-sm fg-pb-property-field" value={customEvent.value != undefined ? customEvent.value : ""} onChange={(e) => { handleOnValueChange(index, "value", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "event", e.target.value); }} />
                                    </div>
                                </div>
                            </div>
                        )))
                : null}
                </div>
            </div>

        </>
    );

}

export default CssCustomEventsCtrl;


import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';


function CssLayoutCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [imageList, setImageList] = useState([{ name: "" }, { name: "" }]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [widgetObj, setWidgetObj] = useState(widgetData);

    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function handleOpenColorSelector() {
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    function parseValue(value) {       
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnAlignChange(e, selectedAlign) {
        updateCssProperty("textAlign", selectedAlign);
    }

    function handleOnOverflowChange(e, selectedOverflow) {
        updateCssProperty("overflow", selectedOverflow);
    }

    

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Layout</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Display</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'display') || "block"} onChange={(e) => { updateCssProperty('display', e.target.value); }}>
                                <option value="none">None</option>
                                <option value="block">Block</option>
                                <option value="flex">Flex</option>
                                <option value="grid">Grid</option>                               
                                <option value="inline">Inline</option>
                                <option value="inline-block">Inline-Block</option>
                                <option value="inline-flex">Inline-Flex</option>                                
                            </select>
                        </div>
                    </div>
                    {getMergedStyleToBP(widgetObj, 'display') == "grid" ?
                    <div>
                        <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                            <div className="fg-pb-property-sub-label">Gap</div>
                            <div>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "5px" }}>
                                    <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'gridGap')} onDataChange={(gridGap) => { updateCssProperty('gridGap', gridGap); }} /></div>
                                    <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'rowGap')} onDataChange={(rowGap) => { updateCssProperty('rowGap', rowGap); }} /></div>
                                    <div style={{ fontSize: "12px", paddingLeft: "5px" }}>Columns</div>
                                    <div style={{ fontSize: "12px", paddingLeft: "5px" }}>Rows</div>
                                </div>
                            </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">TMPL Cols</div>
                                <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridTemplateColumns')} onChange={(e) => { updateCssProperty('gridTemplateColumns',e.target.value); }} /></div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">TMPL Rows</div>
                                <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'gridTemplateRows')} onChange={(e) => { updateCssProperty('gridTemplateRows', e.target.value); }} /></div>
                            </div>
                    </div>
                        : null}
                    {(getMergedStyleToBP(widgetObj, 'display') == "flex" || getMergedStyleToBP(widgetObj, 'display') == "inline-flex") ?
                        <div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Direction</div>
                                <div>
                                    <select className="form-control form-control-sm fg-pb-property-field" style={{width:"100%"}}  value={getMergedStyleToBP(widgetObj, 'flexDirection')} onChange={(e) => { updateCssProperty('flexDirection', e.target.value); }}>
                                        <option value="row">Row</option>
                                        <option value="row-reverse">Row Reverse</option>
                                        <option value="column">Column</option>
                                        <option value="column-reverse">Column-Reverse</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Align</div>
                                <div>
                                    <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'alignItems')} onChange={(e) => { updateCssProperty('alignItems', e.target.value); }}>
                                        <option value="">Select...</option>
                                        <option value="none">none</option>
                                        <option value="start">Start</option>
                                        <option value="center">Center</option>
                                        <option value="end">End</option>
                                        <option value="stretch">Stretch</option>
                                        <option value="baseline">Baseline</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Justify</div>
                                <div>
                                    <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'justifyContent')} onChange={(e) => { updateCssProperty('justifyContent', e.target.value); }}>
                                        <option value="">Select...</option>
                                        <option value="none">none</option>
                                        <option value="start">Start</option>
                                        <option value="center">Center</option>
                                        <option value="end">End</option>
                                        <option value="stretch">Stretch</option>
                                        <option value="baseline">Baseline</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Gap</div>
                                <div>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "5px" }}>
                                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'gridGap')} onDataChange={(gridGap) => { updateCssProperty('gridGap', gridGap); }} /></div>
                                        <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'rowGap')} onDataChange={(rowGap) => { updateCssProperty('rowGap', rowGap); }} /></div>
                                        <div style={{ fontSize: "12px", paddingLeft: "5px" }}>Columns</div>
                                        <div style={{ fontSize: "12px", paddingLeft: "5px" }}>Rows</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Children</div>
                                <div>
                                    <ToggleButtonGroup exclusive value={getMergedStyleToBP(widgetObj, 'flexWrap')} aria-label="align" fullWidth onChange={(e, selectedValue) => { updateCssProperty('flexWrap', selectedValue); }}>
                                        <ToggleButton value="nowrap" title="no-wrap" aria-label="tv" size="small" style={{ padding: "0px 6px 0px 6px", width:"80px" }}>
                                            <span style={{ fontSize: "11px", marginTop: "3px" }}>No Wrap</span>
                                        </ToggleButton>
                                        <ToggleButton value="wrap" title="wrap" aria-label="tv" size="small" style={{ padding: "0px 6px 0px 6px", width: "80px" }}>
                                            <span style={{ fontSize: "11px", marginTop: "3px" }}>Wrap</span>
                                        </ToggleButton>
                                        <ToggleButton value="wrap-reverse" title="wrap-reverse" aria-label="tv" size="small" style={{ padding: "0px 6px 0px 6px", width: "35px" }}>
                                            <SwapHorizIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );

}

export default CssLayoutCtrl;


import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ThumbnailGeneratorCtrl from './ThumbnailGeneratorCtrl';

function FileDetailCtrl({ fileRecord, selectedIndex, fileId, handlerReturnValue, handleDialogOnOK, handleCancelClick }) {

    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    const [formData, setFormData] = useState(fileRecord);
    const [errorList, setErrorList] = useState({});
    const [columnSchemas, setColumnSchemas] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [primaryKeyValue, setPrimaryKeyValue] = useState(fileId);
    let cdnFolder = process.env.REACT_APP_CDN_BASE_URL;
    const videoUrl = cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + fileRecord.original;


    //var url = cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + searchResultData.data[selectedIndex].original;


    function onHandleOKClick() {
        handleSaveForm();
    }

    function onHandleCancelClick() {
        handleCancelClick();
    }

    function addJustInTimeData(formData) {
        //if (primaryKeyValue == 0) {
        //    formData.dateCreated = new Date().toISOString();
        //} else {
        //    formData.dateModified = new Date().toISOString();
        //}
    }

    async function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (await isCustomFormValidationValid() == true) {
                saveForm();
            }
        }
    }

    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "files/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "files/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "SaveForm":
                handleDialogOnOK();
                //setPrimaryKeyValue(data.fileId);
                //setFormData(data);
                //var msg = 'Form has been saved.';
                //setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); handleDialogOnOK(); }})
                //setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    async function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}

        setErrorList(tempErrorList);
        return isValid;
    }

    useEffect(() => {
        //beginSearch();
        //setShowLoader(false);
    }, []);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "crm_tags", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function getFileSource(formData) {
        var url = "";
        if (formData.extension == ".mp4") {
            url = "images/placeholder-video.png";
            if (formData.dataImage != null) {
                url = formData.dataImage;
            }else if (formData.small != null) {
                url = cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + formData.small + "?v=" + formData.dateModified;
            }
        } else {
            url = cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + formData.original + "?v=" + formData.dateModified ;
        }
        return url;
    }

    return (
        <>
            <div className="fg-app-page-box" style={{ width: "900px", maxWidth: "1200px", marginLeft: "0px", marginRight: "0px", paddingBottom: "0px", marginBottom: "0px" }}>
                {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="segment">
                            <div className="segment-title">File Info</div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <TextField id="name" autoFocus required fullWidth label="Name" variant="standard" helperText={errorList['name'] ? <span style={{ color: "red" }}>{errorList['name']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.name || ''} InputLabelProps={{ shrink: formData.name }} inputProps={{ readOnly: true }} onChange={(e) => setFormData(formData => ({ ...formData, name: e.target.value }))} />
                                                <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />
                                            </div>
                                            <div className="col-sm-3">
                                                <div>
                                                    <img src={getFileSource(formData)} style={{width:"100%"}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {formData.extension == '.mp4' ?
                            <div className="segment">
                                <div className="segment-title">Video Thumbnail</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ThumbnailGeneratorCtrl videoUrl={videoUrl} onChange={(value, thumbnailType) => setFormData(formData => ({ ...formData, dataImage: value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12" style={{ padding: "0px", textAlign: "right" }}>
                        <Button onClick={onHandleCancelClick} style={{ padding: "0px", margin: "0px", textAlign: "right" }}>Cancel</Button>
                        <Button onClick={onHandleOKClick} autoFocus style={{ minWidth: "40px", padding: "0px", margin: "0px", textAlign: "right" }}>OK</Button>
                    </div>
                </div>                
            </div>
        </>
    );
}

export default FileDetailCtrl;

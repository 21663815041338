import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import useBAFetch, { BAFetchData } from '../../customhooks/useBAFetch';
import WarningIcon from '@mui/icons-material/Warning';
import PaymentIcon from '@mui/icons-material/Payment';
import DoneIcon from '@mui/icons-material/Done';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import helpers from '../../helpers/Helpers';
import dayjs from 'dayjs';

function ViewPaymentCtrl({baToken, transactionToken}) {
    const [formData, setFormData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function loadTransaction() {
        BAFetchData({
            callName: "GetMainRecord",
            method: "GET",
            url: "accounting/transactionheader/getpaymentrecordbytoken?batoken=" + baToken + "&transactiontoken=" + transactionToken,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }
    

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                setFormData(data);
                setShowLoader(false);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        setShowLoader(false);
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function onPayNowBtnClick() {

    }

    

    useEffect(() => {
        loadTransaction();
    }, []);


    return (
        <> 
            {formData.posted == true ?
                <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", gridGap: "10px", maxWidth: "900px", backgroundColor: "#198754", color: "white", padding: "20px", marginBottom: "20px", borderRadius: "12px" }}>
                    <div><DoneIcon style={{ fontSize: "40px" }} /></div>
                    <div style={{ paddingTop: "5px" }}>Thank you for your payment</div>
                </div>
                : null}
            <div style={{ backgroundColor: "whitesmoke", maxWidth: "900px" }}>
                <div style={{ backgroundColor: "white", padding: "40px", borderTop: "10px solid black" }}>
                    <div style={{textAlign:"center"}}>
                        <div>
                            <img src="./images/fg_email_logo_200x30.png" style={{maxWidth:"200px"}} />
                        </div>
                        <div>Funnel Goal</div>
                        <div>3824 Brownes Ferry Rd., Charlotte, NC 28269</div>
                        <div>704-498-2901</div>
                        <div>support@funnelgoal.io</div>
                        <div style={{fontSize:"44px",fontWeight:"bold"}}>Payment</div>
                    </div>
                    <div style={{ textAlign: "center",padding:"20px 10px 20px 10px",borderTop:"2px solid black",borderBottom:"2px solid black" }}>
                        <div style={{fontWeight:"500"}}>Customer Info</div>
                        <div>{formData.customerName}</div>
                        <div>{formData.customerEmail}</div>
                    </div>
                    <div style={{ display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"10px",width:"100%",padding:"20px 10px 20px 10px"}}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{fontSize:"12px",color:"grey"}}>Payment #</div>
                            <div>{formData.headerId}</div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", color: "grey" }}>Payment Date</div>
                            <div>{dayjs(formData.transactionDate).format('MM/DD/YYYY')}</div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 90px 75px 75px 75px", gridGap: "10px", width: "100%", padding: "0px 10px 0px 10px" }}>
                        <div style={{ fontSize: "12px", color: "grey" }}>Invoice</div>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>Due Date</div>
                        <div style={{ textAlign: "right", fontSize: "12px", color: "grey" }}>Original Amt.</div>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>Open Blaance</div>
                        <div style={{ textAlign: "right", fontSize: "12px", color: "grey" }}>Applied Amt.</div>
                    </div>
                    {formData != null ?
                        <>
                            {formData.details != null ?
                                <>
                                    {formData.details.map((item, index) => (
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 90px 75px 75px 75px", gridGap: "10px", width: "100%", padding: "10px 10px 10px 10px", borderBottom: "1px solid #c0c0c0" }}>
                                            <div style={{ fontSize: "16px",fontWeight:"bold" }}>{item.summary}</div>
                                            <div style={{ textAlign: "center", fontSize: "16px" }}>{dayjs(item.invoiceDate).format('MM/DD/YYYY')}</div>
                                            <div style={{ textAlign: "right", fontSize: "16px" }}>{helpers.formatNumber(item.invoiceOriginalAmt, 2) || '0'}</div>                                           
                                            <div style={{ textAlign: "center", fontSize: "16px" }}>{helpers.formatNumber(item.openBalance, 2) || '0'}</div>
                                            <div style={{ textAlign: "right", fontSize: "16px" }}>{helpers.formatNumber(item.detailAmount, 2) || '0'}</div>
                                        </div>
                                    ))}
                                </>
                                : null}
                        </>
                        : null}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 75px", gridGap: "10px", width: "100%", padding: "40px 10px 0px 10px" }}>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>Amount Paid</div>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>{helpers.formatNumber(formData.headerAmt, 2) || '0'}</div>
                    </div>
                    <div style={{ width: "100%", marginTop: "20px", padding: "20px 10px 20px 10px" }}>
                        <div>
                            {formData.note != null ?
                                <>
                                    <p style={{ margin: "0px", fontSize: "14px", color: "grey" }}>Notes</p>
                                    <p>{formData.note}</p>
                                </>
                                : null}
                            {formData.termsAndConditions != null ?
                                <>
                                    <p style={{ margin: "0px", fontSize: "14px", color: "grey" }}>Terms & Conditions</p>
                                    <p>{formData.termsAndConditions}</p>
                                </>
                                : null}
                        </div>
                    </div>
                </div>
                </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ViewPaymentCtrl;

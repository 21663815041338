import React, { useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';
import { BAChangeDefaultHeaders } from '../customhooks/useBAFetch';

function TransactionViewer() {

    let params = useParams();
    BAChangeDefaultHeaders(params.baToken);

    return (
        <>
            <div style={{ backgroundColor: "whitesmoke" }}>
                <div style={{maxWidth:"900px",margin:"0px auto",padding:"20px"}}>
                    <TransactionViewerCtrl transactionType={params.transactionType} baToken={params.baToken} transactionToken={params.transactionToken} />
                </div>
            </div>
        </>
    );
}

export default TransactionViewer;


import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import LookupFieldCtrl from '../../formcontrols/LookupFieldCtrl';
import ChoiceSelector from '../ChoiceSelector';
import FileManager from '../../../pages/FileManager';
import ButtonFieldCtrl from '../../formcontrols/ButtonFieldCtrl';
import LinkSelector from '../LinkSelector';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';


function CssLinkInfo({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            


    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }


    function handleOnPropertyChangeNoUpdate(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        //propertyChangeCallback(tmpWidgetObj);
    }



    function handleOnLinkOptionClick() {
        var msg = '';
        setGenericDialogSchema({ title: 'What do you want to link to?', component: (dialogType, setReturnValue) => <ChoiceSelector handlerReturnValue={dialogType, setReturnValue}></ChoiceSelector>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleChoiceSelected(returnValues); } })
        setOpenDialog(true);    
    }

    function handleChoiceSelected(linkType) {
        switch (linkType) {
            case 'Event':
                openLinkSelector('Event');
                break;
            case 'File':
                openFileManager();
                break;
            case 'WebsitePage':
                openWebPageSelector();
                break;
            case 'Product':
                openLinkSelector('Product');
                break;
            case 'Blog':
                openLinkSelector('Blog');
                break;
            case 'Other':
                var msg = 'You chose other so simply copy and paste the url in the Link Url property field.';
                setGenericDialogSchema({ title: 'Link Type Other', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog();} })
                setOpenDialog(true);
                break;
        }
    }

    function openFileManager() {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'File Manager', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <FileManager handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} ></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupReturn('File', returnValues); } })
        setOpenDialog(true);
    }

    function openLinkSelector(ContentType) {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'Select ' + ContentType, component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <LinkSelector handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} resourceType={ContentType}></LinkSelector>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupReturn(ContentType, returnValues); } })
        setOpenDialog(true);
    }

    function openWebPageSelector() {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'Select Web Page', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <LinkSelector handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} resourceType="WebsitePage"></LinkSelector>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupReturn('WebsitePage', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupReturn(returnType, returnValue) {
        switch (returnType) {
            case 'File':
                handleOnPropertyChange('linkUrl', returnValue);
                break;
            case 'Blog':
                handleOnPropertyChange('linkUrl', "/" + returnValue);
                break;
            case 'Product':
                handleOnPropertyChange('linkUrl', "/" + returnValue);
                break;
            case 'WebsitePage':
                handleOnPropertyChange('linkUrl', "/" + returnValue);
                break;
        }
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Link Info</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Link Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.linkType || ''} onChange={(e) => { handleOnPropertyChange('linkType', e.target.value); }}>
                                <option value="none">None</option>
                                <option value="basic">Basic Button</option>
                                <option value="process_payment">Process Payment</option>
                                <option value="form_submit">Form Submit</option>
                                <option value="popup">Popup</option>
                                <option value="add_to_cart">Add to Cart</option>
                                <option value="buy_now">Buy Now</option>
                                <option value="remove_from_cart">Remove from Cart</option>
                                <option value="detail_add_to_cart">Detail Add to Cart</option>
                                <option value="initiate-checkout">Initiate Checkout</option>                                
                                <option value="collection_prev">Collection Previous</option>
                                <option value="collection_next">Collection Next</option>
                                <option value="custom_javascript">Custom Javascript</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Link URL</div>
                        <div>
                            <ButtonFieldCtrl value={widgetObj.linkUrl || ''} onClick={() => { handleOnLinkOptionClick(); }} onRemove={() => { handleOnPropertyChange('linkUrl', null); }} onChange={(value) => { handleOnPropertyChange('linkUrl', value); }} style={{ border: "1px solid #ced4da", padding: "0px 0px 0px 3px" }}></ButtonFieldCtrl>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">URL Params</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.linkUrlParams || ''} onChange={(e) => { handleOnPropertyChange('linkUrlParams', e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Target</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.linkTarget || ''} onChange={(e) => { handleOnPropertyChange('linkTarget', e.target.value); }}>
                                <option value="_self">Same Page</option>
                                <option value="_blank">New Window</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Discount #</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.linkDiscountCode || ''} onChange={(e) => { handleOnPropertyChange('linkDiscountCode', e.target.value);}} />
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );

}

export default CssLinkInfo;


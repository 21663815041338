import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Autocomplete from '@mui/material/Autocomplete';


function CssClassNameCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, siteInfo, setSiteInfo,selectedRefernece, setSelectedReference } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});  
    const [referenceList, setReferenceList] = useState([]);
    const [selectedReferenceList, setSelectedReferenceList] = useState([]);


    function handleOnClassNameChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnCustomClassNameChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.customClassName = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function referenceExists(value) {
        var found = false;
        var tmpReferenceList = [...referenceList];
        for (var i = 0; i < tmpReferenceList.length; i++) {
            if (tmpReferenceList[i].label == value) {
                found = true;
                break;
            }
        }
        return found;
    }

    function attachReference(value) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.className == '') {
            tmpWidgetObj.className += value;
        } else {
            tmpWidgetObj.className += ' ' + value;
        }        

        if (referenceExists(value) == false) {
            var tmpReferenceList = [...referenceList];
            tmpReferenceList.push({ label: value, value: value });
            setReferenceList(tmpReferenceList);


            var tmpSiteInfo = { ...siteInfo };
            tmpSiteInfo.styleList[value] = { "id":value,"referenceType": "class", "breakpointStyles": {}};
            setSiteInfo(tmpSiteInfo);
        }
        
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);


    }



    function detachReference(e, reference) {
        var tmpWidgetObj = { ...widgetObj };
        var tmpList = tmpWidgetObj.className.split(' ');
        for (var i = 0; i < tmpList.length; i++) {
            if (tmpList[i] == reference) {
                tmpList.splice(i, 1);
                break;
            }
        }
        var updateList = '';
        var delimeter = '';
        for (var i = 0; i < tmpList.length; i++) {
            updateList += delimeter + tmpList[i];
            delimeter = ',';
        }
        tmpWidgetObj.className = updateList;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnIconChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function openFileManager() {
        var actionType = "FullPath";
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={false} iconOnly={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType) {
        handleOnClassNameChange(imgUrl);
    }


    function loadSelectedReferenceList(widgetData) {
        if (widgetData.className != '') {
            var tmpList = widgetData.className.split(' ');
            var updateList = [];
            var delimeter = '';
            for (var i = 0; i < tmpList.length; i++) {
                updateList.push(tmpList[i])
            }
            setSelectedReferenceList(updateList);
        } else {
            setSelectedReferenceList([]);
        }
    }

    function handleOnReferenceSelected(reference) {
        setSelectedReference(reference);
    }

    function handleOnAttachReference(e, value) {
        attachReference(value);
        document.getElementById('style_name').value = '';
    }

    function handleOnKeyDown(e) {
        var value = e.target.value 
        if (e.key == 'Enter') {
            if (value != '') {
                attachReference(value);
                e.target.blur();
            }
        }
        var test = "test";
        test = "hello";
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        loadSelectedReferenceList(widgetData);
    }, [widgetData])

    useEffect(() => {
        var tmpReferenceList = [];
        tmpReferenceList.push({ label: "Select reference...", value: "" })
            for (const key in siteInfo.styleList) {
                if (siteInfo.styleList[key].referenceType == 'element') {
                    tmpReferenceList.push({ label: "All " + key.replaceAll("#", "") + " elements", value: key })
                } else {
                    tmpReferenceList.push({ label: key, value: key })
                }                
            }
            setReferenceList(tmpReferenceList);
    }, [])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Class Names</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    {widgetObj.elementName != "icon" ?
                        <>
                            <div className="fg-pb-subsection-instruction">Select style reference to use. If you don't see your style reference in the list then type a new reference name and press "ENTER" key.</div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                <div>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "5px" }}>
                                        <div>
                                            <Autocomplete
                                                disablePortal
                                                size="small"
                                                id="combo-box-demo"
                                                options={referenceList}
                                                sx={{ display: "inline-block", width: "100%", margin: "0px", padding: "0px" }}
                                                onChange={(e, newValue) => { handleOnAttachReference(e, newValue.value); }}
                                                renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                                        <input id="style_name" type="text" {...params.inputProps} style={{ padding: "2px", minHeight: "18px !important", border: "1px solid #ced4da", borderRadius: "0.2rem", fontSize: ".875rem", width: "100%" }} placeholder="Select reference or type new reference" onKeyDown={(e) => handleOnKeyDown(e)} />
                                                    </div>
                                                )}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Classes</div>
                                <div>
                                    <div>
                                        {selectedReferenceList.map((reference, index) => (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", gridGap: "5px",border:"1px solid grey",borderRadius:"4px",padding:"2px 6px 2px 6px",marginBottom:"5px" }}>
                                                <div style={{ cursor: "pointer" }} onClick={(e) => {handleOnReferenceSelected(reference);}}>{reference}</div>
                                                <div style={{ cursor: "pointer",textAlign:"right" }} onClick={(e) => {detachReference(e, reference);}}><DeleteForeverIcon /></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                            <div className="fg-pb-property-sub-label">Icon</div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                                <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.className || ''} onChange={(e) => { handleOnClassNameChange(e.target.value); }} /></div>
                                <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                                <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon /></div>
                            </div>
                        </div>
                    }
                    <div className="fg-pb-property-sub-label">Custom Class Name</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.customClassName != undefined ? widgetObj.customClassName : ""} onChange={(e) => { handleOnCustomClassNameChange(e.target.value); }}  />
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssClassNameCtrl;


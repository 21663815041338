import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import NewsReel from '../components/news/NewsReel';
import AcceptAccountInviteForm from '../components/loginforms/AcceptAccountInviteForm';
import { BAFetchData } from '../customhooks/useBAFetch';

function AcceptInvite() {
    const navigate = useNavigate();
    let params = useParams();

    const [primaryKey, setPrimaryKey] = useState(params.iid);
    const [formData, setFormData] = useState({ userName: null, password: null });
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [wlInfo, setWlInfo] = useState(null);

    async function getWLInfo() {
        var path = document.location.href;
        var domain = window.location.protocol + '//' + window.location.host;
        if (path.indexOf('localhost:3000') > -1) {
            domain = 'whitelabel1';
        } else if (path.indexOf('localhost') > -1) {
            domain = document.getElementById('base').href.replace('https://localhost', '').replace('http://localhost', '');
            domain = domain.replaceAll('\/', '');
        }

        var tmpWlInfo = await BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "security/businessaccount/getwhitelabelinfo?wlappdomain=" + domain,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        localStorage.setItem('wl-info', JSON.stringify(tmpWlInfo.data));
        setWlInfo(tmpWlInfo.data);
    }

    useEffect(() => {
        if (wlInfo == null) {
            console.log('get wlInfo', wlInfo);
            getWLInfo();
        }
    }, []);
    

    return (
        <>
            <div style={{ height:"100%",width:"100%",position:"fixed" }}>
                <div style={{ display:"grid",gridTemplateColumns:"400px 1fr",gridGap:"0px", height:"100%"}}>
                    <div style={{padding:"32px",marginTop:"100px"}}>
                        <div className="segment">
                            <div style={{ marginBottom: "40px", textAlign: "center" }}>
                                {wlInfo != null ?
                                    (wlInfo.wlLoginLogo != null ?
                                        <img src={wlInfo.wlLoginLogo}></img>
                                        :
                                        <div className="fgd-brand-nameonly" style={{ color: "#5C9EDF", paddingTop: "1px" }}><span className={wlInfo.wlIconLogo} style={{ marginRight: "10px" }}></span>{wlInfo.wlName}</div>
                                    )
                                    : null}
                            </div>
                            <AcceptAccountInviteForm iid={primaryKey}></AcceptAccountInviteForm>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D9DADF" }}>
                        <div style={{ padding: "24px", borderRadius: "0px", marginTop: "100px", maxWidth: "400px", backgroundColor: "#ffffff", marginLeft: "auto", marginRight: "auto" }}>
                            <NewsReel></NewsReel>
                        </div>
                    </div>

                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AcceptInvite;

